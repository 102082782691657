import React, { useState } from "react";
import PagesIndex from "../../container/PagesIndex";


const ImageViewExtension = ({ imageUrl = "", imageTitle = "", openSingleImageModal = "", setImageData = {} }) => {
  const ImageViewExternsion = imageUrl?.split(".").pop();
  // console.log(ImageViewExternsion, 'ImageViewExternsion', imageUrl);

  return (
    <>
      {ImageViewExternsion === "pdf" || ImageViewExternsion === "PDF" ? (
        <a
          className="upload-document-img"
          href={`${PagesIndex.ImageURL}${imageUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PagesIndex.Svg.PdfImage}
            alt={`PDF Document: ${imageUrl}`}
          />
        </a>
      ) : ImageViewExternsion === "jpg" ||
        ImageViewExternsion === "jpeg" ||
        ImageViewExternsion === "JPG" ||
        ImageViewExternsion === "PNG" ||
        ImageViewExternsion === "png" ? (
        <img
          src={`${PagesIndex.ImageURL}${imageUrl}`}
          className="user-prof-img nft-image"
          alt=""
          style={{
            cursor: "pointer",
            height: "60px",
            width: "80px",
            display: "table-column",
            borderRadius: "6px",
          }}

          onClick={() => {
            openSingleImageModal();
            setImageData({
              imageUrl: imageUrl,
              imageTitle: imageTitle
            })
          }}
        />
      ) : (
        <a
          className="upload-document-img"
          href={`${PagesIndex.ImageURL}${imageUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PagesIndex.Png.nft}
            alt={`Other Document: ${imageUrl}`}
          />
        </a>
      )}
    </>
  );
};

export default ImageViewExtension;
