import React, { useEffect, useState } from 'react'
import PagesIndex from '../../../PagesIndex';
import Index from '../../../Index';
import moment from 'moment';

export const LeafIdea = () => {
    // state declaration
    const [loading, setLoading] = useState(false)
    const [pageData, setPageData] = useState([])
    const [search, setSearch] = useState('')
    const [filterData, setFilteredData] = useState([])

    // const declaration
    const navigate = PagesIndex.useNavigate();
    const { state } = PagesIndex.useLocation()

    // function declaration
    const handleSearch = async (e) => {
        setSearch(e.target.value)
    }
    // Filter fetched data
    useEffect(() => {
        const filtered = state?.row?.filter((item) => {
            // debugger;
            return !search
                ? item
                : item?.userId?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
                item?.title?.toLowerCase().includes(search.toLowerCase()) ||
                item?.userId?.userName?.toLowerCase().includes(search.toLowerCase()) ||
                item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
                // item..toLowerCase().includes(search.toString())||
                new Date(item?.createdAt)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase())
        });
        if (filtered) {
            setFilteredData(filtered);
        } else {
            setFilteredData([]);
        }
    }, [state, search]);
    return (
        <>
            <Index.Box className="page-content-box">
                <Index.Box className="barge-common-box">
                    <Index.Box className="title-header">
                        <Index.Box className="title-header-flex res-title-header-flex">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    Leaf Idea
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="d-flex align-items-center res-set-search">
                                <PagesIndex.Search className="search">
                                    <PagesIndex.StyledInputBase
                                        placeholder="Search"
                                        inputProps={{ "aria-label": "search" }}
                                        onChange={handleSearch}
                                    />
                                </PagesIndex.Search>

                                {/* <Index.Box className="common-button blue-button res-blue-button">
                     <PagesIndex.Link
                       to="/admin/new-member/add"
                       className="no-text-decoration"
                     >
                       {" "}
                       <Index.Button variant="contained" disableRipple>
                         Add Member
                       </Index.Button>
                     </PagesIndex.Link>
                   </Index.Box> */}
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="table-box table_scroll">
                        <Index.TableContainer
                            component={Index.Paper}
                            // className="table-container"
                            className='episap-table-container'

                        >
                            <Index.Table
                                stickyHeader aria-label="sticky table"
                                // className="table-design-main barge-table"
                                className='episap-table-main'
                            >
                                <Index.TableHead>
                                    <Index.TableRow>
                                        <Index.TableCell align="left">S.No. </Index.TableCell>
                                        <Index.TableCell align="left">Leaf Idea Title </Index.TableCell>
                                        <Index.TableCell align="left">Leaf Idea Image </Index.TableCell>
                                        <Index.TableCell align="left">Name </Index.TableCell>
                                        <Index.TableCell align="left">Username </Index.TableCell>
                                        <Index.TableCell align="left">Email</Index.TableCell>
                                        {/* <Index.TableCell align="left">Leaf Idea</Index.TableCell> */}
                                        <Index.TableCell align="left">CreateDate</Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                {/* <Index.Box> */}
                                <Index.TableBody className="table-body">
                                    {!loading ? pageData.length ? pageData.map((row) => {
                                        return (
                                            <Index.TableRow className="">

                                                <Index.TableCell align="left">{row?.sNo}</Index.TableCell>

                                                <Index.TableCell align="left">{row.title}</Index.TableCell>
                                                <Index.TableCell align="left">
                                                    <img
                                                        src={row?.imageAddress
                                                        }
                                                        alt="Image Preview"
                                                        style={{
                                                            height: "60px",
                                                            width: "80px",
                                                            display: "table-column",
                                                        }}
                                                    />
                                                </Index.TableCell>
                                                <Index.TableCell align="left">
                                                    {row?.userId?.fullName}
                                                </Index.TableCell>
                                                <Index.TableCell align="left">  {row?.userId?.userName}</Index.TableCell>
                                                <Index.TableCell align="left">  {row?.userId?.email}</Index.TableCell>
                                                {/* <Index.TableCell align="left" onClick={() => navigate("/admin/leaf-idea")}>{row?.leafIdea ? row?.leafIdea.length : 0}</Index.TableCell> */}
                                                <Index.TableCell align="left">   {moment(row?.createdAt).format("DD/MM/YYYY")} </Index.TableCell>
                                            </Index.TableRow>
                                        )
                                    }) :
                                        <PagesIndex.NoRecordFound colSpan={12} />
                                        : <PagesIndex.PageLoading colSpan={12} />}

                                </Index.TableBody>
                                {/* </Index.Box> */}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>
                    <Index.Box className="pagination-design flex-end">
                        <Index.Stack spacing={2}>
                            <PagesIndex.Pagination
                                fetchData={filterData}
                                setPageData={setPageData}
                                pageData={pageData}
                            />
                        </Index.Stack>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

        </>

    )
}
