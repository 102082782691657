import React from 'react'

export default function Inappropriate() {
    return (

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.0286 2.85715C24 2.85715 21.3143 4.8 20.4 7.65715L17.7714 9.31429C17.4286 9.54286 17.4286 10.0571 17.7714 10.2857L20.4 11.9429C21.3143 14.8 24 16.7429 27.0286 16.7429C30.8571 16.7429 34 13.6 34 9.77143C34 6 30.8571 2.85715 27.0286 2.85715ZM30 11.4286H24.5143C24.1714 11.4286 23.9429 11.1429 23.9429 10.8571C23.9429 10.5714 24.1714 10.2857 24.5143 10.2857H30C30.3429 10.2857 30.5714 10.5714 30.5714 10.8571C30.5714 11.1429 30.2857 11.4286 30 11.4286ZM30 9.14286H24.5143C24.1714 9.14286 23.9429 8.85715 23.9429 8.57143C23.9429 8.28572 24.1714 8 24.5143 8H30C30.3429 8 30.5714 8.28572 30.5714 8.57143C30.5714 8.85715 30.2857 9.14286 30 9.14286Z" fill="black" />
            <path d="M21.1428 29.8857L18.5143 28.2286C17.6 25.3714 14.9143 23.4286 11.8857 23.4286C8.05713 23.4286 4.91428 26.5714 4.91428 30.4C4.91428 34.2286 8.05713 37.3714 11.8857 37.3714C14.9143 37.3714 17.6 35.4286 18.5143 32.5714L21.1428 30.8572C21.5428 30.6286 21.5428 30.1143 21.1428 29.8857ZM12.0571 33.7714C11.3143 33.7714 11.3143 32.6286 12.0571 32.6286C12.3428 32.6286 12.6286 32.9143 12.6286 33.2C12.6286 33.5429 12.3428 33.7714 12.0571 33.7714ZM12.6286 30.8572C12.6286 31.1429 12.3428 31.4286 12.0571 31.4286C11.7714 31.4286 11.4857 31.1429 11.4857 30.8572V27.4286C11.4857 27.1429 11.7714 26.8572 12.0571 26.8572C12.3428 26.8572 12.6286 27.1429 12.6286 27.4286V30.8572Z" fill="black" />
            <path d="M14.8571 13.7714L12.1714 13.1429V11.0857C12.6286 10.5143 12.9143 9.82858 13.0286 9.08572C13.7143 8.85715 14 8.17144 14 7.42858C14.0571 6.68572 13.7714 6.28572 13.4857 6.05715C13.8286 0.285724 5.02856 0.400011 5.37142 6.05715C5.08571 6.28572 4.79999 6.62858 4.85714 7.42858C4.91428 8.17144 5.14285 8.85715 5.82856 9.08572C5.94285 9.94287 6.28571 10.7429 6.91428 11.3143V13.1429L4.05714 13.8286C2.62856 14.2857 1.65714 15.7143 1.65714 17.2572V21.6C1.65714 21.9429 1.94285 22.1714 2.22856 22.1714H16.6857C17.0286 22.1714 17.2571 21.9429 17.2571 21.6V17.2572C17.3143 15.6 16.3429 14.2286 14.8571 13.7714ZM6.28571 7.94287H6.22856C6.05713 7.77144 5.99999 7.08572 6.05714 6.9143C6.34285 6.85715 6.62856 6.62858 6.57142 6.22858C5.99999 1.77144 12.9714 1.54287 12.3429 6.22858C12.2857 6.57144 12.5143 6.85715 12.8571 6.85715C12.8571 6.85715 12.9143 6.9143 12.9143 7.25715C12.8571 7.94287 12.6857 7.94287 12.6857 7.94287C12.3429 7.82858 11.9428 8.1143 11.9428 8.5143C11.9428 9.25715 11.6571 9.94287 11.1428 10.5143C9.54285 12.1714 6.97142 10.8572 6.97142 8.5143C6.97142 8.1143 6.62856 7.88572 6.28571 7.94287ZM11.0286 12V13.3714C10.2857 14.3429 9.02856 14.5143 8.05714 13.3714V12.1143C9.02856 12.5143 10.1143 12.5143 11.0286 12ZM16.1714 20.9714H14.5143V17.0857C14.5143 16.8 14.2286 16.5143 13.9428 16.5143C13.6 16.5143 13.3714 16.8 13.3714 17.0857V20.9714H5.99999V17.0857C5.99999 16.8 5.77142 16.5143 5.42856 16.5143C5.08571 16.5143 4.85714 16.8 4.85714 17.0857V20.9714H2.85714V17.2C2.85714 16.1143 3.54285 15.1429 4.39999 14.8572L7.31428 14.1714C8.68571 15.7714 10.6857 15.4857 11.8286 14.1714L14.5714 14.8C15.4857 15.0857 16.1714 16.0572 16.1714 17.1429V20.9714Z" fill="black" />
            <path d="M9.4857 16.5143C9.14285 16.5143 8.91428 16.7429 8.91428 17.0857V17.3714C8.91428 17.7143 9.14285 17.9429 9.4857 17.9429C9.82856 17.9429 10.0571 17.7143 10.0571 17.3714V17.0857C10.0571 16.7429 9.77142 16.5143 9.4857 16.5143Z" fill="black" />
            <path d="M9.4857 18.6286C9.14285 18.6286 8.91428 18.8571 8.91428 19.2V19.4857C8.91428 19.8286 9.14285 20.0571 9.4857 20.0571C9.82856 20.0571 10.0571 19.8286 10.0571 19.4857V19.2C10.0571 18.9143 9.77142 18.6286 9.4857 18.6286Z" fill="black" />
            <path d="M35.8857 29.9429L33.1428 29.2571V27.1429C33.6 26.5714 33.8857 25.8857 34 25.1429C35.1428 24.7429 35.3143 22.6857 34.5143 22.1143C34.8571 16.3429 26.0571 16.4571 26.4 22.1143C26.1143 22.3429 25.8286 22.6857 25.8857 23.4857C25.9428 24.2286 26.1714 24.9143 26.8571 25.2C26.9714 26.0571 27.3143 26.8571 27.9428 27.4286V29.2571L25.0857 29.9429C23.6571 30.4 22.6857 31.8286 22.6857 33.3714V37.7143C22.6857 38 22.9143 38.2857 23.2571 38.2857H37.7143C38 38.2857 38.2857 38 38.2857 37.7143V33.3714C38.2857 31.7714 37.3143 30.4 35.8857 29.9429ZM27.7714 24.2286C27.6 24.1143 27.4286 24.0571 27.2571 24.1143C27.0286 24.1143 26.9143 23.2 27.0286 23.0857C27.3143 23.0286 27.5428 22.8 27.5428 22.4C26.9714 17.9429 33.9428 17.7143 33.3143 22.4C33.2571 22.7429 33.4857 23.0286 33.7714 23.0286C33.7714 23.0286 33.8286 23.1429 33.8286 23.4286C33.7714 24.1143 33.6 24.1143 33.6 24.1143C33.2571 24 32.8571 24.2857 32.8571 24.6857C32.8571 25.4286 32.5714 26.1714 32.0571 26.6857C30.4571 28.3429 27.8857 27.0857 27.8857 24.6857C28 24.5143 27.8857 24.3429 27.7714 24.2286ZM32 28.1714V29.5429C31.2571 30.5143 30 30.6857 29.0286 29.5429V28.2857C30 28.6857 31.0857 28.6286 32 28.1714ZM37.1428 37.1429H35.4286V33.2571C35.4286 32.9714 35.2 32.6857 34.8571 32.6857C34.5714 32.6857 34.2857 32.9714 34.2857 33.2571V37.1429H27.0286V33.2571C27.0286 32.9714 26.7428 32.6857 26.4571 32.6857C26.1714 32.6857 25.8857 32.9714 25.8857 33.2571V37.1429H23.8857V33.3714C23.8857 32.2857 24.5714 31.3143 25.4286 31.0286L28.2857 30.3429C29.6571 31.8857 31.6571 31.7143 32.8 30.3429L35.5428 30.9714C36.4571 31.2571 37.1428 32.2286 37.1428 33.3143V37.1429Z" fill="black" />
            <path d="M30.4571 32.6857C30.1143 32.6857 29.8857 32.9143 29.8857 33.2572V33.5429C29.8857 33.8857 30.1143 34.1143 30.4571 34.1143C30.8 34.1143 31.0286 33.8857 31.0286 33.5429V33.2572C31.0286 32.9143 30.7429 32.6857 30.4571 32.6857Z" fill="black" />
            <path d="M30.4571 34.8C30.1143 34.8 29.8857 35.0286 29.8857 35.3714V35.6572C29.8857 36 30.1143 36.2286 30.4571 36.2286C30.8 36.2286 31.0286 36 31.0286 35.6572V35.3714C31.0286 35.0857 30.7429 34.8 30.4571 34.8Z" fill="black" />
        </svg>

    )
}
