import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  socialPostListAction,
  activeDeactiveReportedPostAction,
  deleteSocialPostAction,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";

function SocialMediaList() {
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [socialPostList, setSocialPostList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [postId, setPostId] = useState();
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getSocialPost = () => {
    socialPostListAction(adminToken).then((res) => {
      setSocialPostList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSocialPost();
  }, []);

  // Active deactive function declaration
  const handleSwitchChange = (event, id) => {
    setLoading(true);
    activeDeactiveReportedPostAction(
      { postId: id, isActive: event.target.checked },
      adminToken,
      navigate
    ).then((res) => {
      if (res?.status == 200) {
        Index.toast.success(res?.message);
        getSocialPost();
      }
    });
  };

  console.log(socialPostList, "socialPostList");

  // Filter fetched data
  useEffect(() => {
    const filtered = socialPostList?.filter((item) => {
      return !search
        ? item
        : item?.uploadBy?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.tags?.toLowerCase().includes(search.toLowerCase()) ||
            item?.category?.some((it) =>
              it?.categoryName.toLowerCase().includes(search.toLowerCase())
            ) ||
            item?.uploadBy?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.uploadBy?.email
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [socialPostList, search]);

  //Delete post
  const handleDeletePost = () => {
    deleteSocialPostAction({ postId: postId }, adminToken).then((res) => {
      if (res.status == 200) {
        getSocialPost();
        handleDeleteModalClose();
      }
    });
  };

  const handleDeleteModalOpen = (id) => {
    setDeleteModalOpen(!deleteModalOpen);
    setPostId(id);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setPostId();
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Social Media Posts
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Image </Index.TableCell>
                    <Index.TableCell align="left">Title </Index.TableCell>
                    <Index.TableCell align="left">Posted By </Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">Category </Index.TableCell>
                    <Index.TableCell align="left">Tags </Index.TableCell>
                    <Index.TableCell align="left">Likes </Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo} className="">
                            <Index.TableCell>{row?.sNo}</Index.TableCell>

                            <Index.TableCell align="left">
                              <img
                                src={`${PagesIndex?.ImageURL}${row?.uploadPostImage}`}
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                }}
                              />
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row.title}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.uploadBy?.userName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.uploadBy?.email}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.category
                                ? Array.prototype.map
                                    .call(row?.category, function (item) {
                                      return item?.categoryName;
                                    })
                                    .join(", ")
                                : ""}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.tags}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.likes?.length}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>

                            <Index.TableCell className="action-btn-icon">
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip
                                  title={`${
                                    row.isActive ? "Deactive" : "Active"
                                  }`}
                                >
                                  <Index.Switch
                                    size="small"
                                    className="toggel-switch"
                                    {...PagesIndex.label}
                                    checked={row.isActive ? true : false}
                                    onChange={(event) => {
                                      handleSwitchChange(event, row._id);
                                    }}
                                  />
                                </Index.Tooltip>

                                <Index.Tooltip title="View">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      navigate(
                                        `/admin/social-detail/${row?._id}`,
                                        { state: { type: "SocialMedia" } }
                                      )
                                    }
                                  >
                                    <Index.Visibility />
                                  </Index.Button>
                                </Index.Tooltip>

                                <Index.Tooltip title="Delete">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      handleDeleteModalOpen(row?._id)
                                    }
                                  >
                                    <Index.DeleteIcon />
                                  </Index.Button>
                                </Index.Tooltip>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.DeleteModal
        deleteOpen={deleteModalOpen}
        handleDeleteRecord={handleDeletePost}
        handleDeleteClose={handleDeleteModalClose}
        deleteMessage={"Are you sure? Do you really want to delete this post?"}
      />
    </>
  );
}

export default SocialMediaList;
