import React, { useEffect, useState } from 'react';
import UsersReport from './UsersReport';
import PostReport from './PostReport';
import NFTReport from './NFTReport';
import PagesIndex from '../../../PagesIndex';
import Index from '../../../Index';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 120,
        },
    },
};

export default function DashboardFilterTable() {
    const [weeklyData, setWeeklyData] = useState("Weekly")
    const [filterTable, setfilterTable] = useState("Users")
    const [search, setSearch] = useState("");
    const [tableTitle, setTableTitle] = useState("");
    const [downloadExcel, setDownloadExcel] = useState(false);

    const filtervalue = [{
        id: 1, name: "Weekly"
    }, {
        id: 2, name: "Monthly"
    }, {
        id: 3, name: "Quarterly"
    }, {
        id: 4, name: "Yearly"
    }
    ]
    const filterTableDropdown = [{
        id: 1, name: "Users", value: "Users"
    }, {
        id: 2, name: "NFTs", value: "Nft"
    }, {
        id: 3, name: "Social Posts", value: "Post"
    }
    ]

    const handleSearch = async (e) => {
        setSearch(e.target.value.trim());
    };

    useEffect(() => {
        if (filterTable == "Nft") {
            setTableTitle("NFTs");
        } else if (filterTable == "Post") {
            setTableTitle("Social Posts");
        } else {
            setTableTitle("Users");
        }
    }, [filterTable])

    return (
        <>
            <Index.Box className="page-content-box">
                <Index.Box className="episap-common-box">
                    <Index.Box className="title-header">
                        <Index.Box className="title-heading res-title-header-flex">
                            <Index.Box className="table-filter-cont">
                                <Index.Box className="dashboard-filter">
                                    <Index.Box className="title-main">
                                        <Index.Typography
                                            variant="p"
                                            component="p"
                                            className="page-title mb-0"
                                        >
                                            {tableTitle}
                                        </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className='filter-cont'>
                                        <Index.Select
                                            id="filled-hidden-label-normal"
                                            className='filter-table-cont'
                                            variant="filled"
                                            displayEmpty
                                            name="filterTable"
                                            value={filterTable}
                                            onChange={(event) => {
                                                setfilterTable(event.target.value);

                                            }}
                                            input={<Index.OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {filterTableDropdown?.map((item) => (
                                                <Index.MenuItem
                                                    key={item.id}
                                                    value={item.value}
                                                >
                                                    {item.name}
                                                </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                        <Index.Select
                                            id="filled-hidden-label-normal"
                                            className='filter-data-cont'
                                            variant="filled"
                                            displayEmpty
                                            name="filterData"
                                            value={weeklyData}
                                            onChange={(event) => {
                                                setWeeklyData(event.target.value);
                                            }}
                                            input={<Index.OutlinedInput />}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {filtervalue.map((item) => (
                                                <Index.MenuItem
                                                    key={item.id}
                                                    value={item.name}

                                                >
                                                    {item.name}
                                                </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="d-flex align-items-center res-set-search">
                                    <PagesIndex.Search className="searchbar">
                                        <PagesIndex.StyledInputBase
                                            placeholder="Search"
                                            inputProps={{ "aria-label": "search" }}
                                            onChange={handleSearch}
                                        />
                                    </PagesIndex.Search>

                                    <Index.Button
                                        variant="contained"
                                        className="add-user-btn"
                                        onClick={() => {
                                            setDownloadExcel(true)
                                        }}
                                    >
                                        Export
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box>
                                {
                                    filterTable == "Users" ? <UsersReport filter={{ type: filterTable, timeframe: weeklyData }} search={search} weeklyData={weeklyData} downloadExcel={downloadExcel} setDownloadExcel={setDownloadExcel} />
                                        : filterTable == "Nft" ? <NFTReport filter={{ type: filterTable, timeframe: weeklyData }} search={search} weeklyData={weeklyData} downloadExcel={downloadExcel} setDownloadExcel={setDownloadExcel} />
                                            : <PostReport filter={{ type: filterTable, timeframe: weeklyData }} search={search} weeklyData={weeklyData} downloadExcel={downloadExcel} setDownloadExcel={setDownloadExcel} />
                                }
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}
