import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import { getSocialPostDetailAction } from "../../../../redux/admin/action";
import ImageExtension from "../NFTList/ImageExtension";

const SocialDetail = () => {
  // state declaration
  const { id } = PagesIndex.useParams();
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [openNftDescription, setOpenNftDescription] = useState(false);
  const [nftDescription, setNftDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageData, setImageData] = useState({});

  const handleBack = () => {
    // navigate("/admin/social-list");
    navigate(-1);
  };

  const [showFullDescription, setShowFullDescription] = useState(false);
  const description = data?.description;

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getSocialDetails = (socialId) => {
    const postType = state?.type === "SocialMedia" ? "SocialMedia" : "";
    const data = {
      type: postType,
      id: socialId
    }
    getSocialPostDetailAction(data, adminToken, navigate).then(
      (res) => {
        setData(res);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getSocialDetails(id);
  }, [id]);

  // community modal
  const handleClickOpenDescription = (title, description) => {
    setOpenNftDescription(true);
    setDescriptionTitle(title);
    setNftDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenNftDescription(false);
    setDescriptionTitle("");
    setNftDescription("");
  };

  const formatData = (data) => {
    const prefix = data?.substring(0, 4);
    const lastTwoCharacters = data?.substring(data?.length - 4);
    const maskedData = `${prefix}...${lastTwoCharacters}`;
    return maskedData;
  };

  // Open single image
  const openSingleImageModal = () => {
    setImageModalOpen(true);
  };
  const closeSingleImageModal = () => {
    setImageModalOpen(false);
    setImageData({});
  };

  console.log(data, "pageData", state, "state");

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-common-box">
        <Index.Grid container className="mb-10">
          <Index.Box
            className="user-detail-cont bg-right"
            sx={{ justifyContent: "center" }}
          >
            {!loading ? (
              <Index.Grid container spacing={1}>
                <Index.Grid item xs={12}>
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex justify-content-between">
                      <Index.Box className="title-main">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title"
                        >
                          Social Media Post Details
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="form-back-btn">
                        <Index.Button
                          variant="contained"
                          className="back-btn"
                          type="back"
                          onClick={() => handleBack()}
                        >
                          Back
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={6} md={2} lg={1.5}>
                  <Index.Box className="nft-detail-img">
                    <Index.Box className="user-detail-img">
                      {/* <img
                        className="user-prof-img nft-image"
                        src={
                          data?.uploadPostImage
                            ? `${PagesIndex.ImageURL}${data?.uploadPostImage}`
                            : PagesIndex.Jpg.dummyImage
                        }
                        alt=""
                      /> */}
                      <ImageExtension
                        imageUrl={data?.uploadPostImage}
                        imageTitle="Display Image"
                        openSingleImageModal={openSingleImageModal}
                        setImageData={setImageData}
                      />
                    </Index.Box>
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title page-sub-title mb-0 text-center"
                      >
                        Post Image
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={12} md={10} lg={10.5}>
                  <Index.Box className="admin-nft-detail height-auto">
                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Title :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.title ? data?.title : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Name :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.uploadBy?.fullName
                          ? data?.uploadBy?.fullName
                          : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Username :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.uploadBy?.userName
                          ? data?.uploadBy?.userName
                          : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Email :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.uploadBy?.email ? data?.uploadBy?.email : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Created Date :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.createdAt
                          ? moment(data?.createdAt).format("DD/MM/YYYY")
                          : "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        URL :
                      </Index.Typography>

                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        <Index.Tooltip title={data?.url}>
                          {data?.url ? (
                            <a
                              className="open-link"
                              href={`${data?.url}`}
                              target="_blank"
                            >
                              Open Link
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Index.Tooltip>
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Wallet Address :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        <Index.Tooltip
                          placement="top"
                          title={
                            data?.creatorAddress &&
                              data?.creatorAddress != "undefined"
                              ? data?.creatorAddress
                              : "-"
                          }
                        >
                          {data?.creatorAddress &&
                            data?.creatorAddress != "undefined"
                            ? formatData(data?.creatorAddress)
                            : "-"}
                        </Index.Tooltip>
                      </Index.Typography>
                    </Index.Box>

                    {/* <Index.Box className="admin-nft-cont">
                    <Index.Typography variant="p" component="p" className="user-detail-title">
                      NFT Price :
                    </Index.Typography>
                    <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                      {state?.row?.price}
                    </Index.Typography>
                  </Index.Box> */}

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Likes :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.likes?.length ? data?.likes?.length : 0}
                      </Index.Typography>
                    </Index.Box>

                    {/* <Index.Box className="admin-nft-cont">
                    <Index.Typography variant="p" component="p" className="user-detail-title">
                      NFT Status :
                    </Index.Typography>
                    <Index.Typography variant="p" component="p" className="user-detail-subtitle"
                      style={{
                        color:
                          state?.row?.masterAction == "Approved"
                            ? "green"
                            : state?.row?.masterAction == "Rejected"
                              ? "red"
                              : "blue",
                      }}>
                      {!state?.row?.masterAction
                        ? "Pending"
                        : state?.row?.masterAction}

                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="admin-nft-cont">
                    <Index.Typography variant="p" component="p" className="user-detail-title">
                      URL :
                    </Index.Typography>
                    <Index.Tooltip title={state?.row?.url}>
                      {" "}
                      {state?.row?.url &&
                        (state?.row.url?.length > 15
                          ? state?.row.url.slice(0, 15) + "..."
                          : state?.row.url)}
                    </Index.Tooltip>
                  </Index.Box> */}

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Category :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.category
                          ? Array.prototype.map
                            .call(data?.category, function (item) {
                              return item?.categoryName;
                            })
                            .join(", ")
                          : ""}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Sub Category :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.sub_category}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Tags :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        {data?.tags}
                        {/* ? Array.prototype.map
                                  .call(data?.tags, function (item) {
                                    return item?.tagName;
                                  })
                                  .join(", ")
                                : ""} */}
                      </Index.Typography>
                    </Index.Box>

                    {/* <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title"
                      >
                        Description : &nbsp;
                      </Index.Typography>
                      <Index.Tooltip
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleClickOpenDescription(
                            "Social Post",
                            data?.description
                          )
                        }
                      >
                        {"  "}
                        {data?.description &&
                          (data?.description?.length > 25
                            ? data?.description.slice(0, 25) + "..."
                            : data?.description)}
                      </Index.Tooltip>
                    </Index.Box> */}
                  </Index.Box>

                  {/* New */}
                  <Index.Box className="admin-nft-detail height-auto colmn-1">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title"
                    >
                      Description :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-descr"
                    >
                      {description && (
                        <>
                          {showFullDescription ? (
                            <span>{description}</span>
                          ) : (
                            <span>
                              {description.length > 326
                                ? description.slice(0, 326) + "..."
                                : description}
                            </span>
                          )}
                          {description.length > 326 && (
                            <Index.Button
                              className="read-btn ml-5"
                              onClick={toggleDescription}
                            >
                              {showFullDescription ? " Read Less" : " Read More"}
                            </Index.Button>
                          )}
                        </>
                      )}
                    </Index.Typography>
                  </Index.Box>
                  {/* End New */}

                </Index.Grid>
              </Index.Grid>
            ) : (
              <Index.Box className="single-page-loader">
                <PagesIndex.PageLoading />
              </Index.Box>
            )}
          </Index.Box>
        </Index.Grid>
      </Index.Box>
      {/* Nft description show modal start */}
      <Index.Modal
        open={openNftDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle} Description
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {nftDescription ? nftDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* Nft description modal end */}

      {/* Single Image show Popup */}
      <Index.Modal
        open={imageModalOpen}
        // onClose={closeSingleImageModal}
        className="category-modal"
      >
        <PagesIndex.SingleImageModal
          handleClose={closeSingleImageModal}
          imageData={imageData}
        />
      </Index.Modal>

    </Index.Box>
  );
};

export default SocialDetail;
