import React from 'react'
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';
import { OtpSendAction } from '../../../../redux/admin/action';
import { ariaLabel, style } from './UserModal.Style';


export default function OtpSend({ handleVerifyOtp, handleClose }) {

    // const declaration
    const userData = JSON.parse(localStorage.getItem("addUserData"));


    // Initital values
    let initialValues = {
        email: userData.email || '',
        mobileNumber: userData.mobileNumber || '',
    };

    // function declaration
    const handleFormSubmit = (values) => {
        OtpSendAction(values).then((res) => {
            if (res.status == 200) {
                handleVerifyOtp(res?.data[0]?._id)
            }
        })

    }
    const formattedPhoneNumber = `${userData.countryCode}${userData.mobileNumber}`;

    return (

        <Index.Box className="page-content-box">
            <Index.Box className="episap-modal-box">
                <Index.Box className="episap-modal-header">
                    <Index.Box className="edit-modal-circle-main">
                        <Index.Box className="title-header">
                            <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                                <Index.Box className="title-main">
                                    <Index.Typography
                                        variant="p"
                                        component="p"
                                        className="page-title"
                                    >
                                        Profile Verification
                                    </Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="user-circle-img" onClick={handleClose}>
                            <img
                                src={PagesIndex.Svg.whiteclose}
                                className="user-circle-img"
                                alt="icon"
                            />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="admin-design-div login-input-design-div">
                    <PagesIndex.Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                    // validationSchema={PagesIndex.UserVerifyOtpSend}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Index.Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <Index.Box className='register-all-inputs'>
                                    <Index.Typography variant="p" component='p' className='page-sub-title mb-5'>
                                        We will send you an One Time Password on Email.
                                    </Index.Typography>
                                    {/* <Index.Box
                                        component="form"
                                        autoComplete="off"
                                        className="reg-input"
                                    >
                                        <Index.Typography className="re-input-label" variant="label">Enter Mobile Number</Index.Typography>
                                        <Index.Input inputProps={ariaLabel}
                                            name='mobileNumber'
                                            readOnly
                                            // onChange={handleChange}
                                            value={formattedPhoneNumber}

                                            // onChange={(e) => setFieldValue("mobileNumber", e.target.value)}
                                            error={Boolean(

                                                errors.mobileNumber && touched.mobileNumber
                                            )} />
                                        <span className="error-text">{errors.mobileNumber && errors.mobileNumber}</span>
                                    </Index.Box> */}
                                    <Index.Box
                                        component="form"
                                        autoComplete="off"
                                        className="reg-input"
                                    >
                                        <Index.Typography className="re-input-label" variant="label">Enter Email</Index.Typography>
                                        <Index.Input inputProps={ariaLabel}
                                            name='email'
                                            readOnly
                                            onChange={handleChange}
                                            value={values.email}
                                            // helperText={touched.email && errors.email}
                                            error={Boolean(
                                                errors.email && touched.email
                                            )}
                                        />
                                        <span className="error-text">{errors.email && errors.email}</span>
                                    </Index.Box>
                                    <Index.Box className='modal-footer-btn'>
                                        <Index.Button variant="contained" className="submit-btn" type='submit'>
                                            Get OTP
                                        </Index.Button>
                                    </Index.Box>
                                </Index.Box>


                            </Index.Stack>
                        )}
                    </PagesIndex.Formik>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}



