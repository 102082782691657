import React, { useEffect, useState } from "react";
import PagesIndex from "../../../container/PagesIndex";
import Index from "../../Index";

function Sidebar({ open, setOpen }) {
  const { adminProfileData } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const location = Index.useLocation();
  const [menuItem, setMenuItem] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const handleOpenCms = (index) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(null); // Close the currently open dropdown
    } else {
      setOpenDropdownIndex(index); // Open the clicked dropdown
    }
  };


  // Admin Menu
  const adminMenuItem = [
    {
      link: "Dashboard",
      url: "dashboard",
      isChild: false,
      icon: <Index.Svg.Dashboard />,
    },
    {
      link: "Users",
      url: "user-list",
      isChild: false,
      icon: <Index.Svg.UserList />,
      childUrl: ['user-detail'],
    },
    {
      link: "Community Wardens",
      url: "warden-list",
      isChild: false,
      icon: <Index.Svg.MasterWarden />,
      childUrl: ['warden-detail'],
    },
    {
      link: "Master Wardens",
      url: "master-warden-list",
      isChild: false,
      icon: <Index.Svg.MasterWarden />,
      childUrl: [''],
    },
    {
      link: "NFTs",
      url: "nft-list",
      isChild: false,
      icon: <Index.Svg.NftList />,
      childUrl: ['view-single-leaf', 'nft-details', 'idea-tree-view'],
    },
    // {
    //   link: "PFP Ideas",
    //   url: "pfp-ideas",
    //   isChild: false,
    //   icon: <Index.Svg.PfpList />,
    //   childUrl: ['pfp-details'],
    // },
    {
      link: "Expert Ideas",
      url: "expert-ideas",
      isChild: false,
      icon: <Index.Svg.PfpList />,
      childUrl: ['expert-nft-details'],
    },
    {
      link: "Social Media Posts",
      url: "social-list",
      isChild: false,
      icon: <Index.Svg.SocialList />,
      childUrl: ['social-detail'],
    },
    {
      link: "Reported Posts",
      url: "reported-posts",
      isChild: false,
      icon: <Index.Svg.ReportedPost />,
      childUrl: ['reported-social-detail'],
    },
    {
      link: "Inappropriate Request",
      url: "inappropriate-request",
      isChild: false,
      icon: <Index.Svg.Inappropriate />,
      childUrl: [],
    },
    {
      link: "Community Groups",
      url: "group-list",
      isChild: false,
      icon: <Index.Svg.CommunityList />,
      childUrl: ['group-member-list'],
    },
    {
      link: "KYC Requests",
      url: "kyc-request-list",
      isChild: false,
      icon: <Index.Svg.UserRequest />,
      childUrl: ['view-document'],
    },
    {
      link: "Transaction History",
      url: "transaction-history",
      isChild: false,
      icon: <Index.Svg.TransactionHistory />,
      childUrl: [],
    },
    {
      link: "Pending Transactions",
      url: "pending-transaction",
      isChild: false,
      icon: <Index.Svg.TransactionHistory />,
      childUrl: [],
    },
    {
      link: "Warnings",
      url: "warning-list",
      isChild: false,
      icon: <Index.Svg.BlockReport />,
      childUrl: [],
    },
    {
      link: "Referrals",
      url: "referrals",
      isChild: false,
      icon: <Index.Svg.BlockReport />,
      childUrl: [],
    },

    {
      link: "Setup",
      url: "setup",
      isChild: true,
      icon: <Index.Svg.SetupIcon />,
      childUrl: [],
      child: [
        {
          link: "Categories",
          url: "category-management",
          icon: <Index.Svg.CategoryList />,
        },
        // {
        //   link: "Tags",
        //   url: "tags-management",
        //   icon: <Index.Svg.TagsManagement />,
        // },
        // {
        //   link: "PFPs",
        //   url: "pfp-list",
        //   icon: <Index.Svg.PfpList />,
        // },
        // {
        //   link: "Invites",
        //   url: "invite-warden-list",
        //   icon: <Index.Svg.MasterWarden />,
        // },
        {
          link: "Contact Us Requests",
          url: "contact-us-list",
          icon: <Index.Svg.ContactList />,
        },
        {
          link: "Reasons",
          url: "report-list",
          icon: <Index.Svg.BlockReport />,
        },
        {
          link: "Reward Token",
          url: "reward-token",
          icon: <Index.Svg.TagsManagement />,
        },
        {
          link: "Setting Wallet Address",
          url: "setting-wallet-address",
          icon: <Index.Svg.TagsManagement />,
        },
      ],
    },
    {
      link: "CMS",
      url: "cms",
      isChild: true,
      icon: <Index.Svg.CmsIcon />,
      childUrl: [],
      child: [
        {
          link: "App T & C",
          url: "terms-and-condition",
          icon: <Index.Svg.TermsConditions />,
        },
        {
          link: "App P & P",
          url: "privacy-policy",
          icon: <Index.Svg.PrivacyPolicy />,
        },
        // {
        //   link: "Warden Steps",
        //   url: "warden-steps",
        //   icon: <Index.Svg.WardenStep />,
        // },
        {
          link: "Warden R & R",
          url: "warden-roles",
          icon: <Index.Svg.RoleResponsibility />,
        },
        {
          link: "NFT T & C",
          url: "nft-terms-and-condition",
          icon: <Index.Svg.NFTTerm />,
        },
        {
          link: "NFT P & P",
          url: "nft-privacy-policy",
          icon: <Index.Svg.NFTPrivacy />,
        },
        {
          link: "Contact Us",
          url: "contact-us",
          icon: <Index.Svg.ContactComm />,
        },
        {
          link: "About Us",
          url: "about-us",
          icon: <Index.Svg.AboutUsIcon />,
        },
      ],
    },
  ];

  // Warden Menu
  const wardenMenuItem = [
    {
      link: "Dashboard",
      url: "dashboard",
      isChild: false,
      icon: <Index.Svg.Dashboard />,
      childUrl: [],
    },
    {
      link: "Community Wardens",
      url: "warden-list",
      isChild: false,
      icon: <Index.Svg.MasterWarden />,
      childUrl: ['warden-detail'],
    },
    {
      link: "NFTs",
      url: "nft-list",
      isChild: false,
      icon: <Index.Svg.NftList />,
      childUrl: ['nft-details',],
    },
    {
      link: "Reported Posts",
      url: "reported-posts",
      isChild: false,
      icon: <Index.Svg.SocialList />,
      childUrl: ['reported-social-detail'],
    },
    // {
    //   link: "PFP Ideas",
    //   url: "pfp-ideas",
    //   isChild: false,
    //   icon: <Index.Svg.PfpList />,
    //   childUrl: ['pfp-details'],
    // },
    {
      link: "Expert Ideas",
      url: "expert-ideas",
      isChild: false,
      icon: <Index.Svg.PfpList />,
      childUrl: ['pfp-details', 'expert-nft-details'],
    },
    {
      link: "Warnings",
      url: "warning-list",
      isChild: false,
      icon: <Index.Svg.BlockReport />,
      childUrl: [],
    },
    {
      link: "Transaction History",
      url: "transaction-history",
      isChild: false,
      icon: <Index.Svg.TransactionHistory />,
      childUrl: [],
    }
  ];

  useEffect(() => {
    if (adminProfileData?.adminType == 1) {
      // Warden
      setMenuItem(wardenMenuItem);
    } else {
      // Admin
      setMenuItem(adminMenuItem);
    }
  }, [adminProfileData]);

  return (
    <Index.Box className="admin-main-sidebar">
      <Index.Box className="admin-sidebar-logo">
        <Index.Svg.CompanyLogo Light />
        <Index.Button
          onClick={() => {
            setOpen(open);
          }}
          className="sidebar-close-btn"
        >
          <Index.Svg.CloseIcon />
        </Index.Button>
      </Index.Box>

      <Index.Box className="admin-sidebar-list cus-scrollbar">
        <Index.List className="admin-sidebar-list-inner">
          {menuItem &&
            menuItem?.map((item, key) => {
              return (
                <>
                  {item?.isChild === false ? (
                    <Index.ListItem
                      key={key}
                      className="admin-sidebar-listitem"
                    >
                      <Index.RouteLink
                        to={`${adminProfileData?.adminType == 0
                            ? "/admin"
                            : "/warden"
                          }/${item.url}`}
                        onClick={() => {
                          window.innerWidth <= 1023 && setOpen(!open);
                        }}
                        className={`sidebar-link ${location?.pathname ===
                            `${adminProfileData?.adminType == 0
                              ? "/admin"
                              : "/warden"
                            }/${item.url}` ||
                            item?.childUrl?.includes(location?.pathname.split('/')[2]) // check child url for active menu
                            ? "active"
                            : ""
                          }`}
                      >
                        <Index.Box>{item.icon}</Index.Box>
                        {item.link}
                      </Index.RouteLink>
                    </Index.ListItem>
                  ) : (
                    <Index.ListItem>
                      <Index.Box className="cms-dropdown">
                        <Index.Box
                          className="cms-text"
                          onClick={() => handleOpenCms(key)}
                        >
                          <Index.Box className="cms-text">
                            <Index.Box className="mr-10">
                              {item.icon}
                            </Index.Box>
                            {item.link}
                          </Index.Box>
                          <Index.Box>
                            {openDropdownIndex === key ? (
                              <Index.ExpandLess className="expandless-icon" />
                            ) : (
                              <Index.ExpandMore className="expandmore-icon" />
                            )}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="">
                          <Index.Collapse
                            in={openDropdownIndex === key}
                            timeout="auto"
                            unmountOnExit
                            className=""
                          >
                            <Index.List
                              component="div"
                              disablePadding
                              className=""
                            >
                              {item?.child &&
                                item?.child.map((child, ind2) => (
                                  <>
                                    <Index.ListItem
                                      key={ind2}
                                      className="admin-sidebar-listitem"
                                    >
                                      <Index.RouteLink
                                        to={`${adminProfileData?.adminType == 0
                                            ? "/admin"
                                            : "/warden"
                                          }/${child.url}`}
                                        className={`sidebar-link ${location?.pathname ===
                                            `${adminProfileData?.adminType == 0
                                              ? "/admin"
                                              : "warden"
                                            }/${child.url}`
                                            ? "active"
                                            : ""
                                          }`}
                                      >
                                        <Index.Box>{child.icon}</Index.Box>
                                        {child.link}
                                      </Index.RouteLink>
                                    </Index.ListItem>
                                  </>
                                ))}
                            </Index.List>
                          </Index.Collapse>
                        </Index.Box>
                      </Index.Box>
                    </Index.ListItem>
                  )}
                </>
              );
            })}
        </Index.List>
      </Index.Box>
    </Index.Box>
  );
}

export default Sidebar;
