import React, { useEffect, useState } from "react";
import "../categoryManagementList/CategoryManagementList.css";
import "../categoryManagementList/CategoryManagementList.responsive.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  getTagManagementAction,
  deleteTagManagementAction,
} from "../../../../redux/admin/action";

const TagsManagement = () => {
  // state declaration
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [categoryManagement, setCategoryManagement] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  // function declaration
  const handleClickMenu = (e, row) => {
    setActionIndex();
    setAnchorEl(e.currentTarget);
    setEditData(row);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setEditData("");
  };

  // delete function declaration
  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setEditData("");
  };
  const handleDeleteOpen = () => {
    setDeleteModalOpen(true);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    deleteTagManagementAction({ id: editData?._id }, adminToken).then((res) => {

      if (res.status == 200) {
        getTagManagement();
        handleDeleteClose();
      }
    });
  };
  // add edit modal function declaration
  const openCategoryManagementModal = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const handleEdit = (row) => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setModalOpen(false);
    setEditData("");
  };
  // list function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getTagManagement = () => {
    getTagManagementAction(adminToken).then((res) => {
      setCategoryManagement(res);
      setLoading(false);
      // setPageData(res)
    });
  };
  useEffect(() => {
    // getCategoryManagement();
    getTagManagement();
  }, [modalOpen]);

  // Filter fetched data
  useEffect(() => {
    const filtered =
      categoryManagement &&
      categoryManagement?.filter((item) => {
        return !search
          ? item
          : item?.tagName?.toLowerCase().includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [categoryManagement, search]);

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Tags Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                <Index.Box className="add-user-btn">
                  <PagesIndex.Link className="no-text-decoration">
                    {" "}
                    <Index.Button
                      variant="contained"
                      onClick={openCategoryManagementModal}
                    >
                      Add Tag
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container  gridtable"
              className="episap-table-container"
            >
              <Index.Table
                // aria-label="simple table"
                stickyHeader
                aria-label="sticky table"
                // className="table-design-main barge-table category-table-main"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No</Index.TableCell>
                    <Index.TableCell align="left">Name</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row.sNo}>
                            <Index.TableCell align="left">{row.sNo}</Index.TableCell>
                            <Index.TableCell align="left" >{row.tagName}</Index.TableCell>
                            <Index.TableCell align="center" className="action-btn-icon">
                              {/* <Index.IconButton
                                onClick={(e) => {
                                  handleClickMenu(e, row);
                                }}
                              >
                                <Index.MenuIcon className="action-menu-icon category-action-menu-icon" />
                              </Index.IconButton>
                              <Index.Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                keepMounted
                                elevation={2}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >

                                <PagesIndex.ActionItem
                                  className="action-text"
                                  onClick={() => handleEdit()}
                                >
                                  Edit
                                </PagesIndex.ActionItem>

                                <PagesIndex.ActionItem
                                  className="action-text"
                                  onClick={() => handleDeleteOpen()}
                                >
                                  Delete
                                </PagesIndex.ActionItem>

                              </Index.Menu> */}
                              <Index.Tooltip title="Edit">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleEdit()}
                                >
                                  <Index.EditIcon />
                                </Index.Button>
                              </Index.Tooltip>
                              <Index.Tooltip title="Delete">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleDeleteOpen()}
                                >
                                  <Index.DeleteIcon />
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      
      <Index.Modal
        open={modalOpen}
        onClose={closeModal}
        className="category-modal"
      >
        <PagesIndex.CategoryManagementModal
          handleClose={closeModal}
          editData={editData}
          setModalOpen={setModalOpen}
          setEditData={setEditData}          
        />
      </Index.Modal>
      
      <PagesIndex.DeleteModal
        handleDeleteRecord={handleDelete}
        handleDeleteClose={handleDeleteClose}
        deleteOpen={deleteModalOpen}
      />
    </>
  );
};

export default TagsManagement;
