import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./Cms.css";
import { addEditContactUS, getCms } from "../../../../redux/admin/action";
import { styled } from "@mui/material/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import { validateMobileNo } from "../../../../validation/Validation";

const Item = styled(Index.Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
}));

const ContactUs = () => {
  const [addEditData, setAddEditData] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [mobileError, setMobileError] = useState("");
  const [mobileChange, setMobileChange] = useState(false);

  // const  declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  const initialValues = {
    address: addEditData?.address ? addEditData?.address : "",
    email: addEditData?.email,
    phone: addEditData?.phone,
    mobileNumber: addEditData ? String(`${addEditData?.countryCode}${addEditData?.mobileNumber}`) : " ",
    userName: addEditData ? addEditData.userName : "",
    countryCode: addEditData ? addEditData.countryCode : "in",
    country: addEditData ? addEditData.country : "",
    facebookUrl: addEditData?.facebookUrl,
    instagramUrl: addEditData?.instagramUrl,
    linkedinUrl: addEditData?.linkedinUrl,
    twitterUrl: addEditData?.twitterUrl,
    map: addEditData?.map,
  };
  useEffect(() => {
    getCms(adminToken).then((res) => {
      setAddEditData(res[0]?.contactUs);
    });
  }, []);
  const handleSubmitContactUs = (values) => {
    if (mobileError === "") {
      setLoadingUpdate(true);
      addEditContactUS(values, adminToken);
      setTimeout(() => {
        setLoadingUpdate(false);
      }, 5000);
    }
  };

  return (
    <>
      {addEditData && (
        <Index.Box className="episap-common-box">
          <Index.Box className="h-100">
            <Index.Box className="card-center">
              <Index.Box className="card-main profile-card-main p-0 w-100">
                <Index.Box className="title-main mb-15 text-center">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Contact Us
                  </Index.Typography>
                </Index.Box>
                <PagesIndex.Formik
                  // enableReinitialize
                  onSubmit={handleSubmitContactUs}
                  initialValues={initialValues}
                  validationSchema={PagesIndex.contactUsSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="profile-inner">
                        <Index.Box className="">
                          <Index.Grid
                            container
                            columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                          >
                            <Index.Grid item xs={12} sm={12} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="change-input-label"
                                  >
                                    Email
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      name="email"
                                      onBlur={handleBlur}
                                      value={values.email}
                                      onChange={handleChange}
                                      helperText={touched.email && errors.email}
                                      error={Boolean(
                                        errors.email && touched.email
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>

                            {/* <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Mobile Number
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      type="number"
                                      className="admin-input-design input-placeholder"
                                      name="phone"
                                      onBlur={handleBlur}
                                      value={values.phone}
                                      onChange={handleChange}
                                      helperText={touched.phone && errors.phone}
                                      error={Boolean(
                                        errors.phone && touched.phone
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid> */}

                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box
                                  //autoComplete="off"
                                  className="reg-input reg-mobile-num cont-mobile"
                                >
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label contact-mobile"
                                  >
                                    Mobile Number
                                  </Index.Typography>
                                    <MuiPhoneNumber
                                      defaultCountry={"in"}
                                      // className="admin-input-design input-placeholder"
                                      value={values?.mobileNumber}
                                      onChange={(value, countryData) => {
                                        if (
                                          value.length > 0 &&
                                          value.length !== 0
                                        ) {
                                          const phoneNumberDigits = value.replace(
                                            /\D/g,
                                            ""
                                          ); // Remove non-numeric characters
                                          let countryCode = countryData?.dialCode;
                                          setFieldValue(
                                            "mobileNumber",
                                            +phoneNumberDigits.slice(
                                              countryCode.length,
                                              phoneNumberDigits.length
                                            )
                                          );
                                          setFieldValue(
                                            "countryCode",
                                            `${"+"}${countryData.dialCode}`
                                          );
                                          setFieldValue(
                                            "country",
                                            countryData.name
                                          );
                                        }
                                        setMobileError(
                                          validateMobileNo(
                                            value,
                                            countryData.dialCode
                                          )
                                        );
                                        setMobileChange(true);
                                      }}
                                      onKeyPress={(event) => {
                                        const charCode = event.which
                                          ? event.which
                                          : event.keyCode;
                                        if (
                                          charCode > 31 &&
                                          (charCode < 48 || charCode > 57)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  <span className="error-text">
                                    {mobileError && touched.mobileNumber
                                      ? mobileError
                                      : touched.mobileNumber &&
                                        errors.mobileNumber}
                                    {console.log(mobileError, "mobileError")}
                                  </span>
                                </Index.Box>
                              </Item>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Facebook URL
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      name="facebookUrl"
                                      onBlur={handleBlur}
                                      value={values.facebookUrl}
                                      onChange={handleChange}
                                      helperText={
                                        touched.facebookUrl &&
                                        errors.facebookUrl
                                      }
                                      error={Boolean(
                                        errors.facebookUrl &&
                                          touched.facebookUrl
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Instagram URL
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      name="instagramUrl"
                                      type="text"
                                      onBlur={handleBlur}
                                      value={values.instagramUrl}
                                      onChange={handleChange}
                                      helperText={
                                        touched.instagramUrl &&
                                        errors.instagramUrl
                                      }
                                      error={Boolean(
                                        errors.instagramUrl &&
                                          touched.instagramUrl
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    Linkedin URL
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      name="linkedinUrl"
                                      onBlur={handleBlur}
                                      value={values.linkedinUrl}
                                      onChange={handleChange}
                                      helperText={
                                        touched.linkedinUrl &&
                                        errors.linkedinUrl
                                      }
                                      error={Boolean(
                                        errors.linkedinUrl &&
                                          touched.linkedinUrl
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                              <Item className="dashboard-item">
                                <Index.Box>
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    X URL
                                  </Index.Typography>
                                  <Index.Box className="input-design-div with-border mb-15">
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder=""
                                      variant="filled"
                                      className="admin-input-design input-placeholder"
                                      name="twitterUrl"
                                      onBlur={handleBlur}
                                      value={values.twitterUrl}
                                      onChange={handleChange}
                                      helperText={
                                        touched.twitterUrl && errors.twitterUrl
                                      }
                                      error={Boolean(
                                        errors.twitterUrl && touched.twitterUrl
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Item>
                            </Index.Grid>
                            {/* <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                               Map 
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="mobileNumber"
                                  type="number"
                                  onBlur={handleBlur}
                                  onFocus={handleFocus}
                                  value={values.mobileNumber}
                                  onChange={handleChange}
                                  helperText={touched.mobileNumber && errors.mobileNumber}
                                  error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid> */}
                            <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                              <Index.Box className="set-text-area mb-20">
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Address
                                </Index.Typography>
                                <Index.Box className="set-textarea-box-top address-input">
                                  <Index.TextField
                                    className="set-textarea-box"
                                    aria-label="empty textarea"
                                    placeholder=""
                                    multiline
                                    maxRows={4}
                                    name="address"
                                    onBlur={handleBlur}
                                    value={values.address}
                                    sx={{ height: "120px" }}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                        .replace(/\s\s+/g, " ")
                                        .replace(/\n\s*/g, "\n");
                                      setFieldValue("address", newValue);
                                    }}
                                    helperText={
                                      touched.address && errors.address
                                    }
                                    error={Boolean(
                                      errors.address && touched.address
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box mt={5} className="editor-btn text-center">
                        <Index.Button
                          className="editor-submit-btn login-disable"
                          type="submit"
                          data-testid="button"
                          variant="contained"
                          disabled={loadingUpdate}
                        >
                          <img
                            alt="save"
                            src={PagesIndex.Png.invoice}
                            className=""
                          />
                          Update
                        </Index.Button>
                      </Index.Box>
                    </form>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default ContactUs;
