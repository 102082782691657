import React from 'react'

export default function UsersList() {
    return (

        <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.7675 21.3427C31.6756 21.3427 31.5843 21.3447 31.4932 21.3478C30.055 18.544 27.6224 16.3311 24.6639 15.1777C26.804 13.691 28.208 11.2156 28.208 8.41816C28.208 3.88246 24.518 0.192383 19.9823 0.192383C17.0686 0.192383 14.5041 1.71527 13.0423 4.00676C12.4755 3.85355 11.8799 3.77137 11.2654 3.77137C7.505 3.77137 4.44562 6.83074 4.44562 10.5911C4.44562 12.7381 5.4432 14.6561 6.99875 15.9071C2.89648 17.5921 0 21.6301 0 26.3325V30.7466L23.6195 30.7517C24.1921 34.7357 27.6275 37.8076 31.7675 37.8076C36.3069 37.8076 40 34.1146 40 29.5752C40 25.0358 36.307 21.3427 31.7675 21.3427ZM19.9823 2.53613C23.2256 2.53613 25.8643 5.1748 25.8643 8.41816C25.8643 11.6615 23.2256 14.3002 19.9823 14.3002C16.7389 14.3002 14.1002 11.6615 14.1002 8.41816C14.1002 5.1748 16.7389 2.53613 19.9823 2.53613ZM6.78937 10.5911C6.78937 8.12309 8.79734 6.11512 11.2654 6.11512C11.538 6.11512 11.8048 6.14035 12.0641 6.1873C11.8638 6.89699 11.7565 7.64527 11.7565 8.41816C11.7565 10.6199 12.6261 12.6221 14.0397 14.1C13.2766 14.705 12.3127 15.0672 11.2654 15.0672C8.79727 15.0671 6.78937 13.0593 6.78937 10.5911ZM2.34375 28.4029V26.3325C2.34375 21.4132 6.34602 17.4109 11.2654 17.4109C11.3686 17.4109 11.4712 17.4131 11.5736 17.4166C8.80859 19.7895 7.05383 23.3079 7.05383 27.2286V28.4029H2.34375V28.4029ZM9.39758 27.2286C9.39758 21.3922 14.1459 16.6439 19.9823 16.6439C23.835 16.6439 27.2116 18.7143 29.063 21.7996C26.2028 22.7974 24.0569 25.3311 23.6182 28.408H9.39758V27.2286ZM31.7675 35.4639C28.5205 35.4639 25.8788 32.8223 25.8788 29.5753C25.8788 26.3282 28.5205 23.6865 31.7675 23.6865C35.0145 23.6865 37.6562 26.3282 37.6562 29.5753C37.6562 32.8222 35.0146 35.4639 31.7675 35.4639Z" fill="black" />
            <path d="M29.4922 28.3938H30.5956V33.1008H32.9394V26.05H29.4922V28.3938Z" fill="black" />
        </svg>

    )
}
