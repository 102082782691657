import React from 'react'

export default function BlockReport() {
    return (

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_338_1350)">
                <path d="M32 2.00134H8C3.58172 2.00134 0 5.58306 0 10.0013C0 14.4196 3.58172 18.0013 8 18.0013H19.3487L21.9413 32.878C22.0161 33.3359 22.3542 33.7064 22.8034 33.8227C23.2578 33.947 23.7428 33.7899 24.038 33.4227L26.8201 29.956L32.5108 37.4681C32.7241 37.7519 33.0423 37.9385 33.3941 37.986C33.456 37.9945 33.5184 37.9988 33.5808 37.9987C33.8713 37.9985 34.1537 37.9032 34.3848 37.7274L37.4695 35.394C38.0578 34.9474 38.1738 34.1089 37.7288 33.5193L32.0388 26.0053L36.1334 24.2633C36.5631 24.0774 36.8426 23.6555 36.8461 23.1873C36.8565 22.7241 36.5924 22.2984 36.1727 22.102L27.6201 18.0013H32C36.4183 18.0013 40 14.4195 40 10.0013C40 5.58298 36.4183 2.00134 32 2.00134ZM30.7334 25.1134C30.5445 25.1938 30.4033 25.3567 30.3504 25.555C30.2975 25.7534 30.339 25.9649 30.4627 26.1287L36.6667 34.33L33.5734 36.668L27.3673 28.468C27.2438 28.3033 27.0512 28.2049 26.8453 28.2013H26.8353C26.6334 28.2015 26.4424 28.2932 26.316 28.4506L23.2 32.3346L20.3613 16.0013L24.404 17.9392C24.4133 17.9432 24.422 17.9485 24.432 17.9526L35.3086 23.1666L30.7334 25.1134ZM36.7187 14.7167C35.4698 15.9716 33.7705 16.6743 32 16.668H24.8387L20.938 14.8013C20.4861 14.5846 19.9509 14.6386 19.5515 14.9412C19.152 15.2438 18.9551 15.7443 19.0413 16.238L19.1166 16.6713H8C4.31719 16.6713 1.33164 13.6858 1.33164 10.003C1.33164 6.32017 4.31719 3.3347 8 3.3347H32C34.6972 3.33369 37.1293 4.95798 38.1616 7.44978C39.194 9.94158 38.6231 12.81 36.7153 14.7167H36.7187Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36 6.66797H32C31.6318 6.66797 31.3334 6.96648 31.3334 7.33461C31.3334 7.70273 31.6319 8.00125 32 8.00125H33.3334V12.6679C33.3334 13.0361 33.6318 13.3345 34 13.3345C34.3682 13.3345 34.6667 13.0361 34.6667 12.6679V8.00125H36C36.3682 8.00125 36.6667 7.70273 36.6667 7.33461C36.6667 6.96648 36.3682 6.66797 36 6.66797Z" fill="white" />
                <path d="M8.66664 8.66797C8.66664 7.56344 7.77117 6.66797 6.66664 6.66797H4.66664C4.29844 6.66797 4 6.96648 4 7.33461V12.668C4 13.0362 4.29852 13.3346 4.66664 13.3346C5.03477 13.3346 5.33328 13.0362 5.33328 12.668V10.668H6.25469L7.40336 12.9659C7.56797 13.2955 7.96852 13.4292 8.29805 13.2646C8.62758 13.1 8.76133 12.6995 8.59672 12.3699L7.61937 10.4152C8.26227 10.0688 8.66414 9.39836 8.66664 8.66797ZM5.33336 8.00133H6.66672C7.03492 8.00133 7.33336 8.29977 7.33336 8.66797C7.33336 9.03617 7.03484 9.33461 6.66672 9.33461H5.33336V8.00133Z" fill="white" />
                <path d="M17.3334 6.66797H15.3334C14.9652 6.66797 14.6667 6.96648 14.6667 7.33461V12.668C14.6667 13.0362 14.9652 13.3346 15.3334 13.3346C15.7015 13.3346 16 13.0362 16 12.668V10.668H17.3334C18.4379 10.668 19.3334 9.7725 19.3334 8.66797C19.3334 7.56344 18.4379 6.66797 17.3334 6.66797ZM17.3334 9.33469H16V8.00133H17.3334C17.7016 8.00133 18 8.29977 18 8.66797C18 9.03617 17.7015 9.33469 17.3334 9.33469Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 8.00137C13.0349 8.00137 13.3333 7.70286 13.3333 7.33473C13.3333 6.96661 13.0348 6.66809 12.6667 6.66809H10C9.63181 6.66809 9.33337 6.96661 9.33337 7.33473V12.6681C9.33337 13.0363 9.63189 13.3347 10 13.3347H12.6667C13.0349 13.3347 13.3333 13.0363 13.3333 12.6681C13.3333 12.2999 13.0348 12.0015 12.6667 12.0015H10.6667V10.668H12.6667C13.0349 10.668 13.3333 10.3695 13.3333 10.0014C13.3333 9.63317 13.0348 9.33473 12.6667 9.33473H10.6667V8.00137H12.6667Z" fill="white" />
                <path d="M22.6666 6.66797C21.1959 6.66797 20 8.16328 20 10.0013C20 11.8394 21.196 13.3347 22.6666 13.3347C24.1373 13.3347 25.3333 11.8394 25.3333 10.0013C25.3333 8.16328 24.1373 6.66797 22.6666 6.66797ZM22.6666 12.0013C21.944 12.0013 21.3333 11.0853 21.3333 10.0013C21.3333 8.91734 21.944 8.00133 22.6666 8.00133C23.3893 8.00133 24 8.91734 24 10.0013C24 11.0853 23.3893 12.0013 22.6666 12.0013Z" fill="white" />
                <path d="M30.6666 8.66797C30.6666 7.56344 29.7712 6.66797 28.6666 6.66797H26.6666C26.2984 6.66797 26 6.96648 26 7.33461V12.668C26 13.0362 26.2984 13.3346 26.6666 13.3346C27.0348 13.3346 27.3333 13.0362 27.3333 12.668V10.668H28.2546L29.4033 12.9659C29.5098 13.1791 29.7219 13.3193 29.9597 13.3337C30.1975 13.348 30.425 13.2345 30.5563 13.0357C30.6877 12.837 30.703 12.5831 30.5966 12.37L29.6192 10.4153C30.2623 10.0688 30.6641 9.39836 30.6666 8.66797ZM27.3334 8.00133H28.6667C29.0349 8.00133 29.3334 8.29977 29.3334 8.66797C29.3334 9.03617 29.0349 9.33461 28.6667 9.33461H27.3334V8.00133Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_338_1350">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
