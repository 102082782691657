import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { PropTypes } from "prop-types";
import TransactionHistory from "./TransactionHistory";
import ReferralHistory from "./ReferralHistory";
import WardenHistory from "./WardenHistory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes?.node,
  index: PropTypes?.number?.isRequired,
  value: PropTypes?.number?.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TransactionList() {
  const [value, setValue] = useState(0);
  //tab function declaration
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Grid container spacing={2} className="align-items-start">
            <Index.Grid item xs={12} md={6}>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex mb-0">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title mb-0"
                    >
                      Transaction History
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>

          <Index.Grid>
            <Index.Grid item xs={12} md={12}>
              <Index.Box className="table-wrape pl-0 pr-0">
                <Index.Box>
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="admin-tabs-main"
                  >
                    <Index.Tab
                      label="Transaction"
                      {...a11yProps(0)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Referral"
                      {...a11yProps(1)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Become Warden"
                      {...a11yProps(2)}
                      className="admin-tab"
                    />
                  </Index.Tabs>
                </Index.Box>
                <Index.Box></Index.Box>

                {value == 0 ? (
                  <TransactionHistory />
                ) : value == 1 ? (
                  <ReferralHistory />
                ) : (
                  <WardenHistory />
                )}
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TransactionList;
