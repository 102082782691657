import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getAllReferralUsersAction } from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Export_excel } from "../../../../components/common/ExportAll";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function Referrals() {
  const [loading, setLoading] = useState(true);
  const [referralUserList, setReferralUserList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [exportReferralUsers, setExportReferralUsers] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  // function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const ReferralUsers = () => {
    getAllReferralUsersAction(adminToken).then((res) => {
      setReferralUserList(res?.data);
      setFilteredData(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    ReferralUsers();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = referralUserList?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.userId?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
            item?.refferalBy?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [referralUserList, search]);

  const handleFilterUsers = async () => {
    // Convert fromDate and toDate to Date objects
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);
    // Set the time of toDateObj to the end of the day for inclusive comparison
    toDateObj.setHours(23, 59, 59, 999);
    // Filter the referralUserList based on the date range
    const filtered = referralUserList?.filter((item) => {
      const createdAtDate = new Date(item?.createdAt);
      return createdAtDate >= fromDateObj && createdAtDate <= toDateObj;
    });
    setFilteredData(filtered);
  };

  const handleResetFilter = async () => {
    ReferralUsers();
    setFromDate("");
    setToDate("");
  };

  // Export details
  useEffect(() => {
    const rows = filterData?.map((item, index) => {
      return {
        "Sr. No": index + 1,
        Fullname: item?.userId?.fullName ? item?.userId?.fullName : "-",
        Email: item?.userId?.email ? item?.userId?.email : "-",
        Username: item?.userId?.userName ? item?.userId?.userName : "-",
        "Refferal Amount": item?.refferalAmount ? item?.refferalAmount : "-",
        Status: item?.isPaid ? "Received" : "Pending",
        "Referral By": item?.refferalBy?.fullName
          ? item?.refferalBy?.fullName
          : "-",
        "Created Date": item?.createdAt
          ? moment(item?.createdAt).format("DD/MM/YYYY")
          : "-",
      };
    });
    if (rows && rows?.length > 0) {
      setExportReferralUsers(rows);
    } else {
      setExportReferralUsers([]);
    }
  }, [filterData]);

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Referral Users
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Filter and export */}
          <Index.Box className="common-button blue-button">
            <Index.Grid container spacing={3} sx={{ marginBottom: "20px" }}>
              <Index.Grid item xs={12} sm={12} md={12} lg={4}>
                <Index.Box>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    From Date
                  </Index.Typography>
                  <Index.Box className="input-design-div">
                    <Index.Box className="set-date set-date-picker picker-box-set">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem label="">
                            <DatePicker
                              fullWidth
                              format="DD/MM/YYYY"
                              name="nomination_rec_date"
                              maxDate={dayjs(new Date())}
                              value={fromDate}
                              onChange={(value) => {
                                const formatedDate = moment(value.$d).format(
                                  "YYYY/MM/DD"
                                );
                                setFromDate(formatedDate);
                              }}
                              slotProps={{
                                textField: {
                                  readOnly: true,
                                },
                              }}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={12} md={12} lg={4}>
                <Index.Box>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    To Date
                  </Index.Typography>
                  <Index.Box className="input-design-div">
                    <Index.Box className="set-date set-date-picker picker-box-set">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DemoItem label="">
                            <DatePicker
                              fullWidth
                              format="DD/MM/YYYY"
                              name="nomination_rec_date"
                              // maxDate={dayjs(new Date())}
                              value={toDate}
                              onChange={(value) => {
                                const formatedDate = moment(value.$d).format(
                                  "YYYY/MM/DD"
                                );
                                setToDate(formatedDate);
                              }}
                              slotProps={{
                                textField: {
                                  readOnly: true,
                                },
                              }}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={12} md={12} lg={4}>
                <Index.Box className="referell-box">
                  {fromDate && toDate ? (
                    <>
                      <Index.Box className="flex-filter-content">
                        <Index.Box className="common-button blue-button">
                          <Index.Button
                            variant="contained"
                            onClick={() => handleFilterUsers()}
                          >
                            Filter
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="flex-filter-content">
                        <Index.Box className="common-button blue-button">
                          <Index.Button
                            variant="contained"
                            onClick={() => handleResetFilter()}
                          >
                            Reset
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </>
                  ) : null}

                  <Index.Box className="flex-filter-content">
                    <Index.Box className="common-button blue-button">
                      <Index.Button
                        variant="contained"
                        onClick={() => {
                          let curDate = moment(new Date()).format("DD-MM-YYYY");
                          Export_excel(
                            exportReferralUsers,
                            `Referral Users (${curDate})`
                          );
                        }}
                      >
                        Export
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Full Name</Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">Username</Index.TableCell>
                    <Index.TableCell align="left">
                      Referral Amount
                    </Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="left">Referred By</Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    {/* <Index.TableCell align="center">Action</Index.TableCell> */}
                  </Index.TableRow>
                </Index.TableHead>

                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">
                              {row.sNo}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.userId?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.userId?.email}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.userId?.userName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.refferalAmount ? row?.refferalAmount : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              style={{
                                color: row?.isPaid ? "green" : "blue",
                              }}
                            >
                              {row?.isPaid ? "Received" : "Pending"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.refferalBy?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Referrals;
