import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  //   deletePfpAction,
  deleteReasonAction,
  reportListAction,
} from "../../../../redux/admin/action";
import moment from "moment";
import ReportAddEdit from "./ReportAddEdit";

const ReportList = () => {
  // state declaration
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [reportList, setReportList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [reasonId, setReasonId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  const [openmsgDescription, setOpenmsgDescription] = useState(false);
  const [msgDescription, setmsgDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  // delete function declaration
  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setEditData("");
  };
  const handleDeleteOpen = (id) => {
    setReasonId(id);
    setDeleteModalOpen(true);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    deleteReasonAction(reasonId, adminToken).then((res) => {
      if (res.status == 200) {
        getReportList();
        handleDeleteClose();
      }
    });
  };
  // add edit modal function declaration
  const openModal = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const handleEdit = (row) => {
    setModalOpen(true);
    setAnchorEl(null);
    setEditData(row);
  };
  const closeModal = () => {
    setModalOpen(false);
    setEditData("");
  };
  // list function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getReportList = () => {
    reportListAction(adminToken).then((res) => {
      setReportList(res);
      setLoading(false);
      // setPageData(res)
    });
  };
  useEffect(() => {
    !modalOpen && getReportList();
  }, [modalOpen]);

  // Filter fetched data
  useEffect(() => {
    const filtered =
      reportList &&
      reportList?.filter((item) => {
        return !search
          ? item
          : new Date(item?.createdAt)
              .toLocaleDateString("en-GB")
              .includes(search?.toLowerCase()) ||
              item?.reason?.toLowerCase().includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [reportList, search]);

  const handleClickOpenDescription = (title, description) => {
    setOpenmsgDescription(true);
    setDescriptionTitle(title);
    setmsgDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenmsgDescription(false);
    setDescriptionTitle("");
    setmsgDescription("");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Reasons{" "}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                <Index.Box className="add-user-btn">
                  <PagesIndex.Link className="no-text-decoration">
                    {" "}
                    <Index.Button variant="contained" onClick={openModal}>
                      Add Reason
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No</Index.TableCell>
                    {/* <Index.TableCell align="left">Date</Index.TableCell> */}
                    <Index.TableCell align="left">Reason</Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {!loading ? (
                    pageData?.length ? (
                      pageData?.map((row) => {
                        return (
                          // {moment(row?.createdAt).format("DD/MM/YYYY")}
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">
                              {row?.sNo}
                            </Index.TableCell>
                            {/* <Index.TableCell align="left">{moment(row?.timeSlot).format("DD/MM/YYYY")}</Index.TableCell> */}

                            <Index.TableCell
                              align="left"
                              onClick={() =>
                                handleClickOpenDescription(
                                  "Message",
                                  row?.reason
                                )
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              <Index.Tooltip>
                                {row?.reason &&
                                  (row.reason?.length > 40
                                    ? row.reason.slice(0, 40) + "..."
                                    : row.reason)}
                              </Index.Tooltip>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                            <Index.TableCell
                              align="center"
                              className="action-btn-icon"
                            >
                              <Index.Tooltip title="Edit">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleEdit(row)}
                                >
                                  <Index.EditIcon />
                                </Index.Button>
                              </Index.Tooltip>
                              <Index.Tooltip title="Delete">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() =>
                                    handleDeleteOpen({ id: row?._id })
                                  }
                                >
                                  <Index.DeleteIcon />
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={modalOpen}
        // onClose={closeModal}
        className="category-modal"
      >
        <ReportAddEdit
          handleClose={closeModal}
          editData={editData}
          setModalOpen={setModalOpen}
          setEditData={setEditData}
        />
      </Index.Modal>
      <PagesIndex.DeleteModal
        handleDeleteRecord={handleDelete}
        handleDeleteClose={handleDeleteClose}
        deleteOpen={deleteModalOpen}
        deleteMessage={
          "Are you sure? Do you really want to delete this reason?"
        }
      />
      {/* Description Message Popup */}
      <Index.Modal
        open={openmsgDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Message
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {msgDescription ? msgDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default ReportList;
