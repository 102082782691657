import React from 'react'

export default function UserList() {
    return (

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_327_971)">
                <path d="M20.5469 36.875H4.81965C4.15254 36.875 3.75035 36.5027 3.56965 36.2809C3.25809 35.8975 3.13762 35.3988 3.23887 34.9121C4.86152 27.1179 11.7399 21.4264 19.6805 21.2418C19.8124 21.2466 19.9448 21.25 20.0782 21.25C25.9366 21.25 30.7032 16.4837 30.7032 10.625C30.7032 4.76625 25.9366 0 20.0782 0C14.2194 0 9.45316 4.76625 9.45316 10.625C9.45316 14.1022 11.1322 17.1948 13.7223 19.1342C11.3495 19.9152 9.12269 21.1301 7.1741 22.7328C3.60144 25.6717 1.1173 29.7708 0.179492 34.2752C-0.114415 35.6866 0.237773 37.1362 1.14535 38.2526C2.04848 39.3631 3.38777 40 4.81965 40H20.5469C21.4096 40 22.1094 39.3005 22.1094 38.4375C22.1094 37.5745 21.4096 36.875 20.5469 36.875ZM12.5782 10.625C12.5782 6.48953 15.9425 3.125 20.0782 3.125C24.2136 3.125 27.5782 6.48953 27.5782 10.625C27.5782 14.7012 24.3094 18.0283 20.2555 18.1229C20.1971 18.1162 20.1383 18.1119 20.0782 18.1119C19.9561 18.1119 19.8343 18.1137 19.7125 18.1158C15.7461 17.9248 12.5782 14.638 12.5782 10.625ZM40.0782 32.1859C40.0782 33.049 39.3784 33.7484 38.5157 33.7484H26.7969C25.9339 33.7484 25.2344 33.049 25.2344 32.1859C25.2344 31.3233 25.9339 30.6234 26.7969 30.6234H38.5157C39.3784 30.6234 40.0782 31.3233 40.0782 32.1859ZM26.7969 24.375H38.5157C39.3784 24.375 40.0782 25.0745 40.0782 25.9375C40.0782 26.8005 39.3784 27.5 38.5157 27.5H26.7969C25.9339 27.5 25.2344 26.8005 25.2344 25.9375C25.2344 25.0745 25.9339 24.375 26.7969 24.375ZM40.0782 38.4375C40.0782 39.3005 39.3784 40 38.5157 40H26.7969C25.9339 40 25.2344 39.3005 25.2344 38.4375C25.2344 37.5745 25.9339 36.875 26.7969 36.875H38.5157C39.3784 36.875 40.0782 37.5745 40.0782 38.4375Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_327_971">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
