import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import NFTAbi from "../../Connectivity/abi/NFTAbi.json";
import { buyId, payForEvoluteIdea } from "../../../redux/admin/action";

import { useLocation, useParams, useNavigate } from "react-router-dom";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";
import WalletLoader from "../../../components/common/WalletLoader";

const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";

const PolygonMainnet = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com/",
  rpcUrl:
    "https://polygon-mainnet.infura.io/v3/8c79ca71179c4e318999034a96a93f31",
};

const metadata = {
  name: "Episapient.io",
  description: "Episapient.io",
  url: "https://episapient.appworkdemo.com/wallet/backtoapp",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [PolygonMainnet],
  projectId,
  enableAnalytics: true,
});

export default function PlatformFees() {
  const [isLoaded, setLoaded] = useState(false);
  const [processingLog, setProcessingLog] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();
  const [buyIdData, setBuyIdData] = useState([]);
  const { walletProvider } = useWeb3ModalProvider();
  const { id } = PagesIndex.useParams();
  const [loading, setLoading] = useState(false);

  async function handleApprove() {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      "0x3C1e77fb138a2448153008bBe69803285c38ce96",
      tokenAbi,
      signer
    );
    const nftcontract = new ethers.Contract(
      "0x01729d1848e02080532bed93bae7E5Dba513E557",
      NFTAbi,
      signer
    );

    try {
      setProcessingLog("Approving the transaction...");
      let NFTAmount = 250;
      let PFPFees = buyIdData;
      let TotalAmount = NFTAmount + (PFPFees == "" ? 0 : parseInt(PFPFees));
      // console.log(buyIdData, "buyIdData", TotalAmount);
      let fees2 = ethers.utils.parseEther(TotalAmount?.toString());
      // console.log("fees toString", fees2.toString());

      const tx = await tokenContract.transfer(
        "0x01729d1848e02080532bed93bae7E5Dba513E557",
        fees2.toString()
      );

      await tx.wait();
      const receipt = await provider.getTransactionReceipt(tx.hash);
      // console.log(receipt, "receipt");

      if (receipt.status === 1) {
        if (tx.hash) {
          setProcessingLog("Executing smart contract for payment fees...");
          let values = { id: id, tx_hash: tx.hash };
          setProcessingLog("Executing api call...");

          setTimeout(() => {
            payForEvoluteIdea(values).then((res) => {
              setProcessingLog("");
              if (res?.status === 201) {
                setLoading(false);
                navigate("/wallet/success");
              } else {
                setLoading(false);
                navigate("/wallet/success");
              }
            });
          }, 1000);
        } else {
          setLoading(false);
          alert("Transaction is not completed. please try again");
        }
      } else {
        setLoading(false);
        alert("Transaction failed");
      }
    } catch (error) {
      setLoading(false);
      alert(error?.message);
      console.log("error?.message", error?.message);
    }
  }
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  useEffect(() => {
    if (isConnected && isLoaded) {
      handleApprove();
    }
  }, [isConnected, isLoaded]);

  useEffect(() => {
    let values = { id: id };
    buyId(values).then((res) => {
      if (res?.status === 201) {
        setBuyIdData(res?.data?.pfpValue);
        setLoaded(true);
      } else {
        setBuyIdData(res?.data?.pfpValue);
        setLoaded(true);
      }
    });
  }, []);

  // Loader User Effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
  // setVisible

  // 10 Min timer
  const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer); // Clean up the interval on component unmount
    } else {
      setTimeLeft(600);
    }
  }, [loading]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // console.log(address, chainId, isConnected);
  return (
    <>
      <div className="wallet-box">
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.newLogo} alt="" />
            <div className="logo-title">EPISAP</div>
          </div>
          {!isConnected && <w3m-button />}
          <div className="add-word-break">{address}</div>
          <h4 className="wallet-title">
            Idea Upload Fees <br />
            {250 + (buyIdData == "" ? 0 : parseInt(buyIdData))}
            VHT
          </h4>
          <div className="wallet-subtitle">{processingLog}</div>
          {loading && (
            <>
              <p>
                <strong>
                  The payment is still processing. Please don't close the app or
                  turn off your screen. Wait until you get a response from the
                  wallet. If you don't, you might lose your data.
                </strong>
              </p>
              <h3>Countdown Timer</h3>
              <div style={{ fontSize: "1.5rem" }}>{formatTime(timeLeft)}</div>
            </>
          )}
        </div>
      </div>
      {isVisible && (
        <div className="wallet-loader">
          <WalletLoader />
        </div>
      )}
    </>
  );
}
