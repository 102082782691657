import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import close from "../../../../assets/images/svg/close.svg";
import "./viewSingleLeafModel.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import PagesIndex from "../../../PagesIndex";

const ViewSingleLeafnodeModel = (props) => {
  // cat--
  const [catAnchorEl, setCatAnchorEl] = useState(null);
  const catBtnopen = Boolean(catAnchorEl);
  const catHandleClick = (event) => {
    setCatAnchorEl(event.currentTarget);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [openProfile, setProfileOpen] = useState(false);
  const [viewVedio, setViewVedio] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [notImage, setNotImage] = useState(null);
  const [ImagecontentType, setImageContentType] = useState(null);

  const handleProfileOpen = () => setProfileOpen(true);
  const handleProfileClose = () => {
    setProfileOpen(false);
    // setViewVedio(false)
    // setViewImage(false)
  };

  useEffect(() => {
    // const url = 'https://turquoise-wittering-termite-900.mypinata.cloud/ipfs/QmcFNzSuYrVwwrtrEXQdUFthceUACAjRuwRVnEf885shPF';
    const url = props?.handleModelData?.imageAddress;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log("blb",response?.blob())


        return response?.blob();
      })
      .then((data) => {
        const contentType = data.type;
        // setImageContentType(contentType);

        if (contentType.startsWith("image")) {
          setViewVedio(false);
          setViewImage(true);
          // console.log("The URL contains an image.");
        } else if (contentType.startsWith("video")) {
          // console.log("coming-vedio");
          setViewVedio(true);
          setViewImage(false);
        } else {
          setImageContentType(PagesIndex.Jpg.dummyImage);
          setViewImage(false);
          setViewVedio(false);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [props.handleModelData.imageAddress]);

  return (
    <>
      <Index.Modal
        open={props.deleteOpen}
        onClose={props.handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="leaf-modal-inner-main modal-inner">
          {/* <Index.Box className="modal-circle-main">
          <img
            src={Index.Svg.close}
            className="user-circle-img"
            alt="icon"
          />
        </Index.Box> */}
          {/* <Index.Typography
          className="delete-modal-title"
          component="h5"
          variant="h5"
        >
          Are you sure? Do you really want to delete this records?
        </Index.Typography> */}
          <Index.Box
            className="modal-circle-main"
            onClick={props.handleDeleteClose}
          >
            {/* <img  src={ props?.handleModelData?.imageAddress} onLoad={handleImageLoad}  id="checkInData" alt="check" className="check-img-data" /> */}

            <img src={close} className="user-circle-img" alt="icon" />
          </Index.Box>
          <Index.Box className="modal-contained-leaf-wrap">
            <Index.Box
              className="leaf-profile-main"
              onClick={handleProfileOpen}
            >

              {!viewImage ? <img
                src={
                  PagesIndex.Jpg.dummyImage
                }
                className="leaf-profile-img"
              /> : <img
                src={
                  viewImage
                    ? props?.handleModelData?.imageAddress
                    : viewVedio
                      ? PagesIndex.Jpg.videoImg
                      : ""
                }
                className="leaf-profile-img"
              />}
            </Index.Box>
            {/* Zoom-image-Model Start*/}
            <Index.Modal
              open={openProfile}
              onClose={handleProfileClose}
              className="profile-zoom-modal modal"
            >
              <Index.Box className="profile-zoom-modal-inner modal-inner">
                {viewImage && (
                  <img
                    id="yourImgId"
                    src={props?.handleModelData?.imageAddress}
                    className="leaf-profile-zoom-img"
                  />
                )}
                {viewVedio && (
                  <video width="640" height="360" controls>
                    <source
                      src={props?.handleModelData?.imageAddress}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </Index.Box>
            </Index.Modal>
            {/* Zoom-image-Model End*/}

            <Index.Box className="Idealeaft-view-inpt-main">
              <InputLabel htmlFor="title">Title</InputLabel>

              <Index.TextField
                className="admin-input-design input-placeholder"
                id="title"
                value={props?.handleModelData?.title}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Index.Box>
            <Index.Box className="Idealeaft-view-inpt-main">
              <InputLabel htmlFor="ideaType">IdeaType</InputLabel>
              <Index.TextField
                className="admin-input-design input-placeholder"
                id="ideaType"
                value={props?.handleModelData?.ideaType}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Index.Box>
            <Index.Box className="Idealeaft-view-inpt-main">
              <InputLabel htmlFor="desc-label">Descriptions</InputLabel>
              <Index.TextareaAutosize
                className="textarea-control"
                label="Descriptions"
                id="desc-label"
                multiline
                value={props?.handleModelData?.descriptions}
                InputProps={{
                  readOnly: true,
                }}
                maxRows={5}
              />
            </Index.Box>

            <Index.Box className="Idealeaft-view-inpt-main">
              {/* category btn */}

              <span className="title">Category</span>
              <Stack direction="row" spacing={1}>
                {props?.handleModelData?.category &&
                  props?.handleModelData?.category.length > 0 ? (
                  props?.handleModelData?.category?.map((item) => {
                    return (
                      <>
                        <Chip label={item.categoryName} />
                      </>
                    );
                  })
                ) : (
                  <p className="not-found-para">No Category Availbale</p>
                )}
              </Stack>

              {/* sub cate btn */}

              <span className="title">Subcategory</span>
              <Stack direction="row" spacing={1}>
                {props?.handleModelData?.sub_category &&
                  props?.handleModelData?.sub_category.length > 0 ? (
                  props?.handleModelData?.sub_category?.map((item) => {
                    return (
                      <>
                        <Chip label={item.subCategoryName} />
                      </>
                    );
                  })
                ) : (
                  <p className="not-found-para">No Subcategory Availbale</p>
                )}
              </Stack>

              {/* tag */}

              <span className="title">#tag</span>
              <Stack direction="row" spacing={1}>
                {props?.handleModelData?.tags &&
                  props?.handleModelData?.tags.length > 0 ? (
                  props?.handleModelData?.tags?.map((item) => {
                    return (
                      <>
                        <Chip label={item.tagName} />
                      </>
                    );
                  })
                ) : (
                  <p className="not-found-para">No Tags Availbale</p>
                )}
              </Stack>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-btn-flex">
            <Index.Button
              className="modal-btn"
              onClick={props.handleDeleteClose}
            >
              Cancel
            </Index.Button>
            {/* <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={props.handleDeleteRecord}
            >
              Delete
            </Index.Button> */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default ViewSingleLeafnodeModel;
