import React, { useEffect, useState, useCallback } from "react";
import Index from "../../../Index";
import {
  activeDeactiveGroup,
  communityGroupList,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";


function CommunityGroupList() {
  const [loading, setLoading] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);

  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getGroupList = () => {
    communityGroupList(adminToken).then((res) => {
      const sortByCreatedDate = res?.sort((a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt));
      setGroupList(sortByCreatedDate);
      setLoading(false);
    });
  };

  useEffect(() => {
    getGroupList();
  }, []);

  const handleSwitchChange = useCallback(
    (event, id) => {
      setLoading(true);
      activeDeactiveGroup(
        { groupId: id, isActive: event.target.checked },
        adminToken,
      ).then((res) => {
        if (res?.status == 200) {
          getGroupList();
        }
      });
    },
    [] // Dependency array is empty because this function doesn't depend on any external variables
  );

  useEffect(() => {
    const filtered = groupList?.filter((item) => {
      return !search
        ? item
        :
        item?.groupName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.owner?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
        new Date(item?.createdAt)?.toLocaleDateString("en-GB")?.toString().includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [groupList, search]);

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Community Groups
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No. </Index.TableCell>
                    <Index.TableCell align="left">Icon  </Index.TableCell>
                    <Index.TableCell align="left">Group Name</Index.TableCell>
                    <Index.TableCell align="left">Created By  </Index.TableCell>
                    <Index.TableCell align="left">Members</Index.TableCell>
                    <Index.TableCell align="left">Created Date & Time</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell> 
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {

                        return (
                          <Index.TableRow key={row?.sNo} className="">
                            <Index.TableCell>{row?.sNo}</Index.TableCell>

                            <Index.TableCell align="left">
                              <img
                                 src={`${PagesIndex?.ImageURL}${row?.groupIcon}`}
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius:"6px",
                                }}
                              />
                            </Index.TableCell>

                            <Index.TableCell align="left" className="max-w-150 table-title-word-break">
                              {row.groupName}
                            </Index.TableCell>
                            
                            <Index.TableCell align="left">
                              {row?.owner?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.members?.length}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format("DD/MM/YYYY hh:mm A")}
                            </Index.TableCell>
                            <Index.TableCell align="left" className="action-btn-icon">
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip title={`${row.isActive ? 'Disable' : 'Enable'}`}>
                                  <Index.Switch
                                    size="small"
                                    className="toggel-switch"
                                    {...PagesIndex.label}
                                    checked={row.isActive ? true : false}
                                    onChange={(event) => {
                                      handleSwitchChange(event, row._id);
                                    }}
                                  />
                                </Index.Tooltip>
                                
                                <Index.Tooltip title="View">
                                  <Index.Button onClick={() =>
                                    navigate("/admin/group-member-list", {
                                      state: { groupId: row?._id, groupName: row?.groupName },
                                    })
                                  }>
                                    <Index.Visibility
                                      className="icon-btns"
                                    />
                                  </Index.Button>
                                </Index.Tooltip>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default CommunityGroupList;
