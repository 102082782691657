import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  adminNotificationListAction,
  readUnreadNotificationAction,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import { Badge } from "@mui/material";
import moment from "moment";

function Notification() {
  // state declaration
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [loading, setLoading] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const { adminToken, notifications } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  // console.log(notifications, "notifications 33");
  // function declaration
  const getNotifications = () => {
    dispatch(adminNotificationListAction(adminToken, navigate));
    setLoading(false);
  };

  useEffect(() => {
    setNotificationList(notifications)
    setLoading(false);
  }, [notifications])

  const readUnreadPushNotification = (data) => {
    //console.log(data, 'read unread');
    readUnreadNotificationAction(data, adminToken, navigate).then((res) => {
      getNotifications();
    });
  };

  // Notification function
  const onRedirectNotification = (item) => {
    //console.log(item, "item typeeee");
    if (item?.readUnread === false) {
      readUnreadPushNotification({
        id: item?._id,
        readUnread: item?.readUnread,
      });
    }

    switch (item?.type) {
      case "Idea":
        navigate(`/admin/nft-details/${item?.ideaId}`);
        break;

      case "Contact":
        navigate(`/admin/contact-detail/${item?.contactId}`);
        break;

      case "InitiatedIdea":
        navigate(`/admin/nft-details/${item?.ideaId}`);
        break;

      case "Purchase":
        navigate(`/admin/nft-details/${item?.ideaId}`);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          {!loading ? (
            notificationList?.length ? (
              notificationList?.map((items, index) => {
                return (
                  <>
                    <Index.Box key={index} className="notification-main-box">
                      <Index.Box className="notification-box">
                        <Index.Box className="notification-img">
                          <img
                            src={Index.Png.profileimg}
                            alt="logo"
                            className="img-notification"
                          />
                        </Index.Box>
                        <Index.Box
                          onClick={() => onRedirectNotification(items)}
                          sx={{ cursor: "pointer" }}
                        >
                          <Index.Box>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="notification-text table-title-word-break"
                            >
                              {items?.title ? items?.title : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="notification-sub-text table-title-word-break"
                            >
                              {items?.message?.length > 100
                                ? items?.message.substring(0, 100) + "..."
                                : items?.message}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="notification-time"
                          >
                            {moment(items?.createdAt).format(
                              "DD/MM/YYYY hh:mm:ss A"
                            )}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="admin-header-right top-right-notification">
                        {items?.readUnread === false && (
                          <Badge color={"secondary"} variant={"dot"}></Badge>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </>
                );
              })
            ) : (
              <Index.Typography
                variant="p"
                component="p"
                className="notification-sub-text"
              >
                NO DATA AVAILABLE
              </Index.Typography>
            )
          ) : (
            <Index.Box className="single-page-loader">
              <PagesIndex.PageLoading />
            </Index.Box>
          )}

          {/* {notificationList.length == 0 && (
            <Index.Typography
              variant="p"
              component="p"
              className="notification-sub-text"
            >
              NO DATA AVAILABLE
            </Index.Typography>
          )} */}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Notification;
