import React from 'react'
import Index from '../Index'


const NoRecordFound = (props) => {
    return (
        <Index.TableRow className='loading-row'>
            <Index.TableCell className='loading-cell' colSpan={props.colSpan}  >
                {/* <Index.Typography className="table-data-not-found">NO DATA AVAILABLE</Index.Typography> */}
                <Index.Typography className="table-data-not-found"> NO DATA AVAILABLE</Index.Typography>
            </Index.TableCell>
        </Index.TableRow>
    )
}

export default NoRecordFound