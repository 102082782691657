import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import marketplaceAbi from "../../Connectivity/abi/marketplace.json";
import {
  putOnSale,
  sellIdea,
  buySellTransactionHash,
} from "../../../redux/admin/action";
import PagesIndex from "../../PagesIndex";
import WalletLoader from "../../../components/common/WalletLoader";
import CircularProgress from "@mui/material/CircularProgress";

const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";
const PolygonMainnet = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com/",
  rpcUrl:
    "https://polygon-mainnet.infura.io/v3/8c79ca71179c4e318999034a96a93f31",
};

const metadata = {
  name: "Episapient.io",
  description: "Episapient.io",
  url: "https://episapient.appworkdemo.com/wallet/backtoapp",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [PolygonMainnet],
  projectId,
  enableAnalytics: true,
});

export default function PutOnSale() {
  const { walletProvider } = useWeb3ModalProvider();
  const navigate = useNavigate();
  const [processingLog, setProcessingLog] = useState();
  const { wallet_address, id, nft_id, amount, userId } = PagesIndex.useParams();
  // console.log(wallet_address, id, nft_id, amount);
  const [price, setPrice] = useState(amount);
  const [tokenId, setTokenId] = useState(nft_id);
  const [isVisible, setIsVisible] = useState(true);
  const { isConnected } = useWeb3ModalAccount();
  const [loading, setLoading] = useState(false);

  async function handleSell(price, tokenId) {
    // console.log(price, tokenId);
    setLoading(true);
    setProcessingLog("Putting idea on sell...", price, tokenId);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      "0x3C1e77fb138a2448153008bBe69803285c38ce96",
      tokenAbi,
      signer
    );
    const contract = new ethers.Contract(
      "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
      marketplaceAbi,
      signer
    );
    try {
      const totaltoWei = ethers.utils.parseEther(price.toString());
      let platformfee = await contract.sellNFT(
        "0x01729d1848e02080532bed93bae7E5Dba513E557",
        tokenId,
        totaltoWei
      );
      console.log(platformfee.hash, " hash 123");
      await platformfee.wait();
      setProcessingLog("Approving the transactions...");
      let nftSaleIDfinal = await getSaleId(platformfee);
      const txReceipt = await provider.getTransactionReceipt(platformfee.hash);
      console.log(txReceipt, "txReceipt", txReceipt.status);
      // console.log(platformfee, "platformfee", platformfee.hash);
      // console.log(nftSaleIDfinal, "nftSaleIDfinal");

      if (txReceipt.status === 1) {
        if (platformfee.hash && nftSaleIDfinal) {
          // Transaction hash save
          let transactionHashData = {
            type: "Sell",
            nftId: id,
            price: price,
            transactionHash: platformfee.hash,
          };
          buySellTransactionHash(transactionHashData);

          let values = {
            id: id,
            price: price,
            nftSmartContractId: tokenId,
            walletAddress: wallet_address,
            tx_hash: platformfee.hash,
            saleId: nftSaleIDfinal,
            sellerId: userId,
          };
          setProcessingLog("Performing transaction updates...");
          setTimeout(() => {
            putOnSale(values).then((res) => {
              if (res?.status === 201) {
                setLoading(false);
                setProcessingLog("Payment done. Go Back to your app. 201");
                navigate("/wallet/success");
              } else {
                setLoading(false);
                setProcessingLog("Payment done. Go Back to your app.");
                navigate("/wallet/success");
              }
              setProcessingLog("");
            });
          }, 1000);
        } else {
          setLoading(false);
          alert("Transaction is not completed. please try again");
        }
      } else {
        setLoading(false);
        alert("Transaction failed");
      }
    } catch (error) {
      console.error("Error while approving:", error);
      setLoading(false);
      alert(error?.message);
      // console.log("error?.message", error?.message)
    }
  }

  const getSaleId = async (feeTransfer) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const receipt = await provider.getTransactionReceipt(feeTransfer?.hash);
      if (receipt && receipt.logs) {
        const tokenInterface = new ethers.utils.Interface([
          "event sellDetails(address indexed sellerAddress,uint256 indexed selllId,uint256 indexed tokenId,uint256 price)",
        ]);
        const transferLog = receipt.logs.find((log) =>
          log.topics.includes(tokenInterface.getEventTopic("sellDetails"))
        );
        console.log(await transferLog.topics[2], "logData");
        let value = await transferLog.topics[2];
        let saleId = parseInt(value, 16);
        return saleId;
      } else {
        console.log("No logs found in the transaction receipt.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  // 10 Min timer
  const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer); // Clean up the interval on component unmount
    } else {
      setTimeLeft(600);
    }
  }, [loading]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <div className="wallet-box">
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.newLogo} alt="" />
            <div className="logo-title">EPISAP</div>
          </div>
          <w3m-button />
          <h4 className="wallet-title">
            Putting Idea On Sale <br />
            {price} VHT
          </h4>
          <div className="wallet-btn-approve">
            <button
              onClick={() => handleSell(price, tokenId)}
              disabled={!isConnected || loading}
              className={`loader-white ${!isConnected ? "disable-color" : ""}`}
            >
              {loading == true ? <CircularProgress size={16} /> : "Confirm"}
            </button>
          </div>
          <div className="wallet-subtitle">{processingLog}</div>
          {loading && (
            <>
              <p>
                <strong>
                  The payment is still processing. Please don't close the app or
                  turn off your screen. Wait until you get a response from the
                  wallet. If you don't, you might lose your data.
                </strong>
              </p>
              <h3>Countdown Timer</h3>
              <div style={{ fontSize: "1.5rem" }}>{formatTime(timeLeft)}</div>
            </>
          )}
        </div>
      </div>
      {isVisible && (
        <div className="wallet-loader">
          <WalletLoader />
        </div>
      )}
    </>
  );
}
