
import React, { useEffect, useState } from "react"
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  getPfpListAction,
  deletePfpAction,
} from "../../../../redux/admin/action";

const PFPList = () => {
  // state declaration
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [pfpList, setPfpList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const [pfpId, setPfpId] = useState(null);

  // delete function declaration
  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setEditData("");
  };
  const handleDeleteOpen = (id) => {
    setPfpId(id);
    setDeleteModalOpen(true);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    deletePfpAction(pfpId, adminToken).then((res) => {
      if (res.status == 200) {
        getPfpList();
        handleDeleteClose();
      }
    });
  };
  // add edit modal function declaration
  const openPfpModal = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const handleEdit = (row) => {
    setEditData(row);
    setModalOpen(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setModalOpen(false);
    setEditData("");
  };
  // list function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getPfpList = () => {
    getPfpListAction(adminToken).then((res) => {
      setPfpList(res?.data);
      setLoading(false);
      // setPageData(res)
    });
  };
  useEffect(() => {
    !modalOpen && getPfpList();
  }, [modalOpen]);


  // Filter fetched data
  useEffect(() => {
    const filtered =
      pfpList &&
      pfpList?.filter((item) => {
        return !search
          ? item
          : item?.token?.toString().includes(search?.toLowerCase()) ||
           new Date(item?.timeSlot).toLocaleDateString("en-GB").includes(search?.toString())
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [pfpList, search]);

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  PFPs
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                <Index.Box className="add-user-btn">
                  <PagesIndex.Link className="no-text-decoration">
                    {" "}
                    <Index.Button
                      variant="contained"
                      onClick={openPfpModal}
                    >
                      Add PFP
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No</Index.TableCell>
                    {/* <Index.TableCell align="left">Date</Index.TableCell> */}
                    <Index.TableCell align="left">Days</Index.TableCell>
                    <Index.TableCell align="left">Tokens</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>

                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {!loading ? (
                    pageData?.length ? (
                      pageData?.map((row) => {
                        return (
                          // {moment(row?.createdAt).format("DD/MM/YYYY")}
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">{row?.sNo}</Index.TableCell>
                            {/* <Index.TableCell align="left">{moment(row?.timeSlot).format("DD/MM/YYYY")}</Index.TableCell> */}
                            <Index.TableCell align="left">{row?.timeSlot}</Index.TableCell>
                            <Index.TableCell align="left">{row?.token} SAP</Index.TableCell>
                            <Index.TableCell align="center" className="action-btn-icon">
                              <Index.Tooltip title="Edit">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleEdit(row)}
                                >
                                  <Index.EditIcon />
                                </Index.Button>
                              </Index.Tooltip>
                              <Index.Tooltip title="Delete">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleDeleteOpen({id: row?._id})}
                                >
                                  <Index.DeleteIcon />
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={modalOpen}
        // onClose={closeModal}
        className="category-modal"
      >
        <PagesIndex.PfpAddEdit
          handleClose={closeModal}
          editData={editData}
          setModalOpen={setModalOpen}
          setEditData={setEditData}
        />
      </Index.Modal>
      <PagesIndex.DeleteModal
        handleDeleteRecord={handleDelete}
        handleDeleteClose={handleDeleteClose}
        deleteOpen={deleteModalOpen}
        deleteMessage={"Are you sure? Do you really want to delete this PFP?"}
      />
    </>
  );
};

export default PFPList;
