import React, { useEffect, useState } from "react";
import "./ViewSingleLeafBranch.css";
import MenuIcon from "@mui/icons-material/MoreVert";
import { styled, alpha } from "@mui/material/styles";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";

import close from "../../../../assets/images/svg/close.svg";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import { getLeafNodeDetailAction } from "../../../../redux/admin/action";
import moment from "moment/moment";
import PageLoading from "../../../../components/common/PageLoading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const label = { inputProps: { "aria-label": "Switch demo" } };
const ViewSingleLeafBranch = () => {
  const [loading, setLoading] = useState(true);
  const [modelLoadingImage, setodelLoadingImage] = useState(true);

  const [viewSingleLeafNode, setViewSingleLeafNode] = useState([]);
  const [tagsArry, setTagsArry] = useState([]);
  const [openProfile, setProfileOpen] = useState(false);
  const [disLikeData, setDisLikeData] = useState([]);
  const [ViewModelHeading, setViewModelHeading] = useState("");

  const [openLikesDislike, setOpenLikesDislike] = useState(false);
  const [viewVedio, setViewVedio] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  console.log(state, "state");

  const handleProfileOpen = () => {
    setodelLoadingImage(true);
    setProfileOpen(true);
    {
      setTimeout(() => {
        setodelLoadingImage(false);
      }, 400);
    }
  };
  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const handleDisLikeOpen = (data) => {
    setDisLikeData(data);
    setViewModelHeading("Dislikes");
    setOpenLikesDislike(true);
  };

  const handleTotelViewsOpen = (data) => {
    setDisLikeData(data);
    setViewModelHeading("Total views");
    setOpenLikesDislike(true);
  };

  const handleLikeOpen = (data) => {
    setDisLikeData(data);
    setViewModelHeading("Likes");
    setOpenLikesDislike(true);
  };
  const handleDisLikeClose = () => {
    setOpenLikesDislike(false);
    setViewModelHeading("");
    setDisLikeData([]);
  };

  const ideaViwer = (dt) => {
    if (dt?.ideaType == "Main Idea") {
      return dt?.totalViewUser;
    }
    if (dt?.ideaType == "Evolve Idea") {
      return dt?.totalLeafViewUser;
    }

    if (dt?.ideaType == "Unite Idea") {
      let mergeData = [...dt?.totalViewUser, ...dt?.totalLeafViewUser];

      return mergeData;
    }
    // dt?.ideaType=="Main Idea"?  dt?.totalViewUser:  dt?.totalLeafView
  };
  let leafBranchId = null;
  if (state?.row?._id) {
    // console.log('state id =', state?.row?._id);
    leafBranchId = state?.row?._id;
  } else {
    // console.log('state node id =', state?.nodeId);
    leafBranchId = state?.nodeId;
  }
  const getSingleNodeinfo = () => {
    getLeafNodeDetailAction(leafBranchId, adminToken).then((res) => {
      if (res?.status == 200) {
        setLoading(true);
        console.log(res.data, "Response");
        setViewSingleLeafNode(res.data);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getSingleNodeinfo();
  }, []);

  useEffect(() => {
    // const url = 'https://turquoise-wittering-termite-900.mypinata.cloud/ipfs/QmcFNzSuYrVwwrtrEXQdUFthceUACAjRuwRVnEf885shPF';
    const url = viewSingleLeafNode?.imageAddress;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response?.blob();
      })
      .then((data) => {
        const contentType = data.type;
        // setImageContentType(contentType);

        if (contentType.startsWith("image")) {
          setViewVedio(false);
          setViewImage(true);
          // console.log("The URL contains an image.");
        } else if (contentType.startsWith("video")) {
          // console.log("coming-vedio");
          setViewVedio(true);
          setViewImage(false);
        } else {
          // setViewVedio(false);
          // setViewImage(false);
          // console.log("85",ImagecontentType)
          // setImageContentType(PagesIndex.Jpg.dummyImage);
          setViewImage(false);
          setViewVedio(false);
          console.log("The URL contains some other type of content.");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [viewSingleLeafNode.imageAddress]);

  const backNavigate = () => {
    if (viewSingleLeafNode?.mainIdeaId) {
      navigate("/admin/idea-tree-view", {
        state: { main_Idea_id: viewSingleLeafNode?.mainIdeaId },
      });
    }

    if (!viewSingleLeafNode?.mainIdeaId) {
      navigate("/admin/idea-tree-view", {
        state: { main_Idea_id: viewSingleLeafNode?._id },
      });
    }
  };

  const handleBack = () => {
    navigate("/admin/nft-list");
  }

  console.log(viewSingleLeafNode, 'viewSingleLeafNode')
  useEffect(() => {
    if (viewSingleLeafNode?.tags) {
      const tags = viewSingleLeafNode?.tags.split(",");
      setTagsArry(tags);
    }
  }, [viewSingleLeafNode?.tags])

  console.log(tagsArry, 'tagsArry');


  const [showFullDescription, setShowFullDescription] = useState(false);
  const description = viewSingleLeafNode?.descriptions;

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  View Single Leaf
                </Index.Typography>
              </Index.Box>
              <Index.Box className='form-back-btn'>
                <Index.Button variant="contained"
                  className="back-btn"
                  type='back'
                  onClick={() => handleBack()}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Grid container className="mb-10">
            <Index.Box className="user-detail-cont bg-right">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={12} md={12} lg={12}>
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex justify-content-between">
                      <Index.Box className="title-main">
                        <Index.Typography variant="p" component="p" className="page-title">
                          {viewSingleLeafNode?.title}
                        </Index.Typography>
                      </Index.Box>
                      {/* <Index.Box className='form-back-btn'>
                        <Index.Button variant="contained"
                          className="back-btn"
                          type='back'
                          onClick={() => handleBack()}
                        >
                          Back
                        </Index.Button>
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={12} md={2} lg={3}>
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="nft-detail-img">
                        {loading ? (
                          <PagesIndex.PageLoading colSpan={12} />
                        ) : (
                          <img
                            src={
                              viewSingleLeafNode?.imageAddress
                                ? `${PagesIndex.ImageURL}${viewSingleLeafNode?.imageAddress}`
                                : PagesIndex.Jpg.dummyImage
                            }
                            className="leaf-profile-img"
                            onClick={handleProfileOpen}
                          />
                        )}

                        {/* {!viewImage ? (
                        <img
                          src={PagesIndex?.Jpg?.dummyImage}
                          className="leaf-profile-img"
                        onClick={handleProfileOpen}

                        />
                      ) : (
                        <img
                          src={
                            viewImage
                              ? viewSingleLeafNode?.imageAddress
                              : viewVedio
                              ? PagesIndex?.Jpg?.videoImg
                              : ""
                          }
                          className="leaf-profile-img"
                        onClick={handleProfileOpen}

                        />
                      )} */}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="follow-card">
                        <Index.Box className="follow-card-cont" onClick={() => handleLikeOpen(viewSingleLeafNode?.likes)}>
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Png.like}></img>
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography variant="p" component="p" className="user-detail-title">
                              Likes
                            </Index.Typography>
                            <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                              {viewSingleLeafNode?.likeCount}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="follow-card">
                        <Index.Box className="follow-card-cont" onClick={() => handleDisLikeOpen(viewSingleLeafNode?.dislikes)}>
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Png.dislike}></img>
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography variant="p" component="p" className="user-detail-title">
                              DisLikes
                            </Index.Typography>
                            <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                              {viewSingleLeafNode?.disLikeCount}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="follow-card">
                        <Index.Box className="follow-card-cont" onClick={() => handleTotelViewsOpen(ideaViwer(viewSingleLeafNode))}>
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Png.AllViews}></img>
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography variant="p" component="p" className="user-detail-title">
                              Total Views
                            </Index.Typography>
                            <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                              {viewSingleLeafNode?.ideaType == "Main Idea" && (
                                <Index.Typography className="card-price">
                                  {viewSingleLeafNode?.totalView}
                                </Index.Typography>
                              )}
                              {viewSingleLeafNode?.ideaType == "Evolve Idea" && (
                                <Index.Typography className="card-price">
                                  {viewSingleLeafNode?.totalLeafView}
                                </Index.Typography>
                              )}
                              {viewSingleLeafNode?.ideaType == "Unite Idea" && (
                                <Index.Typography className="card-price">
                                  {disLikeData?.length}
                                </Index.Typography>
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Grid>

                <Index.Grid item xs={12} md={8} lg={9} className="single-leaf-box">
                  <Index.Grid container spacing={1}>
                    <Index.Grid item xs={12} md={6} lg={6}>
                      <Index.Box className="single-leaf-cont">
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Title :
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border mb-15">
                          {/* <Index.TextField
                            className="admin-input-design input-placeholder"
                            id="title"
                            value={viewSingleLeafNode?.title}
                            InputProps={{
                              readOnly: true,
                            }}
                          /> */}
                          {viewSingleLeafNode?.title}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={6} lg={6}>
                      <Index.Box className="single-leaf-cont">
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Idea Type :
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border mb-15">
                          {viewSingleLeafNode?.ideaType}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={6} lg={6}>
                      <Index.Box className="single-leaf-cont">
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Full Name :
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border mb-15">
                          {viewSingleLeafNode?.userId?.fullName}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={6} lg={6}>
                      <Index.Box className="single-leaf-cont">
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Username :
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border mb-15">
                          {viewSingleLeafNode?.userId?.userName}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="Idealeaft-view-inpt-main">
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Description :
                        </Index.Typography>
                        <Index.Box className="user-detail-descr input-design-div with-border mb-15">
                          {description && (
                            <>
                              {showFullDescription ? (
                                <span>{description}</span>
                              ) : (
                                <span>
                                  {description.length > 326
                                    ? description.slice(0, 326) + "..."
                                    : description}
                                </span>
                              )}
                              {description.length > 326 && (
                                <Index.Button
                                  className="read-btn"
                                  onClick={toggleDescription}
                                >
                                  {showFullDescription ? " Read Less" : " Read More"}
                                </Index.Button>
                              )}
                            </>
                          )}
                          {/* {viewSingleLeafNode.descriptions} */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                  <Index.Grid container spacing={1}>
                    <Index.Grid item xs={12} md={12} lg={12}>
                      <Index.Box className="leaf-categ-box">
                        <Index.Box className="user-detail">
                          <Index.Typography variant="p" component="p" className="user-detail-title">
                            Category
                          </Index.Typography>
                          <Index.Typography variant="p" component="p" className="user-detail-subtitle leaf-detail">
                            <Index.Box className="input-design-div with-border">
                              <Stack direction="row" spacing={1}>
                                {
                                  viewSingleLeafNode?.category ? (
                                    viewSingleLeafNode?.category.map(function (item) {
                                      return <Chip label={item?.categoryName} />
                                    }
                                    ))
                                    : (
                                      <p className="not-found-para">
                                        No Category Availbale
                                      </p>
                                    )
                                }
                              </Stack>
                            </Index.Box>
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="user-detail">
                          <Index.Typography variant="p" component="p" className="user-detail-title">
                            Sub Category
                          </Index.Typography>
                          <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                            <Index.Box className="input-design-div with-border">
                              <Stack direction="row" spacing={1}>
                                {viewSingleLeafNode?.sub_category ? (
                                  <>
                                    <Chip label={viewSingleLeafNode?.sub_category} />
                                  </>
                                ) : (
                                  <p className="not-found-para">
                                    No Subcategory Availbale
                                  </p>
                                )}
                              </Stack>
                            </Index.Box>
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="user-detail">
                          <Index.Typography variant="p" component="p" className="user-detail-title">
                            #Tags
                          </Index.Typography>
                          <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                            <Index.Box className="input-design-div with-border">
                              <Stack direction="row" spacing={1}>
                                {
                                  tagsArry ? (
                                    tagsArry?.map((item) => (
                                      <Chip label={item} />
                                    ))
                                  ) : (
                                    <p className="not-found-para">No Tags Availbale</p>
                                  )
                                }

                                {/* {viewSingleLeafNode?.tags?.tagName ? (
                                  <>
                                    <Chip label={viewSingleLeafNode?.tags?.tagName} />
                                  </>
                                ) : (
                                  <p className="not-found-para">No Tags Availbale</p>
                                )} */}
                              </Stack>
                            </Index.Box>
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Grid>

              </Index.Grid>
            </Index.Box>
          </Index.Grid>
        </Index.Box>
      </Index.Box>



      <Index.Modal
        open={openProfile}
        onClose={handleProfileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal profile-zoom-modal"
      >
        <Index.Box
          sx={style}
          className="profile-zoom-modal-inner modal-inner-main model-zoom-img"
        >
          {/* {viewImage && (
                          <img
                            id="yourImgId"
                            src={viewSingleLeafNode?.imageAddress}
                            className="leaf-profile-zoom-img"
                          />
                        )} */}
          {/* {viewVedio && (
                          <video width="640" height="360" controls>
                            <source
                              src={viewSingleLeafNode?.imageAddress}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )} */}
          <Index.Box className="modal-header title-main">
            <Index.Box className="edit-modal-circle-main">
              <img
                src={PagesIndex.Svg.close}
                className="modal-close-icon user-circle-img"
                onClick={handleProfileClose}
              />
            </Index.Box>
            <Index.Typography
              id="modal-modal-title"
              className="page-title"
              variant="h6"
              component="h2"
            >
              {ViewModelHeading}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="common-modal-body">
            {modelLoadingImage ? (
              <Index.Box className="common-modal-loader">
                <PagesIndex.PageLoading colSpan={12} />
              </Index.Box>
            ) : (
              <img
                id="yourImgId"
                src={`${PagesIndex.ImageURL}${viewSingleLeafNode?.imageAddress}`}
                className="leaf-profile-zoom-img"
              />
            )}
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      {/* likes dislike model----- */}

      <Index.Modal
        open={openLikesDislike}
        onClose={handleDisLikeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="episap-modal-box"
        >
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography variant="p" component="p" className="page-title">
                      {ViewModelHeading}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img src={PagesIndex.Svg.whiteclose} className="modal-close-icon user-circle-img" onClick={handleDisLikeClose} />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              {disLikeData && disLikeData.length > 0
                ? disLikeData?.map((user) => {
                  return (
                    <Index.Box className="list modal-total-views" key={user._id}>
                      <Index.Box className="image-main-wrapper">
                        <Index.Box className="image-wrape">
                          <img
                            className="user-profile-view"
                            src={
                              user.profile
                                ? `${PagesIndex.ImageURL}${user.profile}`
                                : PagesIndex.Jpg.dummyImage
                            }
                            alt="loading..."
                          />
                        </Index.Box>
                        <Index.Box className="user-detail-title">
                          <Index.Typography className="user-detail-title">
                            {user?.fullName}
                          </Index.Typography>
                          <Index.Typography className="user-detail-subtitle">
                            {user?.userName}
                          </Index.Typography>
                          <Index.Box className="user-detail-subtitle">
                            <Index.Box className="date-like-dislike-model">
                              {moment(user?.createdAt).format("DD/MM/YYYY")}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  );
                })
                : `No ${ViewModelHeading} `}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      {/* <Index.Box className="page-content-box">
        <Index.Box className="mini-card-main">
          <Index.Grid container spacing={5}>
            <Index.Grid item lg={4} md={4} sm={6} xs={12}>
              <Index.Box className="">
                <Index.Box className="card-right-content">
                  <Index.Box className="mini-card-img">
                    <img src={PagesIndex.Png.like}></img>
                  </Index.Box>
                  <Index.Box className="card-right-content-box">
                    <Index.Typography className="card-total">
                      Likes
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      {viewSingleLeafNode?.likeCount}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item lg={4} md={4} sm={6} xs={12}>
              <Index.Box className="">
                <Index.Box className="card-right-content">
                  <Index.Box className="mini-card-img">
                    <img src={PagesIndex.Png.dislike}></img>
                  </Index.Box>
                  <Index.Box className="card-right-content-box">
                    <Index.Typography className="card-total">
                      DisLikes
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      {viewSingleLeafNode?.disLikeCount}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item lg={4} md={4} sm={6} xs={12}>
              <Index.Box className="">
                <Index.Box className="card-right-content">
                  <Index.Box className="mini-card-img">
                    <img src={PagesIndex.Png.card1}></img>
                  </Index.Box>
                  <Index.Box className="card-right-content-box">
                    <Index.Typography className="card-total">
                      Totel Views
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      {viewSingleLeafNode?.totalView}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
        <Index.Box className="barge-common-box">
          <Index.Box sx={{ flexGrow: 1 }}>
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={4}>
                <Index.Box
                  className="leaf-profile-main"
                  onClick={handleProfileOpen}
                >
                  {!viewImage ? (
                    <img
                      src={PagesIndex?.Jpg?.dummyImage}
                      className="leaf-profile-img"
                    />
                  ) : (
                    <img
                      src={
                        viewImage
                          ? viewSingleLeafNode?.imageAddress
                          : viewVedio
                          ? PagesIndex?.Jpg?.videoImg
                          : ""
                      }
                      className="leaf-profile-img"
                    />
                  )}
                </Index.Box>
           
                <Index.Modal
                  open={openProfile}
                  onClose={handleProfileClose}
                  className="profile-zoom-modal modal"
                >
                  <Index.Box className="profile-zoom-modal-inner modal-inner">
                    {viewImage && (
                      <img
                        id="yourImgId"
                        src={viewSingleLeafNode.imageAddress}
                        className="leaf-profile-zoom-img"
                      />
                    )}
                    {viewVedio && (
                      <video width="640" height="360" controls>
                        <source
                          src={viewSingleLeafNode.imageAddress}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Index.Box>
                </Index.Modal>
        
              </Index.Grid>
              <Index.Grid item xs={4}>
                <InputLabel htmlFor="title">Title</InputLabel>

                <Index.TextField
                  className="admin-input-design input-placeholder"
                  id="title"
                  value={viewSingleLeafNode?.title}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Index.Grid>
              <Index.Grid item xs={4}>
                <InputLabel htmlFor="ideaType">IdeaType</InputLabel>
                <Index.TextField
                  className="admin-input-design input-placeholder"
                  id="ideaType"
                  value={viewSingleLeafNode?.ideaType}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Index.Grid>
              <Index.Grid item xs={12}>
                <Index.Box className="Idealeaft-view-inpt-main">
                  <InputLabel htmlFor="desc-label">Descriptions</InputLabel>
                  <Index.TextareaAutosize
                    className="textarea-control"
                    label="Descriptions"
                    id="desc-label"
                    multiline
                    value={viewSingleLeafNode.descriptions}
                    InputProps={{
                      readOnly: true,
                    }}
                    maxRows={5}
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12}>
                <span className="title">Category</span>
                <Stack direction="row" spacing={1}>
                  {viewSingleLeafNode?.category &&
                  viewSingleLeafNode?.category.length > 0 ? (
                    viewSingleLeafNode?.category?.map((item) => {
                      return (
                        <>
                          <Chip label={item.categoryName} />
                        </>
                      );
                    })
                  ) : (
                    <p className="not-found-para">No Category Availbale</p>
                  )}
                </Stack>
              </Index.Grid>
              <Index.Grid item xs={12}>
                <span className="title">Subcategory</span>
                <Stack direction="row" spacing={1}>
                  {viewSingleLeafNode?.sub_category &&
                  viewSingleLeafNode?.sub_category.length > 0 ? (
                    viewSingleLeafNode?.sub_category?.map((item) => {
                      return (
                        <>
                          <Chip label={item.subCategoryName} />
                        </>
                      );
                    })
                  ) : (
                    <p className="not-found-para">No Subcategory Availbale</p>
                  )}
                </Stack>
              </Index.Grid>
              <Index.Grid item xs={12}>
                <span className="title">#tag</span>
                <Stack direction="row" spacing={1}>
                  {viewSingleLeafNode?.tags &&
                  viewSingleLeafNode?.tags.length > 0 ? (
                    viewSingleLeafNode?.tags?.map((item) => {
                      return (
                        <>
                          <Chip label={item.tagName} />
                        </>
                      );
                    })
                  ) : (
                    <p className="not-found-para">No Tags Availbale</p>
                  )}
                </Stack>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
      </Index.Box> */}
    </>
  );
};

export default ViewSingleLeafBranch;
