import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Tree } from "react-d3-tree";
import {
  // getIdeaToleafIdeaAction,
  getTreeNFTList,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import "./IdeaTreeView.css";

function IdeaTreeView() {
  // state declaration
  const [loading, setLoading] = useState(true);
  const [ideaTreeData, setIdeaTreeData] = useState(null); // Initialize as null
  const [viewSingleLeafNode, setViewSingleLeafNode] = useState([]);
  const [leafNodeViewModalOpen, setLeafNodeViewModalOpen] = useState(false);
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { state } = PagesIndex.useLocation();
  let navigate = PagesIndex.useNavigate();

  // const createTreeData = (data, parentId) => {
  //   const children = data
  //     ?.filter((item) => item.parentId == parentId)
  //     ?.map((item) => createTreeData(data, item?._id));

  //   return {
  //     leafImage: data
  //       ? data?.find((item) => item._id == parentId)?.imageAddress
  //       : PagesIndex.Jpg.dummyImage,
  //     id: parentId,
  //     name: data
  //       ? data?.find((item) => item._id == parentId)?.title || "Main Idea"
  //       : "Main Idea",
  //     children: children?.length > 0 ? children : null,
  //   };
  // };

  const renderNodeWithCustomEvents = ({
    nodeDatum,
    toggleNode,
    handleNodeClick,
  }) => {
    return (
      <g>
        <defs>
          <clipPath id="circleClip">
            <circle cx="0" cy="0" r="50" />
          </clipPath>
        </defs>
        <circle
          r={50}
          className="tree-node-line"
          fill="#E9E9D5"
          onClick={toggleNode}
        />
        <Index.Tooltip title={nodeDatum?.title}>
          <g clipPath="url(#circleClip)">
            <image
              x="-50" // Adjust the image position to center within the circle
              y="-50"
              width="100" // Set the width and height to match the circular clip path dimensions
              height="100"
              preserveAspectRatio="none" // Allow the image to cover the circle
              onClick={() => handleNodeClick(nodeDatum?._id)}
              href={`${PagesIndex.ImageURL}${nodeDatum.displayImage}`} // Replace with your image URL
            />
          </g>
        </Index.Tooltip>
      </g>
    );
  };

  const buildTree = (data, parent_id) => {
    const tree = [];
    data.forEach((item) => {
      if (item.parentId === parent_id) {
        const children = buildTree(data, item._id);
        if (children.length > 0) {
          item.children = children;
        }
        tree.push(item);
      }
    });
    return tree;
  };

  const getIdeaTreeNodes = () => {
    getTreeNFTList(state?.main_Idea_id, adminToken)
      .then((res) => {
        let evolvedIdea = [res.data?.idea, ...res.data.leafIdeas];
        let TreeDataGenerate = buildTree(evolvedIdea, res.data?.idea?.parentId);
        setIdeaTreeData(TreeDataGenerate);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((error) => {
        // console.error("Error fetching idea tree data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getIdeaTreeNodes();
  }, [state]);

  const handleLeafViewOpen = () => {
    setLeafNodeViewModalOpen(true);
    // setAnchorEl(null);
  };
  const handleLeafViewClose = () => {
    setLeafNodeViewModalOpen(false);
    // setAnchorEl(null);
    setViewSingleLeafNode([]);
  };

  // Tree view functions start----------
  const handleNodeClick = (id) => {
    if (id?.length > 1) {
      let nodeId = [id];
      navigate("/admin/view-single-leaf", {
        state: { nodeId },
      });
    }

    if (id?.length == 1) {
      let nodeId = id[0];
      navigate("/admin/view-single-leaf", {
        state: { nodeId },
      });
    }
  };

  const backNavigate = () => {
    navigate("/admin/nft-list");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        {ideaTreeData && (
          <Index.Box className="barge-common-box">
            <Index.Box className="back-arrow">
              <img
                className="bck-ar"
                src={PagesIndex.Png.arrowBack}
                onClick={backNavigate}
              />
            </Index.Box>

            <Index.Box className="title-header">
              <Index.Box className="title-header-flex res-title-header-flex tree-head">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Idea list Tree View
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="table-box table_scroll table-tree-loader">
              {loading ? (
                <Index.Box className="tree-loader">
                  {" "}
                  <PagesIndex.PageLoading colSpan={12} />
                </Index.Box>
              ) : (
                <Index.Box style={{ width: "100%", height: "500px" }}>
                  <Tree
                    data={ideaTreeData}
                    orientation="vertical"
                    pathFunc="straight"
                    renderCustomNodeElement={(rd3tProps) =>
                      renderNodeWithCustomEvents({
                        ...rd3tProps,
                        handleNodeClick,
                      })
                    }
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    nodeLabel={"name"}
                    translate={{ x: 550, y: 40 }}
                    scaleExtent={{ min: 0.1, max: 0.6 }}
                    nodeSvgShape={{
                      shape: "circle",
                      shapeProps: {
                        r: 50,
                      },
                    }}
                    transitionDuration={300}
                    enableLegacyTransitions={true}
                    className="mt-10"
                  />
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        )}
      </Index.Box>
      <PagesIndex.ViewSingleLeafnodeModel
        //  handleDeleteRecord={handleDelete}
        handleModelData={viewSingleLeafNode}
        handleDeleteClose={handleLeafViewClose}
        deleteOpen={leafNodeViewModalOpen}
      />
    </>
  );
}

export default IdeaTreeView;
