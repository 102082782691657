import React from "react";

export default function AboutUsBlue() {
  return (
    // <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <g clip-path="url(#clip0_371_965)">
    //         <path d="M19.9992 0C8.95492 0 0 8.95492 0 19.9992C0 31.0434 8.95492 40 19.9992 40C31.0434 40 40 31.0434 40 19.9992C40 8.95492 31.0434 0 19.9992 0ZM24.1625 30.996C23.1331 31.4023 22.3137 31.7105 21.699 31.9238C21.0861 32.1371 20.3733 32.2438 19.5623 32.2438C18.3162 32.2438 17.346 31.939 16.6552 31.3312C15.9644 30.7234 15.6207 29.953 15.6207 29.0167C15.6207 28.6527 15.6461 28.2802 15.6969 27.901C15.7494 27.5217 15.8324 27.095 15.9458 26.6159L17.2343 22.0648C17.3477 21.6279 17.4459 21.2131 17.5238 20.8271C17.6017 20.4377 17.6389 20.0804 17.6389 19.7553C17.6389 19.1763 17.5187 18.7699 17.28 18.5414C17.0379 18.3128 16.5824 18.2011 15.9035 18.2011C15.5716 18.2011 15.2296 18.2502 14.8792 18.3534C14.5321 18.4601 14.2307 18.5566 13.9835 18.6514L14.3238 17.2495C15.167 16.9058 15.9746 16.6112 16.745 16.3674C17.5153 16.1202 18.2434 15.9983 18.9291 15.9983C20.1668 15.9983 21.1217 16.2997 21.7939 16.8957C22.4626 17.4933 22.7996 18.2705 22.7996 19.2254C22.7996 19.4235 22.7759 19.7723 22.7302 20.2701C22.6844 20.7695 22.5981 21.225 22.4728 21.6432L21.1911 26.1807C21.0861 26.5448 20.993 26.9613 20.9084 27.4269C20.8254 27.8925 20.7848 28.248 20.7848 28.4868C20.7848 29.0895 20.9185 29.501 21.1894 29.7194C21.4569 29.9378 21.9259 30.0478 22.5896 30.0478C22.9029 30.0478 23.2533 29.992 23.6495 29.8836C24.0423 29.7752 24.3268 29.6787 24.5062 29.5958L24.1625 30.996ZM23.9357 12.5782C23.338 13.1335 22.6184 13.4112 21.7769 13.4112C20.9371 13.4112 20.2125 13.1335 19.6097 12.5782C19.0104 12.0229 18.7073 11.3473 18.7073 10.5583C18.7073 9.77101 19.0121 9.09376 19.6097 8.53333C20.2125 7.97122 20.9371 7.69185 21.7769 7.69185C22.6184 7.69185 23.3397 7.97122 23.9357 8.53333C24.5333 9.09376 24.833 9.77101 24.833 10.5583C24.833 11.349 24.5333 12.0229 23.9357 12.5782Z" fill="#005C8A" />
    //     </g>
    //     <defs>
    //         <clipPath id="clip0_371_965">
    //             <rect width="40" height="40" fill="white" />
    //         </clipPath>
    //     </defs>
    // </svg>

    // <svg
    //   width="40"
    //   height="39"
    //   viewBox="0 0 40 39"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M39.1687 29.6313L25.0387 3.65675C22.7685 -0.165188 17.2344 -0.170266 14.9611 3.65675L0.831784 29.6313C-1.48899 33.5367 1.32077 38.4821 5.86941 38.4821H34.1299C38.6747 38.4821 41.4895 33.5407 39.1687 29.6313ZM19.9999 33.7946C18.7079 33.7946 17.6562 32.7429 17.6562 31.4509C17.6562 30.1589 18.7079 29.1072 19.9999 29.1072C21.2919 29.1072 22.3436 30.1589 22.3436 31.4509C22.3436 32.7429 21.2919 33.7946 19.9999 33.7946ZM22.3436 24.4197C22.3436 25.7117 21.2919 26.7634 19.9999 26.7634C18.7079 26.7634 17.6562 25.7117 17.6562 24.4197V12.701C17.6562 11.409 18.7079 10.3573 19.9999 10.3573C21.2919 10.3573 22.3436 11.409 22.3436 12.701V24.4197Z"
    //     fill="#005C8A"
    //   />
    // </svg>

    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_59_2)">
        <path
          d="M9.14062 0C5.99069 0 3.42773 2.56296 3.42773 5.71289C3.42773 8.86283 5.99069 11.4258 9.14062 11.4258C12.2906 11.4258 14.8535 8.86283 14.8535 5.71289C14.8535 2.56296 12.2906 0 9.14062 0Z"
          fill="#005C8A"
        />
        <path
          d="M31.7337 9.47532C31.2874 9.02903 30.5643 9.02903 30.118 9.47532L29.7832 9.8101V7.99805C29.7832 6.10792 28.2456 4.57031 26.3555 4.57031H19.4238C18.7923 4.57031 18.2812 5.08135 18.2812 5.71289C18.2812 6.34443 18.7923 6.85547 19.4238 6.85547H26.3555C26.9859 6.85547 27.498 7.36765 27.498 7.99805V9.8101L27.1633 9.47532C26.717 9.02903 25.9939 9.02903 25.5476 9.47532C25.1013 9.92162 25.1013 10.6447 25.5476 11.091L27.8314 13.3748C28.2847 13.8292 29.0098 13.816 29.4499 13.3748L31.7337 11.091C32.18 10.6447 32.18 9.92162 31.7337 9.47532Z"
          fill="#005C8A"
        />
        <path
          d="M17.1387 31.9922H10.2832C9.65281 31.9922 9.14063 31.48 9.14063 30.8496V29.0375L9.4754 29.3723C9.9217 29.8186 10.6448 29.8186 11.0911 29.3723C11.5374 28.926 11.5374 28.2029 11.0911 27.7566L8.8073 25.4728C8.70157 25.3668 8.5749 25.2827 8.43436 25.2246C7.99485 25.0428 7.50377 25.1571 7.18888 25.4728L4.90509 27.7566C4.4588 28.2029 4.4588 28.926 4.90509 29.3723C5.35138 29.8186 6.07448 29.8186 6.52077 29.3723L6.85547 29.0375V30.8496C6.85547 32.7397 8.39308 34.2773 10.2832 34.2773H17.1387C17.7702 34.2773 18.2813 33.7663 18.2813 33.1347C18.2813 32.5032 17.7702 31.9922 17.1387 31.9922Z"
          fill="#005C8A"
        />
        <path
          d="M29.7832 15.9961C26.6333 15.9961 23.9941 18.559 23.9941 21.709C23.9941 24.8589 26.6333 27.4219 29.7832 27.4219C32.9331 27.4219 35.5723 24.8589 35.5723 21.709C35.5723 18.559 32.9331 15.9961 29.7832 15.9961Z"
          fill="#005C8A"
        />
        <path
          d="M14.897 11.2399C13.4405 12.7564 11.4039 13.711 9.14062 13.711C6.87733 13.711 4.84072 12.7564 3.38424 11.2399C1.33621 12.9175 0 15.4335 0 18.2813V21.709C0 22.3405 0.511037 22.8516 1.14258 22.8516H17.1387C17.7702 22.8516 18.2812 22.3405 18.2812 21.709V18.2813C18.2812 15.4335 16.945 12.9175 14.897 11.2399Z"
          fill="#005C8A"
        />
        <path
          d="M35.6158 27.236C34.1593 28.7525 32.0465 29.7071 29.7832 29.7071C27.5199 29.7071 25.4071 28.7525 23.9506 27.236C21.9026 28.9136 20.5664 31.4296 20.5664 34.2774V37.8574C20.5664 38.489 21.0774 39 21.709 39H37.8574C38.489 39 39 38.489 39 37.8574V34.2774C39 31.4296 37.6638 28.9136 35.6158 27.236Z"
          fill="#005C8A"
        />
      </g>
      <defs>
        <clipPath id="clip0_59_2">
          <rect width="39" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
