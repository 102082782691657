// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAP1pb5dMvaYznwSu9PPszss67uCNxBKYw",
  authDomain: "episap.firebaseapp.com",
  projectId: "episap",
  storageBucket: "episap.appspot.com",
  messagingSenderId: "378987795443",
  appId: "1:378987795443:web:0ba15fb7e3092108eb3886",
  measurementId: "G-50TD6MNHQ4"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);