import React from "react";
import Index from "../Index";
import PageIndex from "../../../src/container/PagesIndex"

const InternalServerError = () => {
    const navigate = PageIndex.useNavigate();
  return (
    <Index.Box >
      <Index.Box>
        <Index.Typography className="pagenotfound-text-heading" >Internal Server Error </Index.Typography>
        <Index.Typography className="pagenotfound-text">We couldn't find page you are looking for</Index.Typography>
        <Index.Button onClick={()=> navigate('/')} sx={{display:"block",margin:"10px auto",padding:"4px 8px",color:"#fff",fontWeight:"bold",textTransform:"capitalize !important"}} variant="contained">Go to Login Page</Index.Button>
      </Index.Box>
      <Index.Box className='pagenotfound-img-box'>
        <img className="pagenotfound-img servernotfound" src={PageIndex.Png.internalServerErrorStatusCode} />
      </Index.Box>
      
    </Index.Box>
  );
};

export default InternalServerError;
