import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  addEditWalletAddress,
  getWalletAddress,
} from "../../../../redux/admin/action";
import { styled } from "@mui/material/styles";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const WalletAddress = () => {
  const navigate = PagesIndex.useNavigate();
  const [walletData, setWalletData] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  let initialValues = {};
  if (walletData) {
    initialValues = {
      id: walletData ? walletData?.id : "",
      privateKey: walletData?.decryptedString
        ? walletData?.decryptedString
        : "",
    };
  } else {
    initialValues = {
      privateKey: walletData?.decryptedString
        ? walletData?.decryptedString
        : "",
    };
  }

  useEffect(() => {
    getWalletAddress(adminToken, navigate).then((res) => {
      setWalletData(res);
    });
  }, []);

  const handleSubmit = (values) => {
    setLoadingUpdate(true);
    addEditWalletAddress(values, adminToken);
    setTimeout(() => {
      setLoadingUpdate(false);
    }, 2000);
  };

  return (
    <>
      {/* {walletData && ( */}
      <Index.Box className="episap-common-box">
        <Index.Box className="h-100">
          <Index.Box className="card-center">
            <Index.Box className="card-main profile-card-main p-0 w-100">
              <Index.Box className="title-main mb-15 text-center">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Treasury wallet address
                </Index.Typography>
              </Index.Box>
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={PagesIndex.walletAddressSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Index.Box className="profile-inner">
                      <Index.Box className="">
                        <Index.Grid
                          container
                          columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                        >
                          <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                            <Item className="dashboard-item">
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="change-input-label"
                                >
                                  Add Treasury wallet address
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border mb-15">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder=""
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    name="privateKey"
                                    onBlur={handleBlur}
                                    value={values.privateKey}
                                    onChange={handleChange}
                                    helperText={
                                      touched.privateKey && errors.privateKey
                                    }
                                    error={Boolean(
                                      errors.privateKey && touched.privateKey
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Item>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box mt={5} className="editor-btn text-center">
                      <Index.Button
                        className="editor-submit-btn login-disable"
                        type="submit"
                        data-testid="button"
                        variant="contained"
                        disabled={loadingUpdate}
                      >
                        <img
                          alt="save"
                          src={PagesIndex.Png.invoice}
                          className=""
                        />
                        Update
                      </Index.Button>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* )} */}
    </>
  );
};

export default WalletAddress;
