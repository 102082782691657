import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import marketplaceAbi from "../../Connectivity/abi/marketplace.json";
import CircularProgress from "@mui/material/CircularProgress";
import {
  buyIdea,
  buyPriceAddress,
  buySellTransactionHash,
} from "../../../redux/admin/action";
import PagesIndex from "../../PagesIndex";
import WalletLoader from "../../../components/common/WalletLoader";

export default function BuyIdea() {
  const [priceArray, setPriceArray] = useState([]);
  const [sum, setSum] = useState([]);
  const [addressArray, setAddressArray] = useState([]);
  const { walletProvider } = useWeb3ModalProvider();
  const { id, nft_id, user_id } = PagesIndex.useParams();
  const [buyData, setBuyData] = useState([]);
  const [processingLog, setProcessingLog] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let values = { ideaId: id };
    buyPriceAddress(values).then((res) => {
      console.log("response", res);
      let sum = 0;
      res?.data?.ideaAmount.forEach((value) => {
        sum += parseFloat(value);
      });
      setSum(sum);
      setBuyData(res?.data);
      setPriceArray(res?.ideaAmount);
      setAddressArray(res?.walletAddress);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const { isConnected } = useWeb3ModalAccount();
  // console.log(isConnected, ' = isConnected');
  async function handleBuy(priceArray, addressarray) {
    setLoading(true);
    const price = priceArray?.toString();
    const address = addressarray?.toString();
    setProcessingLog("approving the transaction...");
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      "0x3C1e77fb138a2448153008bBe69803285c38ce96",
      tokenAbi,
      signer
    );
    setProcessingLog("performing smart contract transaction...");
    const contract = new ethers.Contract(
      "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
      marketplaceAbi,
      signer
    );

    try {
      let sum = 0;
      buyData.ideaAmount.forEach((value) => {
        sum += parseFloat(value);
      });

      const totaltoWei = ethers.utils.parseEther(sum.toFixed(18));
      // console.log(totaltoWei, 'totaltoWei');
      const tx = await tokenContract.approve(
        "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
        totaltoWei.toString()
      );
      await tx.wait();

      let pricesWeiArray = buyData.ideaAmount.map((value) => {
        const fixedValue = parseFloat(value).toFixed(18);
        // console.log(`Value: ${value}, Fixed Value: ${fixedValue}`);
        return ethers.utils.parseEther(fixedValue).toString();
      });
      // console.log(pricesWeiArray,"pricesWeiArray", nft_id, buyData.walletAddress);
      let platformfee = await contract.buyIdea(
        nft_id,
        buyData.walletAddress,
        pricesWeiArray
      );
      setProcessingLog(
        "Approving smart contract transaction..."
      );
      await platformfee.wait();

      const txReceipt = await provider.getTransactionReceipt(platformfee.hash);
      console.log(txReceipt, "txReceipt", txReceipt.status);
      if (txReceipt.status === 1) {
        if (platformfee.hash) {
          // Transaction hash save
          let transactionHashData = {
            type: "Buy",
            nftId: id,
            userId: user_id,
            transactionHash: platformfee.hash,
            approvedTransactionHash: tx.hash,
          };
          buySellTransactionHash(transactionHashData);

          let tx_hash = platformfee.hash;
          let values = {
            nftId: id,
            tx_hash: tx_hash,
            price: sum,
            user_id: user_id,
            token_id: nft_id,
          };
          setProcessingLog("Executing api calls...");
          setTimeout(() => {
            buyIdea(values).then((res) => {
              setProcessingLog("Payment done. Go Back to your app. 201");
              setLoading(false);
              navigate("/wallet/success");
              // setProcessingLog("");
            });
          }, 1000);
        } else {
          setLoading(false);
          alert("Transaction is not completed. please try again");
        }
      } else {
        setLoading(false);
        alert("Transaction failed");
      }
    } catch (error) {
      setLoading(false);
      alert(error?.message);
      // console.log("error?.message", error?.message)
      console.error("Error while approving:", error);
    }
  }

  // 10 Min timer
  const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer); // Clean up the interval on component unmount
    } else {
      setTimeLeft(600);
    }
  }, [loading]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <div className="wallet-box">
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.newLogo} alt="" />
            <div className="logo-title">EPISAP</div>
          </div>
          <w3m-button />
          <h4 className="wallet-title">
            Buy NFT Idea <br />
            {sum} VHT
          </h4>
          <div className="wallet-btn-approve">
            <button
              onClick={() =>
                handleBuy(buyData?.ideaAmount, buyData?.walletAddress)
              }
              disabled={!isConnected || loading}
              className={`loader-white ${!isConnected ? "disable-color" : ""}`}
            >
              {loading == true ? <CircularProgress size={16} /> : "Buy"}
            </button>
          </div>
          <div className="wallet-subtitle">{processingLog}</div>
          {loading && (
            <>
              <p>
                <strong>
                  The payment is still processing. Please don't close the app or
                  turn off your screen. Wait until you get a response from the
                  wallet. If you don't, you might lose your data.
                </strong>
              </p>
              <h3>Countdown Timer</h3>
              <div style={{ fontSize: "1.5rem" }}>{formatTime(timeLeft)}</div>
            </>
          )}
        </div>
      </div>
      {isVisible && (
        <div className="wallet-loader">
          <WalletLoader />
        </div>
      )}
    </>
  );
}
