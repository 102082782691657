import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getDiscussionAction } from "../../../../redux/admin/action";
import { RingLoader } from "react-spinners";
import TimeAgo from "./TimeAgo";

const CommunityDiscussion = () => {
  const [discussion, setDiscussion] = useState();
  const [loading, setLoading] = useState(true);
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { state } = PagesIndex.useLocation();

  const getDiscussionList = () => {
    getDiscussionAction({ groupId: state.groupId }, adminToken).then((res) => {
      setDiscussion(res?.data);
      setLoading(false);
      // setPageData(res?.data)
    });
  };
  useEffect(() => {
    getDiscussionList();
  }, []);

  return (
    <Index.Box className="barge-common-box">
      <Index.Box className="title-header">
        <Index.Box className="title-header-flex res-title-header-flex">
          <Index.Box className="title-main">
            <Index.Typography variant="p" component="p" className="page-title">
              Group Discussion List
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="table-box table_scroll">
        <Index.Box className="episap-comment-container">
          {!loading ? (
            discussion?.length ? (
              discussion.map((row) => {
                return (
                  <>
                    <Index.Box key={row?._id} className="comment-box-wrap">
                      <Index.Box className="user-image-box">
                        <Index.Avatar
                          className="user-image"
                          src={
                            row?.user?.profile != ""
                              ? PagesIndex.ImageURL + row?.user?.profile
                              : PagesIndex.Jpg.userprofile
                          }
                          loading="lazy"
                        />
                      </Index.Box>
                      <Index.Box className="comment-box">
                        <Index.Box className="user-name-box">
                          <Index.Typography className="user-name">
                            {row?.user?.fullName}
                          </Index.Typography>
                          <Index.Typography className="comment-date-text">
                            <TimeAgo date={row?.createdAt} />
                          </Index.Typography>
                        </Index.Box>

                        {row.fileType != "media" ? (
                          <Index.Typography className="comment-text">
                            {row?.message}
                          </Index.Typography>
                        ) : (
                          <Index.Box className="comment-image-box">
                            <img src={`${PagesIndex.ImageURL}${row?.file}`} loading="lazy" />
                          </Index.Box>
                        )}
                      </Index.Box>
                    </Index.Box>
                    {row?.latestChildren?.length
                      ? row?.latestChildren?.map((child) => {
                          return (
                            <>
                              <Index.Box key={child?._id} className="reply-box">
                                <Index.Box className="comment-box-wrap ">
                                  <Index.Box className="user-image-box">
                                    <Index.Avatar
                                      className="user-image"
                                      src={
                                        child?.user_id?.profile != ""
                                          ? PagesIndex.ImageURL +
                                            child?.user_id?.profile
                                          : PagesIndex.Jpg.userprofile
                                      }
                                      loading="lazy"
                                    />
                                  </Index.Box>
                                  <Index.Box className="comment-box">
                                    <Index.Box className="user-name-box">
                                      <Index.Typography className="user-name">
                                        {" "}
                                        {child?.user_id?.fullName}
                                      </Index.Typography>
                                      <Index.Typography className="comment-date-text">
                                        <TimeAgo date={child?.createdAt} />
                                      </Index.Typography>
                                    </Index.Box>

                                    {child.fileType != "media" ? (
                                      <Index.Typography className="comment-text">
                                        {child?.reply_message}
                                      </Index.Typography>
                                    ) : (
                                      <Index.Box className="comment-image-box">
                                        <img
                                          src={`${PagesIndex.ImageURL}${child?.file}`}
                                          loading="lazy"
                                        />
                                      </Index.Box>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>

                              {child?.latestSubReplies?.length
                                ? child?.latestSubReplies?.map((subChild) => {
                                    return (
                                      <Index.Box key={subChild?._id} className="sub-reply-box">
                                        <Index.Box className="comment-box-wrap">
                                          <Index.Box className="user-image-box">
                                            <Index.Avatar
                                              className="user-image"
                                              src={
                                                subChild?.user_id?.profile != ""
                                                  ? PagesIndex.ImageURL +
                                                    subChild?.user_id?.profile
                                                  : PagesIndex.Jpg.userprofile
                                              }
                                              loading="lazy"
                                            />
                                          </Index.Box>
                                          <Index.Box className="comment-box">
                                            <Index.Box className="user-name-box">
                                              <Index.Typography className="user-name">
                                                {subChild?.user_id?.fullName}
                                              </Index.Typography>
                                              <Index.Typography className="comment-date-text">
                                                <TimeAgo
                                                  date={subChild?.createdAt}
                                                />
                                              </Index.Typography>
                                            </Index.Box>

                                            {subChild.fileType != "media" ? (
                                              <Index.Typography className="comment-text">
                                                {subChild?.reply_message}
                                              </Index.Typography>
                                            ) : (
                                              <Index.Box className="comment-image-box">
                                                <img
                                                  src={`${PagesIndex.ImageURL}${subChild?.file}`}
                                                  loading="lazy"
                                                />
                                              </Index.Box>
                                            )}
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    );
                                  })
                                : null}
                            </>
                          );
                        })
                      : null}
                  </>
                );
              })
            ) : (
              <Index.Box className="community-discussion-loading-box">
                <Index.Typography className="community-discussion-not-found">
                  NO DATA AVAILABLE
                </Index.Typography>
              </Index.Box>
            )
          ) : (
            <Index.Box className="community-discussion-loading-box">
              <RingLoader
                color="#a6ccff"
                loading="loading"
                size={50}
                className="loader"
              />
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>

      {/* table end */}
    </Index.Box>
  );
};

export default React.memo(CommunityDiscussion);
