import React, { useState } from "react";
import Index from "../../../Index";
import MuiPhoneNumber from "material-ui-phone-number";
import PagesIndex from "../../../PagesIndex";
import {
  addMasterWardenAction,
  editMasterWardenAction,
} from "../../../../redux/admin/action";

import { validateMobileNo } from "../../../../validation/Validation";
import { ariaLabel } from "../addUser/UserModal.Style";

const AddEditMasterWarden = ({ getWardenList, handelClose, updateWarden }) => {
  // state  declaration
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [mobileChange, setMobileChange] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const wardenData = updateWarden;

  // console.log(wardenData, "wardenData");
  // initital values declaration
  let initialValues = {};
  if (wardenData) {
    initialValues = {
      email: wardenData ? wardenData.email : "",
      fullName: wardenData ? wardenData.fullName : "",
      mobileNumber: wardenData
        ? String(`${wardenData?.countryCode}${wardenData?.mobileNumber}`)
        : " ",
      countryCode: wardenData ? wardenData.countryCode : "in",
      country: wardenData ? wardenData.country : "",
      id: wardenData ? wardenData._id : "",
      walletAddress: wardenData ? wardenData.walletAddress : "",
    };
  } else {
    initialValues = {
      email: "",
      fullName: "",
      walletAddress: "",
      mobileNumber: " ",
      countryCode: "",
      country: "",
      confirmPassword: "",
      password: "",
      walletAddress: "",
    };
  }

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  // function declaration
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    if (!mobileChange) {
      values.mobileNumber = wardenData.mobileNumber;
    }
    if (mobileError === "") {
      setIsButtonDisabled(true);
      if (!wardenData) {
        addMasterWardenAction(values, adminToken).then((res) => {
          if (res?.status === 201) {
            setIsButtonDisabled(false);
            setSubmitting(false);
            getWardenList();
            handelClose();
          } else {
            setTimeout(() => {
              setIsButtonDisabled(false);
              setSubmitting(false); // Don't forget to setSubmitting(false) if you're using it
            }, 1000); // Simulating 1 second delay for the API request
          }
        });
      } else {
        editMasterWardenAction(values, adminToken).then((res) => {
          if (res?.status === 200) {
            setIsButtonDisabled(false);
            setSubmitting(false);
            getWardenList();
            handelClose();
          } else {
            setTimeout(() => {
              setIsButtonDisabled(false);
              setSubmitting(false); // Don't forget to setSubmitting(false) if you're using it
            }, 1000); // Simulating 1 second delay for the API request
          }
        });
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Index.Box className="episap-modal-box">
      <Index.Box className="episap-modal-header">
        <Index.Box className="edit-modal-circle-main">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {wardenData ? "Edit" : "Add"} Master Warden
                </Index.Typography>
                {/* <Index.Typography variant="p" component='p' className='page-sub-title'>
                            Please fill the input blow here
                        </Index.Typography> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-circle-img" onClick={handelClose}>
            <img
              src={PagesIndex.Svg.whiteclose}
              className="user-circle-img"
              alt="icon"
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-design-div login-input-design-div">
        <PagesIndex.Formik
          enableReinitialize
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={
            !wardenData
              ? PagesIndex.addWardenValidationSchema
              : PagesIndex.editWardenValidationSchema
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Index.Stack
              component="form"
              spacing={2}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Index.Box className="register-all-inputs">
                <Index.Box
                  autoComplete="off"
                  className="reg-input warden-modal-box"
                >
                  <Index.Typography className="re-input-label" variant="label">
                    Full Name
                  </Index.Typography>
                  <Index.TextField
                    inputProps={ariaLabel}
                    value={values?.fullName}
                    name="fullName"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (e.target.value.length <= 51) {
                        const capitalizedValue =
                          e?.target?.value?.charAt(0)?.toUpperCase() +
                          e.target.value.slice(1);
                        setFieldValue("fullName", capitalizedValue);
                      }
                    }}
                    helperText={touched.fullName && errors.fullName}
                    error={Boolean(errors.fullName && touched.fullName)}
                  />
                </Index.Box>

                <Index.Box
                  autoComplete="off"
                  className="reg-input warden-modal-box"
                >
                  <Index.Typography className="re-input-label" variant="label">
                    Wallet Address
                  </Index.Typography>
                  <Index.TextField
                    inputProps={ariaLabel}
                    value={values?.walletAddress}
                    name="walletAddress"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.walletAddress && errors.walletAddress}
                    error={Boolean(
                      errors.walletAddress && touched.walletAddress
                    )}
                  />
                  {/* <span className="error-text">
                    {errors.walletAddress && errors.walletAddress}
                  </span> */}
                </Index.Box>

                <Index.Box
                  autoComplete="off"
                  className="reg-input warden-modal-box"
                >
                  <Index.Typography className="re-input-label" variant="label">
                    Email
                  </Index.Typography>
                  <Index.TextField
                    autocomplete="off"
                    inputProps={ariaLabel}
                    value={values?.email}
                    name="email"
                    onChange={(e) => {
                      setFieldValue("email", e.target.value.toLowerCase());
                    }}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email}
                    error={Boolean(errors.email && touched.email)}
                  />
                  {/* <span className="error-text">
                    {errors.email && errors.email}
                  </span> */}
                </Index.Box>

                <Index.Box
                  autoComplete="off"
                  className="reg-input warden-modal-box reg-mobile-num"
                >
                  <Index.Typography className="re-input-label" variant="label">
                    Mobile Number
                  </Index.Typography>

                  <MuiPhoneNumber
                    defaultCountry={"in"}
                    value={values?.mobileNumber}
                    onBlur={handleBlur}
                    onChange={(value, countryData) => {
                      if (value.length > 0 && value.length != 0) {
                        const phoneNumberDigits = value.replace(/\D/g, ""); // Remove non-numeric characters
                        let countryCode = countryData?.dialCode;
                        setFieldValue(
                          "mobileNumber",
                          +phoneNumberDigits.slice(
                            countryCode.length,
                            phoneNumberDigits.length
                          )
                        );
                        setFieldValue(
                          "countryCode",
                          `${"+"}${countryData.dialCode}`
                        );
                        setFieldValue("country", countryData.name);
                        // setFieldValue('mobileNumber', parseInt(paddedMobileNumber, 10));
                      }
                      setMobileError(
                        validateMobileNo(value, countryData.dialCode)
                      );
                      setMobileChange(true);
                    }}
                    helperText={touched.mobileNumber && errors.mobileNumber}
                    error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                  />
                  <span className="error-text">
                    {!errors.mobileNumber && mobileError && touched.mobileNumber ? mobileError : ""}
                  </span>
                </Index.Box>

                {!wardenData ? (
                  <>
                    <Index.Box
                      autoComplete="off"
                      className="reg-input warden-modal-box"
                    >
                      <Index.Typography
                        className="re-input-label"
                        variant="label"
                      >
                        Password
                      </Index.Typography>
                      <Index.TextField
                        autocomplete="off"
                        inputProps={ariaLabel}
                        value={values?.password}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={showNewPassword ? "text" : "password"}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                className="new-password-toggle"
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                        helperText={touched.password && errors.password}
                        error={Boolean(errors.password && touched.password)}
                      />
                      {/* <span className="error-text">
                        {errors.password && errors.password}
                      </span> */}
                    </Index.Box>
                    <Index.Box
                      autoComplete="off"
                      className="reg-input warden-modal-box"
                    >
                      <Index.Typography
                        className="re-input-label"
                        variant="label"
                      >
                        Confirm Password
                      </Index.Typography>

                      <Index.TextField
                        inputProps={ariaLabel}
                        value={values?.confirmPassword}
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                className="confirm-password-toggle"
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        error={Boolean(
                          errors.confirmPassword && touched.confirmPassword
                        )}
                      />
                      {/* <span className="error-text">
                        {errors.confirmPassword && errors.confirmPassword}
                      </span> */}
                    </Index.Box>
                  </>
                ) : null}
              </Index.Box>

              <Index.Box className="modal-footer-btn pt-0">
                <Index.Button
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  disabled={isButtonDisabled || isSubmitting}
                >
                  {wardenData ? "UPDATE" : "SAVE"}
                  {/* {isSubmitting ? 'Submitting...' : 'Submit'} */}
                </Index.Button>
              </Index.Box>
            </Index.Stack>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </Index.Box>
  );
};

export default AddEditMasterWarden;
