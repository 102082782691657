import React, { useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { Grid } from "@mui/material";
import { RingLoader } from "react-spinners";

const ViewDocument = () => {
  // const declaration
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);

  console.log(state, "state");

  setTimeout(() => {
    setLoading(false);
  }, 500);

  const handleBack = () => {
    navigate("/admin/kyc-request-list");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box ">
          <Index.Grid item xs={12}>
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex res-title-header-flex justify-content-between">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Experts Documents
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="form-back-btn">
                  <Index.Button
                    variant="contained"
                    className="back-btn"
                    type="back"
                    onClick={() => handleBack()}
                  >
                    Back
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Grid>

          <Index.Box className="table-box table_scroll">
            {!loading ? (
              state?.document?.length ? (
                <Index.Box className="image-modal-box icon-box-all">
                  <Grid container spacing={2}>
                    {state?.document?.map((item) => {
                      const extension = item.split(".").pop();
                      // console.log(extension, "extension");
                      return (
                        <Grid item xs={1.2}>
                          <Index.Box className="document-box icon-img-box">
                            {extension === "pdf" || extension === "PDF" ? (
                              <a
                                className="upload-document-img"
                                href={`${PagesIndex.ImageURL}${item}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={PagesIndex.Svg.PdfImage}
                                  alt={`PDF Document: ${item}`}
                                />
                              </a>
                            ) : extension === "jpg" ||
                              extension === "jpeg" ||
                              extension === "JPG" ||
                              extension === "PNG" ||
                              extension === "png" ? (
                              <a
                                className="upload-document-img"
                                href={`${PagesIndex.ImageURL}${item}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={PagesIndex.Svg.imageSvg}
                                  alt={`Image Document: ${item}`}
                                />
                              </a>
                            ) : (
                              <a
                                className="upload-document-img"
                                href={`${PagesIndex.ImageURL}${item}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={PagesIndex.Svg.imageSvg}
                                  alt={`Other Document: ${item}`}
                                />
                              </a>
                            )}
                          </Index.Box>
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* </Index.Box> */}
                </Index.Box>
              ) : (
                <Index.Box className="community-discussion-loading-box">
                  <Index.Typography className="community-discussion-not-found">
                    NO DATA AVAILABLE
                  </Index.Typography>
                </Index.Box>
              )
            ) : (
              <Index.Box className="community-discussion-loading-box">
                <RingLoader
                  color="#a6ccff"
                  loading="loading"
                  size={50}
                  className="loader"
                />
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ViewDocument;
