import React, { useState } from "react";
import { Outlet } from 'react-router-dom';
import "../defaultLayout/adminLayout.css";
import "../defaultLayout/adminLayout.responsive.css";
import Index from "../../Index";
import Sidebar from "../defaultLayout/Sidebar";
import Header from "../defaultLayout/Header";

const DashboardLayout = () => {
  const [open, setOpen] = useState(true);
  return (
    <Index.Box className="admin-main">
    <Index.Box className={`admin-main-left ${open ? "active" : "deactive"}`}>
      <Sidebar open={open} setOpen={setOpen} />
    </Index.Box>
    <Index.Box className={`admin-main-right ${open ? "active" : "deactive"}`}>
      <Header setOpen={setOpen} open={open} />
      <Index.Box className="admin-content-main">
        <Outlet />
      </Index.Box>
    </Index.Box>
  </Index.Box>
  );
};

export default DashboardLayout;
