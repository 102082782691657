import React, { useState } from 'react';
import Index from "../../../Index";
import MuiPhoneNumber from 'material-ui-phone-number';
import PagesIndex from '../../../PagesIndex';
import { addUserAction, editUserAction } from '../../../../redux/admin/action';
// import { ariaLabel, style } from './UserModal.Style';
import { validateMobileNo } from '../../../../validation/Validation';


const AddUser = ({ handleOtpSend, handleAddUser, handleClose, userEditData }) => {

  // state  declaration
  const navigate = PagesIndex.useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [mobileError, setMobileError] = useState("")
  const [mobileChange, setMobileChange] = useState(false);
  // initital values declaration
  let initialValues;

  if (userEditData) {
    initialValues = {
      email: userEditData ? userEditData.email : "",
      fullName: userEditData ? userEditData.fullName : "",
      // mobileNumber: userEditData ? String(userEditData?.mobileNumber) : " ",
      mobileNumber: userEditData ? String(`${userEditData?.countryCode}${userEditData?.mobileNumber}`) : " ",
      // mobileNumber: userEditData ?   mobileChange ? String(`${userEditData?.countryCode}${userEditData?.mobileNumber}`):userEditData.mobileNumber : " ",
      userName: userEditData ? userEditData.userName : "",
      countryCode: userEditData ? userEditData.countryCode : "in",
      country: userEditData ? userEditData.country : "",
      userId: userEditData ? userEditData._id : "",
    };
  } else {
    initialValues = {
      email: "",
      fullName: "",
      mobileNumber: " ",
      userName: "",
      countryCode: "",
      country: "",
      confirmPassword: "",
      password: "",
      isAgree: true,
    };
  }

  // Back button
  const handleBack = () => {
    navigate("/admin/user-list");
  }

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  // function declaration
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = (values) => {
    localStorage.setItem("addUserData", JSON.stringify(values));
    if (!mobileChange) {
      values.mobileNumber = userEditData.mobileNumber
    }
    if (mobileError === '') {
      if (!userEditData) {
        addUserAction(values).then((res) => {
          if (res?.status === 201) {
            handleOtpSend();
          } else {
            handleAddUser();
          }
        });
      } else {
        editUserAction(values, adminToken).then((res) => {
          if (res.status === 200) {
            handleClose();
          }
        });
      }
    }
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-common-box">

        {/* Add User Form Content */}
        <Index.Box className="common-form-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Create Account
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-sub-title"
                >
                  Please fill the input below here
                </Index.Typography>
              </Index.Box>
              <Index.Box className='form-back-btn'>
                      <Index.Button variant="contained"
                        className="back-btn"
                        type='back'
                        onClick={()=> handleBack()}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
            </Index.Box>
          </Index.Box>   
          <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={!userEditData ? PagesIndex.addUserValidationSchema : PagesIndex.editUserValidationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                }) => (
                  <Index.Stack
                    component="form"
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Index.Box className='register-all-inputs'>
                      <Index.Box
                        component="form"
                        autoComplete="off"
                        className="reg-input"
                      >
                        <Index.Typography
                          className="re-input-label"
                          variant="label"

                        >Full Name
                        </Index.Typography>
                        <Index.Input inputProps={""}
                          value={values.fullName}
                          name="fullName"
                          onChange={(e) => {
                            if (e.target.value.length <= 32) {
                              const capitalizedValue = e?.target?.value?.charAt(0)?.toUpperCase() + e.target.value.slice(1);
                              setFieldValue("fullName", capitalizedValue)
                            }
                          }} />
                        <span className="error-text">{errors.fullName && errors.fullName}</span>
                      </Index.Box>
                      <Index.Box
                        component="form"
                        autoComplete="off"
                        className="reg-input"
                      >
                        <Index.Typography
                          className="re-input-label"
                          variant="label"

                        >
                          Username
                        </Index.Typography>
                        <Index.Input inputProps={""}
                          value={values.userName}
                          name="userName"
                          onChange={handleChange} />
                        <span className="error-text">{errors.userName && errors.userName}</span>
                      </Index.Box>
                      <Index.Box
                        component="form"
                        autoComplete="off"
                        className="reg-input"
                      >
                        <Index.Typography
                          className="re-input-label"
                          variant="label"

                        >Email
                        </Index.Typography>
                        <Index.Input inputProps={""}
                          value={values.email}
                          name="email"
                          onChange={handleChange} />
                        <span className="error-text">{errors.email && errors.email}</span>
                      </Index.Box>
                      <Index.Box
                        component="form"
                        autoComplete="off"
                        className="reg-input reg-mobile-num"
                      >
                        <Index.Typography
                          className="re-input-label"
                          variant="label"

                        >Mobile Number
                        </Index.Typography>

                        <MuiPhoneNumber
                          defaultCountry={'in'}
                          value={values?.mobileNumber}
                          onChange={(value, countryData) => {

                            if (value.length > 0 && value.length != 0) {
                              const phoneNumberDigits = value.replace(/\D/g, ''); // Remove non-numeric characters
                              let countryCode = countryData?.dialCode
                              setFieldValue('mobileNumber', +phoneNumberDigits.slice(countryCode.length, phoneNumberDigits.length))

                              // const paddedMobileNumber = phoneNumberDigits.padStart(10, '0').slice(-10); // Pad and take the last 10 digits
                              setFieldValue('countryCode', `${'+'}${countryData.dialCode}`);
                              setFieldValue('country', countryData.name);
                              // setFieldValue('mobileNumber', parseInt(paddedMobileNumber, 10));
                            }
                            setMobileError(validateMobileNo(value, countryData.dialCode))
                            setMobileChange(true);
                          }}
                        />

                        <span className="error-text">{mobileError && mobileError || errors.mobileNumber && errors.mobileNumber}</span>

                      </Index.Box>
                      {!userEditData ? <>
                        <Index.Box
                          component="form"
                          autoComplete="off"
                          className="reg-input"
                        >
                          <Index.Typography
                            className="re-input-label"
                            variant="label"
                          >Password
                          </Index.Typography>
                          <Index.TextField inputProps={""}
                            value={values.password}
                            name="password"
                            onChange={handleChange}

                            type={showNewPassword ? "text" : "password"}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="new-password-toggle"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                          <span className="error-text">{errors.password && errors.password}</span>
                        </Index.Box>
                        <Index.Box
                          component="form"
                          autoComplete="off"
                          className="reg-input"
                        >
                          <Index.Typography
                            className="re-input-label"
                            variant="label">
                            Confirm Password
                          </Index.Typography>

                          <Index.TextField
                            inputProps={""}
                            value={values.confirmPassword}
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            onChange={handleChange}
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    className="confirm-password-toggle"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              ),
                            }}
                          />
                          <span className="error-text">{errors.confirmPassword && errors.confirmPassword}</span>
                        </Index.Box>
                      </> : null}
                    </Index.Box>

                    <Index.Box className='modal-footer-btn'>
                      <Index.Button variant="contained"
                        className="submit-btn"
                        type='submit'
                      >
                        Submit
                      </Index.Button>

                    </Index.Box>
                  </Index.Stack>
                )}
              </PagesIndex.Formik>       
        </Index.Box>
        {/* End Add User Form Content */}
      </Index.Box>
    </Index.Box>
  )
}

export default AddUser;