import React, { useState, useEffect, useRef } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { adminResendOtpAction, otpVerifyAction } from "../../../../redux/admin/action";
import "./OtpVerify.css";

const OtpVerify = () => {
  // state declaration
  const intervalRef = useRef(null);
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const { state } = PagesIndex.useLocation();
  const [resetId, setResetId] = useState(0);
  const [adminEmail, setAdminEmail] = useState("");

  // let resetId = state ? state._id : null;
  // let adminEmail = state?.data ? state?.data?.email : null;


  // Initital values declaration
  let initialValues = {
    otp: "",
  };

  // functions declaration
  const handleFormSubmit = (values) => {
    setLoading(true);
    otpVerifyAction({ otp: +values.otp, id: resetId }, navigate);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (state) {
      setResetId(state._id);
      setAdminEmail(state?.data?.email);
    }
  }, [state])


  // console.log(resetId, 'statesss', adminEmail);

  //******* Resend OTP  function  ***********
  useEffect(() => {
    setRemainingSeconds(60);
  }, []);

  const resendOtp = () => {
    setRemainingMinutes(0);
    setRemainingSeconds(60);
    adminResendOtpAction({
      email: adminEmail,
    });
  };

  useEffect(() => {
    if (remainingMinutes === 0 && remainingSeconds === 0) {
      clearInterval(intervalRef.current);
    }
  }, [remainingMinutes, remainingSeconds]);

  useEffect(() => {
    if (remainingMinutes === 0 && remainingSeconds === 0) {
      clearInterval(intervalRef.current);
    } else {
      intervalRef.current = setInterval(() => {
        if (remainingSeconds > 0) {
          setRemainingSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          if (remainingMinutes > 0) {
            setRemainingMinutes((prevMinutes) => prevMinutes - 1);
            setRemainingSeconds(59);
          }
        }
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [remainingMinutes, remainingSeconds]);

  return (
    <div>
      <Index.Box className="admin-main admin-main-bg">
        <Index.Box className="admin-main-bg">
          <Index.Box className="auth-main">
            <Index.Box className="auth-right">
              <Index.Box className="auth-common-box">
                <Index.Box className="auth-header">
                  <Index.Box className="logo-name">
                    <img
                      src={PagesIndex.Png.LogoName}
                      alt="logo"
                      className=""
                    />
                  </Index.Box>
                  <Index.Box className="auth-title auth-logo-title">
                    Verify OTP
                  </Index.Box>
                  <Index.Box className="auth-subtitle">
                    Enter the OTP here which is sent on your Email
                  </Index.Box>
                </Index.Box>

                <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={PagesIndex.validationSchemaOtpVerify}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="auth-body">
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Enter OTP
                              </Index.FormHelperText>
                              <Index.OtpInput
                                name="otp"
                                inputStyle="inputStyle"
                                numInputs={4}
                                type="number"
                                renderSeparator={<span> </span>}
                                value={values.otp}
                                // validateChar="number"
                                onChange={(file) => setFieldValue("otp", file)}
                                error={Boolean(errors.otp)}
                                className="form-input login"
                                renderInput={(props) => <input {...props} />}
                              />
                            </Index.Box>
                            <Index.FormHelperText className="error-text error-otp-text">
                              {touched.otp && errors.otp}
                            </Index.FormHelperText>

                            <Index.Box className="resend-otp-box">


                              <Index.Typography className="acc-already-txt resend-otm-timer text-white" variant="p" component='p'>
                                {
                                  remainingSeconds != 0 && (
                                    <>
                                      Resend OTP in <Index.Typography>{`0${remainingMinutes}`}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</Index.Typography>
                                    </>
                                  )
                                }
                              </Index.Typography>


                              {/* <Index.Typography className="acc-already-txt resend-otm-timer text-white" variant="p" component='p'>
                                Resend OTP in <Index.Typography>{`0${remainingMinutes}`}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</Index.Typography>
                              </Index.Typography> */}

                              <Index.Typography className="acc-already-txt resend-link text-white" variant="p" component="p">
                                <Index.Link
                                  aria-disabled={remainingSeconds != 0}
                                  onClick={remainingSeconds != 0 ? undefined : resendOtp}
                                  style={{ cursor: remainingSeconds != 0 ? 'not-allowed' : 'pointer' }}
                                >
                                  Resend OTP
                                </Index.Link>
                              </Index.Typography>
                            </Index.Box>

                          </Index.Grid>

                          {/* Button */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="auth-btn-box">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disableRipple
                                disabled={loading}
                                className="login-disable"
                              >
                                Submit
                              </Index.Button>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>

                        {/* <Index.Box
                          className="box-login-text forgot bluebox-text back-link-box"
                          sx={{ mt: 3 }}
                        >
                          <PagesIndex.Link to="/admin/forgot-password">
                            <Index.Box className='back-wraper'>
                              < Index.KeyboardBackspaceIcon className="back-icon" />
                              <Index.Typography className="auth-link"
                                variant="body1"
                                component="p"
                              >
                                Back to Forgot Password
                              </Index.Typography>
                            </Index.Box>
                          </PagesIndex.Link>
                        </Index.Box> */}
                      </Index.Box>
                    </Index.Stack>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default OtpVerify;
