import React, { useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { addPfpAction, editPfpAction } from "../../../../redux/admin/action";

const PfpAddEdit = (props) => {
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { editData, setModalOpen, setEditData, handleClose } = props;

  // Initital values
  const initialValues = {
    timeSlot: editData ? editData?.timeSlot : "",
    token: editData ? editData?.token : "",
  };
  // functions declaration
  const handlePfpFormSubmit = (values) => {
    if (editData) {
      editPfpAction({ ...values, id: editData._id }, adminToken).then((res) => {
        if (res?.status === 200) {
          setModalOpen(false);
          setEditData("");
        }
      });
    } else {
      addPfpAction(values, adminToken).then((res) => {
        if (res?.status === 201) {
          setModalOpen(false);
          setEditData("");
        }
      });
    }
  };

  const handleFocus = () => {
    // setLoading(false);
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {!editData?._id ? "Add PFP" : "Edit PFP"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img" onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="user-circle-img"
                  alt="icon"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handlePfpFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.PfpSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="Days"
                    variant="filled"
                    type="number"
                    className="admin-input-design input-placeholder"
                    name="timeSlot"
                    data-testid="title-input"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.timeSlot}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 3);
                    }}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>


                                        <DatePicker
                                           hiddenLabel
                                           id="filled-hidden-label-normal"
                                           // placeholder="Title"
                                           variant="filled"
                                        
                                           format="DD/MM/YYYY"
                                           className="admin-input-design input-placeholder datepicker"
                                           name="timeSlot"
                                           data-testid="title-input"
                                           autoComplete="off"
                                           onBlur={handleBlur}
                                           onFocus={handleFocus}
                                           value={values.timeSlot}
                                           onChange={(date) =>
                                            setFieldValue("timeSlot", date ? dayjs(date) : null)
                                          }
                                           helperText={
                                               touched.timeSlot && errors.timeSlot
                                           }
                                           error={Boolean(
                                               errors.timeSlot && touched.timeSlot
                                           )}
                                        />

                                    </LocalizationProvider> */}
                  <span className="error-text">
                    {errors.timeSlot && errors.timeSlot}
                  </span>
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="Token"
                    variant="filled"
                    type="text"
                    className="admin-input-design input-placeholder"
                    name="token"
                    data-testid="title-input"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.token}
                    onChange={handleChange}
                    helperText={touched.token && errors.token}
                    error={Boolean(errors.token && touched.token)}
                  />

                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                    // disableRipple
                    // disabled={loading}
                    >
                      {!editData?._id ? "Submit" : "Update"}
                    </Index.Button>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PfpAddEdit;
