import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { PropTypes } from "prop-types";
import NFTRequest from "./NFTRequest";
import PFPRequest from "./PFPRequest";
import ExpertRequest from "./ExpertRequest";
import { useNavigate, useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes?.node,
  index: PropTypes?.number?.isRequired,
  value: PropTypes?.number?.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function InappropriateList() {
  const navigate = useNavigate();
  const location = useLocation();
  // Helper function to get query parameter
  const getQueryParam = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  // Retrieve the initial tab value from the query parameter or default to 0
  const initialTab = parseInt(getQueryParam("tab")) || 0;
  const [value, setValue] = useState(initialTab);

  // Update the URL query parameter on tab change
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", value);
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [value, navigate, location.search]);

  // Tab function declaration
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Grid container spacing={2} className="align-items-start">
            <Index.Grid item xs={12} md={6}>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex mb-0">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title mb-0"
                    >
                      Master Warden Inappropriate Requests
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>

          <Index.Grid>
            <Index.Grid item xs={12} md={12}>
              <Index.Box className="table-wrape pl-0 pr-0">
                <Index.Box>
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="admin-tabs-main"
                  >
                    <Index.Tab
                      label="NFT's Requests"
                      {...a11yProps(0)}
                      className="admin-tab"
                    />
                    {/* <Index.Tab
                      label="PFP Requests"
                      {...a11yProps(1)}
                      className="admin-tab"
                    /> */}
                    <Index.Tab
                      label="Expert Requests"
                      {...a11yProps(2)}
                      className="admin-tab"
                    />
                  </Index.Tabs>
                </Index.Box>
                <Index.Box></Index.Box>

                {value == 0 ? (
                  <NFTRequest />
                ) 
                // : value == 1 ? (
                //   <PFPRequest />
                // )
                 : (
                  <ExpertRequest />
                )}
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default InappropriateList;
