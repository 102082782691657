import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  activeDeactiveGroupMember,
  communityGroupMemberList,
} from "../../../../redux/admin/action";
import moment from "moment";

const MemberList = ({ groupMemberList, getGroupMemberList, loading }) => {
  // state declaration
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [disableEvent, setDisableEvent] = useState();
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { state } = PagesIndex.useLocation();

  //   function declaration

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  // Filter fetched data
  useEffect(() => {
    const filtered =
      groupMemberList?.members &&
      groupMemberList?.members?.filter((item) => {
        // debugger;
        return !search
          ? item
          : item?.userId?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item?.userId?.userName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          ` ${item?.userId?.countryCode} ${item?.userId?.mobileNumber}`
            ?.toString()
            .includes(search.toString()) ||
          new Date(item?.joinedDate)
            ?.toLocaleDateString("en-GB")
            ?.toString()
            .includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [groupMemberList, search]);

  // Active deactive function declaration
  const handleSwitchChange = (id, isActive) => {
    activeDeactiveGroupMember(
      { userId: userId || id, groupId: state.groupId, isActive: disableEvent === undefined ? isActive : disableEvent },
      adminToken
    ).then((res) => {
      if (res?.status == 200) {
        handleDeleteModalClose();
        getGroupMemberList();
      }
    });
  };
  // Confirm msg  before delete member from the list
  const handleDeleteModalOpen = async (id, event, isActive) => {

    setUserId(id);
    setDisableEvent(event.target.checked)
    if (isActive) {
      setDeleteModalOpen(!deleteModalOpen)
    }
    else {
      handleSwitchChange(id, event.target.checked)
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setUserId();
    setDisableEvent()
  };

  return (
    <Index.Box className="episap-common-box pl-0 pr-0">
      <Index.Box className="title-header">
        <Index.Box className="title-header-flex res-title-header-flex">
          <Index.Box className="title-main">
            <Index.Typography variant="p" component="p" className="page-title">
              Group Members
            </Index.Typography>
          </Index.Box>
          <Index.Box className="d-flex align-items-center res-set-search">
            <PagesIndex.Search className="searchbar">
              <PagesIndex.StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearch}
                type="text"
              />
            </PagesIndex.Search>
            {/* <Index.TextField
            size="small"
            placeholder="Search"
            className="search"
            onChange={(e) => setSearchData(e.target.value)}
 
          /> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="table-box table_scroll">
        <Index.TableContainer
          component={Index.Paper}
          // className="table-container gridtable"
          className="episap-user-details-table"
        >
          <Index.Table
            stickyHeader
            aria-label="sticky table"
            className="episap-table-main contact-table user-details"
          >
            <Index.TableHead>
              <Index.TableRow>
                <Index.TableCell align="left">No </Index.TableCell>
                <Index.TableCell align="left">Profile Picture</Index.TableCell>
                <Index.TableCell align="left">Full Name</Index.TableCell>
                <Index.TableCell align="left">Username</Index.TableCell>
                <Index.TableCell align="left">Mobile Number</Index.TableCell>
                <Index.TableCell align="left">Joined Date & Time </Index.TableCell>
                <Index.TableCell align="center">Action</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            {/* <Index.Box> */}
            <Index.TableBody className="table-body">
              {!loading ? (
                pageData?.length ? (
                  pageData.map((row) => {
                    return (
                      <Index.TableRow key={row?._id}>
                        <Index.TableCell>{row?.sNo}</Index.TableCell>
                        <Index.TableCell align="left">
                          <img
                            src={
                              row?.userId && row?.userId?.profile != ""
                                ? `${PagesIndex.ImageURL}${row?.userId?.profile}`
                                : PagesIndex.Jpg.dummyImage
                            }
                            alt="Image Preview"
                            style={{
                              height: "60px",
                              width: "60px",
                              display: "table-column",
                              borderRadius: "6px",
                            }}
                          />
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          {row?.userId?.fullName}
                          <p>({row?.type})</p>
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          {row?.userId?.userName}
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          {row?.userId?.countryCode} {row?.userId?.mobileNumber}
                        </Index.TableCell>

                        <Index.TableCell align="left">
                          {moment(row?.joinedDate).format("DD/MM/YYYY hh:mm A")}
                        </Index.TableCell>

                        <Index.TableCell align="left">
                          <Index.Box sx={{ display: "flex" }}>
                            <Index.Tooltip title={`${row.isActive ? 'Disable' : 'Enable'}`}>
                              <Index.Switch
                                className="toggel-switch"
                                {...PagesIndex.label}
                                checked={row.isActive ? true : false}
                                onChange={(event) => {
                                  handleDeleteModalOpen(row?.userId?._id, event, row?.isActive);
                                }}
                              />
                            </Index.Tooltip>
                          </Index.Box>
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })
                ) : (
                  <PagesIndex.NoRecordFound colSpan={12} />
                )
              ) : (
                <PagesIndex.PageLoading colSpan={12} />
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
      <Index.Box className="pagination-design flex-end">
        <Index.Stack spacing={2}>
          <PagesIndex.Pagination
            fetchData={filterData}
            setPageData={setPageData}
            pageData={pageData}
          />
        </Index.Stack>
      </Index.Box>
      {/* table end */}

      <PagesIndex.DeleteModal
        deleteOpen={deleteModalOpen}
        handleDeleteRecord={handleSwitchChange}
        handleDeleteClose={handleDeleteModalClose}
        deleteMessage="Are you sure? Do you really want to disable this member from the group?"
        buttonTitle="Disable"
      />
    </Index.Box>
  );
};

export default MemberList;
