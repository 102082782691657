import React, { useState } from "react";
import Index from "../../../Index";
import MuiPhoneNumber from "material-ui-phone-number";
import PagesIndex from "../../../PagesIndex";
import { addUserAction, editUserAction } from "../../../../redux/admin/action";
import { ariaLabel, style } from "./UserModal.Style";
import { validateMobileNo } from "../../../../validation/Validation";

export const AddUser = ({
  handleOtpSend,
  handleAddUser,
  handleClose,
  userEditData,
}) => {
  // state  declaration
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [mobileChange, setMobileChange] = useState(false);
  // initital values declaration
  let initialValues;

  if (userEditData) {
    initialValues = {
      email: userEditData ? userEditData.email : "",
      fullName: userEditData ? userEditData.fullName : "",
      // mobileNumber: userEditData ? String(userEditData?.mobileNumber) : " ",
      mobileNumber: userEditData
        ? String(`${userEditData?.countryCode}${userEditData?.mobileNumber}`)
        : " ",
      // mobileNumber: userEditData ?   mobileChange ? String(`${userEditData?.countryCode}${userEditData?.mobileNumber}`):userEditData.mobileNumber : " ",
      userName: userEditData ? userEditData.userName : "",
      countryCode: userEditData ? userEditData.countryCode : "in",
      country: userEditData ? userEditData.country : "",
      userId: userEditData ? userEditData._id : "",
    };
  } else {
    initialValues = {
      email: "",
      fullName: "",
      mobileNumber: " ",
      userName: "",
      countryCode: "",
      country: "",
      confirmPassword: "",
      password: "",
      isAgree: true,
    };
  }

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  // function declaration
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = (values) => {
    localStorage.setItem("addUserData", JSON.stringify(values));
    if (!mobileChange) {
      values.mobileNumber = userEditData.mobileNumber;
    }
    if (mobileError === "") {
      if (!userEditData) {
        addUserAction(values).then((res) => {
          if (res?.status === 201) {
            handleOtpSend();
          } else {
            handleAddUser();
          }
        });
      } else {
        editUserAction(values, adminToken).then((res) => {
          if (res?.status === 200) {
            handleClose();
          }
        });
      }
    }
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-modal-box">
        <Index.Box className="episap-modal-header">
          <Index.Box className="edit-modal-circle-main">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    {userEditData ? "Edit" : "Add"} User
                  </Index.Typography>
                  {/* <Index.Typography variant="p" component='p' className='page-sub-title'>
                                            Please fill the input blow here
                                        </Index.Typography> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-circle-img" onClick={handleClose}>
              <img
                src={PagesIndex.Svg.whiteclose}
                className="user-circle-img"
                alt="icon"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-design-div login-input-design-div">
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={
              !userEditData
                ? PagesIndex.addUserValidationSchema
                : PagesIndex.editUserValidationSchema
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Index.Stack
                component="form"
                spacing={2}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Index.Box className="register-all-inputs">
                  <Index.Box autoComplete="off" className="reg-input">
                    <Index.Typography
                      className="re-input-label"
                      variant="label"
                    >
                      Full Name
                    </Index.Typography>
                    <Index.TextField
                      inputProps={ariaLabel}
                      value={values.fullName}
                      name="fullName"
                      onChange={(e) => {
                        if (e.target.value.length <= 51) {
                          const capitalizedValue =
                            e?.target?.value?.charAt(0)?.toUpperCase() +
                            e.target.value.slice(1);
                          setFieldValue("fullName", capitalizedValue);
                        }
                      }}
                      helperText={touched.fullName && errors.fullName}
                      error={Boolean(errors.fullName && touched.fullName)}
                    />
                  </Index.Box>

                  <Index.Box autoComplete="off" className="reg-input">
                    <Index.Typography
                      className="re-input-label"
                      variant="label"
                    >
                      Username
                    </Index.Typography>
                    <Index.TextField
                      inputProps={ariaLabel}
                      value={values.userName}
                      autoComplete="off"
                      name="userName"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setFieldValue("userName", e.target.value.trim())
                      }} 
                      helperText={touched.userName && errors.userName}
                      error={Boolean(errors.userName && touched.userName)}
                    />
                  </Index.Box>

                  <Index.Box autoComplete="off" className="reg-input">
                    <Index.Typography
                      className="re-input-label"
                      variant="label"
                    >
                      Email
                    </Index.Typography>
                    <Index.TextField
                      inputProps={ariaLabel}
                      value={values.email}
                      name="email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value.toLowerCase());
                      }}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                    />
                  </Index.Box>

                  <Index.Box
                    autoComplete="off"
                    className="reg-input reg-mobile-num"
                  >
                    <Index.Typography
                      className="re-input-label"
                      variant="label"
                    >
                      Mobile Number
                    </Index.Typography>

                    <MuiPhoneNumber
                      defaultCountry={"in"}
                      value={values?.mobileNumber}
                      onChange={(value, countryData) => {
                        if (value.length > 0 && value.length !== 0) {
                          const phoneNumberDigits = value.replace(/\D/g, ""); // Remove non-numeric characters
                          let countryCode = countryData?.dialCode;
                          setFieldValue(
                            "mobileNumber",
                            +phoneNumberDigits.slice(
                              countryCode.length,
                              phoneNumberDigits.length
                            )
                          );
                          setFieldValue(
                            "countryCode",
                            `${"+"}${countryData.dialCode}`
                          );
                          setFieldValue("country", countryData.name);
                        }
                        // {console.log(value,"Value")}
                        setMobileError(
                          validateMobileNo(value, countryData.dialCode)
                        );
                        setMobileChange(true);
                      }}
                      onKeyPress={(event) => {
                        const charCode = event.which ? event.which : event.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          event.preventDefault();
                        }
                      }}
                    // helperText={touched.mobileNumber && errors.mobileNumber}
                    // error={Boolean(errors.mobileNumber && touched.mobileNumber)}
                    />
                    <span className="error-text">
                      {mobileError && touched.mobileNumber ? mobileError : (touched.mobileNumber && errors.mobileNumber)}
                      {/* {console.log(mobileError,"mobileError")} */}
                    </span>
                  </Index.Box>


                  {!userEditData ? (
                    <>
                      <Index.Box autoComplete="off" className="reg-input">
                        <Index.Typography
                          className="re-input-label"
                          variant="label"
                        >
                          Password
                        </Index.Typography>
                        <Index.TextField
                          inputProps={ariaLabel}
                          value={values.password}
                          name="password"
                          // onChange={handleChange}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value.trim())
                          }} 
                          type={showNewPassword ? "text" : "password"}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="new-password-toggle"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            ),
                          }}
                          helperText={touched.password && errors.password}
                          error={Boolean(errors.password && touched.password)}
                        />
                      </Index.Box>

                      <Index.Box autoComplete="off" className="reg-input">
                        <Index.Typography
                          className="re-input-label"
                          variant="label"
                        >
                          Confirm Password
                        </Index.Typography>

                        <Index.TextField
                          inputProps={ariaLabel}
                          value={values.confirmPassword}
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          // onChange={handleChange}
                          onChange={(e) => {
                            setFieldValue("confirmPassword", e.target.value.trim())
                          }} 
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="confirm-password-toggle"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            ),
                          }}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          error={Boolean(
                            errors.confirmPassword && touched.confirmPassword
                          )}
                        />
                      </Index.Box>
                    </>
                  ) : null}
                </Index.Box>
                <Index.Box className="modal-footer-btn">
                  <Index.Button
                    variant="contained"
                    className="submit-btn"
                    type="submit"
                  >
                    Submit
                  </Index.Button>
                </Index.Box>
              </Index.Stack>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};
