import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#ff9b39" />
                        <stop offset="100%" stopColor="#d43e2d" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
    );
}

export default function WalletLoader() {
    return (
        <div className="text-center">
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <GradientCircularProgress />
        </Stack>
        </div>
    );
}