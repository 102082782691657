import React, { useState } from "react";
import Index from "../../container/Index";
import PagesIndex from "../../container/PagesIndex";

const SingleImageModal = ({ handleClose, imageData }) => {
  return (
    <>
      <Index.Box sx={PagesIndex.style} className="episap-modal-box">
        <Index.Box className="episap-modal-header">
          <Index.Box className="edit-modal-circle-main">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    {imageData?.imageTitle}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-circle-img">
              <img
                src={PagesIndex.Svg.whiteclose}
                className="modal-close-icon user-circle-img"
                onClick={handleClose}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-design-div admin-design-div login-input-design-div">
          <Index.Box className="follow-list-section follow-list-section-img">
            <img
              src={`${PagesIndex.ImageURL}${imageData?.imageUrl}`}
              className="user-prof-img nft-image"
              alt=""
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SingleImageModal;
