import React, {memo, useEffect} from "react";
import Index from "../../../../Index";
import moment from "moment";
import PagesIndex from "../../../../PagesIndex";

const WardenAction = ({pageData, loading, setLoading}) => {
  useEffect(()=> {
    setLoading(false);
  }, [])
  
  return (
    <>
      <Index.Table
        stickyHeader
        aria-label="sticky table"
        className="episap-table-main contact-table user-details"
      >
        <Index.TableHead>
          <Index.TableRow>
            <Index.TableCell align="left">Sr. No.</Index.TableCell>
            <Index.TableCell align="left">Action Type</Index.TableCell>
            <Index.TableCell align="left">Idea Title</Index.TableCell>
            <Index.TableCell align="left">Created Date & Time</Index.TableCell>
          </Index.TableRow>
        </Index.TableHead>

        <Index.TableBody>
          {!loading ? (
            pageData?.length ? (
              pageData.map((row) => (
                <Index.TableRow key={row._id}>
                  <Index.TableCell align="left">{row.sNo}</Index.TableCell>
                  <Index.TableCell
                    align="left"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {row?.actionType}
                  </Index.TableCell>
                  <Index.TableCell
                    className="table-title-word-break"
                    align="left"
                  >
                    {row?.ideaId?.title}
                  </Index.TableCell>
                  <Index.TableCell align="left">
                    {moment(row?.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </Index.TableCell>
                </Index.TableRow>
              ))
            ) : (
              <PagesIndex.NoRecordFound colSpan={12} />
            )
          ) : (
            <PagesIndex.PageLoading colSpan={12} />
          )}
        </Index.TableBody>
      </Index.Table>
    </>
  );
};

export default memo(WardenAction);
