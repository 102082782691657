import React from 'react'

export default function WardenStep() {
    return (

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_353_1248)">
                <path d="M17.7197 6.07263H18.3447C18.6683 6.07263 18.9307 5.81029 18.9307 5.48669C18.9307 5.1631 18.6683 4.90076 18.3447 4.90076H17.7197C17.3961 4.90076 17.1338 5.1631 17.1338 5.48669C17.1338 5.81029 17.3961 6.07263 17.7197 6.07263Z" fill="white" />
                <path d="M15.1416 6.07263H15.7666C16.0902 6.07263 16.3525 5.81029 16.3525 5.48669C16.3525 5.1631 16.0902 4.90076 15.7666 4.90076H15.1416C14.818 4.90076 14.5557 5.1631 14.5557 5.48669C14.5557 5.81029 14.818 6.07263 15.1416 6.07263Z" fill="white" />
                <path d="M12.5635 6.07263H13.1885C13.5121 6.07263 13.7744 5.81029 13.7744 5.48669C13.7744 5.1631 13.5121 4.90076 13.1885 4.90076H12.5635C12.2399 4.90076 11.9775 5.1631 11.9775 5.48669C11.9775 5.81029 12.2399 6.07263 12.5635 6.07263Z" fill="white" />
                <path d="M28.0322 6.07258H28.6572C28.8065 6.07258 28.9424 6.01625 29.0458 5.92438C29.2698 8.7457 31.6356 10.9734 34.5135 10.9734C37.5389 10.9734 40.0001 8.51203 40.0001 5.48672C40.0001 4.56289 39.7654 3.64867 39.3214 2.84273C39.1652 2.55938 38.8089 2.45617 38.5254 2.61227C38.242 2.76844 38.1389 3.12477 38.295 3.4082C38.6439 4.04133 38.8282 4.76008 38.8282 5.48664C38.8282 7.86586 36.8927 9.80141 34.5135 9.80141C32.1343 9.80141 30.1987 7.86578 30.1987 5.48664C30.1987 3.1075 32.1342 1.17188 34.5135 1.17188C35.24 1.17188 35.9588 1.35625 36.5919 1.70516C36.8754 1.86133 37.2317 1.7582 37.3879 1.47469C37.5439 1.19125 37.4408 0.834922 37.1574 0.67875C36.3515 0.234687 35.4372 0 34.5135 0C31.6356 0 29.2698 2.22766 29.0458 5.04898C28.9424 4.95703 28.8065 4.90078 28.6572 4.90078H28.0322C27.7086 4.90078 27.4463 5.16313 27.4463 5.48672C27.4463 5.81031 27.7086 6.07258 28.0322 6.07258Z" fill="white" />
                <path d="M20.2979 6.07263H20.9229C21.2465 6.07263 21.5088 5.81029 21.5088 5.48669C21.5088 5.1631 21.2465 4.90076 20.9229 4.90076H20.2979C19.9743 4.90076 19.7119 5.1631 19.7119 5.48669C19.7119 5.81029 19.9742 6.07263 20.2979 6.07263Z" fill="white" />
                <path d="M25.4541 6.07263H26.0791C26.4028 6.07263 26.665 5.81029 26.665 5.48669C26.665 5.1631 26.4028 4.90076 26.0791 4.90076H25.4541C25.1304 4.90076 24.8682 5.1631 24.8682 5.48669C24.8682 5.81029 25.1304 6.07263 25.4541 6.07263Z" fill="white" />
                <path d="M22.876 6.07263H23.501C23.8246 6.07263 24.0869 5.81029 24.0869 5.48669C24.0869 5.1631 23.8246 4.90076 23.501 4.90076H22.876C22.5523 4.90076 22.29 5.1631 22.29 5.48669C22.29 5.81029 22.5523 6.07263 22.876 6.07263Z" fill="white" />
                <path d="M35.0994 21.8718C35.0994 21.5482 34.8371 21.2859 34.5134 21.2859C34.1898 21.2859 33.9275 21.5482 33.9275 21.8718V22.4968C33.9275 22.8204 34.1898 23.0828 34.5134 23.0828C34.8371 23.0828 35.0994 22.8204 35.0994 22.4968V21.8718Z" fill="white" />
                <path d="M34.5134 26.4421C34.1898 26.4421 33.9275 26.7045 33.9275 27.0281V27.6531C33.9275 27.9767 34.1898 28.239 34.5134 28.239C34.8371 28.239 35.0994 27.9767 35.0994 27.6531V27.0281C35.0994 26.7045 34.837 26.4421 34.5134 26.4421Z" fill="white" />
                <path d="M35.0994 24.45C35.0994 24.1264 34.8371 23.864 34.5134 23.864C34.1898 23.864 33.9275 24.1264 33.9275 24.45V25.075C33.9275 25.3985 34.1898 25.6609 34.5134 25.6609C34.8371 25.6609 35.0994 25.3985 35.0994 25.075V24.45Z" fill="white" />
                <path d="M35.0994 19.2937C35.0994 18.9701 34.8371 18.7078 34.5134 18.7078C34.1898 18.7078 33.9275 18.9701 33.9275 19.2937V19.9187C33.9275 20.2423 34.1898 20.5046 34.5134 20.5046C34.8371 20.5046 35.0994 20.2423 35.0994 19.9187V19.2937Z" fill="white" />
                <path d="M35.0994 16.7156C35.0994 16.392 34.8371 16.1296 34.5134 16.1296C34.1898 16.1296 33.9275 16.392 33.9275 16.7156V17.3406C33.9275 17.6642 34.1898 17.9265 34.5134 17.9265C34.8371 17.9265 35.0994 17.6642 35.0994 17.3406V16.7156Z" fill="white" />
                <path d="M35.0994 11.5593C35.0994 11.2357 34.8371 10.9734 34.5134 10.9734C34.1898 10.9734 33.9275 11.2357 33.9275 11.5593V12.1843C33.9275 12.5079 34.1898 12.7703 34.5134 12.7703C34.8371 12.7703 35.0994 12.5079 35.0994 12.1843V11.5593Z" fill="white" />
                <path d="M35.0994 14.1375C35.0994 13.8139 34.8371 13.5515 34.5134 13.5515C34.1898 13.5515 33.9275 13.8139 33.9275 14.1375V14.7625C33.9275 15.086 34.1898 15.3484 34.5134 15.3484C34.8371 15.3484 35.0994 15.086 35.0994 14.7625V14.1375Z" fill="white" />
                <path d="M15.8123 33.9274H15.1873C14.8637 33.9274 14.6013 34.1897 14.6013 34.5133C14.6013 34.8369 14.8637 35.0992 15.1873 35.0992H15.8123C16.1358 35.0992 16.3982 34.8369 16.3982 34.5133C16.3982 34.1897 16.1359 33.9274 15.8123 33.9274Z" fill="white" />
                <path d="M10.9502 34.0071C10.888 33.3218 10.6987 32.6627 10.3848 32.0417C10.2388 31.7529 9.88617 31.6371 9.5975 31.7831C9.30867 31.9291 9.19297 32.2816 9.33891 32.5703C9.64586 33.1775 9.80148 33.8311 9.80148 34.5133C9.80148 36.8925 7.86586 38.8281 5.48672 38.8281C3.10758 38.8281 1.17188 36.8925 1.17188 34.5134C1.17188 32.1343 3.1075 30.1986 5.48664 30.1986C6.26586 30.1986 7.02992 30.4091 7.69609 30.8072C7.97398 30.9732 8.33367 30.8826 8.49969 30.6049C8.6657 30.3271 8.57508 29.9673 8.29734 29.8013C7.6175 29.395 6.85781 29.1427 6.07258 29.0585V26.3175L10.7188 24.115C10.9234 24.0181 11.0538 23.8119 11.0538 23.5856C11.0538 23.3593 10.9234 23.1531 10.7188 23.0561L6.07258 20.8538V20.4374C6.07258 20.1138 5.81023 19.8514 5.48664 19.8514C5.16305 19.8514 4.9007 20.1138 4.9007 20.4374V29.0585C2.14992 29.3518 0 31.686 0 34.5134C0 37.5387 2.46133 40 5.48664 40C8.34102 40 10.6922 37.8088 10.949 35.0203C11.124 34.9189 11.242 34.7301 11.242 34.5133C11.242 34.2971 11.1246 34.1087 10.9502 34.0071ZM9.09992 23.5855L6.07258 25.0205V22.1505L9.09992 23.5855Z" fill="white" />
                <path d="M18.3904 33.9274H17.7654C17.4418 33.9274 17.1794 34.1897 17.1794 34.5133C17.1794 34.8369 17.4418 35.0992 17.7654 35.0992H18.3904C18.714 35.0992 18.9763 34.8369 18.9763 34.5133C18.9763 34.1897 18.7141 33.9274 18.3904 33.9274Z" fill="white" />
                <path d="M13.2341 33.9274H12.6091C12.2855 33.9274 12.0232 34.1897 12.0232 34.5133C12.0232 34.8369 12.2855 35.0992 12.6091 35.0992H13.2341C13.5577 35.0992 13.8201 34.8369 13.8201 34.5133C13.8201 34.1897 13.5578 33.9274 13.2341 33.9274Z" fill="white" />
                <path d="M26.1248 33.9274H25.4998C25.1761 33.9274 24.9138 34.1897 24.9138 34.5133C24.9138 34.8369 25.1761 35.0992 25.4998 35.0992H26.1248C26.4484 35.0992 26.7107 34.8369 26.7107 34.5133C26.7107 34.1897 26.4484 33.9274 26.1248 33.9274Z" fill="white" />
                <path d="M23.5466 33.9274H22.9216C22.598 33.9274 22.3357 34.1897 22.3357 34.5133C22.3357 34.8369 22.598 35.0992 22.9216 35.0992H23.5466C23.8703 35.0992 24.1326 34.8369 24.1326 34.5133C24.1326 34.1897 23.8703 33.9274 23.5466 33.9274Z" fill="white" />
                <path d="M20.9685 33.9274H20.3435C20.0199 33.9274 19.7576 34.1897 19.7576 34.5133C19.7576 34.8369 20.0199 35.0992 20.3435 35.0992H20.9685C21.2922 35.0992 21.5544 34.8369 21.5544 34.5133C21.5544 34.1897 21.2922 33.9274 20.9685 33.9274Z" fill="white" />
                <path d="M32.8148 6.96954C32.6543 7.1397 32.6104 7.38892 32.7029 7.60368C32.7955 7.81845 33.0071 7.95751 33.241 7.95751H35.7855C36.1092 7.95751 36.3714 7.69517 36.3714 7.37157C36.3714 7.04798 36.1092 6.78563 35.7855 6.78563H34.5992L35.775 5.53931C36.0381 5.26048 36.183 4.89571 36.183 4.51235C36.183 3.68712 35.5117 3.01587 34.6865 3.01587H33.5236C33.2 3.01587 32.9377 3.27821 32.9377 3.60181C32.9377 3.9254 33.2 4.18774 33.5236 4.18774H34.6865C34.8655 4.18774 35.0111 4.33337 35.0111 4.51235C35.0111 4.59548 34.9797 4.67462 34.9226 4.73517L32.8148 6.96954Z" fill="white" />
                <path d="M5.48664 10.9734C8.35164 10.9734 10.7093 8.7657 10.9514 5.96242C11.0994 5.85609 11.1962 5.68289 11.1962 5.48672C11.1962 5.29055 11.0995 5.11734 10.9514 5.01102C10.7094 2.20766 8.35164 0 5.48664 0C2.46133 0 0 2.46133 0 5.48664C0 8.51195 2.46133 10.9734 5.48664 10.9734ZM5.48664 1.17188C7.86586 1.17188 9.80141 3.1075 9.80141 5.48664C9.80141 7.86578 7.86578 9.80141 5.48664 9.80141C3.1075 9.80141 1.17188 7.86586 1.17188 5.48664C1.17188 3.10742 3.1075 1.17188 5.48664 1.17188Z" fill="white" />
                <path d="M4.63843 4.18774H5.18343V7.37157C5.18343 7.69517 5.44577 7.95751 5.76937 7.95751C6.09296 7.95751 6.3553 7.69517 6.3553 7.37157V3.60181C6.3553 3.27821 6.09296 3.01587 5.76937 3.01587H4.63843C4.31483 3.01587 4.05249 3.27821 4.05249 3.60181C4.05249 3.9254 4.31476 4.18774 4.63843 4.18774Z" fill="white" />
                <path d="M34.6115 29.0291C34.5795 29.0237 34.5469 29.0203 34.5134 29.0203C34.4798 29.0203 34.4472 29.0237 34.4152 29.0291C31.5938 29.079 29.2863 31.2682 29.0484 34.0407C28.9516 33.9697 28.8324 33.9273 28.7031 33.9273H28.0781C27.7545 33.9273 27.4922 34.1896 27.4922 34.5132C27.4922 34.8368 27.7545 35.0992 28.0781 35.0992H28.7031C28.8324 35.0992 28.9516 35.0567 29.0484 34.9858C29.2891 37.7906 31.6474 39.9999 34.5134 39.9999C37.5387 40 40 37.5386 40 34.5133C40 31.5208 37.5916 29.0818 34.6115 29.0291ZM34.5134 38.8281C32.1341 38.8281 30.1986 36.8925 30.1986 34.5133C30.1986 32.1342 32.1341 30.1985 34.5134 30.1985C36.8926 30.1985 38.8281 32.1341 38.8281 34.5133C38.8281 36.8925 36.8926 38.8281 34.5134 38.8281Z" fill="white" />
                <path d="M34.7489 32.0425H33.3352C33.0115 32.0425 32.7493 32.3048 32.7493 32.6284C32.7493 32.952 33.0115 33.2144 33.3352 33.2144H34.7489C34.9455 33.2144 35.1054 33.3743 35.1054 33.5708C35.1054 33.7674 34.9454 33.9273 34.7489 33.9273H34.0892C33.7655 33.9273 33.5033 34.1897 33.5033 34.5133C33.5033 34.8369 33.7655 35.0992 34.0892 35.0992H34.7489C34.9455 35.0992 35.1054 35.2591 35.1054 35.4557C35.1054 35.6522 34.9454 35.8122 34.7489 35.8122H33.3352C33.0115 35.8122 32.7493 36.0745 32.7493 36.3981C32.7493 36.7217 33.0115 36.984 33.3352 36.984H34.7489C35.5916 36.984 36.2772 36.2984 36.2772 35.4557C36.2772 35.1001 36.1541 34.7732 35.9496 34.5133C36.154 34.2533 36.2772 33.9265 36.2772 33.5708C36.2773 32.7281 35.5916 32.0425 34.7489 32.0425Z" fill="white" />
                <path d="M5.48667 31.8322C4.00823 31.8322 2.80542 33.035 2.80542 34.5134C2.80542 35.9918 4.00823 37.1947 5.48667 37.1947C6.96511 37.1947 8.16792 35.9918 8.16792 34.5134C8.16792 33.035 6.96511 31.8322 5.48667 31.8322ZM5.48667 36.0227C4.6544 36.0227 3.97729 35.3456 3.97729 34.5133C3.97729 33.6811 4.6544 33.004 5.48667 33.004C6.31894 33.004 6.99605 33.6811 6.99605 34.5133C6.99605 35.3456 6.31894 36.0227 5.48667 36.0227Z" fill="white" />
                <path d="M27.9161 28.9807V22.1874C27.9161 21.8639 27.6538 21.6015 27.3301 21.6015C27.0065 21.6015 26.7442 21.8639 26.7442 22.1874V28.6292H13.2556V11.3707H26.7442V19.4531C26.7442 19.7767 27.0065 20.039 27.3301 20.039C27.6538 20.039 27.9161 19.7767 27.9161 19.4531V11.0192C27.9161 10.5668 27.5481 10.1989 27.0958 10.1989H12.9041C12.4517 10.1989 12.0837 10.5668 12.0837 11.0192V28.9807C12.0837 29.4331 12.4517 29.801 12.9041 29.801H27.0958C27.5481 29.801 27.9161 29.4331 27.9161 28.9807Z" fill="white" />
                <path d="M22.565 14.0941C22.8886 14.0941 23.1509 13.8318 23.1509 13.5082C23.1509 13.1846 22.8886 12.9222 22.565 12.9222H17.4348C17.1112 12.9222 16.8489 13.1846 16.8489 13.5082C16.8489 13.8318 17.1112 14.0941 17.4348 14.0941H22.565Z" fill="white" />
                <path d="M24.1725 15.3922H22.2656C21.942 15.3922 21.6797 15.6546 21.6797 15.9781C21.6797 16.3017 21.942 16.5641 22.2656 16.5641H24.1725C24.4962 16.5641 24.7584 16.3017 24.7584 15.9781C24.7584 15.6546 24.4962 15.3922 24.1725 15.3922Z" fill="white" />
                <path d="M15.2415 15.9781C15.2415 16.3017 15.5038 16.5641 15.8274 16.5641H20.4686C20.7923 16.5641 21.0546 16.3017 21.0546 15.9781C21.0546 15.6546 20.7923 15.3922 20.4686 15.3922H15.8274C15.5038 15.3922 15.2415 15.6546 15.2415 15.9781Z" fill="white" />
                <path d="M24.1724 17.4484H15.8274C15.5038 17.4484 15.2415 17.7107 15.2415 18.0343C15.2415 18.3579 15.5038 18.6202 15.8274 18.6202H24.1724C24.4961 18.6202 24.7583 18.3579 24.7583 18.0343C24.7583 17.7107 24.4961 17.4484 24.1724 17.4484Z" fill="white" />
                <path d="M24.1725 19.5044H20.7812C20.4576 19.5044 20.1953 19.7667 20.1953 20.0903C20.1953 20.4139 20.4576 20.6763 20.7812 20.6763H24.1725C24.4962 20.6763 24.7584 20.4139 24.7584 20.0903C24.7584 19.7667 24.4962 19.5044 24.1725 19.5044Z" fill="white" />
                <path d="M15.8274 20.6763H19.0624C19.386 20.6763 19.6483 20.4139 19.6483 20.0903C19.6483 19.7667 19.386 19.5044 19.0624 19.5044H15.8274C15.5038 19.5044 15.2415 19.7667 15.2415 20.0903C15.2415 20.4139 15.5038 20.6763 15.8274 20.6763Z" fill="white" />
                <path d="M24.1724 21.5605H15.8274C15.5038 21.5605 15.2415 21.8229 15.2415 22.1465C15.2415 22.4701 15.5038 22.7324 15.8274 22.7324H24.1724C24.4961 22.7324 24.7583 22.4701 24.7583 22.1465C24.7583 21.8229 24.4961 21.5605 24.1724 21.5605Z" fill="white" />
                <path d="M24.1725 23.6166H21.4063C21.0826 23.6166 20.8203 23.8789 20.8203 24.2025C20.8203 24.5261 21.0826 24.7885 21.4063 24.7885H24.1725C24.4962 24.7885 24.7584 24.5261 24.7584 24.2025C24.7584 23.8789 24.4962 23.6166 24.1725 23.6166Z" fill="white" />
                <path d="M15.8274 24.7885H19.7655C20.0892 24.7885 20.3515 24.5261 20.3515 24.2025C20.3515 23.8789 20.0892 23.6166 19.7655 23.6166H15.8274C15.5038 23.6166 15.2415 23.8789 15.2415 24.2025C15.2415 24.5261 15.5038 24.7885 15.8274 24.7885Z" fill="white" />
                <path d="M15.8274 26.8445H24.1724C24.4961 26.8445 24.7583 26.5821 24.7583 26.2585C24.7583 25.935 24.4961 25.6726 24.1724 25.6726H15.8274C15.5038 25.6726 15.2415 25.935 15.2415 26.2585C15.2415 26.5821 15.5038 26.8445 15.8274 26.8445Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_353_1248">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
