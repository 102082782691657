import React,{useEffect, useState} from 'react';
import {ethers} from "ethers";
import {
    useWeb3Modal,
    useWeb3ModalAccount,
    useWeb3ModalProvider,
  } from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import marketplaceAbi from "../../Connectivity/abi/marketplace.json";
import { buyIdea, buyPriceAddress } from '../../../redux/admin/action';
import PagesIndex from '../../PagesIndex';

export default function Success() {
  
  return (
    <div>
      Your transaction is completed we are redirecting back to the app...
    </div>
  )
}
