import React, { useEffect, useState } from "react";
import "./SubCategoryManagementList.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  deleteSubCategoryManagementAction,
  getSubCategoryManagementAction,
} from "../../../../redux/admin/action";

import { useLocation, useParams } from "react-router-dom";


const SubCategoryManagementList = () => {
  // state declaration
  const navigate = PagesIndex.useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [subcategoryManagement, setSubCategoryManagement] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");
  const params = useParams();

  // Back Button
  const handleBack = () => {
    navigate("/admin/category-management");
  }

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  //get-subcat-list

  const getsubCategoryManagement = () => {
    getSubCategoryManagementAction(params?.categoryId, adminToken).then((res) => {
      setSubCategoryManagement(res);
      setLoading(false);
      // setPageData(res)
    });
  };
  // function declaration
  const handleClickMenu = (e, row) => {
    setActionIndex();
    setAnchorEl(e.currentTarget);
    setEditData(row);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setEditData("");
  };

  // delete function declaration
  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setEditData("");
  };
  const handleDeleteOpen = (id) => {
    setSubCategoryId(id);
    setDeleteModalOpen(true);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    deleteSubCategoryManagementAction(subCategoryId, adminToken).then(
      (res) => {
        // debugger;
        if (res.status == 200) {
          getsubCategoryManagement();
          handleDeleteClose();
          setEditData("");
        }
      }
    );
  };
  // add edit modal function declaration
  const openSubCategoryManagementModal = (id) => {
    setSubCategoryId(id);
    setModalOpen(true);
    setAnchorEl(null);
  };
  const handleEdit = (row) => {
    setEditData(row);
    setModalOpen(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setModalOpen(false);
    setEditData("");
  };
  // list function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };


  // Filter fetched data
  useEffect(() => {
    const filtered =
      subcategoryManagement &&
      subcategoryManagement?.filter((item) => {
        return !search
          ? item
          : item?.subCategoryName?.toLowerCase().includes(search?.toString());
      });

    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [subcategoryManagement, search]);

  useEffect(() => {
    !modalOpen && getsubCategoryManagement();
  }, [modalOpen]);

  useEffect(() => {
    !deleteModalOpen && getsubCategoryManagement();
  }, [deleteModalOpen]);


  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className='form-back-btn mb-15'>
            <Index.Button variant="contained"
              className="back-btn"
              type='back'
              onClick={() => handleBack()}
            >
              Back
            </Index.Button>
          </Index.Box>
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                {params?.categoryName && `${params?.categoryName} `} &rarr;  Sub Category 
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                <Index.Box className="add-user-btn">
                  <PagesIndex.Link className="no-text-decoration">
                    <Index.Button
                      variant="contained"
                      onClick={() =>
                        openSubCategoryManagementModal(params?.categoryId)
                      }
                    >
                      Add sub Category
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No</Index.TableCell>
                    <Index.TableCell align="left">Image</Index.TableCell>
                    <Index.TableCell align="left">Parent Category</Index.TableCell>
                    <Index.TableCell align="left">Title</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row.sNo}>
                            <Index.TableCell align="left">{row.sNo}</Index.TableCell>
                            <Index.TableCell align="left">
                              <img
                                src={
                                  row?.subCategoryImage
                                    ? `${PagesIndex.ImageURL}${row?.subCategoryImage}`
                                    : PagesIndex.Png.profileimg
                                }
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              />
                            </Index.TableCell>
                            
                            <Index.TableCell align="left">
                              {params?.categoryName}
                            </Index.TableCell>    

                            <Index.TableCell align="left">
                              {row.subCategoryName}
                            </Index.TableCell>
                            <Index.TableCell align="center">

                              {/* <Index.IconButton
                                onClick={(e) => {
                                  handleClickMenu(e, row);
                                }}
                              >
                                <Index.MenuIcon className="action-menu-icon category-action-menu-icon" />
                              </Index.IconButton>

                              <Index.Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                keepMounted
                                elevation={2}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >

                                <PagesIndex.ActionItem
                                  className="action-text"
                                  onClick={() => handleEdit()}
                                >
                                  Edit
                                </PagesIndex.ActionItem>

                                <PagesIndex.ActionItem
                                  className="action-text"
                                  onClick={() => handleDeleteOpen()}
                                >
                                  Delete
                                </PagesIndex.ActionItem>

                              </Index.Menu> */}

                              <Index.Tooltip title="Edit">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleEdit(row)}
                                >
                                  <Index.EditIcon />
                                </Index.Button>
                              </Index.Tooltip>
                              <Index.Tooltip title="Delete">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleDeleteOpen({id: row?._id})}
                                >
                                  <Index.DeleteIcon />
                                </Index.Button>
                              </Index.Tooltip>

                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={modalOpen}
        onClose={closeModal}
        className="category-modal"
      >
        <PagesIndex.SubCategoryModel
          handleClose={closeModal}
          editData={editData}
          setModalOpen={setModalOpen}
          setEditData={setEditData}
          subCategoryId={subCategoryId}
        />
      </Index.Modal>
      <PagesIndex.DeleteModal
        handleDeleteRecord={handleDelete}
        handleDeleteClose={handleDeleteClose}
        deleteOpen={deleteModalOpen}
      />
    </>
  );
};

export default SubCategoryManagementList;
