import React, {memo, useEffect} from "react";
import Index from "../../../../Index";
import moment from "moment";
import PagesIndex from "../../../../PagesIndex";

const NFTList = ({ pageData, loading, setLoading, handleClickOpenDescription }) => {
  useEffect(()=> {
    setLoading(false);
  }, [loading])
  
  return (
    <>
      <Index.Table
        stickyHeader
        aria-label="sticky table"
        className="episap-table-main contact-table user-details"
      >
        <Index.TableHead>
          <Index.TableRow>
            <Index.TableCell align="left">No</Index.TableCell>
            <Index.TableCell align="left">NFT Name</Index.TableCell>
            <Index.TableCell align="left">Image</Index.TableCell>
            <Index.TableCell align="left">Created By</Index.TableCell>
            <Index.TableCell align="left">Created Date & Time</Index.TableCell>
            <Index.TableCell align="left">Description</Index.TableCell>
          </Index.TableRow>
        </Index.TableHead>

        <Index.TableBody>
          {!loading ? (
            pageData.length ? (
              pageData.map((row) => (
                <Index.TableRow key={row._id}>
                  <Index.TableCell align="left">{row.sNo}</Index.TableCell>

                  <Index.TableCell
                    className="table-title-word-break"
                    align="left"
                  >
                    {row.title}
                  </Index.TableCell>

                  <Index.TableCell align="left">
                    <img
                      src={`${PagesIndex.ImageURL}${row?.displayImage}`}
                      alt="Image Preview"
                      style={{
                        height: "60px",
                        width: "80px",
                        display: "table-column",
                      }}
                    />
                  </Index.TableCell>

                  <Index.TableCell align="left">
                    {row?.uploadBy?.userName}
                  </Index.TableCell>

                  <Index.TableCell align="left">
                    {moment(row?.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleClickOpenDescription("NFT", row?.descriptions)
                    }
                  >
                    <Index.Tooltip title={"View Description"}>
                      {row?.descriptions &&
                        (row?.descriptions?.length > 15
                          ? row?.descriptions?.slice(0, 15) + "..."
                          : row?.descriptions)}
                    </Index.Tooltip>
                  </Index.TableCell>
                </Index.TableRow>
              ))
            ) : (
              <PagesIndex.NoRecordFound colSpan={12} />
            )
          ) : (
            <PagesIndex.PageLoading colSpan={12} />
          )}
        </Index.TableBody>
      </Index.Table>
    </>
  );
};

export default memo(NFTList);
