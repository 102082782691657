const ADMIN = "admin";
const COMMON = "common";
const USER = "user";
const WALLET = "wallet";

export const Api = {
  Admin: {
    getDashboardData: `${ADMIN}/get-dashboard`,
    getMasterWardenDashboardData: `${ADMIN}/get-master-warden-dashboard`,
    login: `${ADMIN}/login`,
    getSingleLeafNodeDetail: `${ADMIN}/get-single-idea-leaf-idea`,
    getIdeaToleafIdeaList: `${ADMIN}/get-idea-leaf-idea`,
    deleteSubCategory: `${ADMIN}/delete-subcategory`,
    addSubCategory: `${ADMIN}/add-subcategory`,
    forgetPassword: `${ADMIN}/forgotPassMail`,
    verifyOtp: `${ADMIN}/verify-otp`,
    otpSend: `${ADMIN}/resend-admin-otp`,
    resetPassword: `${ADMIN}/reset-password`,
    changePassword: `${ADMIN}/change-password`,
    updateProfile: `${ADMIN}/edit-profile`,
    getAdminProfile: `${ADMIN}/get-Admin`,
    getUserList: `${ADMIN}/get-userlist`,
    getWardenCms: `${ADMIN}/getWarden`,
    getWardenSteps: `${ADMIN}/getSteps`,
    addEditWardenSteps: `${ADMIN}/addEditSteps`,
    addEditWardenRoles: `${ADMIN}/addeditWarden`,
    addEditPrivacy: `${ADMIN}/addEdit-privacy-policy`,
    addEdittermsCondition: `${ADMIN}/addEdit-terms-conditions`,
    addEditAboutUs: `${ADMIN}/addEdit-about-us`,
    nftAddEdittermsCondition: `${ADMIN}/addEdit-nft-terms-conditions`,
    nftAddEditPrivacy: `${ADMIN}/addEdit-nft-privacy-policy`,
    contactStatusUpdate: `${ADMIN}/update-request-status`,
    addEditContactUS: `${ADMIN}/addEdit-contact-us`,
    activeDeactiveUser: `${ADMIN}/active-deactive-user`,

    editCategory: `${ADMIN}/update-category`,
    addCategory: `${ADMIN}/add-category`,
    addTag: `${ADMIN}/add-edit-tag`,
    editTag: `${ADMIN}/add-edit-tag`,
    deleteCategory: `${ADMIN}/delete-category`,
    deleteTag: `${ADMIN}/delete-tag`,
    nftList: `${ADMIN}/get-nft-idea-list`,
    userDetail: `${ADMIN}/view-user-details`,
    editUser: `${ADMIN}/edit-user`,
    deleteUser: `${ADMIN}/delete-user`,
    getActivityLog: `${ADMIN}/get-admin-nft-activity-log`,
    nftActiveDeactive: `${ADMIN}/active-deactive-nft`,
    reportedPostActiveDeactive: `${ADMIN}/active-inactive-social-media-post`,
    userContactList: `${ADMIN}/get-all-user-contact-us`,
    PFPList: `${ADMIN}/get-all-pfp`,
    addPfp: `${ADMIN}/create-pfp`,
    editPfp: `${ADMIN}/update-pfp`,
    deletePfp: `${ADMIN}/delete-pfp`,
    inviteWardenList: `${ADMIN}/get-all-warden-step-email`,
    addWarden: `${ADMIN}/send-warden-step-email`,
    whiteListedUser: `${ADMIN}/identity-request-kyc-list`,
    whiteListedApproveReject: `${ADMIN}/identity-approve-or-reject`,
    expertList: `${ADMIN}/get-all-document-list`,
    expertApproveReject: `${ADMIN}/document-approve-or-reject`,
    getSingleWardenActions: `${ADMIN}/get-single-warden-action-list`,

    communityGroupList: `${ADMIN}/get-all-community-group-list`,
    communityGroupMemberList: `${ADMIN}/get-community-group`,
    activeDeactiveGroupMember: `${ADMIN}/disable-user`,
    activeDeactiveGroup: `${ADMIN}/disable-group`,
    reportList: `${ADMIN}/get-all-block-report-reason`,
    editReport: `${ADMIN}/update-block-report-reason`,
    addReport: `${ADMIN}/add-block-report-reason`,
    deleteReport: `${ADMIN}/delete-block-report-reason`,
    getDiscussion: `${ADMIN}/get-discussions`,
    getReports: `${ADMIN}/get-filter-dashbord`,
    logOut: `${ADMIN}/logout`,
    categorySubmit: `${ADMIN}/admin-select-user-catagory`,
    addMasterWarden: `${ADMIN}/create-master-warden`,
    editMasterWarden: `${ADMIN}/update-master-warden`,
    masterWardenList: `${ADMIN}/get-all-master-warden`,
    masterWardenDelete: `${ADMIN}/delete-master-warden`,
    getTransactionHistory: `${ADMIN}/get-all-transaction-history`,
    getPendingTransaction: `${ADMIN}/get-pending-transaction-list`,
    getPFPIdeas: `${ADMIN}/get-all-idea-pfp`,

    getSubCategory: `${COMMON}/get-subcategory`,
    getCms: `${COMMON}/get-cms`,
    getNftCms: `${COMMON}/get-nft-cms`,
    getallcategory: `${COMMON}/getallcategory`,
    getalltag: `${COMMON}/get-tags`,
    socialPostList: `${ADMIN}/get-all-social-media-post`,
    socialPostDelete: `${ADMIN}/delete-social-media-post`,
    reportedPostList: `${ADMIN}/get-social-media-post-report-list`,
    updatePFPIdeaAction: `${ADMIN}/approve-reject-pfp-nft-idea`,
    notificationList: `${ADMIN}/get-admin-notification-list`,
    inappropriateNFTRequestList: `${ADMIN}/get-admin-inappropriate-idea-list`,
    inappropriatePFPRequestList: `${ADMIN}/get-admin-inappropriate-idea-list`,
    inappropriateExpertRequestList: `${ADMIN}/get-admin-inappropriate-idea-list`,
    inappropriateStatusUpdateRequest: `${ADMIN}/approve-reject-admin-inappropriate-idea`,
    getContactDetailRequest: `${ADMIN}/get-single-user-contact-us`,
    getSocialDetailRequest: `${ADMIN}/get-single-post-detail-action`,
    getWalletAddress: `${ADMIN}/get-private-key`,
    addEditWalletAddress: `${ADMIN}/add-private-key`,
    addEditRewardTokenRequest: `${ADMIN}/store-refferal-reward-amount`,
    getRewardTokenRequest: `${ADMIN}/get-refferal-reward-amount`,
    getReferralsUserRequest: `${ADMIN}/get-all-refferal-users-list`,
    getReferralsLogHistoryRequest: `${ADMIN}/get-reward-amount-history`,
  },
  Wallet: {
    evaluteIdea: `${WALLET}/evolute-idea`,
    buyIdea: `${WALLET}/buy-idea`,
    sellIdea: `${WALLET}/sell-idea`,
    putOnSale: `${WALLET}/put-on-sale`,
    masterProcessingIdea: `${WALLET}/masterprocessing-idea`,
    buyPriceAdress: `${WALLET}/calculate-buy-idea-amount`,
    buyId: `${WALLET}/get-idea-details`,
    pfp: `${WALLET}/extend-pfp-idea-time`,
    becameworden: `${WALLET}/change-user-to-warden`,
    transactionHash: `${WALLET}/buy-sell-transaction-hash`,
  },
  AddUser: {
    addUser: `${USER}/register`,
    otpSend: `${USER}/verify-profile-otp`,
    verifyOtp: `${USER}/otp-verify-profile`,
    resendOtp: `${USER}/resend-profile-otp`,
    readUnreadNotificationRequest: `${USER}/read-unread-notification`,
    treeNFTRequest: `${USER}/get-idea`,
  },
  MasterWarden: {
    nftList: `${ADMIN}/get-nft-idea-list`,
    getInappropriateNftList: `${ADMIN}/get-inappropriate-nft-idea-list`,
    nftActionList: `${ADMIN}/get-nft-idea-action-list`,
    updateNftAction: `${ADMIN}/approve-reject-nft-idea`,
    wardenList: `${ADMIN}/get-warden-user-list`,
    wardenWarnig: `${ADMIN}/send-warden-warning`,
    wardenWarnigList: `${ADMIN}/get-master-warden-warning-list`,
    changeWardenToUser: `${ADMIN}/change-warden-to-user`,
    expertUserIdeaList: `${ADMIN}/get-expert-nft-idea-list`,
    expertIdeaActionUpdate: `${ADMIN}/approve-reject-expert-nft-idea`,
    inappropriateStatusUpdateAction: `${ADMIN}/master-warden-inappropriate-idea`,
    getMasterWardenTransactionHistory: `${ADMIN}/get-master-warden-transaction-history`,
  },
};
