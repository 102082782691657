import React, { useState } from "react";
import Catergoryselect from "../../container/pages/admin/addUser/Catergoryselect";
import { AddUser } from "../../container/pages/admin/addUser/AddUser";
import Index from "../Index";
import OtpSend from "../../container/pages/admin/addUser/OtpSend";
import { OtpVerify } from "../../container/pages/admin/addUser/OtpVerify";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 2,
    borderRadius: 1,
};
export const CommonModal = ({ authStep, setAuthStep, open, handleClose,userEditData }) => {
    const [verifyId, setVerifyId] = useState(null)
    const [userToken, setUserToken] = useState(null)
    const [id, setId] = useState();


    const handleAddUser = () => {
        setAuthStep("Add_User");
    };

    const handleOtpSend = () => {
        setAuthStep("Otp_Send");

    };
    const handleVerifyOtp = (res) => {

        setVerifyId(res)
        setAuthStep("Otp_Verify");

    };
    const handdleCategorySelect = (res) => {
        setAuthStep("Catergory_Select")
    
        setUserToken(res)

    }

    return (
        <>
            <Index.Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="auth-modal login-with-modal"
            >
                <Index.Box
                    sx={style}
                    className="auth-modal-inner-main login-with-modal-inner-main"
                >
                    {authStep === "Add_User" && (
                        <AddUser
                            handleClose={handleClose}
                            handleAddUser={handleAddUser}
                            onClose={handleClose}
                            handleOtpSend={handleOtpSend}
                            userEditData={userEditData}

                        />
                    )}

                    {authStep === "Otp_Send" && (
                        <OtpSend
                            handleVerifyOtp={handleVerifyOtp}
                            handleClose={handleClose}
                            setId={setId}
                        />
                    )}
                    {authStep === "Otp_Verify" && (
                        <OtpVerify
                            handleCaterSelect={handdleCategorySelect}
                            handleVerifyOtp={handleVerifyOtp}
                            handleClose={handleClose}
                            verifyId={verifyId}
                        />
                    )}

                    {authStep === "Catergory_Select" && (
                        <Catergoryselect
                            handleClose={handleClose}
                            handleOtpSend={handleOtpSend}
                            userToken={userToken}
                            verifyId={verifyId}
                        />
                    )}

                </Index.Box>
            </Index.Modal>
        </>
    );
};
