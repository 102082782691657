import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  activeDeactiveGroupMember,
  communityGroupMemberList,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import "./CommunityMemberList.css";
import { PropTypes } from "prop-types";
import MemberList from "./MemberList";
import CommunityDiscussion from "./CommunityDiscussion";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes?.node,
  index: PropTypes?.number?.isRequired,
  value: PropTypes?.number?.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GroupMemberList() {
  // state declaration
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);

  const [openNftDescription, setOpenNftDescription] = useState(false);
  const [nftDescription, setNftDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  const navigate = PagesIndex.useNavigate();
  const handleBack = () => {
    navigate("/admin/group-list");
  };

  // community modal
  const handleClickOpenDescription = (title, description) => {
    setOpenNftDescription(true);
    setDescriptionTitle(title);
    setNftDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenNftDescription(false);
    setDescriptionTitle("");
    setNftDescription("");
  };

  //tab function declaration
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { state } = PagesIndex.useLocation();

  //   function declaration

  const getGroupMemberList = () => {
    communityGroupMemberList({ groupId: state.groupId }, adminToken).then(
      (res) => {
        setGroupMemberList(res?.data);
        setLoading(false);
        // setPageData(res?.data)
      }
    );
  };

  useEffect(() => {
    getGroupMemberList();
  }, []);

  const formatData = (data) => {
    const prefix = data?.substring(0, 3);
    const lastTwoCharacters = data?.substring(data?.length - 3);
    const maskedData = `${prefix}...${lastTwoCharacters}`;
    return maskedData;
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Grid container spacing={2} className="align-items-start">
            <Index.Grid item xs={12} md={6}>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex mb-0">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title mb-0"
                    >
                      Community Group Details
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={12} md={6}>
              <Index.Box className="form-back-btn text-end">
                <Index.Button
                  variant="contained"
                  className="back-btn"
                  type="back"
                  onClick={() => handleBack()}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={12} md={6}>
              <Index.Box className="box-title">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title page-sub-title mb-0 text-center"
                  >
                    Group Detail
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-detail-cont pb-0 bg-none pt-10">
                <Index.Grid container spacing={1}>
                  <Index.Grid item xs={12} md={3}>
                    <Index.Box className="user-detail-img group-member-img">
                      <img
                        src={
                          groupMemberList?.groupIcon == ""
                            ? PagesIndex.Jpg.dummyImage
                            : `${PagesIndex.ImageURL}${groupMemberList?.groupIcon}`
                        }
                        alt=""
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} md={9}>
                    <Index.Grid container>
                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Group Name
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {groupMemberList?.groupName}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Category
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {groupMemberList?.category &&
                          groupMemberList?.category.length > 0
                            ? groupMemberList?.category[0]?.categoryName
                            : ""}
                        </Index.Typography>
                      </Index.Box>
                      
                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Description
                        </Index.Typography>

                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                          onClick={() =>
                            handleClickOpenDescription(
                              "Community",
                              groupMemberList?.description
                            )
                          }
                        >
                          <Index.Tooltip>
                            {groupMemberList?.description &&
                              (groupMemberList?.description?.length > 15
                                ? groupMemberList?.description.slice(0, 15) +
                                  "..."
                                : groupMemberList?.description)}
                          </Index.Tooltip>
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Created Date
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {moment(groupMemberList?.createdAt).format(
                            "DD/MM/YYYY"
                          )}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={12} md={6}>
              <Index.Box className="box-title">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title page-sub-title mb-0 text-center"
                  >
                    Owner Detail
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-detail-cont pb-0 bg-none pt-10">
                <Index.Grid container spacing={1}>
                  <Index.Grid item xs={12} md={3}>
                    <Index.Box className="user-detail-img group-member-img">
                      <img
                        src={
                          groupMemberList?.owner?.profile == ""
                            ? PagesIndex.Jpg.dummyImage
                            : `${PagesIndex.ImageURL}${groupMemberList?.owner?.profile}`
                        }
                        alt=""
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} md={9}>
                    <Index.Grid container>
                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Name
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {groupMemberList?.owner?.fullName}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Username
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {groupMemberList?.owner?.userName}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Mobile No.
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {groupMemberList?.owner?.countryCode}{" "}
                          {groupMemberList?.owner?.mobileNumber}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Email
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {groupMemberList?.owner?.email}
                        </Index.Typography>
                      </Index.Box>
                      {/* <Index.Box className="user-detail user-group-box-detail">
                        <Index.Typography variant="p" component="p" className="user-detail-title">
                          Description
                        </Index.Typography>
                        <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                          <Index.Tooltip title={groupMemberList?.description}> {
                            groupMemberList?.description &&
                            (groupMemberList.description?.length > 15
                              ? groupMemberList.description.slice(0, 15) + "..."
                              : groupMemberList.description)
                          }</Index.Tooltip>
                        </Index.Typography>
                      </Index.Box> */}
                    </Index.Grid>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>

          <Index.Grid>
            <Index.Grid item xs={12} md={12}>
              <Index.Box className="table-wrape pl-0 pr-0">
                <Index.Box>
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="admin-tabs-main"
                  >
                    <Index.Tab
                      label="Group Members"
                      {...a11yProps(0)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Group Discussion"
                      {...a11yProps(1)}
                      className="admin-tab"
                    />
                    {/* <Index.Tab
                  label="Wardens"
                  {...a11yProps(2)}
                  className="admin-tab"
                /> */}
                  </Index.Tabs>
                </Index.Box>
                <Index.Box></Index.Box>

                {value == 0 ? (
                  <MemberList
                    groupMemberList={groupMemberList}
                    getGroupMemberList={getGroupMemberList}
                    loading={loading}
                  />
                ) : (
                  <CommunityDiscussion />
                )}
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>

      {/* Nft description show modal start */}
      <Index.Modal
        open={openNftDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle} Description
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {nftDescription ? nftDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* Nft description modal end */}
    </>
  );
}

export default GroupMemberList;
