import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import { getContactDetailsAction } from "../../../../redux/admin/action";

const ContactDetail = () => {
  const { id } = PagesIndex.useParams();
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [contactData, setContactData] = useState({});
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  const [openNftDescription, setOpenNftDescription] = useState(false);
  const [nftDescription, setNftDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  const handleBack = () => {
    navigate("/admin/contact-us-list");
  };

  // community modal
  const handleClickOpenDescription = (title, description) => {
    setOpenNftDescription(true);
    setDescriptionTitle(title);
    setNftDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenNftDescription(false);
    setDescriptionTitle("");
    setNftDescription("");
  };

  const getContactDetails = () => {
    getContactDetailsAction(id, adminToken, navigate).then((res) => {
      setContactData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getContactDetails();
  }, [id]);

//   console.log(contactData, "contactData");

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-common-box">
        <Index.Grid container className="mb-10">
          <Index.Box className="user-detail-cont bg-right">
            <Index.Grid container spacing={1}>
              <Index.Grid item xs={12}>
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex justify-content-between">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Contact Details
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="form-back-btn">
                      <Index.Button
                        variant="contained"
                        className="back-btn"
                        type="back"
                        onClick={() => handleBack()}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} md={12} lg={12}>
                {!loading ? (
                  <>
                    <Index.Box className="admin-nft-detail">
                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Full Name :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {contactData?.userId?.fullName
                            ? contactData?.userId?.fullName
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Username :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {contactData?.userId?.userName
                            ? contactData?.userId?.userName
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Email :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {contactData?.userId?.email
                            ? contactData?.userId?.email
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Created Date :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {contactData?.createdAt
                            ? moment(contactData?.createdAt).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Mobile :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {contactData?.userId?.mobileNumber
                            ? contactData?.userId?.mobileNumber
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Status :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                          style={{
                            color:
                              contactData?.requestStatus === "Completed"
                                ? "green"
                                : contactData?.requestStatus === "In Progress"
                                ? "blue"
                                : contactData?.requestStatus === "Pending"
                                ? "blue"
                                : "red",
                          }}
                        >
                          {contactData?.requestStatus
                            ? contactData?.requestStatus
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Subject :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {contactData?.subject ? contactData?.subject : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Message :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                          onClick={() =>
                            handleClickOpenDescription(
                              "Message",
                              contactData?.message
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          {contactData?.message ? contactData?.message : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Remark :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                          onClick={() =>
                            handleClickOpenDescription(
                              "Remark",
                              contactData?.remarks
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          {contactData?.remarks ?
                                  (contactData?.remarks?.length > 35
                                    ? contactData?.remarks.slice(0, 35) + "..."
                                    : contactData?.remarks) : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </>
                ) : (
                    <Index.Box className="single-page-loader">
                  <PagesIndex.PageLoading />
                  </Index.Box>
                )}
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Grid>
      </Index.Box>
      {/* Nft description show modal start */}
      <Index.Modal
        open={openNftDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {nftDescription ? nftDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* Nft description modal end */}
    </Index.Box>
  );
};

export default ContactDetail;
