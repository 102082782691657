import React from "react";
import "../subCategoryManagementModel/SubCategoryModel.css";
import "../subCategoryManagementModel/SubCategoryModel.responsive.css";
import PagesIndex from "../../../PagesIndex";
import { useTheme } from "@mui/material/styles";
import Index from "../../../Index";
import { contactStatusUpdateAction } from "../../../../redux/admin/action";
import { getStyles } from "../addUser/UserModal.Style";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RemarkModal = (props) => {
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { setUserId, setOpenModal, handleClose, userId, userContact } = props;
  const theme = useTheme();
  // Initital values
  const initialValues = {
    requestStatus: userId ? userId.status : "",
    remarks: "",
  };

  // functions declaration
  const handleFormSubmit = (values) => {
    contactStatusUpdateAction(
      { ...values, contactusId: userId.id },
      adminToken
    ).then((res) => {
      if (res?.status === 200) {
        setUserId("");
        setOpenModal(false);
        userContact();
      }
    });
  };
  const status = [
    { label: "Pending", value: "Pending" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Rejected", value: "Rejected" },
  ];
  return (
    <>
      {/* <Index.Modal open={true}> */}
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Contact Status
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="user-circle-img"
                  alt="icon"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div ">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.contactStatusSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="status-select">
                    <Index.Select
                      id="filled-hidden-label-normal"
                      placeholder="Title"
                      variant="filled"
                      className="admin-input-design input-placeholder"
                      displayEmpty
                      name="requestStatus"
                      value={values.requestStatus}
                      onChange={(event) => {
                        setFieldValue("requestStatus", event.target.value);
                      }}
                      input={<Index.OutlinedInput />}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {status.map((name) => (
                        <Index.MenuItem
                          key={name.value}
                          value={name.value}
                          style={getStyles(name, values.requestStatus, theme)}
                        >
                          {name.value}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.Box>
                  <span className="error-text">
                    {touched.requestStatus && errors.requestStatus}
                  </span>

                  <Index.Box className="input-textarea">
                    <Index.TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Remark"
                      multiline
                      name="remarks"
                      onBlur={handleBlur}
                      value={values.remarks}
                      // onChange={handleChange}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/\s\s+/g, " ")
                          .replace(/\n\s*/g, "\n");
                        setFieldValue("remarks", newValue);
                      }}
                    />
                  </Index.Box>
                  <span className="error-text">
                    {touched.remarks && errors.remarks}
                  </span>

                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                    // disableRipple
                    // disabled={loading}
                    >
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* </Index.Modal> */}
    </>
  );
};

export default RemarkModal;
