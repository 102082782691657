import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getMasterWardenNftList,
  getWardenWarningAction,
  updateNftStatus,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import "../nftIdea/NftIdeaList.css";

function WardenWarnig() {
  // state declaration
  const [loading, setLoading] = useState(true);
  const [warningList, setWarningList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);

  const [openmsgDescription, setOpenmsgDescription] = useState(false);
  const [msgDescription, setmsgDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();

  // function declaration

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const getWardenWarningList = () => {
    getWardenWarningAction(adminToken).then((res) => {
      setWarningList(res);
      setLoading(false);
      // setPageData(res?.data)
    });
  };

  useEffect(() => {
    getWardenWarningList();
  }, []);

  //   // Active deactive function declaration
  //   const nftStatusUpdate = (data) => {
  //     updateNftStatus(data, adminToken).then((res) => {
  //       // debugger;
  //       if (res?.status == 200) {
  //         getNftList();
  //       }
  //     });
  //   };

  // Filter fetched data
  useEffect(() => {
    const filtered = warningList?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.ideaId?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.wardenId?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.wardenId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.wardenId?.email
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item.comment.toLowerCase().includes(search.toString()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [warningList, search]);

  const handleClickOpenDescription = (title, description) => {
    setOpenmsgDescription(true);
    setDescriptionTitle(title);
    setmsgDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenmsgDescription(false);
    setDescriptionTitle("");
    setmsgDescription("");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Warnings
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container"
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                // className="table-design-main barge-table"
                className="episap-table-main master-warden-nft-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">NFT Image</Index.TableCell>
                    <Index.TableCell align="left">NFT Title</Index.TableCell>
                    <Index.TableCell align="left">Full Name </Index.TableCell>
                    <Index.TableCell align="left">Username </Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">
                      Warning Message
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo} className="">
                            <Index.TableCell>{row?.sNo}</Index.TableCell>
                            <Index.TableCell align="left">
                              <img
                                src={
                                  PagesIndex.ImageURL +
                                  row?.ideaId?.displayImage
                                }
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              />
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.ideaId?.title &&
                                (row?.ideaId?.title?.length > 20
                                  ? row?.ideaId?.title.slice(0, 20) + "..."
                                  : row?.ideaId?.title)}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.wardenId?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.wardenId?.userName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.wardenId?.email}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              onClick={() =>
                                handleClickOpenDescription(
                                  "Warning Message",
                                  row?.comment
                                )
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              <Index.Tooltip>
                                {row?.comment &&
                                  (row.comment?.length > 15
                                    ? row.comment.slice(0, 15) + "..."
                                    : row.comment)}
                              </Index.Tooltip>
                            </Index.TableCell>

                            {/* <Index.TableCell  align="left"
                              style={{ color: row?.masterAction == "Approved" ? "green" : row?.masterAction == "Rejected" ? "red" : "blue" }}
                            >{!row?.masterAction ? "Pending" : row?.masterAction}</Index.TableCell>
                            <Index.TableCell  align="left">{row?.pfpDays}</Index.TableCell>

                            <Index.TableCell 
                              style={{ color: row?.availableOnSale == true ? "green" : "red" }}
                              align="left">{row?.availableOnSale == true ? "Yes" : "No"}</Index.TableCell>
                            <Index.TableCell  align="left">{row?.price}</Index.TableCell> */}
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                            {/* <Index.TableCell  align="left">
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() =>
                                    navigate("/warden/nft-details", {
                                      state: { row },
                                    })
                                  }>
                                  <Index.Visibility
                                  />
                                </Index.Button>

                                {row?.masterAction == "Rejected" ?
                                  <Index.Tooltip title="Rejected">
                                    <Index.Button  >
                                      <Index.ThumbDownAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>

                                  : row?.masterAction == "Approved" ?
                                    <Index.Tooltip title="Approved">
                                      <Index.Button  >
                                        <Index.ThumbUpAltIcon className="icon-btns" />
                                      </Index.Button>
                                    </Index.Tooltip>
                                    :
                                    <Index.Box sx={{ display: "flex" }}>


                                      <Index.Tooltip title="Approve">
                                        <Index.Button
                                          onClick={() => nftStatusUpdate({ masterAction: "Approved", id: row?._id })}
                                        >
                                          <Index.ThumbUpOffAltIcon className="icon-btns" />
                                        </Index.Button>
                                      </Index.Tooltip>
                                      <Index.Tooltip title="Reject">
                                        <Index.Button
                                          onClick={() => nftStatusUpdate({ masterAction: "Rejected", id: row?._id })}
                                        >
                                          <Index.ThumbDownOffAltIcon className="icon-btns" />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    </Index.Box>
                                }


                              </Index.Box>
                            </Index.TableCell> */}
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
                {/* </Index.Box> */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Description Message Popup */}
      <Index.Modal
        open={openmsgDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {msgDescription ? msgDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default WardenWarnig;
