import React from 'react'

export default function NftList() {
    return (

        
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.7843 9.67749L20.5468 0.30249C20.3806 0.206371 20.192 0.155762 20 0.155762C19.8079 0.155762 19.6193 0.206371 19.4531 0.30249L3.21558 9.67749C3.04922 9.77346 2.91109 9.91157 2.81508 10.0779C2.71908 10.2442 2.66859 10.4329 2.6687 10.625V29.375C2.66859 29.567 2.71908 29.7557 2.81508 29.9221C2.91109 30.0884 3.04922 30.2265 3.21558 30.3225L19.4531 39.6975C19.6193 39.7936 19.8079 39.8442 20 39.8442C20.192 39.8442 20.3806 39.7936 20.5468 39.6975L36.7843 30.3225C36.9507 30.2265 37.0888 30.0884 37.1848 29.9221C37.2808 29.7557 37.3313 29.567 37.3312 29.375V10.625C37.3313 10.4329 37.2808 10.2442 37.1848 10.0779C37.0888 9.91157 36.9507 9.77346 36.7843 9.67749ZM35.1437 28.74L20 37.4869L4.8562 28.7431V11.2569L20 2.51311L35.1437 11.2569V28.74Z" fill="white"/>
<path d="M14.375 14.5312C14.085 14.5314 13.8069 14.6467 13.6018 14.8518C13.3967 15.0569 13.2814 15.335 13.2812 15.625V20.2562L10.3244 15.0825C10.2047 14.8732 10.0192 14.7094 9.7967 14.6164C9.57425 14.5235 9.32732 14.5066 9.09431 14.5685C8.86129 14.6304 8.65525 14.7675 8.50821 14.9586C8.36118 15.1496 8.28139 15.3839 8.28125 15.625V24.375C8.28125 24.6651 8.39648 24.9433 8.6016 25.1484C8.80672 25.3535 9.08492 25.4688 9.375 25.4688C9.66508 25.4688 9.94328 25.3535 10.1484 25.1484C10.3535 24.9433 10.4688 24.6651 10.4688 24.375V19.7438L13.4256 24.9175C13.5453 25.1268 13.7308 25.2906 13.9533 25.3836C14.1757 25.4765 14.4227 25.4934 14.6557 25.4315C14.8887 25.3696 15.0948 25.2325 15.2418 25.0414C15.3888 24.8504 15.4686 24.6161 15.4688 24.375V15.625C15.4686 15.335 15.3533 15.0569 15.1482 14.8518C14.9431 14.6467 14.665 14.5314 14.375 14.5312Z" fill="white"/>
<path d="M17.6563 15.625V24.375C17.6563 24.6651 17.7715 24.9433 17.9766 25.1484C18.1817 25.3535 18.4599 25.4688 18.75 25.4688C19.0401 25.4688 19.3183 25.3535 19.5234 25.1484C19.7285 24.9433 19.8438 24.6651 19.8438 24.375V21.0938H22.5C22.7901 21.0938 23.0683 20.9785 23.2734 20.7734C23.4785 20.5683 23.5938 20.2901 23.5938 20C23.5938 19.7099 23.4785 19.4317 23.2734 19.2266C23.0683 19.0215 22.7901 18.9063 22.5 18.9063H19.8438V16.7188H22.5C22.7901 16.7188 23.0683 16.6035 23.2734 16.3984C23.4785 16.1933 23.5938 15.9151 23.5938 15.625C23.5938 15.3349 23.4785 15.0567 23.2734 14.8516C23.0683 14.6465 22.7901 14.5313 22.5 14.5313H18.75C18.6063 14.5312 18.4641 14.5594 18.3313 14.6143C18.1986 14.6693 18.078 14.7498 17.9764 14.8514C17.8748 14.953 17.7943 15.0736 17.7393 15.2063C17.6844 15.3391 17.6562 15.4813 17.6563 15.625Z" fill="white"/>
<path d="M26.25 16.7188H27.6562V24.375C27.6563 24.6651 27.7715 24.9433 27.9766 25.1484C28.1817 25.3535 28.4599 25.4688 28.75 25.4688C29.0401 25.4688 29.3183 25.3535 29.5234 25.1484C29.7285 24.9433 29.8438 24.6651 29.8438 24.375V16.7188H31.25C31.5401 16.7188 31.8183 16.6035 32.0234 16.3984C32.2285 16.1933 32.3438 15.9151 32.3438 15.625C32.3438 15.3349 32.2285 15.0567 32.0234 14.8516C31.8183 14.6465 31.5401 14.5312 31.25 14.5312H26.25C25.9599 14.5312 25.6817 14.6465 25.4766 14.8516C25.2715 15.0567 25.1562 15.3349 25.1562 15.625C25.1562 15.9151 25.2715 16.1933 25.4766 16.3984C25.6817 16.6035 25.9599 16.7188 26.25 16.7188Z" fill="white"/>
</svg>


    )
}
