import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./Cms.css";
import { addEditPrivacy, getCms } from "../../../../redux/admin/action";
import dataService from "../../../../config/CkService";

const PrivayPolicy = () => {
  // state declaration
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [addEditData, setAddEditData] = useState({});

  // const  declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  const initialValues = {
    description: addEditData ? addEditData?.description : "",
    title: addEditData ? addEditData?.title : "",
  };

  // function declaration
  const handleSubmit = (values) => {
    setLoadingUpdate(true);
    addEditPrivacy(values, adminToken);
    setTimeout(() => {
      setLoadingUpdate(false);
    }, 5000);
  };

  useEffect(() => {
    getCms(adminToken).then((res) => {
      const { description, title } = res[0]?.privacyPolicy;
      setAddEditData({ description, title });
      setLoading(false);
    });
  }, []);

  // CKEditor custom image upload function
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            dataService
              .post("admin/cms-image", body, {
                headers: {
                  auth: adminToken,
                },
              })
              .then((res) => {
                resolve({
                  default: `${PagesIndex.ImageURL}/${res?.data?.data?.fileName[0]}`,
                });
              })
              .catch((err) => {
                console.log(err, "error msg");
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <>
      {!loading && (
        <Index.Box className="episap-common-box text-center">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main cms-heading">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  App Privacy and Policy
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <PagesIndex.Formik
            // enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.privacyPolicySchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form
                spacing={2}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Index.Box className="episap-cms-box">
                  <Index.Box className="grid-row cms-page-row">
                    <Index.Box className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        {/* <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 5",
                            md: "span 4",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Title
                            </Index.Typography>
                            <Index.Box className="form-group cms-input-box">
                              <Index.TextField
                                fullWidth
                                id="title"
                                size="small"
                                className="form-control"
                                placeholder="Enter title"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                              />
                              <span className="error-text-ck">
                                {errors.title && errors.title}
                              </span>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box> */}

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box" mt={4}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Description
                            </Index.Typography>
                            <Index.Box className="form-group epibox-editor">
                              <PagesIndex.CKEditor
                                config={{
                                  toolbar: {
                                    items: [
                                      "heading",
                                      "|",
                                      "bold",
                                      "italic",
                                      "|",
                                      "bulletedList",
                                      "numberedList",
                                      "|",
                                      "link",
                                      "|",
                                      "undo",
                                      "redo",
                                    ],
                                  },
                                  extraPlugins: [uploadPlugin],
                                  mediaEmbed: { previewsInData: true },
                                }}
                                contenteditable="true"
                                editor={PagesIndex.ClassicEditor}
                                name="description"
                                className="ck-editor-img"
                                data={values?.description}
                                onChange={(e, editor) => {
                                  const data = editor.getData();
                                  setFieldValue("description", data);
                                }}
                              />
                            </Index.Box>
                          </Index.Box>
                          <span className="error-text-ck">
                            {errors.description && errors.description}
                          </span>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box mt={5} className="editor-btn">
                    <Index.Button
                      className="editor-submit-btn login-disable"
                      type="submit"
                      data-testid="button"
                      variant="contained"
                      disabled={loadingUpdate}
                    >
                      <img
                        alt="save"
                        src={PagesIndex.Png.invoice}
                        className=""
                      />
                      Update
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      )}
    </>
  );
};

export default PrivayPolicy;
