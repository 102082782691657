import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "../src/redux/store"
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const persistStore = Store();
root.render(
  // <React.StrictMode>
    <Provider store={persistStore.store}>
      <PersistGate persistor={persistStore.persistor}>
        <App />
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);
// if (window.Cypress) {
//   //  @ts-ignore
//   window.store = store;
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
