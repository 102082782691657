import React from 'react'

export default function UserRequest() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_337_1046)">
                <path d="M17.2656 6.17188H32.8906V8.51562H17.2656V6.17188Z" fill="white" />
                <path d="M19.6094 10.8594H30.5469V13.2031H19.6094V10.8594Z" fill="white" />
                <path d="M10.1562 0V12.1875C6.2793 12.1875 3.125 15.3418 3.125 19.2188C3.125 21.2659 4.00482 23.1116 5.40588 24.3976C2.25586 25.5438 0 28.5681 0 32.1094V40H20.3125V32.1094C20.3125 28.5681 18.0566 25.5438 14.9066 24.3976C16.272 23.1442 17.142 21.3599 17.1854 19.375H25.0781V26.2546L32.9404 19.375H40V0L10.1562 0ZM10.1562 14.5312C12.7411 14.5312 14.8438 16.6339 14.8438 19.2188C14.8438 21.8036 12.7411 23.9062 10.1562 23.9062C7.57141 23.9062 5.46875 21.8036 5.46875 19.2188C5.46875 16.6339 7.57141 14.5312 10.1562 14.5312ZM12.1094 26.25C15.3403 26.25 17.9688 28.8785 17.9688 32.1094V37.6562H2.34375V32.1094C2.34375 28.8785 4.97223 26.25 8.20312 26.25H12.1094ZM37.6562 17.0312H32.0596L27.4219 21.0892V17.0312H16.8387C16.16 14.9631 14.5465 13.3154 12.5 12.5897V2.34375H37.6562V17.0312Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_337_1046">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
