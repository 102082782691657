import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  inappropriateExpertRequestListAction,
  inappropriateApproveRejectUpdateStatus,
} from "../../../../redux/admin/action";
import moment from "moment";

const ExpertRequest = () => {
  // state declaration
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [expertList, setExpertList] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  //   function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  // Filter fetched data
  useEffect(() => {
    const filtered =
      expertList &&
      expertList?.filter((item) => {
        // debugger;
        return !search
          ? item
          : item?.userId?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              item?.title?.toLowerCase().includes(search.toLowerCase()) ||
              item?.ideaType?.toLowerCase().includes(search.toLowerCase()) ||
              item?.adminAction?.toLowerCase().includes(search.toLowerCase()) ||
              new Date(item?.createdAt)
                ?.toLocaleDateString("en-GB")
                ?.toString()
                .includes(search?.toLowerCase()) ||
              new Date(item?.joinedDate)
                ?.toLocaleDateString("en-GB")
                ?.toString()
                .includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [expertList, search]);

  const getExpertRequestData = () => {
    inappropriateExpertRequestListAction(adminToken).then((res) => {
      setExpertList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getExpertRequestData();
  }, []);

  // Active Inactive popup
  const handleStatusModalOpen = (data) => {
    setStatusModalOpen(!statusModalOpen);
    setStatusData(data);
  };
  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
    setStatusData({});
  };

  // Active deactive function declaration
  const expertStatusUpdate = () => {
    setLoading(true);
    handleStatusModalClose();
    inappropriateApproveRejectUpdateStatus(statusData, adminToken).then(
      (res) => {
        if (res?.status == 200) {
          getExpertRequestData();
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    );
  };

  return (
    <Index.Box className="episap-common-box pl-0 pr-0">
      <Index.Box className="title-header">
        <Index.Box className="title-header-flex res-title-header-flex">
          <Index.Box className="title-main">
            <Index.Typography variant="p" component="p" className="page-title">
              Expert Requests
            </Index.Typography>
          </Index.Box>
          <Index.Box className="d-flex align-items-center res-set-search">
            <PagesIndex.Search className="searchbar">
              <PagesIndex.StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearch}
                type="text"
              />
            </PagesIndex.Search>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="table-box table_scroll">
        <Index.TableContainer
          component={Index.Paper}
          // className="table-container gridtable"
          className="episap-user-details-table"
        >
          <Index.Table
            stickyHeader
            aria-label="sticky table"
            className="episap-table-main contact-table user-details"
          >
            <Index.TableHead>
              <Index.TableRow>
                <Index.TableCell align="left">No </Index.TableCell>
                <Index.TableCell align="left">NFT Image</Index.TableCell>
                <Index.TableCell align="left">NFT Title</Index.TableCell>
                <Index.TableCell align="left">Idea Type</Index.TableCell>
                <Index.TableCell align="left">Full Name</Index.TableCell>
                <Index.TableCell align="left">
                  Master Warden Action
                </Index.TableCell>
                <Index.TableCell align="left">Admin Action</Index.TableCell>
                <Index.TableCell align="left">
                  Created Date & Time
                </Index.TableCell>
                <Index.TableCell align="center">Action</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            {/* <Index.Box> */}
            <Index.TableBody className="table-body">
              {!loading ? (
                pageData?.length ? (
                  pageData.map((row) => {
                    return (
                      <Index.TableRow key={row?.sNo} className="">
                        <Index.TableCell>{row?.sNo}</Index.TableCell>
                        <Index.TableCell align="left">
                          <img
                            src={`${PagesIndex.ImageURL}${row?.displayImage}`}
                            alt="Image Preview"
                            loading="lazy"
                            style={{
                              height: "60px",
                              width: "60px",
                              display: "table-column",
                              borderRadius: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate(`/admin/nft-details/${row?._id}`)
                            }
                          />
                        </Index.TableCell>

                        <Index.TableCell
                          align="left"
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/admin/nft-details/${row?._id}`)
                          }
                        >
                          {row.title.length > 18
                            ? row.title.substring(0, 18) + "..."
                            : row.title}
                        </Index.TableCell>

                        <Index.TableCell align="left">
                          {row?.ideaType == "Main Idea" && (
                            <Index.Chip
                              color="success"
                              size="small"
                              label={row?.ideaType}
                            />
                          )}
                          {row?.ideaType == "Evolve Idea" && (
                            <Index.Chip
                              color="warning"
                              size="small"
                              label={row?.ideaType}
                            />
                          )}
                          {row?.ideaType == "PFP Idea" && (
                            <Index.Chip
                              color="primary"
                              size="small"
                              label={row?.ideaType}
                            />
                          )}
                          {(row?.ideaType == "Merge Idea" ||
                            row?.ideaType == "Unite Idea") && (
                            <Index.Chip
                              color="secondary"
                              size="small"
                              label={"Merge Idea"}
                            />
                          )}
                        </Index.TableCell>

                        <Index.TableCell align="left">
                          {row?.userId?.fullName}
                        </Index.TableCell>

                        <Index.TableCell
                          className="table-td"
                          align="left"
                          style={{
                            color:
                              row?.masterAction == "Approved"
                                ? "green"
                                : row?.masterAction == "Rejected" ||
                                  row?.masterAction == "Inappropriate"
                                ? "red"
                                : "blue",
                          }}
                        >
                          {!row?.masterAction ? "Pending" : row?.masterAction}
                        </Index.TableCell>

                        <Index.TableCell
                          className="table-td"
                          align="left"
                          style={{
                            color:
                              row?.adminAction == "Approved"
                                ? "green"
                                : row?.adminAction == "Rejected" ||
                                  row?.adminAction == "Inappropriate"
                                ? "red"
                                : "blue",
                          }}
                        >
                          {!row?.adminAction ? "Pending" : row?.adminAction}
                        </Index.TableCell>

                        <Index.TableCell align="left">
                          {moment(row?.createdAt).format("DD/MM/YYYY hh:mm A")}
                        </Index.TableCell>

                        <Index.TableCell className="table-td" align="left">
                          <Index.Box sx={{ display: "flex" }}>
                            <Index.Tooltip title="View">
                              <Index.Button
                                onClick={() =>
                                  navigate(`/admin/nft-details/${row?._id}`)
                                }
                              >
                                <Index.Visibility className="icon-btns" />
                              </Index.Button>
                            </Index.Tooltip>

                            {row?.adminAction == "Rejected" ? (
                              <Index.Tooltip title="Downvoted">
                                <Index.Button>
                                  <Index.ThumbDownAltIcon className="icon-btns" />
                                </Index.Button>
                              </Index.Tooltip>
                            ) : row?.adminAction == "Approved" ? (
                              <Index.Tooltip title="Upvoted">
                                <Index.Button>
                                  <Index.ThumbUpAltIcon className="icon-btns" />
                                </Index.Button>
                              </Index.Tooltip>
                            ) : (
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip title="Upvote">
                                  <Index.Button
                                    onClick={() => {
                                      handleStatusModalOpen({
                                        adminAction: "Approved",
                                        id: row?._id,
                                      });
                                    }}
                                  >
                                    <Index.ThumbUpOffAltIcon className="icon-btns" />
                                  </Index.Button>
                                </Index.Tooltip>
                                <Index.Tooltip title="Downvote">
                                  <Index.Button
                                    onClick={() => {
                                      handleStatusModalOpen({
                                        adminAction: "Rejected",
                                        id: row?._id,
                                      });
                                    }}
                                  >
                                    <Index.ThumbDownOffAltIcon className="icon-btns" />
                                  </Index.Button>
                                </Index.Tooltip>
                              </Index.Box>
                            )}
                          </Index.Box>
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })
                ) : (
                  <PagesIndex.NoRecordFound colSpan={12} />
                )
              ) : (
                <PagesIndex.PageLoading colSpan={12} />
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
      <Index.Box className="pagination-design flex-end">
        <Index.Stack spacing={2}>
          <PagesIndex.Pagination
            fetchData={filterData}
            setPageData={setPageData}
            pageData={pageData}
          />
        </Index.Stack>
      </Index.Box>
      {/* table end */}

      {/* Active/Inactive Modal start */}
      <PagesIndex.ConfirmModal
        statusModalOpen={statusModalOpen}
        handleUpdateRecord={expertStatusUpdate}
        handleStatusModalClose={handleStatusModalClose}
        message={`Are you sure? Do you really want to ${
          statusData?.adminAction === "Approved" ? "Upvote" : "Downvote"
        } this Expert Idea?`}
      />
    </Index.Box>
  );
};

export default ExpertRequest;
