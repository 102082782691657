import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./routes/Routers";
import "../src/assets/css/global.css";
import "../src/assets/css/admin.css";
import "../src/assets/css/admin.responsive.css";
import "../src/assets/css/newadmin.css";
import "../src/assets/css/globanew.css";
import "../src/assets/css/global.responsive.css";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routers />
    </div>
  );
}

export default App;
