import React, { useEffect, useState } from 'react';
import { ethers } from "ethers";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import marketplaceAbi from "../../Connectivity/abi/marketplace.json";
import { buyIdea, buyPriceAddress } from '../../../redux/admin/action';
import PagesIndex from '../../PagesIndex';

export default function Backtoapp() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location = "episapient://";
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="wallet-box">
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.newLogo} alt="" />
            <div className="logo-title">Episap</div>
          </div>

          <div>
            Your transaction is completed. Please go back to your app.
          </div>
        </div>
      </div>

    </>

  )
}
