import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/jpg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";
import {
  validationSchemaLogin,
  categorySchema,
  validationSchemaForgotpassword,
  validationSchemaResetPassword,
  validationSchemaChangepassword,
  validationSchemaOtpVerify,
  validationSchemaProfile,
  validationSchemaCountactUs,
  addEditCategory,
  UserVerifyOtpSend,
  addUserValidationSchema,
  addUserOtpVerifySchema,
  addTagsManagement,
  addEditSubCategory,
  editUserValidationSchema,
  contactUsSchema,
  PfpSchema,
  AddWardenSchema,
  contactStatusSchema,
  addEditReasonSchema,
  aboutUsSchema,
  wardenStepsSchema,
  wardenRolesSchema,
  nftTermAndConditionSchema,
  nftPrivacyPolicySchema,
  privacyPolicySchema,
  termsAndConditionSchema,
  addWardenValidationSchema,
  editWardenValidationSchema,
  wardenWarningSchema,
  walletAddressSchema,
  inappropriateCommentSchema,
} from "../validation/Validation";
import DeleteModal from "../components/common/DeleteModal";
import SingleImageModal from "../components/common/SingleImageModal";
import ConfirmModal from "../components/common/ConfirmModal";
import PageLoading from "../components/common/PageLoading";
import NoRecordFound from "../components/common/NoRecordFound";
import Pagination from "../components/common/Pagination";
import FollowersFollowingList from "./pages/admin/followerFollowingList/FollowersFollowingList";
import CategoryManagementModal from "./pages/admin/CategoryManagementModal/CategoryManagementModal";
import SubCategoryModel from "./pages/admin/subCategoryManagementModel/SubCategoryModel";
import { CommonModal } from "../components/common/CommonModal";
import {
  ActionItem,
  Search,
  StyledInputBase,
  label,
} from "../components/common/Table.Style";
import { ImageURL } from "../config/DataService";
import PfpAddEdit from "./pages/admin/pfpAddEdit/PfpAddEdit";
import ViewSingleLeafnodeModel from "./pages/admin/ideaTreeView/ViewSingleLeafnodeModel";
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';

const PagesIndex = {
  ImageURL,
  Formik,
  Svg,
  Png,
  Jpg,
  CKEditor,
  ClassicEditor,
  Outlet,
  Link,
  useDispatch,
  useNavigate,
  useLocation,
  useParams,
  useSelector,
  useState,
  axios,
  validationSchemaLogin,
  categorySchema,
  validationSchemaForgotpassword,
  validationSchemaResetPassword,
  validationSchemaChangepassword,
  validationSchemaCountactUs,
  validationSchemaOtpVerify,
  validationSchemaProfile,
  addEditCategory,
  UserVerifyOtpSend,
  addUserValidationSchema,
  addUserOtpVerifySchema,
  addTagsManagement,
  addEditSubCategory,
  editUserValidationSchema,
  PfpSchema,
  contactUsSchema,
  contactStatusSchema,
  AddWardenSchema,
  addEditReasonSchema,
  aboutUsSchema,
  wardenStepsSchema,
  wardenRolesSchema,
  nftTermAndConditionSchema,
  nftPrivacyPolicySchema,
  privacyPolicySchema,
  termsAndConditionSchema,
  addWardenValidationSchema,
  editWardenValidationSchema,
  wardenWarningSchema,
  inappropriateCommentSchema,
  PageLoading,
  walletAddressSchema,
  NoRecordFound,
  Pagination,
  FollowersFollowingList,
  CategoryManagementModal,
  SubCategoryModel,
  DeleteModal,
  SingleImageModal,
  ConfirmModal,
  ViewSingleLeafnodeModel,
  CommonModal,
  PfpAddEdit,
  // table style
  ActionItem,
  Search,
  StyledInputBase,
  label,
  DoNotDisturbOnTotalSilenceIcon,
};

export default PagesIndex;
