import React, { useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  addReasonAction,
  editReasonAction,
} from "../../../../redux/admin/action";

const ReportAddEdit = (props) => {
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { editData, setModalOpen, setEditData, handleClose } = props;

  // Initital values
  const initialValues = {
    reason: editData ? editData?.reason : "",
  };
  // functions declaration
  const handleFormSubmit = (values) => {
    if (editData) {
      editReasonAction({ ...values, id: editData._id }, adminToken).then(
        (res) => {
          if (res?.status == 200) {
            setModalOpen(false);
            setEditData("");
          }
        }
      );
    } else {
      addReasonAction(values, adminToken).then((res) => {
        if (res?.status === 201) {
          setModalOpen(false);
          setEditData("");
        }
      });
    }
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {!editData?._id ? "Add Reason" : "Edit Reason"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img" onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="user-circle-img"
                  alt="icon"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.addEditReasonSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-textarea">
                    <Index.TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Reason"
                      multiline
                      name="reason"
                      onBlur={handleBlur}
                      value={values.reason}
                      onChange={handleChange}
                    />
                  </Index.Box>

                  <span className="error-text">
                    {touched.reason && errors.reason}
                  </span>
                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                    >
                      {!editData?._id ? "Submit" : "Update"}
                    </Index.Button>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ReportAddEdit;
