import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  activeDeactiveUser,
  deleteUserAction,
  getWardenList,
  updateWardenToUserStatus,
} from "../../../../redux/admin/action";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const WardenList = () => {
  // state declaration
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wardenData, setWardenData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [userId, setUserId] = useState();
  const [wardenId, setWardenId] = useState({});
  const [userEditData, setUserEditData] = useState({});
  const [authStep, setAuthStep] = useState("");

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();
  const handleSearch = (e) => {
    setSearch(e.target.value.trim());
  };

  const copyToClipboard = async (address) => {
    try {
      await navigator.clipboard.writeText(address);
      // setWalletAddress(address);
      // You can also use a toast or alert to inform the user
      Index.toast.success("Copied successfully");
    } catch (err) {
      Index.toast.error("Could not copy address");
    }
  };

  const wardenList = () => {
    getWardenList(adminToken).then((res) => {
      setWardenData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!open) {
      wardenList();
    }
  }, [open]);

  const handleSwitchChange = (event, id) => {
    setLoading(true);
    activeDeactiveUser(
      { userId: id, isActive: event.target.checked },
      adminToken
    ).then((res) => {
      if (res?.status === 200) {
        Index.toast.success(res?.message);
        wardenList();
      }
    });
  };

  // Filter fetched data
  useEffect(() => {
    const filtered = wardenData?.filter((item) => {
      const activeStatus = item?.isActive ? 'active' : "deactive"
      return !search
        ? item
        : item?.fullName?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.email?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.role?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.payAmount?.toString().includes(search?.toString()) ||
            item?.userName?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.walletAddress?.toLowerCase().includes(search?.toLowerCase()) ||
            activeStatus?.toLowerCase().includes(search?.toLowerCase()) ||
            (item?.createdAt &&
              moment(item?.createdAt)
                .format("DD/MM/YYYY hh:mm A")
                ?.toString()
                ?.toLowerCase()
                .includes(search.toLowerCase())) ||
            ` ${item.countryCode}${item?.mobileNumber}`
              ?.toString()
              .includes(search.toString());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [wardenData, search]);

  const handelClose = () => {
    setOpen(false);
  };
  const handleAddUser = () => {
    setAuthStep("Add_User");
    setOpen(true);
  };

  // Delete function declaration
  const handleDeleteUser = () => {
    deleteUserAction(userId, adminToken).then((res) => {
      if (res.status === 200) {
        wardenList();
        setDeleteModalOpen(false);
      }
    });
  };

  const handleDeleteModalOpen = (id) => {
    setDeleteModalOpen(!open);
    setUserId(id);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setUserId();
  };

  // Popup confirmation warden to user status update
  const handleUpdateUserModalOpen = (id) => {
    setUserModalOpen(!userModalOpen);
    setWardenId(id);
  };
  const handleUpdateUserModalClose = () => {
    setUserModalOpen(false);
    setWardenId({});
  };

  // Active deactive function declaration
  const statusUpdate = () => {
    setLoading(true);
    handleUpdateUserModalClose();
    updateWardenToUserStatus(wardenId, adminToken).then((res) => {
      if (res?.status === 200) {
        wardenList();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Community Warden List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                {/* <Index.Link>
                  <Index.Button
                    variant="contained"
                    className="add-user-btn"
                    value={search}
                    onClick={() => {
                      setUserEditData();
                      handleAddUser();
                    }}
                  >
                    Add user
                  </Index.Button>
                </Index.Link> */}

                <PagesIndex.CommonModal
                  handleClose={handelClose}
                  authStep={authStep}
                  setAuthStep={setAuthStep}
                  userEditData={userEditData}
                  open={open}
                  setOpen={setOpen}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container"
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                // className="table-design-main barge-table"
                className="episap-table-main user-list-table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow>
                    <Index.TableCell align="left">No</Index.TableCell>
                    <Index.TableCell align="left">Full Name </Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">
                      Mobile Number
                    </Index.TableCell>
                    <Index.TableCell align="left">Username</Index.TableCell>
                    <Index.TableCell align="left">
                      Wallet Address
                    </Index.TableCell>
                    <Index.TableCell align="left">Warnings</Index.TableCell>
                    <Index.TableCell align="left">
                      Locking Amount
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">Warden Category</Index.TableCell> */}
                    <Index.TableCell align="left">Role</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">
                              {row?.sNo}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/admin/warden-detail/${row?._id}`)
                              }
                            >
                              {row?.fullName ? row?.fullName : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/admin/warden-detail/${row?._id}`)
                              }
                            >
                              {row?.email ? row?.email : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left" style={{whiteSpace: 'nowrap'}}>
                              {row?.mobileNumber
                                ? `${row?.countryCode} ${row?.mobileNumber}`
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.userName ? row?.userName : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.Box className="wallet-address">
                                <Index.Tooltip
                                  title={row?.walletAddress}
                                  placement="top"
                                >
                                  {row?.walletAddress
                                    ? `${row?.walletAddress.substring(
                                        0,
                                        4
                                      )}.....${row?.walletAddress.substring(
                                        row?.walletAddress?.length - 4
                                      )}`
                                    : "-"}
                                </Index.Tooltip>

                                {row?.walletAddress && (
                                  <Index.Box style={{ cursor: "pointer" }}>
                                    <ContentCopyIcon
                                      fontSize="small"
                                      onClick={() =>
                                        copyToClipboard(row?.walletAddress)
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.wardenWarningCount
                                ? row?.wardenWarningCount
                                : 0}
                            </Index.TableCell>
                            
                            <Index.TableCell align="left">
                              {row?.payAmount ? row?.payAmount : "-"}
                            </Index.TableCell>

                            {/* <Index.TableCell align="left">
                              {row?.wardenCategory.length
                                ? Array.prototype.map
                                    .call(row?.wardenCategory, function (item) {
                                      return item?.categoryName;
                                    })
                                    .join(", ")
                                : "-"}
                            </Index.TableCell> */}

                            <Index.TableCell align="left">
                              {row?.role ? row?.role : "-"}
                            </Index.TableCell>

                            <Index.TableCell
                              style={{ color: row?.isActive ? "green" : "red" }}
                              align="left"
                            >
                              {row?.isActive ? "Active" : "Deactive"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                            <Index.TableCell class="action-btn-icon">
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip
                                  title={`${
                                    row?.isActive ? "Deactive" : "Active"
                                  }`}
                                >
                                  <Index.Switch
                                    size="small"
                                    className="toggel-switch"
                                    {...PagesIndex.label}
                                    checked={row?.isActive ? true : false}
                                    onChange={(event) => {
                                      handleSwitchChange(event, row?._id);
                                    }}
                                  />
                                </Index.Tooltip>

                                <Index.Tooltip title="View">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      navigate(
                                        `/admin/warden-detail/${row?._id}`
                                      )
                                    }
                                  >
                                    <Index.Visibility />
                                  </Index.Button>
                                </Index.Tooltip>
                                {/* <Index.Tooltip title="Edit">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() => {
                                      setUserEditData(row);
                                      handleAddUser();
                                    }}
                                  >
                                    <Index.EditIcon />
                                  </Index.Button>
                                </Index.Tooltip> */}
                                <Index.Tooltip title="Delete">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      handleDeleteModalOpen({
                                        userId: row?._id,
                                      })
                                    }
                                  >
                                    <Index.DeleteIcon />
                                  </Index.Button>
                                </Index.Tooltip>

                                <Index.Tooltip title="Warden to user">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      handleUpdateUserModalOpen({
                                        wardenId: row?._id,
                                      })
                                    }
                                  >
                                    <Index.Svg.AboutUsBlue />
                                  </Index.Button>
                                </Index.Tooltip>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
                {/* </Index.Box> */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.DeleteModal
        deleteOpen={deleteModalOpen}
        handleDeleteRecord={handleDeleteUser}
        handleDeleteClose={handleDeleteModalClose}
        deleteMessage={
          "Are you sure? Do you really want to delete this warden?"
        }
      />
      {/* Warden to user update popup */}
      <PagesIndex.ConfirmModal
        statusModalOpen={userModalOpen}
        handleUpdateRecord={statusUpdate}
        handleStatusModalClose={handleUpdateUserModalClose}
        message={"Are you sure? Do you really want to change the role from warden to user?"}
      />
    </>
  );
};

export default WardenList;
