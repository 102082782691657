import React, { useEffect, useState } from "react";
import "./CategoryManagementList.css";
import "./CategoryManagementList.responsive.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { deleteCategoryManagementAction, getCategoryManagementAction, } from "../../../../redux/admin/action";

const CategoryManagementList = () => {

  // state declaration
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [categoryManagement, setCategoryManagement] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [categoryId, setCategoryId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  let navigate = PagesIndex.useNavigate();

  // delete function declaration
  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setEditData("");
  };
  const handleDeleteOpen = (id) => {
    setCategoryId(id);
    setDeleteModalOpen(true);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    deleteCategoryManagementAction(categoryId, adminToken).then(
      (res) => {
        if (res.status == 200) {
          getCategoryManagement();
          handleDeleteClose();
        }
      }
    );
  };
  // add edit modal function declaration
  const openCategoryManagementModal = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };
  const handleEdit = (row) => {
    setEditData(row);
    setModalOpen(true);
    setAnchorEl(null);
  };
  const closeModal = () => {
    setModalOpen(false);
    setEditData("");
  };

  // list function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getCategoryManagement = () => {
    getCategoryManagementAction(adminToken).then((res) => {
      setCategoryManagement(res);
      setLoading(false);
      // setPageData(res)
    });
  };
  useEffect(() => {
    getCategoryManagement();
  }, [modalOpen]);

  // Filter fetched data
  useEffect(() => {
    const filtered =
      categoryManagement &&
      categoryManagement?.filter((item) => {
        return !search
          ? item
          : item?.categoryName?.toLowerCase().includes(search?.toLowerCase());
      });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [categoryManagement, search]);

  const navigateSubCategory = (categoryName, id) => {
    navigate(`/admin/sub-category/${categoryName}/${id}`);
  };
  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Category Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                <Index.Box className="add-user-btn">
                  <PagesIndex.Link className="no-text-decoration">
                    {" "}
                    <Index.Button
                      variant="contained"
                      onClick={openCategoryManagementModal}
                    >
                      Add Category
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container  gridtable"
              className="episap-table-container"
            >
              <Index.Table
                // aria-label="simple table"
                stickyHeader
                aria-label="sticky table"
                // className="table-design-main barge-table category-table-main"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No</Index.TableCell>
                    <Index.TableCell align="left">Image</Index.TableCell>
                    <Index.TableCell align="left">Title</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                    <Index.TableCell align="left">
                      View Sub Category
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row.sNo}>
                            <Index.TableCell align="left">{row.sNo}</Index.TableCell>
                            <Index.TableCell align="left">
                              <img
                                src={
                                  row?.categoryImage
                                    ? `${PagesIndex.ImageURL}${row?.categoryImage}`
                                    : PagesIndex.Png.profileimg
                                }
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius:"6px",
                                }}
                              // className="category-image"
                              />
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.categoryName}
                            </Index.TableCell>                            
                            <Index.TableCell align="center" className="action-btn-icon">
                              <Index.Tooltip title="Edit">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleEdit(row)}
                                >
                                  <Index.EditIcon />
                                </Index.Button>
                              </Index.Tooltip>
                              <Index.Tooltip title="Delete">
                                <Index.Button
                                  className="icon-btns"
                                  onClick={() => handleDeleteOpen({id: row?._id})}
                                >
                                  <Index.DeleteIcon />
                                </Index.Button>
                              </Index.Tooltip>

                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.Button>
                                <Index.Visibility
                                  onClick={() => {
                                    navigateSubCategory(row?.categoryName, row?._id);
                                  }}
                                  className="icon-btns"
                                />
                              </Index.Button>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={modalOpen}
        // onClose={closeModal}
        className="category-modal"
      >
        <PagesIndex.CategoryManagementModal
          handleClose={closeModal}
          editData={editData}
          setModalOpen={setModalOpen}
          setEditData={setEditData}
        />
      </Index.Modal>
      <PagesIndex.DeleteModal
        handleDeleteRecord={handleDelete}
        handleDeleteClose={handleDeleteClose}
        deleteOpen={deleteModalOpen}
        deleteMessage={"Are you sure? Do you really want to delete this category?"}
      />
    </>
  );
};

export default CategoryManagementList;
