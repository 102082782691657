import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { transactionHistory } from "../../../../redux/admin/action";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const WardenHistory = () => {
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const [loading, setLoading] = useState(true);
  const [transactionList, setTransactionList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getWardenHistory = () => {
    transactionHistory(adminToken).then((res) => {
      const result = res?.filter(
        (item) => item?.transactionType === "Become Warden"
      );
      setTransactionList(result);
      setLoading(false);
    });
  };

  useEffect(() => {
    getWardenHistory();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = transactionList?.filter((item) => {
      return !search
        ? item
        : item?.mainNftId?.title
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.userId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.price?.toString().includes(search.toString()) ||
            item?.transactionType
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.walletAddress
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.transactionHash
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [transactionList, search]);

  const copyToClipboard = async (address) => {
    try {
      await navigator.clipboard.writeText(address);
      // You can also use a toast or alert to inform the user
      Index.toast.success("Copied successfully");
    } catch (err) {
      Index.toast.error("Could not copy address");
    }
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Become Warden History
              </Index.Typography>
            </Index.Box>
            <Index.Box className="d-flex align-items-center res-set-search">
              <PagesIndex.Search className="searchbar">
                <PagesIndex.StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                />
              </PagesIndex.Search>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="table-box table_scroll">
          <Index.TableContainer
            component={Index.Paper}
            className="episap-table-container"
          >
            <Index.Table
              stickyHeader
              aria-label="sticky table"
              className="episap-table-main"
            >
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell>No </Index.TableCell>
                  {/* <Index.TableCell>NFT Image</Index.TableCell> */}
                  <Index.TableCell>Username</Index.TableCell>
                  <Index.TableCell>Amount</Index.TableCell>
                  <Index.TableCell>Transaction Type</Index.TableCell>
                  <Index.TableCell>Title</Index.TableCell>
                  <Index.TableCell>From Address</Index.TableCell>
                  <Index.TableCell>To Address</Index.TableCell>
                  <Index.TableCell>Transaction Hash</Index.TableCell>
                  <Index.TableCell>Created Date & Time</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {!loading ? (
                  pageData?.length ? (
                    pageData.map((row) => {
                      return (
                        <Index.TableRow key={row?.sNo}>
                          <Index.TableCell>{row?.sNo}</Index.TableCell>

                          {/* <Index.TableCell>
                            {row?.mainNftId?.displayImage ? (
                              <img
                                src={`${PagesIndex.ImageURL}${row?.mainNftId?.displayImage}`}
                                alt="Image Preview"
                                loading="lazy"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              />
                            ) : (
                              <img
                                src={`https://ui-avatars.com/api/?background=random&color=fff&name=${row?.transactionType}`}
                                alt="Image Preview"
                                loading="lazy"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              />
                            )}
                          </Index.TableCell> */}

                          <Index.TableCell>
                            {row?.refModel === "Admin"
                              ? row?.userId?.fullName
                              : row?.refModel === "User"
                              ? row?.userId?.userName
                              : " -"}
                          </Index.TableCell>
                          <Index.TableCell>{row?.price} SAP</Index.TableCell>
                          <Index.TableCell>
                            {row?.transactionType}
                          </Index.TableCell>
                          <Index.TableCell className="table-title-word-break">
                            {row?.mainNftId?.title
                              ? row?.mainNftId?.title
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box className="wallet-address">
                              <Index.Tooltip
                                title={row?.fromAddress}
                                placement="top"
                              >
                                {row?.fromAddress
                                  ? `${row?.fromAddress.substring(
                                      0,
                                      4
                                    )}.....${row?.fromAddress.substring(
                                      row?.fromAddress?.length - 4
                                    )}`
                                  : "-"}
                              </Index.Tooltip>

                              {row?.fromAddress && (
                                <Index.Box style={{ cursor: "pointer" }}>
                                  <ContentCopyIcon
                                    fontSize="small"
                                    onClick={() =>
                                      copyToClipboard(row?.fromAddress)
                                    }
                                  />
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box className="wallet-address">
                              <Index.Tooltip
                                title={row?.toAddress}
                                placement="top"
                              >
                                {row?.toAddress
                                  ? `${row?.toAddress.substring(
                                      0,
                                      4
                                    )}.....${row?.toAddress.substring(
                                      row?.toAddress?.length - 4
                                    )}`
                                  : "-"}
                              </Index.Tooltip>

                              {row?.toAddress && (
                                <Index.Box style={{ cursor: "pointer" }}>
                                  <ContentCopyIcon
                                    fontSize="small"
                                    onClick={() =>
                                      copyToClipboard(row?.toAddress)
                                    }
                                  />
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box className="wallet-address">
                              <Index.Tooltip
                                title={row?.transactionHash}
                                placement="top"
                              >
                                {row?.transactionHash
                                  ? `${row?.transactionHash?.substring(
                                      0,
                                      4
                                    )}.....${row?.transactionHash?.substring(
                                      row?.transactionHash?.length - 4
                                    )}`
                                  : "-"}
                              </Index.Tooltip>

                              {row?.transactionHash && (
                                <Index.Box style={{ cursor: "pointer" }}>
                                  <ContentCopyIcon
                                    fontSize="small"
                                    onClick={() =>
                                      copyToClipboard(row?.transactionHash)
                                    }
                                  />
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell>
                            {moment(row?.createdAt).format(
                              "DD/MM/YYYY  hh:mm A"
                            )}
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <PagesIndex.NoRecordFound colSpan={12} />
                  )
                ) : (
                  <PagesIndex.PageLoading colSpan={12} />
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="pagination-design flex-end">
          <Index.Stack spacing={2}>
            <PagesIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
            />
          </Index.Stack>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default WardenHistory;
