import React, { useState, useEffect } from "react";
import Index from "../Index";


const Pagination = ({ fetchData, setPageData, pageData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);


    useEffect(() => {
        //Pagination
        const recordsPerPage = 10;
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        const records = fetchData.slice(firstIndex, lastIndex);
        const numbersOfPage = Math.ceil(fetchData.length / recordsPerPage);
        // const numbers = [...Array(numbersOfPage + 1).keys()].slice(1);
        setPageCount(numbersOfPage);
        const serialNumber = (currentPage - 1) * recordsPerPage + 1;
        setPageData(
          records?.map((ele, index) => {
            return { ...ele, sNo: serialNumber + index };
          })
        )
        if (records.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }, [fetchData, currentPage, setPageData]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            {pageData.length !== 0 ? (
                <Index.Box className="pagination-main">
                    <Index.Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className="pagination"
                    />
                </Index.Box>
            ) : null}
        </>
    );
};

export default Pagination;
