import React from 'react'

export default function NotificationIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2657 12.3217V10.0781C19.2657 6.80522 17.0902 4.0312 14.1094 3.12516V2.10938C14.1094 0.946266 13.1632 0 12.0001 0C10.8369 0 9.89068 0.946266 9.89068 2.10938V3.12516C6.9099 4.0312 4.73443 6.80517 4.73443 10.0781V12.3217C4.73443 15.1966 3.63859 17.9227 1.64879 19.9977C1.45379 20.2011 1.39899 20.5011 1.50952 20.7602C1.62005 21.0194 1.87459 21.1875 2.1563 21.1875H8.55516C8.88179 22.7903 10.3023 24 12.0001 24C13.6979 24 15.1183 22.7903 15.4449 21.1875H21.8438C22.1255 21.1875 22.38 21.0194 22.4905 20.7602C22.6011 20.5011 22.5463 20.2011 22.3513 19.9977C20.3615 17.9227 19.2657 15.1965 19.2657 12.3217ZM11.2969 2.10938C11.2969 1.72167 11.6124 1.40625 12.0001 1.40625C12.3878 1.40625 12.7032 1.72167 12.7032 2.10938V2.84663C12.4718 2.82431 12.2372 2.8125 12.0001 2.8125C11.7629 2.8125 11.5284 2.82431 11.2969 2.84663V2.10938ZM12.0001 22.5938C11.0834 22.5938 10.3019 22.0059 10.0116 21.1875H13.9885C13.6982 22.0059 12.9167 22.5938 12.0001 22.5938ZM3.67177 19.7812C5.27307 17.6348 6.14068 15.0371 6.14068 12.3217V10.0781C6.14068 6.84727 8.7692 4.21875 12.0001 4.21875C15.2309 4.21875 17.8594 6.84727 17.8594 10.0781V12.3217C17.8594 15.0371 18.727 17.6348 20.3284 19.7812H3.67177Z" fill="black" />
            <path d="M21.1407 10.0782C21.1407 10.4665 21.4555 10.7813 21.8439 10.7813C22.2322 10.7813 22.547 10.4665 22.547 10.0782C22.547 7.26098 21.4499 4.6124 19.4579 2.62036C19.1833 2.34581 18.7381 2.34576 18.4635 2.62036C18.1889 2.89495 18.1889 3.34012 18.4635 3.61472C20.19 5.34117 21.1407 7.63659 21.1407 10.0782Z" fill="black" />
            <path d="M2.15625 10.7813C2.54456 10.7813 2.85938 10.4665 2.85938 10.0781C2.85938 7.63661 3.81019 5.34119 5.53659 3.61473C5.81119 3.34014 5.81119 2.89497 5.53659 2.62037C5.26205 2.34578 4.81683 2.34578 4.54223 2.62037C2.55019 4.61242 1.45312 7.26095 1.45312 10.0781C1.45312 10.4665 1.76794 10.7813 2.15625 10.7813Z" fill="black" />
        </svg>
    )
}
