import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useSelector } from "react-redux";
import { changepasswordAction } from "../../../../redux/admin/action";

function ChangePassword() {
  //  state declaration
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //  state declaration
  const { adminDetails, adminToken } = useSelector(
    (state) => state.AdminReducer
  );

  // Initital values declaration
  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  // function declaration
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleFormSubmit = async (values) => {
    // console.log(values, "values");
    changepasswordAction(values, adminToken)
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaChangepassword}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Index.Stack
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Index.Grid container spacing={3}>
                  <Index.Grid item xs={12} md={12}>
                    <Index.Box className="user-detail-cont">
                      <Index.Grid container spacing={2}>
                        <Index.Grid item xs={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex res-title-header-flex">
                              <Index.Box className="title-main cms-heading">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Change Password
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} md={4}>
                          <Index.Box className="user-detail-img">
                            <img
                              src={PagesIndex.Png.resetIcon}
                              alt="Image Preview"
                              className="profile-img"
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} md={8}>
                          <Index.Grid container>
                            <Index.Grid item xs={12} md={6}>
                              <Index.Box className="change-pwd-cont mb-15">
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="change-input-label"
                                >
                                  Old Password
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  placeholder="Old Password"
                                  className="admin-input-design input-placeholder password-set"
                                  name="oldPassword"
                                  type={showOldPassword ? "text" : "password"}
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props  ",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props ",
                                  }}
                                  onBlur={handleBlur}
                                  value={values.oldPassword}
                                  onChange={handleChange}
                                  helperText={
                                    touched.oldPassword && errors.oldPassword
                                  }
                                  error={Boolean(
                                    errors.oldPassword && touched.oldPassword
                                  )}
                                  // sx={{ mb: 3 }}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="old-password-toggle"
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowOldPassword}
                                          edge="end"
                                        >
                                          {showOldPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>

                              <Index.Box className="change-pwd-cont mb-15">
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="change-input-label label-set "
                                >
                                  New Password
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  placeholder="New Password"
                                  className="admin-input-design input-placeholder password-set"
                                  name="newPassword"
                                  type={showNewPassword ? "text" : "password"}
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props",
                                  }}
                                  onBlur={handleBlur}
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  helperText={
                                    touched.newPassword && errors.newPassword
                                  }
                                  error={Boolean(
                                    errors.newPassword && touched.newPassword
                                  )}
                                  // sx={{ mb: 3 }}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="new-password-toggle"
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowNewPassword}
                                          edge="end"
                                        >
                                          {showNewPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>

                              <Index.Box className="change-pwd-cont mb-15">
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="change-input-label label-set "
                                >
                                  Confirm Password
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  variant="filled"
                                  placeholder="Confirm Password"
                                  className="admin-input-design input-placeholder password-set-box"
                                  name="confirmPassword"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props",
                                  }}
                                  onBlur={handleBlur}
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  helperText={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                  error={Boolean(
                                    errors.confirmPassword &&
                                      touched.confirmPassword
                                  )}
                                  sx={{ mb: 3 }}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="confirm-password-toggle"
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                          edge="end"
                                        >
                                          {showConfirmPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                />
                              </Index.Box>

                              <Index.Box className="res-set-search text-center">
                                <Index.Button
                                  variant="contained"
                                  type="submit"
                                  disableRipple
                                >
                                  Submit
                                </Index.Button>
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Grid>
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>
              </Index.Stack>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default ChangePassword;
