import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  deleteMasterWardenAction,
  getMasterWardenAction,
} from "../../../../redux/admin/action";
import moment from "moment";
import AddEditMasterWarden from "./AddEditMasterWarden";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 2,
  borderRadius: 1,
};

const MasterWardenList = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [masterWarden, setMasterWarden] = useState([]);
  const [updateWarden, setUpdateWarden] = useState({});
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [wardenId, setWardenId] = useState();
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const handelClose = () => {
    setOpen(false);
  };

  const handleAddMasterWarden = () => {
    setOpen(true);
  };

  const getWardenList = () => {
    getMasterWardenAction(adminToken).then((res) => {
      setMasterWarden(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    !modalOpen && getWardenList();
  }, [modalOpen]);

  useEffect(() => {
    const filtered = masterWarden?.filter((item) => {
      return !search
        ? item
        : item?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
            item?.email?.toLowerCase().includes(search.toLowerCase()) ||
            item?.walletAddress?.toLowerCase().includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase()) ||
            ` ${item.countryCode}${item?.mobileNumber}`
              ?.toString()
              .toLowerCase()
              .includes(search.toString());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [masterWarden, search]);

  const handleDeleteUser = () => {
    deleteMasterWardenAction(wardenId, adminToken).then((res) => {
      if (res.status == 200) {
        getWardenList();
        setDeleteModalOpen(false);
      }
    });
  };

  const handleDeleteModalOpen = (id) => {
    setDeleteModalOpen(!deleteModalOpen);
    setWardenId(id);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setWardenId();
  };

  const copyToClipboard = async (address) => {
    try {
      await navigator.clipboard.writeText(address);
      // setWalletAddress(address);
      // You can also use a toast or alert to inform the user
      Index.toast.success("Copied successfully");
    } catch (err) {
      Index.toast.error("Could not copy address");
    }
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Master Wardens List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
                <Index.Link>
                  <Index.Button
                    variant="contained"
                    className="add-user-btn"
                    onClick={() => {
                      setUpdateWarden("");
                      handleAddMasterWarden();
                    }}
                  >
                    Add Master Warden
                  </Index.Button>
                </Index.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main user-list-table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Full Name </Index.TableCell>
                    <Index.TableCell align="left">Email </Index.TableCell>
                    <Index.TableCell align="left">
                      Mobile Number{" "}
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Wallet Address
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">
                              {row?.sNo}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.email}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {`${row?.countryCode}${row?.mobileNumber}`}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="wallet-address">
                                <Index.Tooltip title={`${row?.walletAddress}`}>
                                  {row?.walletAddress
                                    ? `${row?.walletAddress?.substring(
                                        0,
                                        4
                                      )}.....${row?.walletAddress?.substring(
                                        row?.walletAddress?.length - 4
                                      )}`
                                    : "-"}
                                </Index.Tooltip>
                                {row?.walletAddress && (
                                  <Index.Box style={{ cursor: "pointer" }}>
                                    <ContentCopyIcon
                                      fontSize="small"
                                      onClick={() =>
                                        copyToClipboard(row?.walletAddress)
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                            <Index.TableCell className="action-btn-icon">
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip title="Edit">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() => {
                                      setUpdateWarden(row);
                                      handleAddMasterWarden();
                                    }}
                                  >
                                    <Index.EditIcon />
                                  </Index.Button>
                                </Index.Tooltip>
                                <Index.Tooltip title="Delete">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      handleDeleteModalOpen({ id: row._id })
                                    }
                                  >
                                    <Index.DeleteIcon />
                                  </Index.Button>
                                </Index.Tooltip>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.DeleteModal
        deleteOpen={deleteModalOpen}
        handleDeleteRecord={handleDeleteUser}
        handleDeleteClose={handleDeleteModalClose}
        deleteMessage={
          "Are you sure? Do you really want to delete this master warden?"
        }
      />

      {/* Add Edit Master Warden Popup */}
      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="auth-modal login-with-modal"
      >
        <Index.Box
          sx={style}
          className="auth-modal-inner-main login-with-modal-inner-main"
        >
          <AddEditMasterWarden
            handelClose={handelClose}
            getWardenList={getWardenList}
            updateWarden={updateWarden}
          />
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default MasterWardenList;
