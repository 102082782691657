import React, { useEffect, useState, memo, useMemo } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  getUserDetailListAction,
  getWardenActionsList,
} from "../../../../redux/admin/action";
import moment from "moment";
import { PropTypes } from "prop-types";
import WardenAction from "./tabs/WardenAction";
import NFTList from "./tabs/NFTList";
import SocialPost from "./tabs/SocialPost";
import CommunityGroup from "./tabs/CommunityGroup";
import Referrals from "./tabs/Referrals";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function WardenDetail() {
  // followers modal state declaration
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [openFollowers, setOpenFollowers] = useState(false);
  const [userDetailData, setUserDetailData] = useState([]);
  const [follows, setFollows] = useState([]);
  const [following, setFollowing] = useState([]);
  const [nftListData, setNftListData] = useState([]);
  const [joinedGroup, setJoinedGroup] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [actionsData, setActionsData] = useState([]);
  const [referralData, setReferralData] = useState([]);

  // Back Button
  const handleBack = () => {
    navigate(-1);
  };

  // following modal state declaration
  const [openCommunity, setOpenCommunity] = useState(false);
  const [openFollowing, setOpenFollowing] = useState(false);

  const [openNftDescription, setOpenNftDescription] = useState(false);
  const [nftDescription, setNftDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  // followers modal function declaration
  const handleOpenFollowers = () => setOpenFollowers(true);
  const handleCloseFollowers = () => setOpenFollowers(false);

  // following modal function declaration
  const handleOpenFollowing = () => setOpenFollowing(true);
  const handleCloseFollowing = () => setOpenFollowing(false);

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { id } = PagesIndex.useParams();

  const handleOpenCommunity = (data) => {
    setOpenCommunity(true);
    setCommunityData(data);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseCommunity = () => setOpenCommunity(false);

  const getUserDetailList = () => {
    getUserDetailListAction(id, adminToken, navigate).then((res) => {
      if (res?.status == 200) {
        const userData = res?.data;
        setUserDetailData(userData?.user);
        setFollows(userData?.followerList[0]?.followers);
        setFollowing(userData?.followingList[0]?.following);
        // setFollowingListData(userData.followingList.followers);
        setNftListData(userData?.nftDetails?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }));
        setSocialData(userData?.socialMediaList?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }));
        setJoinedGroup(userData?.userJoinedGroup?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }));
        setReferralData(userData?.refferalUsers?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }));
        // setLoading(false);
      }
    });
  };

  const getActionList = () => {
    getWardenActionsList(id, adminToken, navigate).then((res) => {
      if (res?.status == 200) {
        setActionsData(res?.data);
      }
    });
  };

  useEffect(() => {
    getUserDetailList();
    getActionList();
  }, []);

  //filter function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  useMemo(() => {
    const filtered = (
      value === 0
        ? actionsData
        : value === 1
        ? nftListData
        : value === 2
        ? socialData
        : value === 3
        ? joinedGroup
        : value === 4
        ? referralData
        : null
    )?.filter((item) => {
      return !search
        ? item
        : item?.actionType?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.ideaId?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.groupName?.toLowerCase().includes(search.toLowerCase()) ||
            item?.owner?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
            item?.uploadBy?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            (item?.createdAt &&
              moment(item?.createdAt)
                .format("DD/MM/YYYY hh:mm A")
                ?.toString()
                ?.toLowerCase()
                .includes(search.toLowerCase()));
    });
    if(value){
      setLoading(true);
    }
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [nftListData, socialData, joinedGroup, actionsData, referralData, search, value])

  const handleClickOpenDescription = (title, description) => {
    setOpenNftDescription(true);
    setDescriptionTitle(title);
    setNftDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenNftDescription(false);
    setDescriptionTitle("");
    setNftDescription("");
  };

  console.log(pageData, "page-data", actionsData);

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Warden Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="form-back-btn">
                  <Index.Button
                    variant="contained"
                    className="back-btn"
                    type="back"
                    onClick={() => handleBack()}
                  >
                    Back
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Grid container spacing={3}>
            <Index.Grid item xs={12} md={3}>
              <Index.Box className="user-detail-cont">
                <Index.Grid container spacing={2}>
                  <Index.Grid item xs={12} md={12}>
                    <Index.Box className="user-detail-img">
                      <img
                        src={
                          userDetailData?.profile == ""
                            ? PagesIndex.Jpg.dummyImage
                            : `${PagesIndex.ImageURL}${userDetailData?.profile}`
                        }
                        alt=""
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} md={12}>
                    <Index.Grid container>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          First Name
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData.fullName}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Email ID
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData.email}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Mobile No
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData.countryCode}{" "}
                          <span>{userDetailData.mobileNumber}</span>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          User Id
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData.userName}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          NFT Action Count
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {actionsData?.length}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="follow-card">
                        <Index.Box
                          className="follow-card-cont"
                          onClick={handleOpenFollowers}
                        >
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Svg.followers} loading="lazy" alt="" />
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-title"
                            >
                              Followers
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-subtitle"
                            >
                              {follows ? follows?.length : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          className="follow-card-cont"
                          onClick={handleOpenFollowing}
                        >
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Svg.following} loading="lazy" alt="" />
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-title"
                            >
                              Following
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-subtitle"
                            >
                              {following ? following?.length : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={12} md={9}>
              <Index.Box className="table-wrape pl-0 pr-0">
                <Index.Box className="tab-back-btn">
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="admin-tabs-main"
                  >
                    <Index.Tab
                      label="Action"
                      {...a11yProps(0)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="NFT List"
                      {...a11yProps(1)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Social Post List"
                      {...a11yProps(2)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Community Groups"
                      {...a11yProps(3)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Referral"
                      {...a11yProps(4)}
                      className="admin-tab"
                    />
                  </Index.Tabs>
                </Index.Box>

                <Index.Box className="episap-common-box pl-0 pr-0">
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex">
                      <Index.Box className="title-main">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title page-sub-title"
                        >
                          {value === 0
                            ? "Warden Action List"
                            : value === 1
                            ? "NFT List"
                            : value === 2
                            ? "Social Post List"
                            : value === 3
                            ? "Community"
                            : value === 4
                            ? "Referral Users"
                            : ""}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="d-flex align-items-center res-set-search">
                        <PagesIndex.Search className="searchbar">
                          <PagesIndex.StyledInputBase
                            placeholder="Search"
                            inputProps={{ "aria-label": "search" }}
                            onChange={handleSearch}
                            type="text"
                          />
                        </PagesIndex.Search>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="table-box table_scroll">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="episap-user-details-table"
                    >
                      {value === 0 ? ( // Warden actions
                        <WardenAction pageData={pageData} loading={loading} setLoading={setLoading} />
                      ) : value === 1 ? ( // NFT List
                        <NFTList pageData={pageData} loading={loading} setLoading={setLoading} handleClickOpenDescription={handleClickOpenDescription} />
                      ) : value === 2 ? ( // Social Post
                        <SocialPost pageData={pageData} loading={loading} setLoading={setLoading} handleClickOpenDescription={handleClickOpenDescription} />
                      ) : value === 3 ? ( // Community Group
                        <CommunityGroup pageData={pageData} loading={loading} setLoading={setLoading} handleClickOpenDescription={handleClickOpenDescription} handleOpenCommunity={handleOpenCommunity} />
                      ) : value === 4 ? ( // Referrals
                        <Referrals pageData={pageData} loading={loading} setLoading={setLoading} />
                      ) : (
                        ""
                      )}
                    </Index.TableContainer>
                  </Index.Box>

                  <Index.Box className="pagination-design flex-end">
                    <Index.Stack spacing={2}>
                      <PagesIndex.Pagination
                        fetchData={filterData}
                        setPageData={setPageData}
                        pageData={pageData}
                      />
                    </Index.Stack>
                  </Index.Box>
                  {/* table end */}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>

          <Index.Grid container spacing={3}>
            <Index.Grid item xs={12} md={12}></Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
      <>
        {/* followers modal start */}
        <Index.Modal
          open={openFollowers}
          onClose={handleCloseFollowers}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Followers
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseFollowers}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-design-div admin-design-div login-input-design-div">
              <Index.Box className="follow-list-section">
                {follows?.length ? (
                  follows?.map((data) => {
                    return (
                      <Index.Box
                        className="list modal-total-views"
                        key={data._id}
                      >
                        <Index.Box className="image-main-wrapper">
                          <Index.Box className="image-wrape">
                            <img
                              className="user-profile-view"
                              src={
                                data.profile
                                  ? `${PagesIndex.ImageURL}${data.profile}`
                                  : PagesIndex.Jpg.userprofile
                              }
                              alt="loading..."
                            />
                          </Index.Box>
                          <Index.Box className="user-detail-title">
                            <Index.Typography className="user-detail-title">
                              {data?.fullName}
                            </Index.Typography>
                            <Index.Typography className="user-detail-subtitle">
                              {data?.userName}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    );
                  })
                ) : (
                  <Index.Typography className="user-detail-subtitle">
                    No Record Found
                  </Index.Typography>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* followers modal end */}

        {/* following modal start */}
        <Index.Modal
          open={openFollowing}
          onClose={handleCloseFollowing}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Following
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseFollowing}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-design-div admin-design-div login-input-design-div">
              <Index.Box className="follow-list-section">
                {following?.length ? (
                  following.map((data) => {
                    return (
                      <Index.Box className="list modal-total-views">
                        <Index.Box className="image-main-wrapper">
                          <Index.Box className="image-wrape">
                            <img
                              className="user-profile-view"
                              src={
                                data.profile
                                  ? `${PagesIndex.ImageURL}${data.profile}`
                                  : PagesIndex.Jpg.userprofile
                              }
                            />
                          </Index.Box>
                          <Index.Box className="user-detail-title">
                            <Index.Typography className="user-detail-title">
                              {data?.fullName}
                            </Index.Typography>
                            <Index.Typography className="user-detail-subtitle">
                              {data?.userName}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    );
                  })
                ) : (
                  <Index.Typography className="user-detail-subtitle">
                    No Record Found
                  </Index.Typography>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* following modal end */}

        {/* Community modal start */}
        <Index.Modal
          open={openCommunity && openCommunity}
          onClose={handleCloseCommunity}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box className="episap-modal-box">
            <Index.Box
              sx={PagesIndex.style}
              className="user-detail-popup community-popup"
            >
              <Index.Box className="episap-modal-header">
                <Index.Box className="edit-modal-circle-main">
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                      <Index.Box className="title-main">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title"
                        >
                          {communityData?.groupName}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-circle-img">
                    <img
                      src={PagesIndex.Svg.whiteclose}
                      className="modal-close-icon user-circle-img"
                      onClick={handleCloseCommunity}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="modal-body">
                <Index.Box className="community-detail">
                  <Index.Box className="group-image-wrape">
                    <Index.Box className="image-wrape">
                      <img
                        src={
                          communityData?.groupIcon == ""
                            ? PagesIndex.Jpg.dummyImage
                            : `${PagesIndex.ImageURL}${communityData?.groupIcon}`
                        }
                        alt=""
                        className="img-wraper-img"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="group-detail-wrape">
                    <Index.Typography component="h6" variant="h6">
                      Group More Detail {" "}
                      <Index.Box component="span" variant="span">
                        ({communityData?.members?.length} Members)
                      </Index.Box>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="follow-list-section">
                  {communityData?.members?.length > 0 ? (
                    communityData?.members?.map((data) => {
                      return (
                        <Index.Box className="list community-users modal-total-views">
                          <Index.Box className="image-main-wrapper">
                            <Index.Box className="image-wrape">
                              <img
                                className="user-profile-view"
                                src={
                                  data?.userId?.profile == ""
                                    ? PagesIndex.Jpg.userprofile
                                    : `${PagesIndex.ImageURL}${data?.userId?.profile}`
                                }
                              />
                            </Index.Box>
                            <Index.Box className="user-detail-title">
                              <Index.Typography className="user-detail-title">
                                {data?.userId?.fullName}
                              </Index.Typography>
                              <Index.Typography className="user-detail-subtitle">
                                {data?.userId?.userName}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      );
                    })
                  ) : (
                    <Index.Typography className="user-detail-subtitle">
                      No Record Found
                    </Index.Typography>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* community modal end */}

        {/* Nft description show modal start */}
        <Index.Modal
          open={openNftDescription}
          onClose={handleCloseDescription}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {descriptionTitle} Description
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseDescription}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-design-div admin-design-div login-input-design-div">
              <Index.Box className="follow-list-section">
                <Index.FormHelperText>
                  {nftDescription ? nftDescription : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* Nft description modal end */}
      </>
    </>
  );
}

export default memo(WardenDetail);
