import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useTheme } from "@mui/material/styles";
import {
  categorySubmitAction,
  getCategoryManagementAction,
//   getSubCategoryManagementAction,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
// import { MenuProps, getStyles } from "./UserModal.Style";

export default function Catergoryselect({ handleClose, userToken, verifyId }) {
  // state declaration
  const [category, setCategory] = useState([]);
  // const [subCategory, setSubCategory] = useState([])
  // initial value declaration
  const initialValues = {
    categoryId: [],
    userId: verifyId ? verifyId : "",
    // subCategoryId: '',
  };

  // function declaration
  // const handleChangeCategory = (id) => {
  //     getSubCategoryManagementAction(id).then((res) => {
  //         setSubCategory(res)
  //     })
  // };

  useEffect(() => {
    getCategoryManagementAction().then((res) => {
      setCategory(res);
    });
  }, []);

  const handleFormSubmit = (values) => {
    const data = {
        categoryId: values.categoryId,
        userId: verifyId
    }
    categorySubmitAction(data);
    handleClose();
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-modal-box">
        <Index.Box className="episap-modal-header">
          <Index.Box className="edit-modal-circle-main">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Select Category
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-circle-img" onClick={handleClose}>
              <img
                src={PagesIndex.Svg.whiteclose}
                className="user-circle-img"
                alt="icon"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="login-input-design-div">
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.categorySchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Index.Stack
                component="form"
                spacing={2}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Index.Box className="register-all-inputs">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-sub-title mb-5"
                  >
                    Please select the category.
                  </Index.Typography>
                  <Index.Box
                    component="form"
                    autoComplete="off"
                    className="reg-input reg-drop"
                  >
                    <Index.Typography
                      className="re-input-label"
                      variant="label"
                    >
                      Category
                    </Index.Typography>
                    <Index.FormControl className="select-cat">
                      <Index.Select
                        // displayEmpty
                        multiple
                        name="categoryId"
                        value={values.categoryId}
                        onChange={handleChange}
                        renderValue={(selected) => {
                            let catData = category
                            .filter((item) =>
                                selected.includes(item._id)
                            )
                            .map((item) => item?.categoryName);
                            return catData.join(", ");
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem disabled value="">
                          Select Category
                        </Index.MenuItem>
                        {category?.map((name) => (
                            <Index.MenuItem
                                key={name._id}
                                value={name._id}
                                >
                            <Index.Checkbox className="check-btn-icon"
                                checked={
                                    values.categoryId.indexOf(
                                        name._id
                                    ) > -1
                                }
                            />
                            <Index.ListItemText
                                primary={name.categoryName}
                            />
                            </Index.MenuItem>
                        ))}
                      </Index.Select>
                    </Index.FormControl>

                    <span className="error-text">
                      {touched.categoryId && errors.categoryId}
                    </span>
                  </Index.Box>

                  {
                    console.log(errors, 'errorserrors')
                  }

                  {/* <Index.Box component="form" autoComplete="off" className="reg-input reg-drop">
                    <Index.Typography className="re-input-label" variant="label">Sub Category</Index.Typography>
                    <Index.TextField className='mt-5 mb-5' multiple displayEmpty name="subCategoryId" value={values.subCategoryId || []}
                        onChange={(event) => {
                            const selectedValue = event.target.value;
                            setFieldValue("subCategoryId", selectedValue);
                        }}
                        input={
                            <Index.OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <Index.MenuItem disabled value="">
                            Select Sub Category
                        </Index.MenuItem>
                        {subCategory.length > 0 &&
                            subCategory.map((name) => (
                                <Index.MenuItem key={name._id} value={name._id} style={getStyles(name, values.subCategoryId, theme)}>
                                    {name.subCategoryName}
                                </Index.MenuItem>
                            ))}
                    </Index.TextField>
                    <span className="error-text">{touched.categoryId && errors.categoryId}</span>
                </Index.Box> */}

                  <Index.Box
                    className="modal-footer-btn mt-15"
                    style={{ width: "100%" }}
                  >
                    <Index.Button
                      variant="contained"
                      className="submit-btn"
                      style={{ textAlign: "center" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Index.Button>
                    {/* <Index.Button
                      variant="contained"
                      className="submit-btn"
                      style={{ float: "right" }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Index.Button> */}
                    {/* <Index.Typography className="acc-already-txt" variant="p" component='p'>
                        <Index.Link onClick={handleClose}>Skip</Index.Link>
                    </Index.Typography> */}
                  </Index.Box>
                </Index.Box>
              </Index.Stack>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
