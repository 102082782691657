import React, {useState, useEffect} from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import * as XLSX from 'xlsx';
import { getReportAction } from "../../../../redux/admin/action";

const UserReport = ({ filter, search, weeklyData, downloadExcel, setDownloadExcel }) => {
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  // function declaration
  const getUserList = () => {
    getReportAction(filter, adminToken).then((res) => {
        setUserList(res?.data);
        setLoading(false);
        // setPageData(res)
    });
  };

  useEffect(() => {
      getUserList();
  }, [weeklyData]);

  // Filter fetched data
  useEffect(() => {
    const filtered = userList?.filter((item) => {
      return !search
        ? item
        : item?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
            item?.role?.toLowerCase().includes(search.toLowerCase()) ||
            item?.email?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userName?.toLowerCase().includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase()) ||
            ` ${item.countryCode}${item?.mobileNumber}`
              ?.toString()
              .includes(search.toString());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [userList, search]);


  useEffect(() => {
    if(downloadExcel){
      handleDownloadExcel();
    }
  }, [downloadExcel])

  // Download table function
  const handleDownloadExcel = () => {
      const data = filterData.map((item) => ({
          'ID': item._id,
          'Full Name': item?.fullName,
          'Username': item?.userName,
          'Email': item?.email,
          'Mobile Number': `${item.countryCode} ${item.mobileNumber}`,
          'Role': item?.role
      }));
      // Create a worksheet from the array of objects
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook and add the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate a blob from the workbook
      const blob = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      // Convert the string to a Blob
      const blobData = new Blob([s2ab(blob)], { type: 'application/octet-stream' });

      // Create a download link and trigger the download
      const url = URL.createObjectURL(blobData);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Users.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setDownloadExcel(false); // Download state false after download the file
  };

  // Utility function to convert string to ArrayBuffer
  const s2ab = s => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
  };

  return (
    <>
      <Index.Box className='main-table-struct'>
        <Index.Box className="table-box table_scroll">
          <Index.TableContainer
            component={Index.Paper}
            // className="table-container gridtable"
            className="episap-table-container"
          >
            <Index.Table

              stickyHeader
              aria-label="sticky table"
              // className="table-design-main barge-table"
              className="episap-table-main contact-table user-details"
            >
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell align="left">No </Index.TableCell>
                  <Index.TableCell align="left">Full Name </Index.TableCell>
                  <Index.TableCell align="left">Email </Index.TableCell>
                  <Index.TableCell align="left">Mobile Number </Index.TableCell>
                  <Index.TableCell align="left">Username</Index.TableCell>
                  <Index.TableCell align="left"> User Role</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {/* <Index.Box> */}
              <Index.TableBody className="table-body">
                {!loading ? (
                  pageData.length ? (
                    pageData.map((row) => {
                      return (
                        <Index.TableRow key={row.sNo}>
                          <Index.TableCell>{row.sNo}</Index.TableCell>
                          <Index.TableCell>{row.fullName}</Index.TableCell>
                          <Index.TableCell align="left">
                            {row.email}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {`${row.countryCode} ${row.mobileNumber}`}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row.userName}
                          </Index.TableCell>
                          <Index.TableCell align="left">{row.role}</Index.TableCell>

                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <PagesIndex.NoRecordFound colSpan={12} />
                  )
                ) : (
                  <PagesIndex.PageLoading colSpan={12} />
                )}
              </Index.TableBody>
              {/* </Index.Box> */}
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="pagination-design flex-end">
          <Index.Stack spacing={2}>
            <PagesIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
            />
          </Index.Stack>
        </Index.Box>

      </Index.Box>
    </>
  );
};

export default UserReport;
