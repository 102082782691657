import * as yup from "yup";
import { parsePhoneNumberFromString as parsePhoneNumber } from "libphonenumber-js";

// const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const phoneRegExp = /^(?!(\d)\1+$)\d{10}$/;
const tagsRegex = /^#[^#\s].*$/;
// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
// const phoneWithCode =/^\+91 \d{5}-\d{5}$/;
const emailRegex = /^(?![0-9]+@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// Generic regex pattern for wallet address validation
const walletAddressRegex = /^[a-zA-Z0-9]{20,60}$/;

// Not Start with Space Validation
yup.addMethod(yup.string, "noLeadingSpace", function (field) {
  return this.test({
    name: "no-leading-space",
    message: `${field} cannot start with a space`,
    test: function (value) {
      if (value) {
        return !value.startsWith(" ");
      }
      return true;
    },
  });
});

//Login Validation schema
export const validationSchemaLogin = yup.object().shape({
  email: yup
    .string("Please enter Email.")
    .required("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    ),
  password: yup.string().required("Please enter Password."),
  // .min(8, "Password must be at least 8 characters")
  // .max(16, "Password must be at most 16 characters"),
});

//forgot password
export const validationSchemaForgotpassword = yup.object().shape({
  email: yup
    .string("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
});

// otp verify
export const validationSchemaOtpVerify = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter OTP.")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number."),
});

// reset password
export const validationSchemaResetPassword = yup.object().shape({
  password: yup
    .string()
    .required("Password is required.")
    .max(12, "Password cannot exceed 12 characters.")
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character ex. Admin@123"
    ),

  // password: yup
  //   .string()
  //   .required("Please enter New Password.")
  //   .min(6, "Password must be at least 6 characters.")
  //   .max(20, "Password cannot exceed 20 characters.")
  //   .matches(/^(?=.*[0-9])/, "Password must contain at least one number.")
  //   .matches(
  //     /^(?=.*[A-Z])/,
  //     "Password must contain at least one capital letter."
  //   )
  //   .matches(
  //     /^(?=.*[@$!%*#?&])/,
  //     "Password must contain at least special character."
  //   )
  //   .matches(/^(?=.*[a-z])/, "Password must contain at least small letter."),
  confirm_password: yup
    .string()
    .required("Please enter Confirm Password.")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password should same as the password."
    ),
});

// change password
export const validationSchemaChangepassword = yup.object().shape({
  oldPassword: yup.string().required("Please enter Old Password."),
  newPassword: yup
    .string()
    .required("Please enter New Password.")
    .max(18, "Password cannot exceed 18 characters.")
    .notOneOf(
      [yup.ref("oldPassword"), null],
      "Old Password and New Password should be different."
    )
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. Admin@123"
    ),
  // .min(6, "Password must be at least 6 characters.")
  // .max(18, "Password cannot exceed 18 characters.")
  // .matches(/^(?=.*[0-9])/, "Password must contain at least one number.")
  // .matches(
  //   /^(?=.*[A-Z])/,
  //   "Password must contain at least one capital letter."
  // )
  // .matches(
  //   /^(?=.*[@$!%*#?&])/,
  //   "Password must contain at least special character."
  // )
  // .matches(/^(?=.*[a-z])/, "Password must contain at least small letter."),
  confirmPassword: yup
    .string()
    .required("Please enter Confirm Password.")
    .oneOf(
      [yup.ref("newPassword"), null],
      "Confirm Password should same as the password."
    ),
});

export const validationSchemaProfile = yup.object().shape({
  fullName: yup
    .string("Please enter Full Name.")
    .required("Please enter Full Name.")
    .max(30, "Full Name cannot exceed 30 characters.")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: "Please enter valid Full Name.",
      excludeEmptyString: true,
    }),
  email: yup
    .string("Please enter Email")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  mobileNumber: yup
    .string("Please enter Mobile Number.")
    .required("Please enter Mobile Number.")
    .matches(phoneRegExp, "Please enter valid Mobile Number."),
  profile: yup
    .mixed()
    .required("Image is required.")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"];
            let fileFormat = value?.split(".");
            return format?.includes(fileFormat[fileFormat?.length - 1]);
          }
        } else {
          return true;
        }
      }
    )
    .test("size", "Image size is too large.", (value) => {
      if (value?.size) {
        return value?.size <= 2 * 1024 * 1024;
      }
      return true;
    }),
});
export const addEditCategory = yup.object().shape({
  categoryName: yup
    .string("Please enter Title.")
    .required("Please enter Title.")
    .max(20, "Title cannot exceed 20 characters.")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: "Please enter valid Title.",
      excludeEmptyString: true,
    }),
  categoryImage: yup
    .mixed()
    .required("Image is required.")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"];
            let fileFormat = value?.split(".");
            return format?.includes(fileFormat[fileFormat?.length - 1]);
          }
        } else {
          return true;
        }
      }
    )
    .test(
      "FILE_SIZE",
      "File size too large, max file size is 2.048 Mb",
      (value) => {
        if (value?.size !== undefined) {
          return !value || (value && value?.size <= 2048000);
        } else {
          return true;
        }
      }
    ),
  // categoryImage: yup
  //   .mixed()
  //   .required('Image is required')
  //   .test('size', 'Image size is too large', (value) => {
  //     if (value?.size) {
  //       return value?.size <= 2 * 1024 * 1024;
  //     }
  //     return true
  //   })
  //   //Invalid file format. Please upload jpg, png files only.
  //   .test('format', 'Field accept only jpg, jpeg, png file type.', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
});
//for tags managment
export const addTagsManagement = yup.object().shape({
  tagName: yup
    .string("Please enter Tag name.")
    .required("Please enter Tag name.")
    .max(100, "Tag name cannot exceed 100 characters.")
    .matches(tagsRegex, "First letter must be #"),
});
//sub-cat-
export const addEditSubCategory = yup.object().shape({
  subCategoryName: yup
    .string("Please enter Title.")
    .required("Please enter Title.")
    .max(100, "Title cannot exceed 100 characters."),
  subCategoryImage: yup
    .mixed()
    .required("Image is required.")
    .test("size", "Image size is too large", (value) => {
      if (value?.size) {
        return value?.size <= 2 * 1024 * 1024;
      }
      return true;
    }),
});

export const validationSchemaCountactUs = yup.object().shape({
  address: yup
    .string("Please enter Address.")
    .required("Please enter Address."),
  email: yup
    .string()
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  phone: yup
    .string("Please enter Phone Number.")
    .required("Please enter Phone Number.")
    .matches(phoneRegExp, "Please enter valid Phone Number."),
});

// Add user
export const addUserValidationSchema = yup.object().shape({
  fullName: yup
    .string("Please enter Full Name.")
    .required("Please enter Full Name.")
    .min(3, "Full Name must be at least 3 characters.")
    .max(50, "Full Name cannot be more then 50 characters.")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: "Please enter valid Full Name.",
      excludeEmptyString: true,
    }),
  // .matches(/^[a-zA-Z][a-zA-Z\s']*$/,{message:'Please enter a valid Full Name. Only alphabets and spaces are allowed.',excludeEmptyString:true}),
  // userName: yup.string("Please enter Username.")
  //   .required("Please enter Username.")
  //   .matches(/^[A-Za-z0-9\_]+$/,{message:'Please enter a valid Username. Only alphabets and numbers are allowed.', excludeEmptyString:true}),
  userName: yup
    .string()
    .matches(
      /^[a-zA-Z0-9\_]+$/,
      "Username can only contain alphanumeric characters."
    )
    .min(3, "Username must be at least 3 characters.")
    .max(50, "Username cannot be more then 50 characters.")
    .test("no-numbers", "Username cannot contain only numbers", (value) => {
      // Test if the username contains only numbers
      return !/^\d+$/.test(value);
    })
    .trim() // Remove leading and trailing whitespace
    .required("Please enter Username."),
  email: yup
    .string("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  mobileNumber: yup
    .string("Please enter Mobile Number.")
    .min(3, "Please enter Mobile Number."),
  // .matches(phoneRegExp, 'Please enter valid Mobile Number.'),
  password: yup
    .string()
    .required("Please enter New Password.")
    // .min(6, 'Password must be at least 6 characters.')
    .max(12, "Password cannot exceed 12 characters.")
    // .matches(/^(?=.*[0-9])/,
    //   'Password must contain at least one number.'
    // ).matches(/^(?=.*[A-Z])/,
    //   'Password must contain at least one capital letter.'
    // ).matches(/^(?=.*[@$!%*#?&])/,
    //   'Password must contain at least special character.'
    // ).matches(/^(?=.*[a-z])/,
    //   'Password must contain at least small letter.'
    // )
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. Admin@123"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter Confirm Password.")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password should same as the password."
    ),
});

export const validateMobileNo = (mobileNo, dialCode) => {
  const parsedPhoneNumber = parsePhoneNumber(mobileNo, dialCode);
  console.log(parsedPhoneNumber, "validation mobile");
  if (parsedPhoneNumber === undefined || parsedPhoneNumber === "") {
    return "Please enter Mobile Number.";
  }
  if (
    !parsedPhoneNumber ||
    !parsedPhoneNumber.isValid() ||
    parsedPhoneNumber.isNonGeographic()
  ) {
    return "Please enter a valid Mobile Number.";
  }
  if (/(\d)\1{6,}/.test(parsedPhoneNumber.number)) {
    return "Mobie Number cannot have continuous single numbers";
  }
  return "";
};

// edit user
export const editUserValidationSchema = yup.object().shape({
  fullName: yup
    .string("Please enter Full Name.")
    .required("Please enter Full Name.")
    .min(3, "Full Name must be at least 3 characters.")
    .max(50, "Full Name cannot be more then 50 characters.")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: "Please enter valid Full Name.",
      excludeEmptyString: true,
    }),
  userName: yup
    .string()
    .matches(
      /^[a-zA-Z0-9\_]+$/,
      "Username can only contain alphanumeric characters."
    )
    .min(3, "Username must be at least 3 characters.")
    .max(50, "Username cannot be more then 50 characters.")
    .test("no-numbers", "Username cannot contain only numbers", (value) => {
      // Test if the username contains only numbers
      return !/^\d+$/.test(value);
    })
    .trim() // Remove leading and trailing whitespace
    .required("Please enter Username."),
  email: yup
    .string("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  mobileNumber: yup
    .string("Please enter Mobile Number.")
    .required("Please enter Mobile Number."),
  // .matches(phoneRegExp, 'Please enter valid Mobile Number.'),
});

// User Otp send
export const UserVerifyOtpSend = yup.object().shape({
  email: yup
    .string("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  mobileNumber: yup
    .string("Please enter Mobile Number.")
    .required("Please enter Mobile Number.")
    .matches(phoneRegExp, "Please enter valid Mobile Number."),
});
//add user otp verify
export const addUserOtpVerifySchema = yup.object().shape({
  // otp: yup.string()
  //   .required("Please enter OTP.")
  //   .matches(/^\d{4}$/, "OTP must be a 4-digit number."),
  emailOtp: yup
    .string()
    .required("Please enter OTP.")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number."),
});

export const categorySchema = yup.object().shape({
  categoryId: yup.array().min(1, "Select at least one category"),
  // categoryId: yup.string()
  //   .required("Please select Category."),
  // subCategoryId: yup.array()
  //   .required("Please select Sub Category.")
});

export const PfpSchema = yup.object().shape({
  timeSlot: yup
    .string()
    .required("Please enter Days.")
    .max(3, "Day cannot be more then 3 digits.")
    .matches(/^[1-9]+[0-9]*$/, "Only number allowed."),
  token: yup
    .string()
    .required("Please enter Token.")
    .matches(/^[1-9]+[0-9]*$/, "Only number allowed."),
});

export const contactUsSchema = yup.object().shape({
  address: yup
    .string()
    .required("Please enter Address.")
    .min(10, "Address must be at least 10 characters.")
    .max(800, "Address cannot be more then 800 characters."),
  email: yup
    .string()
    .required("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    ),
  phone: yup
    .string("Please enter Mobile Number.")
    .required("Please enter Mobile Number.")
    .matches(phoneRegExp, "Please enter valid Mobile Number."),
  facebookUrl: yup
    .string()
    .required("Please enter Facebook URL."),
    // .matches(
    //   /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_]+\/?$/,
    //   "Enter a valid Facebook URL."
    // ),
  instagramUrl: yup
    .string()
    .required("Please enter Instagram URL."),
    // .url("Enter a valid Instagram URL.")
    // .matches(
    //   /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9(\.\?)?]/,
    //   "Enter a valid Instagram URL."
    // ),
  linkedinUrl: yup
    .string()
    .required("Please enter LinkedIn URL."),
    // .url("Enter a valid LinkedIn URL.")
    // .matches(
    //   /^(https?:\/\/)?(www\.)?linkedin\.com\/company\/[a-zA-Z0-9-]+/,
    //   "Enter a valid LinkedIn URL."
    // ),
  twitterUrl: yup
    .string()
    .required("Please enter X URL."),
    // .url("Enter a valid X URL.")
    // .matches(
    //   /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+/,
    //   "Enter a valid X URL."
    // ),
});

export const contactStatusSchema = yup.object().shape({
  requestStatus: yup.string().required("Please select Status."),
  remarks: yup
    .string()
    .required("Please enter Remark.")
    .noLeadingSpace("Remark")
    .matches(/^(?!\s)(?=.*\S)[a-zA-Z0-9\s.'-]+(?<!\s)$/, {
      message:
        "Please enter only alphabets and numeric value without leading or trailing white spaces.",
      excludeEmptyString: true,
    })
    .min(5, "Remark must be at least 5 characters.")
    .max(250, "Remark cannot be more then 250 characters."),
});

// User Otp send
export const AddWardenSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
});

// User Otp send
export const addEditReasonSchema = yup.object().shape({
  reason: yup
    .string()
    .required("Please enter Reason.")
    .matches(/^[a-zA-Z.,][a-zA-Z\s',.]*$/, {
      message: "Please enter valid Reason.",
      excludeEmptyString: true,
    }),
});

export const aboutUsSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter Title.")
    .max(250, "Title cannot exceed 250 characters."),
  description: yup.string().required("Please enter Description."),
});

export const wardenStepsSchema = yup.object().shape({
  description: yup.string().required("Please enter Description."),
});

export const wardenRolesSchema = yup.object().shape({
  stackAmount: yup.string().required("Please enter Stack Amount."),

  description: yup.string().required("Please enter Description."),
});

export const nftTermAndConditionSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter Title.")
    .max(250, "Title cannot exceed 250 characters."),
  description: yup.string().required("Please enter Description."),
});

export const nftPrivacyPolicySchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter Title.")
    .max(250, "Title cannot exceed 250 characters."),
  description: yup.string().required("Please enter Description."),
});

export const privacyPolicySchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter Title.")
    .max(250, "Title cannot exceed 250 characters."),
  description: yup.string().required("Please enter Description."),
});

export const termsAndConditionSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter Title.")
    .max(250, "Title cannot exceed 250 characters."),
  description: yup.string().required("Please enter Description."),
});

export const addWardenValidationSchema = yup.object().shape({
  fullName: yup
    .string("Please enter your Full Name.")
    .required("Please enter Full Name.")
    .min(3, "Full Name must be at least 3 characters.")
    .max(50, "Full Name cannot be more then 50 characters.")
    // .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
    //   message: "Special characters, numbers and white space are not allowed",
    //   excludeEmptyString: true,
    // }),
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message:
        "Please enter a valid Full Name. Only alphabets and spaces are allowed.",
      excludeEmptyString: true,
    }),
  walletAddress: yup
    .string("Please enter Wallet Address.")
    .min(20, "Wallet Address must be at least 20 characters.")
    .max(60, "Wallet Address cannot exceed 60 characters.")
    .required("Please enter Wallet Address.")
    .matches(walletAddressRegex, "Invalid Wallet Address")
    .matches(/^(?!\s)(?=.*\S)[a-zA-Z0-9\s.'-]+(?<!\s)$/, {
      message:
        "Please enter only alphabets and numeric value without leading or trailing white spaces.",
      excludeEmptyString: true,
    }),
  email: yup
    .string("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  mobileNumber: yup
    .string("")
    .min(3, "Please enter Mobile Number."),
  // .matches(phoneRegExp, 'Please enter valid Mobile Number.'),
  password: yup
    .string()
    .required("Please enter New Password.")
    // .min(6, 'Password must be at least 6 characters.')
    .max(12, "Password cannot exceed 12 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. Admin@123"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter Confirm Password.")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password should same as the Password."
    ),
});

export const editWardenValidationSchema = yup.object().shape({
  fullName: yup
    .string("Please enter Full Name.")
    .min(3, "Full Name must be at least 3 characters.")
    .max(50, "Full Name cannot be more then 50 characters.")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message:
        "Please enter a valid Full Name. Only alphabets and spaces are allowed.",
      excludeEmptyString: true,
    })
    .required("Please enter your Full Name."),
  walletAddress: yup
    .string("Please enter Wallet Address.")
    .min(20, "Wallet Address must be at least 20 characters.")
    .max(60, "Wallet Address cannot exceed 60 characters.")
    .required("Please enter Wallet Address.")
    .matches(walletAddressRegex, "Invalid Wallet Address")
    .matches(/^(?!\s)(?=.*\S)[a-zA-Z0-9\s.'-]+(?<!\s)$/, {
      message:
        "Please enter only alphabets and numeric value without leading or trailing white spaces.",
      excludeEmptyString: true,
    }),
  email: yup
    .string("Please enter Email.")
    .email("Please enter valid Email.")
    .matches(emailRegex, "Please enter valid Email. Only numerics is not allow.")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Please enter valid Email."
    )
    .required("Please enter Email."),
  mobileNumber: yup
    .string("")
    .required("Please enter Mobile Number."),
});

// Warden warning validation
export const wardenWarningSchema = yup.object().shape({
  comment: yup
    .string()
    .required("Please enter Warning message.")
    .noLeadingSpace("Warning message")
    .max(250, "Warning message cannot exceed 250 characters."),
  // .matches(/^(?!\s)(?=.*\S)[a-zA-Z0-9\s.'-]+(?<!\s)$/, {
  //   message: "Please enter only alphabets and numeric value without leading or trailing white spaces and special characters.",
  //   excludeEmptyString: true,
  // })
  // .matches(/^[a-zA-Z0-9\s.'-]+$/, {
  //   message: "Please enter only alphabets and numeric allowed.",
  //   excludeEmptyString: true,
  // }),
});

// Inappropriate comment warning validation
export const inappropriateCommentSchema = yup.object().shape({
  comment: yup
    .string()
    .required("Please enter comment.")
    .noLeadingSpace("Comment")
    .max(250, "Comment cannot exceed 250 characters."),
});

// Wallet Address validation
export const walletAddressSchema = yup.object().shape({
  privateKey: yup.string().required("Please enter treasury wallet address."),
});
