import React, { useState } from "react";
import "./SubCategoryModel.css";
import "./SubCategoryModel.responsive.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  addSubCategoryManagementAction,
  getSubCategoryManagementAction,
} from "../../../../redux/admin/action";




const SubCategoryModel = (props) => {


  //state declaration
  const [image, setImage] = useState("");

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { editData, setModalOpen, setEditData, handleClose, subCategoryId } = props;
  let navigate = PagesIndex.useNavigate();
  // Initital values
  const initialValues = {
    subCategoryName: editData ? editData?.subCategoryName : "",
    subCategoryImage: editData ? editData?.subCategoryImage : "",
    parentId: subCategoryId,
  };

  // functions declaration
  const handleImageChange = (e) => {
    setImage(e?.target?.files[0]);
  };


  // const getsubCategoryManagement = () => {
  //   getSubCategoryManagementAction(subCategoryId).then((res) => {
  //     console.log("ress", res);
  //     // setSubCategoryManagement(res)

  //     // setLoading(false)
  //     // setPageData(res)
  //   });
  // };

  const handleCategoryFormSubmit = (values) => {
    if (editData) {
      const formData = new FormData();
      formData.append("subCategoryName", values.subCategoryName);
      formData.append("subCategoryImage", values?.subCategoryImage);
      formData.append("parentId", subCategoryId);
      formData.append("id", editData?._id);

      addSubCategoryManagementAction(formData, adminToken).then((res) => {

        if (res?.status === 200) {
          setEditData("");
          setModalOpen(false);
        }
      });
    } else {
      const formData = new FormData();
      formData.append("subCategoryName", values.subCategoryName);
      formData.append("subCategoryImage", values?.subCategoryImage);
      formData.append("parentId", subCategoryId);
      addSubCategoryManagementAction(formData, adminToken).then((res) => {

        if (res?.status === 201) {
          setEditData("");
          setModalOpen(false);
        }
      });
    }
  };

  const handleFocus = () => {
    // setLoading(false);
  };

  return (
    <>
      {/* <Index.Modal open={true}> */}
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {!editData?._id ? "Add Sub Category" : "Edit Sub Category"}
                    </Index.Typography>
                  </Index.Box>
                  {/* <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search" >
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </Search>
              </Index.Box> */}
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img" onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="user-circle-img"
                  alt="icon"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleCategoryFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.addEditSubCategory}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="episap-modal-cont">
                    <Index.Box>
                      <img
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : editData?.subCategoryImage
                              ? `${PagesIndex.ImageURL}${editData?.subCategoryImage}`
                              : PagesIndex.Png.addcategorynew
                        }
                        alt="Image Preview"
                        className="profile-img mb-15"
                      />
                    </Index.Box>

                    <Index.Box>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="File"
                        variant="filled"
                        type="file"
                        className="admin-input-design input-placeholder"
                        name="subCategoryImage"
                        data-testid="File-input"
                        autoComplete="off"
                        accept="image/jpeg"
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        // value={values?.subCategoryImage}
                        onChange={(e) => {
                          handleImageChange(e);
                          setFieldValue("subCategoryImage", e?.target?.files[0]);
                        }}
                        helperText={
                          touched?.subCategoryImage && errors.subCategoryImage
                        }
                        error={Boolean(
                          errors.subCategoryImage && touched.subCategoryImage
                        )}
                      />
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="Title"
                        variant="filled"
                        type="text"
                        className="admin-input-design input-placeholder"
                        name="subCategoryName"
                        data-testid="title-input"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={values.subCategoryName}
                        onChange={handleChange}
                        helperText={
                          touched.subCategoryName && errors.subCategoryName
                        }
                        error={Boolean(
                          errors.subCategoryName && touched.subCategoryName
                        )}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                    // disableRipple
                    // disabled={loading}
                    >
                      Submit
                    </Index.Button>
                  </Index.Box>

                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* </Index.Modal> */}
    </>
  );
};

export default SubCategoryModel;
