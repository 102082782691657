import React, { useState } from "react";
import PagesIndex from "../../../PagesIndex";

const ImageExtension = ({
  imageUrl = "",
  imageTitle = "",
  openSingleImageModal = "",
  setImageData = {},
}) => {
  const imageExtension = imageUrl?.split(".").pop();
  // console.log(imageExtension, "imageExtension", imageUrl);

  return (
    <>
      {imageExtension === "pdf" || imageExtension === "PDF" ? (
        <a
          className="upload-document-img"
          href={`${PagesIndex.ImageURL}${imageUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PagesIndex.Svg.PdfImage}
            alt={`PDF Document: ${imageUrl}`}
          />
        </a>
      ) : imageExtension === "jpg" ||
        imageExtension === "jpeg" ||
        imageExtension === "JPG" ||
        imageExtension === "PNG" ||
        imageExtension === "png" ? (
        <img
          src={`${PagesIndex.ImageURL}${imageUrl}`}
          className="user-prof-img nft-image"
          alt=""
          style={{ cursor: "pointer" }}
          onClick={() => {
            openSingleImageModal();
            setImageData({
              imageUrl: imageUrl,
              imageTitle: imageTitle,
            });
          }}
        />
      ) : imageExtension === "mp4" || imageExtension === "mp3" ? (
        <a
          className="upload-document-img"
          href={`${PagesIndex.ImageURL}${imageUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PagesIndex.Png.mp4Icon}
            alt={`MP4 Document: ${imageUrl}`}
          />
        </a>
      ) : (
        <a
          className="upload-document-img"
          href={`${PagesIndex.ImageURL}${imageUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={PagesIndex.Png.nft} alt={`Other Document: ${imageUrl}`} />
        </a>
      )}
    </>
  );
};

export default ImageExtension;
