import {
  Box,
  Chip,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Avatar,
  Stack,
  Pagination,
  SwipeableDrawer,
  Grid,
  InputBase,
  FormLabel,
  ListItemText,
} from "@mui/material";

import SourceIcon from '@mui/icons-material/Source';
import { Option } from '@mui/base/Option';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ReplyIcon from '@mui/icons-material/Reply';
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Input from "@mui/material/Input";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Png from "../assets/Png";
import Svg from "../assets/Svg";
import Jpg from "../assets/jpg";

export default {
  Png,
  Svg,
  Jpg,
  Tooltip,
  tooltipClasses,
  Input,
  FormLabel,
  ListItemText,
  OtpInput,
  toast,
  InputBase,
  Grid,
  Box,
  Chip,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  PreviewIcon,
  Visibility,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  SwipeableDrawer,
  MenuIcon,
  EditIcon,
  SearchIcon,
  DeleteIcon,
  MuiOtpInput,
  Avatar,
  ReplyIcon,
  KeyboardBackspaceIcon,
  Option,
  ThumbDownOffAltIcon,
  ThumbUpOffAltIcon,
  ThumbDownAltIcon,
  ThumbUpAltIcon,
  SourceIcon,
  
};
