import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./Cms.css";
import { addEditWardenSteps, addEditWardenStepss, getWardenSteps } from "../../../../redux/admin/action";

const WardenSteps = () => {
    // state declaration
    const [loading, setLoading] = useState(true);
    const [addEditData, setAddEditData] = useState({})

    // const  declaration
    const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);


    const initialValues = {
        description: addEditData ? addEditData?.description : ""
    }



    const handleDescriptionChange = (e, editor) => {
        const data = editor.getData();
        setAddEditData({ ...addEditData, description: data })
    };

    // function declaration
    const handleSubmit = (values) => {
        addEditWardenSteps(values, adminToken)
    }

    useEffect(() => {
        getWardenSteps(adminToken).then((res) => {

            setAddEditData(res[0])
            setLoading(false)
        })
    }, [])

    return (
        <>{
            !loading &&

            <Index.Box className="barge-common-box text-center">

                <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex">
                        <Index.Box className="title-main cms-heading">
                            <Index.Typography
                                variant="p"
                                component="p"
                                className="page-title"
                            >
                                Steps to become a Warden
                            </Index.Typography>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

              
                <PagesIndex.Formik
                    // enableReinitialize
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={PagesIndex.wardenStepsSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue

                    }) => (
                        <form
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}

                        >
                            <Index.Box className="episap-cms-box">
                                <Index.Box className="grid-row cms-page-row">
                                    <Index.Box className="grid-main">
                                        <Index.Box
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                                        >
                                            {/* <Index.Box
                                    gridColumn={{
                                        xs: "span 12",
                                        sm: "span 5",
                                        md: "span 4",
                                        lg: "span 12",
                                    }}
                                    className="grid-column"
                                >
                                    <Index.Box className="input-box">
                                        <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                        >
                                            Title
                                        </Index.Typography>
                                        <Index.Box className="form-group cms-input-box">
                                            <Index.TextField
                                                fullWidth
                                                id="title"
                                                size="small"
                                                className="form-control"
                                                placeholder="Enter title"
                                                name="title"
                                                onChange={handleTitleChange}
                                                value={addEditData?.title}
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box> */}
                                            <Index.Box
                                                gridColumn={{
                                                    xs: "span 12",
                                                    sm: "span 12",
                                                    md: "span 12",
                                                    lg: "span 12",
                                                }}
                                                className="grid-column"
                                            >
                                                <Index.Box className="input-box" mt={4}>
                                                    <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                    >
                                                        {/* Steps to become a Warden      */}
                                                        Description
                                                    </Index.Typography>
                                                    <Index.Box className="form-group epibox-editor">
                                                        <PagesIndex.CKEditor
                                                            editor={PagesIndex.ClassicEditor}
                                                            // data={props.values.description}
                                                            name="description"
                                                            // data={addEditData?.description}
                                                            // onChange={handleDescriptionChange}
                                                            data={values?.description}
                                                            onChange={(e, editor) => {
                                                                const data = editor.getData();
                                                                setFieldValue("description", data)
                                                            }}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                                <span className="error-text-ck">{errors.description && errors.description}</span>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box mt={5} className="editor-btn">
                                    <Index.Button className="editor-submit-btn" onClick={handleSubmit}>
                                        <img alt="save" src={PagesIndex.Png.invoice} className="" />
                                        Update
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                        </form>
                    )}
                </PagesIndex.Formik>
            </Index.Box>
        }
        </>
    );
};

export default WardenSteps;
