import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./newAdminDashboard.css";
import "./newAdminDashboard.responsive.css";
import DashboardFilterTable from "./DashboardFilterTable";
import {
  getDashboardDataAction,
  getMasterWardenDashboardDataAction,
  getAllReferralUsersAction,
} from "../../../../redux/admin/action";

const Dashboard = () => {
  const { adminToken, adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [dashboardData, setDashboardData] = useState([]);
  const [masterWardenData, setMasterWardenData] = useState({});
  const [referralUserList, setReferralUserList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const getAdminDashboardData = () => {
    // Admin dashboard api
    getDashboardDataAction(adminToken).then((res) => {
      setDashboardData(res?.data);
    });
  };

  const getMasterWardenDashboardData = () => {
    getMasterWardenDashboardDataAction(adminToken).then((res) => {
      setMasterWardenData(res?.data);
    });
  };

  const getReferralUsers = () => {
    getAllReferralUsersAction(adminToken).then((res) => {
      setReferralUserList(res?.data);
      const refTotalAmount = res?.data?.reduce(
        (acc, item) => acc + (+item?.refferalAmount || 0),
        0
      );
      setTotalAmount(refTotalAmount);
    });
  };

  useEffect(() => {
    getAdminDashboardData();
    getMasterWardenDashboardData();
    getReferralUsers();
  }, []);

  return (
    <Index.Box className="dashboard-main">
      {/* Title */}
      <Index.Box className="dashboard-common-header">
        <Index.Typography className="header-title">Dashboard</Index.Typography>
      </Index.Box>
      {/* End Title */}

      {/* Dashboard Box */}
      <Index.Box className="total-counter-box">
        {adminDetails?.adminType === 0 && ( // Admin Dashboard
          <>
            <Index.Grid container spacing={2}>
              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Users
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {dashboardData?.users ? dashboardData?.users : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <Index.Svg.UsersList />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Wardens
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {dashboardData?.wardens ? dashboardData?.wardens : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <Index.Svg.WardenListBlack />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Posts
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {dashboardData?.socialMediaPost
                        ? dashboardData?.socialMediaPost
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <img src={Index.Svg.post} />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      NFT's
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {dashboardData?.ideas ? dashboardData?.ideas : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <Index.Svg.NftListBlack />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Groups
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {dashboardData?.communityGroups
                        ? dashboardData?.communityGroups
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <img src={Index.Svg.groups} />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Referral Users
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {referralUserList ? referralUserList?.length : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <Index.Svg.UsersList />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={2.4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Referral Amount
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {totalAmount ? totalAmount : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <img src={Index.Svg.amount} />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </>
        )}

        {/* Master warden dashboard */}
        {adminDetails?.adminType === 1 && (
          <>
            <Index.Grid container spacing={2}>
              <Index.Grid item lg={4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Total Wardens
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {masterWardenData?.wardens
                        ? masterWardenData?.wardens
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <Index.Svg.UsersList />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item lg={4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      Inappropriate Content Pending Request
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {masterWardenData?.inappropriateIdeas
                        ? masterWardenData?.inappropriateIdeas
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <img src={Index.Svg.pending} />
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              {/* <Index.Grid item lg={4}>
                <Index.Box className="total-counter-item">
                  <Index.Box className="counter-item-left">
                    <Index.Typography className="counter-title">
                      PFP Content Pending Request
                    </Index.Typography>
                    <Index.Typography className="counter-number">
                      {masterWardenData?.pfpIdeas
                        ? masterWardenData?.pfpIdeas
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="counter-item-right icon-bg">
                    <img src={Index.Svg.pending} />
                  </Index.Box>
                </Index.Box>
              </Index.Grid> */}
            </Index.Grid>
          </>
        )}
      </Index.Box>
      {/* End Dashboard Box */}
      <Index.Box className="filter-cont">
        <DashboardFilterTable />
      </Index.Box>
    </Index.Box>
  );
};

export default Dashboard;
