import React from 'react'

export default function AboutUsIcon() {
    return (

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_353_1441)">
                <path d="M22.6728 28.1818C22.2639 28.2199 21.8533 28.1277 21.5001 27.9181C21.2419 27.6531 21.1182 27.2853 21.1637 26.9181C21.1733 26.6125 21.2097 26.3083 21.2728 26.0091C21.3338 25.6658 21.4126 25.326 21.5091 24.9909L22.5819 21.2999C22.6925 20.9356 22.7656 20.561 22.8001 20.1817C22.8001 19.7727 22.8546 19.4908 22.8546 19.3272C22.8774 18.5981 22.5661 17.8984 22.0091 17.4272C21.324 16.9013 20.4709 16.6428 19.6091 16.6999C18.9915 16.7092 18.3787 16.8103 17.7909 16.9999C17.1485 17.1999 16.4727 17.4393 15.7637 17.7181L15.4546 18.9181C15.6637 18.8454 15.9183 18.7636 16.2091 18.6727C16.4866 18.5905 16.7743 18.5476 17.0637 18.5454C17.4696 18.5014 17.8782 18.6011 18.2182 18.8272C18.4491 19.1028 18.5575 19.4607 18.5182 19.8181C18.5172 20.1239 18.4837 20.4286 18.4182 20.7272C18.3545 21.0454 18.2727 21.3817 18.1727 21.7363L17.0909 25.4453C17.0037 25.7901 16.934 26.139 16.8819 26.4908C16.8394 26.792 16.8181 27.0957 16.8182 27.3999C16.8137 28.134 17.1494 28.8288 17.7272 29.2817C18.4229 29.8158 19.2882 30.0806 20.1636 30.0271C20.78 30.0398 21.3943 29.9507 21.9818 29.7635C22.4969 29.5877 23.1848 29.3362 24.0455 29.0089L24.3364 27.8635C24.1033 27.9602 23.8632 28.0392 23.6182 28.0998C23.3083 28.1706 22.9902 28.1981 22.6728 28.1818Z" fill="white" />
                <path d="M23.809 10.6818C23.3142 10.2274 22.6624 9.98295 21.9908 9.99998C21.3196 9.98482 20.6684 10.229 20.1726 10.6818C19.2638 11.4654 19.1624 12.8373 19.946 13.7462C20.0159 13.8272 20.0916 13.9029 20.1726 13.9727C21.2079 14.8987 22.7737 14.8987 23.8089 13.9727C24.7177 13.1814 24.8129 11.8033 24.0216 10.8945C23.9558 10.8188 23.8847 10.7477 23.809 10.6818Z" fill="white" />
                <path d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM20 38.1818C9.95844 38.1818 1.8182 30.0416 1.8182 20C1.8182 9.95844 9.95844 1.8182 20 1.8182C30.0416 1.8182 38.1818 9.95844 38.1818 20C38.1818 30.0416 30.0416 38.1818 20 38.1818Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_353_1441">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
