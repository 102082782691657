import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getInviteWardenList, } from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import AddWardenModal from "./AddWardenModal";
// import "./NFTList.css";

function InviteWardenList() {
    // state declaration
    const [loading, setLoading] = useState(true);
    const [inviteList, setInviteList] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [search, setSearch] = useState("");
    const [filterData, setFilteredData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false)
    // const declaration
    const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);


    // function declaration

    const handleSearch = async (e) => {
        setSearch(e.target.value);
    };
    const inviteWardenList = () => {
        getInviteWardenList(adminToken).then((res) => {

            setInviteList(res);
            setLoading(false);
            // setPageData(res?.data)
        });
    };

    useEffect(() => {
        inviteWardenList();
    }, []);

    // add edit modal function declaration
    const openAddWardenModal = () => {
        setModalOpen(true);

    };
    const closeModal = () => {
        setModalOpen(false);
    };
    // Filter fetched data
    useEffect(() => {
        const filtered = inviteList?.filter((item) => {
            // debugger;
            return !search
                ? item
                : item?.email?.toLowerCase().includes(search.toLowerCase()) ||
                // item..toLowerCase().includes(search.toString())||
                new Date(item?.createdAt)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase());
        });
        if (filtered) {
            setFilteredData(filtered);
        } else {
            setFilteredData([]);
        }
    }, [inviteList, search]);
    return (
        <>
            <Index.Box className="page-content-box">
                <Index.Box className="episap-common-box">
                    <Index.Box className="title-header">
                        <Index.Box className="title-header-flex res-title-header-flex">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    Invite List
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="d-flex align-items-center res-set-search">
                                <PagesIndex.Search className="searchbar">
                                    <PagesIndex.StyledInputBase
                                        placeholder="Search"
                                        inputProps={{ "aria-label": "search" }}
                                        onChange={handleSearch}
                                    />
                                </PagesIndex.Search>
                                <Index.Box className="add-user-btn">
                                    <PagesIndex.Link

                                        className="no-text-decoration"
                                    >
                                        {" "}
                                        <Index.Button variant="contained" disableRipple onClick={openAddWardenModal}>
                                            Invite Warden
                                        </Index.Button>
                                    </PagesIndex.Link>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="table-box table_scroll">
                        <Index.TableContainer
                            component={Index.Paper}
                            // className="table-container"
                            className="episap-table-container"
                        >
                            <Index.Table
                                stickyHeader
                                aria-label="sticky table"
                                // className="table-design-main barge-table"
                                className="episap-table-main contact-table user-details"
                            >
                                <Index.TableHead>
                                    <Index.TableRow>
                                        <Index.TableCell align="left">No</Index.TableCell>
                                        <Index.TableCell align="left">Email</Index.TableCell>
                                        <Index.TableCell align="left">Invited Date</Index.TableCell>
                                        <Index.TableCell align="left">Invited Time</Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                {/* <Index.Box> */}
                                <Index.TableBody className="table-body">
                                    {!loading ? (
                                        pageData?.length ? (
                                            pageData.map((row) => {
                                                return (
                                                    <Index.TableRow key={row?.sNo} className="">
                                                        <Index.TableCell>{row?.sNo}</Index.TableCell>
                                                        <Index.TableCell align="left">
                                                            {row?.email}
                                                        </Index.TableCell>
                                                        <Index.TableCell align="left">
                                                            {moment(row?.createdAt).format("DD/MM/YYYY")}
                                                        </Index.TableCell>
                                                        <Index.TableCell align="left">
                                                            {moment(row?.createdAt).format("hh:mm A")}
                                                        </Index.TableCell>
                                                    </Index.TableRow>
                                                );
                                            })
                                        ) : (
                                            <PagesIndex.NoRecordFound colSpan={12} />
                                        )
                                    ) : (
                                        <PagesIndex.PageLoading colSpan={12} />
                                    )}
                                </Index.TableBody>
                                {/* </Index.Box> */}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>
                    <Index.Box className="pagination-design flex-end">
                        <Index.Stack spacing={2}>
                            <PagesIndex.Pagination
                                fetchData={filterData}
                                setPageData={setPageData}
                                pageData={pageData}
                            />
                        </Index.Stack>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            <Index.Modal
                open={modalOpen}
                // onClose={closeModal}
                className="category-modal"
            >
                <AddWardenModal handleClose={closeModal} setModalOpen={setModalOpen} inviteWardenList={inviteWardenList} />
            </Index.Modal>

        </>
    );
}

export default InviteWardenList;
