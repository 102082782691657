import React, { useEffect, useState } from "react";
import './EmployeeList.css'
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { activeDeactiveUser, getUserWardenEmployeeList } from "../../../../redux/admin/action";


const EmployeeList = () => {
  // state declaration
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [loading, setLoading] = useState(true)
  const [employeeList, setEmployeeList] = useState([])
  const [pageData, setPageData] = useState([])
  const [search, setSearch] = useState("")
  const [filterData, setFilteredData] = useState([])

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();

  // function declaration
  const handleClickMenu = (e) => {
    setActionIndex();
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = (row) => {
    // navigate("/admin/new-member/add");
  };
  const handleSearch = async (e) => {
    setSearch(e.target.value)
  }
  const getEmployeeList = () => {
    getUserWardenEmployeeList({ "role": "Employee" }, adminToken)
      .then((res) => {
        setEmployeeList(res)
        setLoading(false)
      })
  }

  useEffect(() => {
    getEmployeeList()
  }, [])

  const handleSwitchChange = (event, id) => {
    activeDeactiveUser({ id: id, status: event.target.checked }, adminToken).then((res) => {
      if (res.status === 200) {
        getEmployeeList()
      }
    })
  }

  useEffect(() => {
    const filtered = employeeList?.filter((item) => {
      return !search
        ? item
        : item?.fullName?.toLowerCase().includes(search.toString()) ||
        item?.email?.toLowerCase().includes(search.toString()) ||
        // item..toLowerCase().includes(search.toString())||
        item?.mobileNumber?.toString().toLowerCase().includes(search.toString())
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [employeeList, search]);


  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Employee List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                {/* <Index.Box className="common-button blue-button res-blue-button">
                  <PagesIndex.Link
                    to="/admin/new-member/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Member
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container"
              className='episap-table-container'
            >
              <Index.Table
                // aria-label="simple table"
                stickyHeader aria-label="sticky table"
                // className="table-design-main barge-table"
                className='episap-table-main'
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">S.No</Index.TableCell>
                    <Index.TableCell align="left">
                      Name Of Employee{" "}
                    </Index.TableCell>
                    <Index.TableCell align="left">Email Id </Index.TableCell>
                    <Index.TableCell align="left">Mobile No </Index.TableCell>
                    <Index.TableCell align="left">Employee Id</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {!loading ? pageData.length ? pageData.map((row) => {
                    return (
                      <Index.TableRow key={row.sNo}>
                        <Index.TableCell align="left">{row.sNo}</Index.TableCell>
                        <Index.TableCell>{row.fullName}</Index.TableCell>
                        <Index.TableCell align="left">{row.email}</Index.TableCell>
                        <Index.TableCell align="left">{row.mobileNumber}</Index.TableCell>
                        <Index.TableCell align="left">{row.userName}</Index.TableCell>
                        <Index.TableCell style={{ color: row.isActive ? "green" : "red" }}
                          align="left">
                          {row.isActive ? "Active" : "Deactive"}
                        </Index.TableCell>
                        <Index.TableCell sx={{ display: "flex" }}>
                          <Index.Switch

                            {...PagesIndex.label}
                            checked={row.isActive ? true : false}
                            onChange={(event) => { handleSwitchChange(event, row._id) }}
                          />
                          <Index.IconButton
                            onClick={(e) => {
                              handleClickMenu(e);
                            }}
                          >
                            <Index.MenuIcon className="action-menu-icon" />
                          </Index.IconButton>

                          <Index.Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            keepMounted
                            elevation={2}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <PagesIndex.ActionItem
                              className="action-text"
                              onClick={() => handleEdit()}
                            >
                              View
                            </PagesIndex.ActionItem>
                            <PagesIndex.ActionItem
                              className="action-text"
                              onClick={() => handleEdit()}
                            >
                              Edit
                            </PagesIndex.ActionItem>

                            <PagesIndex.ActionItem className="action-text" disabled>
                              Delete
                            </PagesIndex.ActionItem>
                          </Index.Menu>

                        </Index.TableCell>
                      </Index.TableRow>
                    )
                  }) :
                    <PagesIndex.NoRecordFound colSpan={12} />
                    : <PagesIndex.PageLoading colSpan={12} />}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default EmployeeList;
