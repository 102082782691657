import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "../nftIdea/NftIdeaList.css";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import ImageExtension from "../../admin/NFTList/ImageExtension";
import { wardenNftActionList } from "../../../../redux/admin/action";
import WardenWarningModel from "../nftIdea/WardenWarningModel";
import VerifiedIcon from "@mui/icons-material/Verified";

export const ExpertNftView = () => {
  // state declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const [pageData, setPageData] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [data, setData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageData, setImageData] = useState({});

  const [openmsgDescription, setOpenmsgDescription] = useState(false);
  const [msgDescription, setmsgDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  const formatData = (data) => {
    const prefix = data?.substring(0, 3);
    const lastTwoCharacters = data?.substring(data?.length - 3);
    const maskedData = `${prefix}...${lastTwoCharacters}`;
    return maskedData;
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleSearch = async (e) => {
    setSearch(e.target.value);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  // console.log(pageData, 'PageData')

  // function declaration
  const getWardenActions = () => {
    wardenNftActionList({ ideaId: state?.row?._id }, adminToken).then((res) => {
      setActionList(res);
      setLoading(false);
      setPageData(res);
    });
  };

  useEffect(() => {
    getWardenActions();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = actionList?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.userId?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.actionType?.toLowerCase().includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [actionList, search]);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const description = state?.row?.descriptions;

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const openSingleImageModal = () => {
    setImageModalOpen(true);
  };
  const closeSingleImageModal = () => {
    setImageModalOpen(false);
    setImageData({});
  };

  const handleClickOpenDescription = (title, description) => {
    setOpenmsgDescription(true);
    setDescriptionTitle(title);
    setmsgDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenmsgDescription(false);
    setDescriptionTitle("");
    setmsgDescription("");
  };

  // Reason Popup Msg
  const handleNFTMsgPopupReason = (
    masterWardenReason,
    inappropriateIdeaReason
  ) => {
    let result;
    if (
      typeof masterWardenReason === "object" &&
      masterWardenReason !== null &&
      Object.keys(masterWardenReason)?.length > 0
    ) {
      result = masterWardenReason?.details;
    } else if (
      typeof inappropriateIdeaReason === "object" &&
      inappropriateIdeaReason !== null &&
      Object.keys(inappropriateIdeaReason)?.length > 0
    ) {
      result = inappropriateIdeaReason?.details;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  // Check reson type and show reason
  const handleNFTReasonCheck = (
    masterWardenReason,
    inappropriateIdeaReason
  ) => {
    let result;
    if (
      typeof masterWardenReason === "object" &&
      masterWardenReason !== null &&
      Object.keys(masterWardenReason)?.length > 0
    ) {
      if (masterWardenReason?.details?.length > 15) {
        result = masterWardenReason?.details.slice(0, 15) + "...";
      } else {
        result = masterWardenReason?.details;
      }
    } else if (
      typeof inappropriateIdeaReason === "object" &&
      inappropriateIdeaReason !== null &&
      Object.keys(inappropriateIdeaReason)?.length > 0
    ) {
      if (inappropriateIdeaReason?.details?.length > 15) {
        result = inappropriateIdeaReason?.details.slice(0, 15) + "...";
      } else {
        result = inappropriateIdeaReason?.details;
      }
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  // Admin Action Status
  // const handleNFTStatus = (nftApproved, masterAction, adminAction) => {
  //   let result;
  //   if (
  //     (nftApproved === "Inappropriate" || nftApproved === "Pending") &&
  //     !masterAction
  //   ) {
  //     result = <string style={{ color: "blue" }}>Pending</string>;
  //   } else if (
  //     (nftApproved === "Approved" || masterAction === "Approved") &&
  //     adminAction === "Pending"
  //   ) {
  //     result = <string style={{ color: "green" }}>Approved</string>;
  //   } else if (
  //     (nftApproved === "Rejected" || masterAction === "Rejected") &&
  //     adminAction === "Pending"
  //   ) {
  //     result = <string style={{ color: "red" }}>Rejected</string>;
  //   } else if (masterAction === "Inappropriate" && adminAction === "Pending") {
  //     result = <string style={{ color: "blue" }}>Pending</string>;
  //   } else if (adminAction === "Approved") {
  //     result = <string style={{ color: "green" }}>Approved</string>;
  //   } else if (adminAction === "Rejected") {
  //     result = <string style={{ color: "red" }}>Rejected</string>;
  //   } else {
  //     result = <string>-</string>;
  //   }
  //   return result;
  // };

  console.log(state.row, "nftDetails");

  const handleNFTStatus = (
    nftApproved,
    pfpStatus,
    masterAction,
    adminAction
  ) => {
    console.log(
      nftApproved,
      " =nftApproved , ",
      masterAction,
      " =masterAction , ",
      adminAction,
      " =adminAction"
    );
    let result;
    if (
      (nftApproved === "Inappropriate" || nftApproved === "Pending") &&
      adminAction === "Pending" &&
      (masterAction === "Pending" || !masterAction)
    ) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (
      nftApproved === "Pending" &&
      (pfpStatus == "Approved" || pfpStatus === "Rejected") &&
      (masterAction == "Approved" || masterAction === "Rejected")
    ) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (
      (nftApproved === "Approved" || masterAction === "Approved") &&
      adminAction === "Pending"
    ) {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (
      (nftApproved === "Rejected" || masterAction === "Rejected") &&
      adminAction === "Pending"
    ) {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else if (masterAction === "Inappropriate" && adminAction === "Pending") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (adminAction === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (adminAction === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  // PFP Status
  const handlePFPStatus = (masterAction, pfpStatus, adminAction) => {
    let result;
    if (masterAction === "Approved" && pfpStatus === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (masterAction === "Rejected" && pfpStatus === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else if (masterAction === "Inappropriate" && adminAction === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (masterAction === "Inappropriate" && adminAction === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else if (masterAction === "Inappropriate") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (!pfpStatus || pfpStatus === "Pending") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-common-box">
        <Index.Grid container className="mb-10">
          <Index.Box className="user-detail-cont bg-right">
            <Index.Grid container spacing={1}>
              <Index.Grid item xs={12}>
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex justify-content-between">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Idea Details
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="form-back-btn">
                      <Index.Button
                        variant="contained"
                        className="back-btn"
                        type="back"
                        onClick={() => handleBack()}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={6} md={2} lg={1.5}>
                <Index.Box className="nft-detail-img">
                  <Index.Box className="user-detail-img">
                    <ImageExtension
                      imageUrl={state?.row?.imageAddress}
                      imageTitle="NFT"
                      openSingleImageModal={openSingleImageModal}
                      setImageData={setImageData}
                    />
                  </Index.Box>
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title page-sub-title mb-0 text-center"
                    >
                      NFT
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={6} md={2} lg={1.5}>
                <Index.Box className="nft-detail-img">
                  <Index.Box className="user-detail-img nft-img-fill">
                    <ImageExtension
                      imageUrl={state?.row?.displayImage}
                      imageTitle="Dislay Image"
                      openSingleImageModal={openSingleImageModal}
                      setImageData={setImageData}
                    />
                  </Index.Box>
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title page-sub-title mb-0 text-center"
                    >
                      Display Image
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} md={8} lg={9}>
                <Index.Box className="admin-nft-detail" sx={{ height: "auto" }}>
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      NFT Name :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {state?.row?.title}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box
                    className="admin-nft-cont"
                    sx={{ alignItems: "center" }}
                  >
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title"
                    >
                      Idea Type :
                    </Index.Typography>
                    <Index.Box sx={{ marginLeft: "10px" }}>
                      {state?.row?.ideaType == "Main Idea" && (
                        <Index.Chip
                          color="success"
                          size="small"
                          label={state?.row?.ideaType}
                        />
                      )}
                      {state?.row?.ideaType == "Evolve Idea" && (
                        <Index.Chip
                          color="warning"
                          size="small"
                          label={state?.row?.ideaType}
                        />
                      )}
                      {state?.row?.ideaType == "PFP Idea" && (
                        <Index.Chip
                          color="primary"
                          size="small"
                          label={state?.row?.ideaType}
                        />
                      )}
                      {(state?.row?.ideaType == "Merge Idea" ||
                        state?.row?.ideaType == "Unite Idea") && (
                        <Index.Chip
                          color="secondary"
                          size="small"
                          label={"Merge Idea"}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      Created Date :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {" "}
                      {moment(state?.row?.createdAt).format("DD/MM/YYYY")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      Contract Address :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {formatData(state?.row?.contractAddress)}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      PFP Days :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {state?.row?.pfpDays}
                    </Index.Typography>
                  </Index.Box>

                  {state?.row?.addInPFP && (
                    <>
                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          PFP Start Date :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {moment(state?.row?.pfpStartDate).format(
                            "DD/MM/YYYY"
                          )}
                          &nbsp; &nbsp;
                          {moment(state?.row?.createdAt).format("hh:mm A")}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="admin-nft-cont">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          PFP End Date :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {moment(state?.row?.pfpEndDate).format("DD/MM/YYYY")}
                          &nbsp; &nbsp;
                          {state?.row?.pfpEndTime}
                        </Index.Typography>
                      </Index.Box>
                    </>
                  )}

                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      NFT Price :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {state?.row?.price}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      Available On Sale :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle description"
                      style={{
                        color:
                          state?.row?.availableOnSale === true
                            ? "green"
                            : "red",
                      }}
                    >
                      {state?.row?.availableOnSale === true ? "Yes" : "No"}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      NFT Status :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle description"
                    >
                      {handleNFTStatus(
                        state?.row?.nftApproved,
                        state?.row?.pfpStatus,
                        state?.row?.masterAction,
                        state?.row?.adminAction
                      )}
                    </Index.Typography>
                  </Index.Box>

                  {state?.row?.addInPFP && (
                    <Index.Box className="admin-nft-cont">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-title title-max-width"
                      >
                        PFP Status :
                      </Index.Typography>

                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle description"
                      >
                        {handlePFPStatus(
                          state?.row?.masterAction,
                          state?.row?.pfpStatus,
                          state?.row?.adminAction
                        )}
                      </Index.Typography>
                    </Index.Box>
                  )}

                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      URL : &nbsp;
                    </Index.Typography>
                    {state?.row?.url && state?.row?.url != "undefined" ? (
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="user-detail-subtitle"
                      >
                        <Index.Tooltip title={state?.row?.url}>
                          {" "}
                          {state?.row?.url &&
                            (state?.row.url?.length > 15
                              ? state?.row.url.slice(0, 15) + "..."
                              : state?.row.url)}
                        </Index.Tooltip>
                      </Index.Typography>
                    ) : (
                      " N/A"
                    )}
                  </Index.Box>
                  {/* <Index.Box className="admin-nft-cont">
                    <Index.Typography variant="p" component="p" className="user-detail-title title-max-width">
                      Description :
                    </Index.Typography>
                    <Index.Typography variant="p" component="p" className="user-detail-subtitle description">
                      <Index.Tooltip title={state?.row?.descriptions}>
                        {" "}
                        {state?.row?.descriptions &&
                          (state?.row.descriptions?.length > 15
                            ? state?.row.descriptions.slice(0, 15) + "..."
                            : state?.row.descriptions)}
                      </Index.Tooltip>
                    </Index.Typography>
                  </Index.Box> */}
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      Categories :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {state?.row?.category
                        ? Array.prototype.map
                            .call(state?.row?.category, function (item) {
                              return item?.categoryName;
                            })
                            .join(", ")
                        : ""}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      Sub-Categories :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {state?.row?.sub_category}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="admin-nft-cont">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-title title-max-width"
                    >
                      Tags :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-detail-subtitle"
                    >
                      {state?.row?.tags}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                {/* New */}
                <Index.Box className="admin-nft-detail colmn-1">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-title"
                  >
                    Description :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-descr"
                  >
                    {description && (
                      <>
                        {showFullDescription ? (
                          <span>{description}</span>
                        ) : (
                          <span>
                            {description.length > 326
                              ? description.slice(0, 326) + "..."
                              : description}
                          </span>
                        )}
                        {description.length > 326 && (
                          <Index.Button
                            className="read-btn ml-5"
                            onClick={toggleDescription}
                          >
                            {showFullDescription ? " Read Less" : " Read More"}
                          </Index.Button>
                        )}
                      </>
                    )}
                  </Index.Typography>
                </Index.Box>
                {/* End New */}
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Grid>

        <Index.Grid container className="mb-10">
          <Index.Box className="user-detail-cont bg-right pb-0">
            <Index.Grid container spacing={1}>
              <Index.Grid item xs={12} md={3}>
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex justify-content-between">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        NFT Creator Details
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="user-detail-img nft-detail-box">
                      <img
                        src={
                          state?.row?.userId?.profile === ""
                            ? PagesIndex.Jpg.dummyImage
                            : `${PagesIndex.ImageURL}${state?.row?.userId?.profile}`
                        }
                        alt=""
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="admin-nft-cont">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-title title-max-width"
                  >
                    Name :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-subtitle"
                  >
                    {state?.row?.userId?.fullName}
                  </Index.Typography>
                  {state?.row?.userId?.role == "Expert" && (
                    <Index.Box className="verify-icon-container">
                      <VerifiedIcon color={"primary"} />
                    </Index.Box>
                  )}
                </Index.Box>
                <Index.Box className="admin-nft-cont">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-title title-max-width"
                  >
                    Username :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-subtitle"
                  >
                    {" "}
                    {state?.row?.userId?.userName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-nft-cont">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-title title-max-width"
                  >
                    Email :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-subtitle"
                  >
                    {state?.row?.userId?.email}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-nft-cont">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-title title-max-width"
                  >
                    Mobile No :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-detail-subtitle"
                  >
                    {state?.row?.userId?.mobileNumber}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} md={9}>
                <Index.Box className="">
                  <Index.Box className="table-wrape pt-0">
                    <Index.Box className="title-header">
                      <Index.Box className="title-header-flex res-title-header-flex">
                        <Index.Box className="title-main">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Warden Action List
                          </Index.Typography>
                        </Index.Box>
                        {/* <Index.Box className="d-flex align-items-center res-set-search">
                          <PagesIndex.Search className="searchbar">
                            <PagesIndex.StyledInputBase
                              placeholder="Search"
                              inputProps={{ "aria-label": "search" }}
                              onChange={handleSearch}
                            />
                          </PagesIndex.Search>
                        </Index.Box> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="table-box table_scroll">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="episap-table-container"
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="episap-table-main contact-table user-details"
                        >
                          <Index.TableHead>
                            <Index.TableRow>
                              <Index.TableCell align="left">
                                S.No.{" "}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Warden Name
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Warden Email
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Status
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Reason
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Action Date & Time
                              </Index.TableCell>
                              <Index.TableCell align="center">
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody className="table-body">
                            {!loading ? (
                              pageData?.length ? (
                                pageData.map((row) => {
                                  return (
                                    <Index.TableRow className="">
                                      <Index.TableCell>
                                        {row?.sNo}
                                      </Index.TableCell>

                                      <Index.TableCell align="left">
                                        {row?.userId?.fullName
                                          ? row?.userId?.fullName
                                          : row?.adminId?.fullName}
                                      </Index.TableCell>

                                      <Index.TableCell align="left">
                                        {row?.userId?.email
                                          ? row?.userId?.email
                                          : row?.adminId?.email}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        align="left"
                                        style={{
                                          color:
                                            row?.actionType == "Approved" ||
                                            row?.actionType == "upvote"
                                              ? "green"
                                              : "red",
                                        }}
                                      >
                                        {row?.actionType == "Rejected" ||
                                        row?.actionType == "downvote"
                                          ? "Downvote"
                                          : row?.actionType == "approved" ||
                                            row?.actionType == "Approved" ||
                                            row?.actionType == "upvote"
                                          ? "Upvote"
                                          : row?.actionType ==
                                              "inappropriate" ||
                                            row?.actionType == "Inappropriate"
                                          ? "Inappropriate"
                                          : ""}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        align="left"
                                        onClick={() =>
                                          handleClickOpenDescription(
                                            "Reason Message",
                                            handleNFTMsgPopupReason(
                                              row?.masterWardenReason,
                                              row?.inappropriateIdeaReason
                                            )
                                          )
                                        }
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <Index.Tooltip>
                                          {handleNFTReasonCheck(
                                            row?.masterWardenReason,
                                            row?.inappropriateIdeaReason
                                          )}
                                        </Index.Tooltip>
                                      </Index.TableCell>

                                      <Index.TableCell align="left">
                                        {moment(row?.createdAt).format(
                                          "DD/MM/YYYY hh:mm A"
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell align="center">
                                        {
                                          // Warning status true then hide button
                                          row?.warningSent == false &&
                                          row?.userId &&
                                          Object.keys(row?.userId)?.length ? (
                                            <Index.Tooltip title="Send Warning">
                                              <Index.Button
                                                onClick={() => {
                                                  openModal();
                                                  setData({
                                                    ideaId: row?.ideaId?._id,
                                                    wardenId: row?.userId?._id,
                                                    ideaActionId: row?._id,
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={PagesIndex.Png.warning}
                                                  className="warning-icon"
                                                />
                                              </Index.Button>
                                            </Index.Tooltip>
                                          ) : row?.warningSent == false &&
                                            row?.adminId &&
                                            Object.keys(row?.adminId)
                                              ?.length ? (
                                            <Index.Tooltip title="No Warning">
                                              <img
                                                src={
                                                  PagesIndex.Png.notWarningAlert
                                                }
                                                className="warning-icon"
                                              />
                                            </Index.Tooltip>
                                          ) : (
                                            <Index.Tooltip title="Warning Sent">
                                              <img
                                                src={
                                                  PagesIndex.Png.warningBlack
                                                }
                                                className="warning-icon"
                                              />
                                            </Index.Tooltip>
                                          )
                                        }
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                              ) : (
                                <PagesIndex.NoRecordFound colSpan={12} />
                              )
                            ) : (
                              <PagesIndex.PageLoading colSpan={12} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                    <Index.Box className="pagination-design flex-end">
                      <Index.Stack spacing={2}>
                        <PagesIndex.Pagination
                          fetchData={filterData}
                          setPageData={setPageData}
                          pageData={pageData}
                        />
                      </Index.Stack>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Grid>
      </Index.Box>
      {/* Single Image show Popup */}
      <Index.Modal
        open={imageModalOpen}
        onClose={closeSingleImageModal}
        className="category-modal"
      >
        <PagesIndex.SingleImageModal
          handleClose={closeSingleImageModal}
          imageData={imageData}
        />
      </Index.Modal>

      <Index.Modal
        open={modalOpen}
        onClose={closeModal}
        className="category-modal"
      >
        <WardenWarningModel
          handleClose={closeModal}
          data={data}
          // editData={editData}
          setModalOpen={setModalOpen}
          // setEditData={setEditData}
          getWardenActions={getWardenActions}
        />
      </Index.Modal>

      {/* Description Message Popup */}
      <Index.Modal
        open={openmsgDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {msgDescription ? msgDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
};
