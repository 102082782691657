import axios from "axios";
const API_ENDPOINT = "https://api.episap.appworkdemo.com/api/"; // https live
// const API_ENDPOINT = "http://localhost:3031/api/"; // localhost

const dataService = axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    // config.headers.auth = localStorage.getItem("AdminToken");
    return config;
  },
  (error) => {
    return error;
  }
);

export default dataService;
