import { styled, alpha } from "@mui/material/styles";
import Index from "../../container/Index";


export const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));
  
 export const ActionItem = styled(Index.MenuItem)(() => ({
    fontFamily: "poppins",
    lineHeight: "15px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#595F69",
  }));
  
export  const StyledInputBase = styled(Index.InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
  
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));
  export const label = { inputProps: { "aria-label": "Switch demo" } };