import React, { useState } from "react";
import "./CategoryManagementModal.css";
import "./CategoryManagementModal.responsive.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import {
  addCategoryManagementAction,
  editCategoryManagementAction,
  editTagManagementAction,
} from "../../../../redux/admin/action";

const CategoryManagementModal = (props) => {
  //state declaration
  const [image, setImage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { editData, setModalOpen, setEditData, handleClose } = props;
  const { pathname } = PagesIndex.useLocation();
  // const { pathname } = location;

  // Initital values
  const initialValues = {
    categoryName: editData ? editData?.categoryName : "",
    categoryImage: editData ? editData?.categoryImage : "",
  };
  const initialValuesTags = {
    tagName: editData ? editData?.tagName : "",
  };

  // functions declaration
  const handleImageChange = (e) => {
    setImage(e?.target?.files[0]);
  };

  const handleCategoryFormSubmit = (values) => {
    setIsSubmitting(true);
    const formData = new FormData();
    // formData.append("categoryName", values.categoryName);
    if (pathname === "/admin/category-management") {
      formData.append("categoryName", values.categoryName);
      formData.append("categoryImage", values?.categoryImage);
      formData.append("id", editData ? editData?._id : null);

      if (editData?._id) {
        editCategoryManagementAction(formData, adminToken).then((res) => {
          if (res?.status === 200) {
            setModalOpen(false);
            setEditData("");
          }
        });
      } else {
        addCategoryManagementAction(formData, adminToken).then((res) => {
          if (res?.status === 201) {
            setModalOpen(false);
            setEditData("");
          }
        });
      }
    } else {
      const urlencoded = new URLSearchParams();
      urlencoded.append("tagName", values.tagName);
      // urlencoded.append("categoryImage", values?.categoryImage);
      if (editData?._id) {
        urlencoded.append("id", editData ? editData?._id : "");
      }
      // editTagManagementAction
      editTagManagementAction(urlencoded, adminToken).then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          handleClose();
          setModalOpen(false);
          setEditData("");
          setIsSubmitting(false);
        }
      });
    }
  };

  const handleFocus = () => {
    // setLoading(false);
  };

  return (
    <>
      {/* <Index.Modal open={true}> */}
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {pathname === "/admin/category-management" ? (
                        <p>
                          {!editData?._id ? "Add Category" : "Edit Category"}
                        </p>
                      ) : (
                        <p>{!editData?._id ? "Add Tag" : "Edit Tag"}</p>
                      )}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img" onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="user-circle-img"
                  alt="icon"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleCategoryFormSubmit}
              // initialValues={initialValues}
              initialValues={
                pathname === "/admin/category-management"
                  ? initialValues
                  : initialValuesTags
              }
              // validationSchema={PagesIndex.addEditCategory}
              validationSchema={
                pathname === "/admin/category-management"
                  ? PagesIndex.addEditCategory
                  : PagesIndex.addTagsManagement
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="episap-modal-cont">
                    <Index.Box>
                      <img
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : editData?.categoryImage
                            ? `${PagesIndex.ImageURL}${editData?.categoryImage}`
                            : PagesIndex.Png.addcategorynew
                        }
                        alt="Image Preview"
                        className={`profile-img mb-15 ${
                          pathname === "/admin/category-management"
                            ? ""
                            : "tags-management"
                        }`}
                      />
                    </Index.Box>
                    
                    <Index.Box>
                      <input
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        placeholder="File"
                        variant="filled"
                        type="file"
                        accept="image/*"
                        className={`admin-input-design input-placeholder ${
                          pathname === "/admin/category-management"
                            ? ""
                            : "tags-management"
                        }`}
                        name="categoryImage"
                        data-testid="File-input"
                        // autoComplete="off"
                        // onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={(e) => {
                          handleImageChange(e);
                          setFieldValue("categoryImage", e?.target?.files[0]);
                        }}
                        helperText={
                          touched?.categoryImage && errors.categoryImage
                        }
                        error={Boolean(
                          errors.categoryImage && touched.categoryImage
                        )}
                      />
                      <span className="error-text">{errors.categoryImage && errors.categoryImage}</span>

                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        // placeholder="Title"
                        placeholder={
                          pathname === "/admin/category-management"
                            ? "Title"
                            : "Tag name"
                        }
                        variant="filled"
                        type="text"
                        className="admin-input-design input-placeholder"
                        // name="categoryName"
                        name={
                          pathname === "/admin/category-management"
                            ? "categoryName"
                            : "tagName"
                        }
                        data-testid="title-input"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={
                          pathname === "/admin/category-management"
                            ? values.categoryName
                            : values.tagName
                        }
                        onChange={handleChange}
                        // helperText={touched.categoryName && errors.categoryName}
                        helperText={
                          pathname === "/admin/category-management"
                            ? touched.categoryName && errors.categoryName
                            : touched.tagName && errors.tagName
                        }
                        error={Boolean(
                          pathname === "/admin/category-management"
                            ? errors.categoryName && touched.categoryName
                            : errors.tagName && touched.tagName
                        )}
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                    >
                      {!editData?._id ? "Submit" : "Update"} 
                    </Index.Button>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* </Index.Modal> */}
    </>
  );
};

export default CategoryManagementModal;
