import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../forgotPassword/ForgotPassword.css";
import { forgotPasswordAction } from "../../../../redux/admin/action";

const ForgotPassword = () => {
  // state declaration
  const [loading, setLoading] = useState(false);

  // const declaration
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  // Initital values declaration
  let initialValues = {
    email: "",
  };

  // functions declaration 
  const handleFormSubmit = (values) => {
    forgotPasswordAction(values, navigate)
  }

  return (
    <div>
      <Index.Box className="admin-main admin-main-bg">
        <Index.Box className="admin-main-bg">
          <Index.Box className="auth-main">
            <Index.Box className="auth-right">
              <Index.Box className="auth-common-box">

                <Index.Box className="auth-header">
                  <Index.Box className="logo-name">
                    <img src={PagesIndex.Png.LogoName} alt="logo" className="" />
                  </Index.Box>
                  <Index.Box className="auth-title auth-logo-title">
                    Forgot Password
                  </Index.Box>
                  <Index.Box className="auth-subtitle">
                    Enter your Email and we'll send you an OTP on your Email
                  </Index.Box>
                </Index.Box>

                <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={
                    PagesIndex.validationSchemaForgotpassword
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="auth-body">
                        <Index.Grid container spacing={3}>
                          {/* Email */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Enter your email
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Enter your email"
                                variant="filled"
                                className="form-input login"
                                name="email"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={() => setLoading(false)}
                                value={values?.email}
                                onChange={(e) => { 
                                  setFieldValue('email', e.target.value.toLowerCase().trim());
                                }}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* Button */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="auth-btn-box">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disableRipple
                                disabled={loading}
                              >
                                Submit
                              </Index.Button>
                            </Index.Box>
                          </Index.Grid>

                        </Index.Grid>

                        <Index.Box
                          className="box-login-text forgot bluebox-text back-link-box"
                          sx={{ mt: 3 }}
                        >
                          <PagesIndex.Link to="/">
                            <Index.Box className='back-wraper'>
                              < Index.KeyboardBackspaceIcon className="back-icon" />
                              <Index.Typography className="auth-link"
                                variant="body1"
                                component="p"
                              >
                                Back to Login
                              </Index.Typography>
                            </Index.Box>
                          </PagesIndex.Link>
                        </Index.Box>

                      </Index.Box>
                    </Index.Stack>
                  )}
                </PagesIndex.Formik>


              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ForgotPassword;
