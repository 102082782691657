import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import {
  addEditRewardToken,
  getRewardToken,
  getAllReferralLogHistoryAction,
} from "../../../../redux/admin/action";
import { styled } from "@mui/material/styles";
import moment from "moment";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AddEditRewardToken = () => {
  const navigate = PagesIndex.useNavigate();
  const [rewardToken, setRewardToken] = useState();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  let initialValues = {};
  if (rewardToken) {
    initialValues = {
      rewardAmount: rewardToken?.rewardAmount ? rewardToken?.rewardAmount : ""
    };
  } else {
    initialValues = {
        rewardAmount: ""
    };
  }

  useEffect(() => {
    getRewardToken(adminToken, navigate).then((res) => {
      setRewardToken(res);
    });
  }, []);

  const handleSubmit = (values) => {
    setLoadingUpdate(true);
    addEditRewardToken(values, adminToken);
    setTimeout(() => {
      rewardLogHistory();
      setLoadingUpdate(false);
    }, 2000);
  };

  // Log history functions 
  const [loading, setLoading] = useState(true);
  const [historyList, setHistoryList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilteredData] = useState([]);

  const rewardLogHistory = () => {
    getAllReferralLogHistoryAction(adminToken).then((res) => {
      setHistoryList(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    rewardLogHistory();
  }, []);

  // Filter fetched data
  useEffect(() => {
    if (historyList) {
      setFilteredData(historyList);
    } else {
      setFilteredData([]);
    }
  }, [historyList]);


  return (
    <>
      <Index.Box className="episap-common-box">
        <Index.Box className="h-100">
          <Index.Box className="card-center">
            <Index.Box className="card-main profile-card-main p-0 w-100">
              <Index.Box className="title-main mb-15 text-center">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Reward Token
                </Index.Typography>
              </Index.Box>
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={handleSubmit}
                initialValues={initialValues}
                // validationSchema={PagesIndex.walletAddressSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form
                    spacing={2}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <Index.Box className="profile-inner">
                      <Index.Box className="">
                        <Index.Grid
                          container
                          columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                        >
                          <Index.Grid item xs={12} sm={12} md={12} lg={12}>
                            <Item className="dashboard-item">
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="change-input-label"
                                >
                                  Add Reward Amount
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border mb-15">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder=""
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    name="rewardAmount"
                                    onBlur={handleBlur}
                                    value={values.rewardAmount}
                                    onChange={handleChange}
                                    helperText={
                                      touched.rewardAmount && errors.rewardAmount
                                    }
                                    error={Boolean(
                                      errors.rewardAmount && touched.rewardAmount
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Item>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box mt={5} className="editor-btn text-center">
                      <Index.Button
                        className="editor-submit-btn login-disable"
                        type="submit"
                        data-testid="button"
                        variant="contained"
                        disabled={loadingUpdate}
                      >
                        <img
                          alt="save"
                          src={PagesIndex.Png.invoice}
                          className=""
                        />
                        Update
                      </Index.Button>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Log History */}
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Referral Reward History
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Referral Amount</Index.TableCell>
                    <Index.TableCell align="left">
                      From Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      To Date & Time
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

     
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">
                              {row.sNo}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.rewardAmount ? row?.rewardAmount : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.updatedAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
                
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AddEditRewardToken;
