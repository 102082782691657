import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {getReportAction} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import * as XLSX from 'xlsx';
function PostReport({ filter, search, weeklyData, downloadExcel, setDownloadExcel }) {

  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const [loading, setLoading] = useState(true);
  const [socialPostList, setSocialPostList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilteredData] = useState([]);

  const getSocialPost = () => {
    getReportAction(filter,adminToken).then((res) => {
      setSocialPostList(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSocialPost();
  }, [weeklyData]);


  // Filter fetched data
  useEffect(() => {
    const filtered = socialPostList?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.uploadBy?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.title?.toLowerCase().includes(search.toLowerCase()) ||
          item?.uploadBy?.userName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.uploadBy?.email?.toLowerCase().includes(search.toLowerCase()) ||
          new Date(item?.createdAt)?.toLocaleDateString("en-GB")?.toString().includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [socialPostList, search]);


  useEffect(() => {
    if(downloadExcel){
      handleDownloadExcel();
    }
  }, [downloadExcel])

  // Download table function
  const handleDownloadExcel = () => {
  const data = filterData.map((item) => ({
    'ID': item._id,
    'NFT Title': item?.title,
    'Full Name': item?.uploadBy?.fullName,
    'Username': item?.uploadBy?.userName,
    'Email': item?.uploadBy?.email,
    'Create Date': moment(item?.createdAt).format("DD/MM/YYYY"),
  }));
    // Create a worksheet from the array of objects
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate a blob from the workbook
    const blob = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    // Convert the string to a Blob
    const blobData = new Blob([s2ab(blob)], { type: 'application/octet-stream' });

    // Create a download link and trigger the download
    const url = URL.createObjectURL(blobData);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Socialpost.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setDownloadExcel(false); // Download state false after download the file
  };
  
  // Utility function to convert string to ArrayBuffer
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  };
  return (
    <>
      <Index.Box className="page-content-box">
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container"
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                // className="table-design-main barge-table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">NFT Title </Index.TableCell>
                    <Index.TableCell align="left">Image </Index.TableCell>
                    <Index.TableCell align="left">Full Name </Index.TableCell>
                    <Index.TableCell align="left">Username </Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                 
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row.sNo}>
                            <Index.TableCell>{row?.sNo}</Index.TableCell>
                            <Index.TableCell align="left">
                              {row.title}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <img
                              src={`${PagesIndex?.ImageURL}${row?.uploadPostImage}`}
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                }}
                              />
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.uploadBy?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.uploadBy?.userName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.uploadBy?.email}
                            </Index.TableCell>
                          
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format("DD/MM/YYYY")}
                            </Index.TableCell>
                          
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
                {/* </Index.Box> */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
      </Index.Box>
    </>
  );
}

export default PostReport;
