import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getPFPIdeaList,
  updatePfpIdeaStatus,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import InappropriateCommentModal from "../../../../components/common/InappropriateCommentModal";

function PFPIdeaList() {
  // state declaration
  const navigate = PagesIndex.useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pfpIdeas, setPFPIdeas] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [type, setType] = useState("");
  const [inappropriate, setInappropriate] = useState({});
  // const declaration
  const { adminToken, adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  useEffect(() => {
    if (adminDetails?.adminType == 1) {
      setType("warden");
    } else {
      setType("admin");
    }
  }, [adminDetails]);

  // function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const getWardenWarningList = () => {
    getPFPIdeaList(adminToken).then((res) => {
      setPFPIdeas(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getWardenWarningList();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = pfpIdeas?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.ideaType?.toLowerCase().includes(search.toLowerCase()) ||
            item?.pfpStatus?.toLowerCase().includes(search.toLowerCase()) ||
            item?.adminAction?.toLowerCase().includes(search.toLowerCase()) ||
            item?.masterAction?.toLowerCase().includes(search.toLowerCase()) ||
            new Date(item?.pfpStartDate)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase()) ||
            new Date(item?.pfpEndDate)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [pfpIdeas, search]);

  // Active Inactive popup
  const handleStatusModalOpen = (data) => {
    setStatusModalOpen(!statusModalOpen);
    setStatusData(data);
  };
  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
    setStatusData({});
  };

  // Active deactive function declaration
  const pfpIdeasStatusUpdate = () => {
    setLoading(true);
    handleStatusModalClose();
    updatePfpIdeaStatus(statusData, adminToken).then((res) => {
      if (res?.status == 200) {
        getWardenWarningList();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  // Inappropriate action popup
  const openModal = (data) => {
    setModalOpen(true);
    setInappropriate(data);
  };
  const closeModal = () => {
    setModalOpen(false);
    setInappropriate({});
  };

  // PFP Status
  const handlePFPStatus = (status) => {
    let result;
    console.log(status, "pfp status");
    if (status === "Pending" || !status) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (status === "Inappropriate") {
      result = <string style={{ color: "red" }}>Inappropriate</string>;
    } else if (status === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (status === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else {
      result = <string style={{ color: "blue" }}>-</string>;
    }
    return result;
  };

  // Master Wardens Status
  const handleMasterWardensStatus = (masterActionStatus) => {
    let result;
    if (!masterActionStatus || masterActionStatus === "Pending") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (masterActionStatus === "Inappropriate") {
      result = <string style={{ color: "red" }}>Inappropriate</string>;
    } else if (masterActionStatus === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (masterActionStatus === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  // Admin Action Status
  const handleAdminActionStatus = (status, masterAction) => {
    let result;
    if (
      (status === "Pending" && masterAction === "Approved") ||
      masterAction === "Rejected"
    ) {
      result = <string>-</string>;
    } else if (masterAction === "Inappropriate" && status === "Pending") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (status === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (status === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  PFP Ideas
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container"
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                // className="table-design-main barge-table"
                className="episap-table-main master-warden-nft-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Idea Image</Index.TableCell>
                    <Index.TableCell align="left">Title</Index.TableCell>
                    <Index.TableCell align="left">Idea Type</Index.TableCell>
                    <Index.TableCell align="left">
                      PFP Start Date
                    </Index.TableCell>
                    <Index.TableCell align="left">PFP End Date</Index.TableCell>
                    <Index.TableCell align="left">PFP Status</Index.TableCell>
                    <Index.TableCell align="left">
                      Master Warden Status
                    </Index.TableCell>
                    <Index.TableCell align="left">Admin Status</Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>

                    {adminDetails?.adminType == 1 && ( // Only actions available for warden master
                      <Index.TableCell align="center">Action</Index.TableCell>
                    )}
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell>{row?.sNo}</Index.TableCell>
                            <Index.TableCell align="left">
                              <img
                                src={
                                  row?.displayImage
                                    ? `${PagesIndex.ImageURL}${row?.displayImage}`
                                    : ""
                                }
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              />
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/${type}/nft-details/${row?._id}`)
                              }
                            >
                              {row?.title.length > 18
                                ? row?.title.substring(0, 18) + "..."
                                : row?.title}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.ideaType == "Main Idea" && (
                                <Index.Chip
                                  color="success"
                                  size="small"
                                  label={row?.ideaType}
                                />
                              )}
                              {row?.ideaType == "Evolve Idea" && (
                                <Index.Chip
                                  color="warning"
                                  size="small"
                                  label={row?.ideaType}
                                />
                              )}
                              {(row?.ideaType == "Merge Idea" ||
                                row?.ideaType == "Unite Idea") && (
                                <Index.Chip
                                  color="secondary"
                                  size="small"
                                  label={"Merge Idea"}
                                />
                              )}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.pfpStartDate
                                ? moment(row?.pfpStartDate).format("DD/MM/YYYY")
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.pfpEndDate
                                ? moment(row?.pfpEndDate).format("DD/MM/YYYY")
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {handlePFPStatus(row?.pfpStatus)}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {handleMasterWardensStatus(row?.masterAction)}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {handleAdminActionStatus(
                                row?.adminAction,
                                row?.masterAction
                              )}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>

                            {adminDetails?.adminType == 1 && ( // Only actions available for warden master
                              <Index.TableCell align="left">
                                <Index.Box sx={{ display: "flex" }}>
                                  {row?.masterAction !== "Inappropriate" ? (
                                    row?.pfpStatus == "Rejected" ? (
                                      <Index.Tooltip title="Rejected">
                                        <Index.Button>
                                          <Index.ThumbDownAltIcon className="icon-btns" />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    ) : row?.pfpStatus == "Approved" ? (
                                      <Index.Tooltip title="Approved">
                                        <Index.Button>
                                          <Index.ThumbUpAltIcon className="icon-btns" />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    ) : (
                                      <Index.Box sx={{ display: "flex" }}>
                                        <Index.Tooltip title="Approve">
                                          <Index.Button
                                            onClick={() => {
                                              handleStatusModalOpen({
                                                pfpStatus: "Approved",
                                                nftIdeaId: row?._id,
                                              });
                                            }}
                                          >
                                            <Index.ThumbUpOffAltIcon className="icon-btns" />
                                          </Index.Button>
                                        </Index.Tooltip>
                                        <Index.Tooltip title="Reject">
                                          <Index.Button
                                            onClick={() => {
                                              handleStatusModalOpen({
                                                pfpStatus: "Rejected",
                                                nftIdeaId: row?._id,
                                              });
                                            }}
                                          >
                                            <Index.ThumbDownOffAltIcon className="icon-btns" />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      </Index.Box>
                                    )
                                  ) : (
                                    <>
                                      <Index.Tooltip title="Inappropriated">
                                        <Index.Button className="icon-btns">
                                          <PagesIndex.DoNotDisturbOnTotalSilenceIcon />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    </>
                                  )}

                                  {
                                    // Master action not available then show button. it is in pending state
                                    !row?.masterAction && (
                                      <Index.Tooltip title="Inappropriate">
                                        <Index.Button
                                          className="icon-btns"
                                          onClick={() => {
                                            openModal({
                                              action: "Inappropriate",
                                              id: row?._id,
                                            });
                                          }}
                                        >
                                          <PagesIndex.DoNotDisturbOnTotalSilenceIcon />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    )
                                  }
                                </Index.Box>
                              </Index.TableCell>
                            )}
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
                {/* </Index.Box> */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Active Deactive Popup Confirmation */}
      <PagesIndex.ConfirmModal
        statusModalOpen={statusModalOpen}
        handleUpdateRecord={pfpIdeasStatusUpdate}
        handleStatusModalClose={handleStatusModalClose}
        message={`Are you sure? Do you really want to ${
          statusData?.pfpStatus === "Approved" ? "Approve" : "Reject"
        } this PFP Idea?`}
      />

      {/* Inappropriate Confirmation */}
      <Index.Modal
        open={modalOpen}
        onClose={closeModal}
        className="category-modal"
      >
        <InappropriateCommentModal
          handleClose={closeModal}
          data={inappropriate}
          setModalOpen={setModalOpen}
          getInappropriateList={getWardenWarningList}
        />
      </Index.Modal>
    </>
  );
}

export default PFPIdeaList;
