import React, { useEffect, useState } from 'react'
import Index from '../../../Index';

import {getActivityLogAction } from '../../../../redux/admin/action';
import PagesIndex from '../../../PagesIndex';
import moment from "moment";



function ActivityLogList() {
  // state declaration
  const [loading, setLoading] = useState(true)
  const [activityLog, setActivityLog] = useState([])
  const [pageData, setPageData] = useState([])
  const [search, setSearch] = useState('')
  const [filterData, setFilteredData] = useState([])

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation()

  // function declaration

  const handleSearch = async (e) => {
    setSearch(e.target.value)
  }
  const  getActivityLogList = () => {
    getActivityLogAction({mainNftId:state?.row},adminToken).then((res) => {
      setActivityLog(res)
      setLoading(false)
      // setPageData(res?.data)
    })
  }

  useEffect(() => {
    getActivityLogList(adminToken)
  }, [])

  // Filter fetched data
  useEffect(() => {
    const filtered = activityLog?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.userId?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.title?.toLowerCase().includes(search.toLowerCase()) ||
        item?.userId?.userName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
        // item..toLowerCase().includes(search.toString())||
        new Date(item?.createdAt)
          ?.toLocaleDateString("en-GB")
          ?.toString()
          .includes(search?.toLowerCase())
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [activityLog, search]);
  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  NFT Activity List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="search">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container"
              className='episap-table-container'

            >
              <Index.Table
                stickyHeader aria-label="sticky table"
                // className="table-design-main barge-table"
                className='episap-table-main'
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">S.No. </Index.TableCell>
                    <Index.TableCell align="left">NFT Title </Index.TableCell>
                    <Index.TableCell align="left">Nft Image </Index.TableCell>
                    <Index.TableCell align="left">Name </Index.TableCell>
                    <Index.TableCell align="left">Username </Index.TableCell>
                    <Index.TableCell align="left">Activity Status</Index.TableCell>
                    <Index.TableCell align="left">Activity Details</Index.TableCell>
                    <Index.TableCell align="left">CreateDate</Index.TableCell>
          
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ?pageData.length ? pageData.map((row) => {
                    return (
                      <Index.TableRow className="">

                        <Index.TableCell align="left" >{row?.sNo}</Index.TableCell>

                        <Index.TableCell align="left">{row?.mainNftId?.title}</Index.TableCell>
                        <Index.TableCell align="left">
                          <img
                            src={row?.imageAddress
                            }
                            alt="Image Preview"
                            style={{
                              height: "60px",
                              width: "80px",
                              display: "table-column",
                            }}
                          />
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          {row?.userId?.fullName}
                        </Index.TableCell>
                        <Index.TableCell align="left">  {row?.userId?.userName}</Index.TableCell>
                        <Index.TableCell align="left">  {row?.activityType}</Index.TableCell>
                        <Index.TableCell align="left">  {row?.details}</Index.TableCell>
                        <Index.TableCell align="left">{moment(row?.createdAt).format("DD/MM/YYYY")} </Index.TableCell>
                    
                      </Index.TableRow>
                    )
                  }) :
                    <PagesIndex.NoRecordFound colSpan={12} />
                    : <PagesIndex.PageLoading colSpan={12} />}

                </Index.TableBody>
                {/* </Index.Box> */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>

  )
}

export default ActivityLogList
