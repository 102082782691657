import { Navigate, Outlet, useLocation } from "react-router-dom";
import PagesIndex from "../container/PagesIndex";

const PrivateRoutes = () => {
  const location = useLocation();
  const { adminDetails } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const isValidToken = (adminToken) => {
    if (!adminToken) return false;
    return true;
  };

  if(isValidToken(adminToken) && adminDetails?.adminType == 0 && location?.pathname?.includes('/admin')){
    return <Outlet /> 
  }else if( isValidToken(adminToken) && adminDetails?.adminType == 1 && location?.pathname?.includes('/warden')){
    return <Outlet /> 
  }else {
    return <Navigate to="/login" />
  }
};

export default PrivateRoutes;
