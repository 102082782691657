import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  activeDeactiveNftAction,
  nftListAction,
  getLeafNodeDetailAction,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import "./NFTList.css";

function NFTList() {
  // state declaration
  const [loading, setLoading] = useState(true);
  const [nftList, setNftList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [leafBranchId, setLeafBranchId] = useState("");

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const navigate = PagesIndex.useNavigate();

  // Leaf tree view function
  const getSingleNodeinfo = () => {
    getLeafNodeDetailAction(leafBranchId, adminToken).then((res) => {
      if (res?.status == 200) {
        setLoading(true);
        // console.log(res.data.mainIdeaId, "Response abc", res.data._id);
        //setViewSingleLeafNode(res.data);
        setLoading(false);

        if (res?.data?.mainIdeaId) {
          navigate("/admin/idea-tree-view", {
            state: { main_Idea_id: res?.data?.mainIdeaId },
          });
        }

        if (!res?.data?.mainIdeaId) {
          navigate("/admin/idea-tree-view", {
            state: { main_Idea_id: res.data._id },
          });
        }
      }
    });
  };
  useEffect(() => {
    if (leafBranchId) {
      getSingleNodeinfo();
    }
  }, [leafBranchId]);

  // function declaration

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const getNftList = () => {
    nftListAction(adminToken).then((res) => {
      setNftList(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getNftList();
  }, []);

  // Active deactive function declaration
  const handleSwitchChange = (event, id) => {
    setLoading(true);
    activeDeactiveNftAction(
      { nftId: id, isActive: event.target.checked },
      adminToken
    ).then((res) => {
      if (res?.status == 200) {
        Index.toast.success(res?.message);
        getNftList();
      }
    });
  };

  // Filter fetched data
  useEffect(() => {
    const filtered = nftList?.filter((item) => {
      return !search
        ? item
        : item?.userId?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
            item?.ideaType?.toLowerCase().includes(search.toLowerCase()) ||
            item?.countWarden?.toString().includes(search.toString()) ||
            (item?.category || []).some((category) =>
              category?.categoryName
                ?.toLowerCase()
                .includes(search.toLowerCase())
            ) ||
            (item?.nftApproved == "Approved"
              ? "Approved"
              : item?.nftApproved == "Inappropriate"
              ? "Inappropriate"
              : "Pending"
            )
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            (item?.masterAction == "Approved"
              ? "Approved"
              : item?.masterAction == "Rejected"
              ? "Rejected"
              : "Pending"
            )
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            (item?.availableOnSale ? "Yes" : "No")
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [nftList, search]);

  // Wardens Status
  const handleWardensStatus = (nftApproved, createdBy, addInPFP, masterActionStatus) => {
    //console.log(status, " =nftApproved , ",  createdBy,  " =createdBy , ",  addInPFP,  " =addInPFP", masterActionStatus, "masterAction" );
    let result;
    if (createdBy === "Expert") {
      result = <string>-</string>;
    } else {
      if (nftApproved === "Pending" && addInPFP === true && (masterActionStatus === 'Approved' || masterActionStatus === 'Rejected')) {
        result = <string style={{ color: "blue" }}>-</string>;
      } else if (nftApproved === "Pending") {
        result = <string style={{ color: "blue" }}>Pending</string>;
      } else if (nftApproved === "Inappropriate") {
        result = <string style={{ color: "red" }}>Inappropriate</string>;
      } else if (nftApproved === "Approved") {
        result = <string style={{ color: "green" }}>Approved</string>;
      } else if (nftApproved === "Rejected") {
        result = <string style={{ color: "red" }}>Rejected</string>;
      } else {
        result = <string>-</string>;
      }
    }
    return result;
  };

  // Master Wardens Status
  const handleMasterWardensStatus = (nftApprovedStatus, pfpStatus, masterActionStatus, createdBy) => {
    let result;
    if (nftApprovedStatus === "Inappropriate" && !masterActionStatus) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (nftApprovedStatus === 'Pending' && createdBy === 'Expert' && masterActionStatus !== "Inappropriate") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (nftApprovedStatus === 'Pending' &&  (pfpStatus == "Approved" || pfpStatus === "Rejected") &&  (masterActionStatus == "Approved" || masterActionStatus === "Rejected")) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (masterActionStatus === "Inappropriate") {
      result = <string style={{ color: "red" }}>Inappropriate</string>;
    } else if (masterActionStatus === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (masterActionStatus === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  return (
    <Index.Box className="page-content-box">
      <Index.Box className="episap-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                NFTs List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="d-flex align-items-center res-set-search">
              <PagesIndex.Search className="searchbar">
                <PagesIndex.StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                />
              </PagesIndex.Search>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="table-box table_scroll">
          <Index.TableContainer
            component={Index.Paper}
            className="episap-table-container"
          >
            <Index.Table
              stickyHeader
              aria-label="sticky table"
              className="episap-table-main"
            >
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell align="left">No</Index.TableCell>
                  <Index.TableCell align="left">NFT Image</Index.TableCell>
                  <Index.TableCell align="left">NFT Title</Index.TableCell>
                  <Index.TableCell align="left">Idea Type</Index.TableCell>
                  <Index.TableCell align="left">Full Name</Index.TableCell>
                  <Index.TableCell align="left">Username</Index.TableCell>
                  <Index.TableCell align="left">Email</Index.TableCell>
                  <Index.TableCell align="left">Action Count</Index.TableCell>
                  <Index.TableCell align="left">Category</Index.TableCell>
                  <Index.TableCell align="left">Wardens Status</Index.TableCell>
                  <Index.TableCell align="left">
                    Master Warden Status
                  </Index.TableCell>
                  {/* <Index.TableCell align="left">PFP days</Index.TableCell> */}
                  <Index.TableCell align="left">
                    Available On Sell
                  </Index.TableCell>
                  <Index.TableCell align="left">Selling Price</Index.TableCell>
                  <Index.TableCell align="left">
                    Created Date & Time
                  </Index.TableCell>
                  <Index.TableCell align="center">Action</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {!loading ? (
                  pageData?.length ? (
                    pageData.map((row) => {
                      return (
                        <Index.TableRow key={row?.sNo} className="">
                          <Index.TableCell>{row?.sNo}</Index.TableCell>
                          <Index.TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/admin/nft-details/${row?._id}`)
                            }
                          >
                            <img
                              src={`${PagesIndex.ImageURL}${row?.displayImage}`}
                              alt="Image Preview"
                              loading="lazy"
                              style={{
                                height: "60px",
                                width: "80px",
                                display: "table-column",
                                borderRadius: "6px",
                              }}
                            />
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/admin/nft-details/${row?._id}`)
                            }
                          >
                            {row.title.length > 18
                              ? row.title.substring(0, 18) + "..."
                              : row.title}
                          </Index.TableCell>

                          <Index.TableCell
                            className="table-td"
                            align="left"
                            // style={{ color: row?.ideaType == "Main Idea" ? "red" : row?.ideaType == "Evolve Idea" ? "Green" : row?.ideaType == "Merge Idea" ? "Blue" : "pink" }}
                          >
                            {/* {row?.ideaType == "Unite Idea" && <Index.Chip color="info" size="small" label={row?.ideaType} />} */}
                            {row?.ideaType == "Main Idea" && (
                              <Index.Chip
                                color="success"
                                size="small"
                                label={row?.ideaType}
                              />
                            )}
                            {row?.ideaType == "Evolve Idea" && (
                              <Index.Chip
                                color="warning"
                                size="small"
                                label={row?.ideaType}
                              />
                            )}
                            {row?.ideaType == "PFP Idea" && (
                              <Index.Chip
                                color="primary"
                                size="small"
                                label={row?.ideaType}
                              />
                            )}
                            {(row?.ideaType == "Merge Idea" ||
                              row?.ideaType == "Unite Idea") && (
                              <Index.Chip
                                color="secondary"
                                size="small"
                                label={"Merge Idea"}
                              />
                            )}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/admin/nft-details/${row?._id}`)
                            }
                          >
                            {row?.userId?.fullName}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {" "}
                            {row?.userId?.userName}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {" "}
                            {row?.userId?.email}
                          </Index.TableCell>
                          <Index.TableCell className="table-td" align="left">
                            {row?.countWarden ? row?.countWarden : 0}
                          </Index.TableCell>

                          <Index.TableCell className="table-td" align="left">
                            {row?.category
                              ? Array.prototype.map
                                  .call(row?.category, function (item) {
                                    return item?.categoryName;
                                  })
                                  .join(", ")
                              : ""}
                          </Index.TableCell>

                          <Index.TableCell className="table-td" align="left">
                            {handleWardensStatus(
                              row?.nftApproved,
                              row?.createdBy,
                              row?.addInPFP,
                              row?.masterAction
                            )}
                          </Index.TableCell>

                          <Index.TableCell className="table-td" align="left">
                            {handleMasterWardensStatus(
                              row?.nftApproved,
                              row?.pfpStatus,
                              row?.masterAction,
                              row?.createdBy
                            )}
                          </Index.TableCell>

                          {/* <Index.TableCell className="table-td" align="left">
                            {row?.pfpDays}
                          </Index.TableCell> */}

                          <Index.TableCell
                            className="table-td"
                            style={{
                              color:
                                row?.availableOnSale == true ? "green" : "red",
                            }}
                            align="left"
                          >
                            {row?.availableOnSale == true ? "Yes" : "No"}
                          </Index.TableCell>
                          <Index.TableCell className="table-td" align="left">
                            {row?.availableOnSale ? row?.price + " SAP" : "N/A"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {moment(row?.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            className="action-btn-icon"
                          >
                            <Index.Box sx={{ display: "flex" }}>
                              <Index.Tooltip
                                title={`${
                                  row.isActive ? "Deactive" : "Active"
                                }`}
                              >
                                <Index.Switch
                                  size="small"
                                  className="toggel-switch"
                                  {...PagesIndex.label}
                                  checked={row.isActive ? true : false}
                                  onChange={(event) => {
                                    handleSwitchChange(event, row._id);
                                  }}
                                />
                              </Index.Tooltip>

                              <Index.Tooltip title="View">
                                <Index.Button
                                  // onClick={() =>
                                  //   navigate("/admin/nft-details", {
                                  //     state: { row },
                                  //   })
                                  // }
                                  onClick={() =>
                                    navigate(`/admin/nft-details/${row?._id}`)
                                  }
                                >
                                  <Index.Visibility className="icon-btns" />
                                </Index.Button>
                              </Index.Tooltip>

                              <Index.Tooltip title="Leaf View">
                                <Index.Button
                                  onClick={() => {
                                    setLeafBranchId(row?._id);
                                    // navigate("/admin/view-single-leaf", {
                                    //   state: { row },
                                    // })
                                  }}
                                >
                                  <Index.PreviewIcon className="icon-btns" />
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <PagesIndex.NoRecordFound colSpan={12} />
                  )
                ) : (
                  <PagesIndex.PageLoading colSpan={12} />
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="pagination-design flex-end">
          <Index.Stack spacing={2}>
            <PagesIndex.Pagination
              fetchData={filterData}
              setPageData={setPageData}
              pageData={pageData}
            />
          </Index.Stack>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default NFTList;
