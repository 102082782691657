import { RingLoader } from "react-spinners";
import Index from "../Index";

const PageLoading = (props) => (
    <Index.TableRow className="loading-row" rowspane={10}>
        <Index.TableCell className="loading-cell" colSpan={props.colSpan} >
            <Index.Box className="loading-spinner" >
                <RingLoader
                    color="#a6ccff"
                    loading="loading"
                    size={50}
                />
            </Index.Box>
        </Index.TableCell>
    </Index.TableRow>
);

export default PageLoading