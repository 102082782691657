import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { expertsApprove, getExpertList } from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";

function UserRequest() {
  // state declaration
  const [loading, setLoading] = useState(true);
  const [expertList, setexpertList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  const navigate = PagesIndex.useNavigate();
  // function declaration

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const getExperts = () => {
    getExpertList(adminToken).then((res) => {
      setexpertList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getExperts();
  }, []);

  // approve reject function
  const approveReject = (data) => {
    setLoading(true);
    expertsApprove(data, adminToken).then((res) => {
      if (res.status === 200) {
        getExperts();
        setLoading(false);
      }
    });
  };

  // Filter fetched data
  useEffect(() => {
    const filtered = expertList?.filter((item) => {
      return !search
        ? item
        : item?.created_by?.userName
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
            item?.created_by?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            (item?.status == "Yes"
              ? "approved"
              : item?.status == "No"
              ? "rejected"
              : "pending"
            )
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.created_by?.mobileNumber
              ?.toString()
              .includes(search?.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [expertList, search]);

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  KYC Requests
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Full Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Mobile Number{" "}
                    </Index.TableCell>
                    <Index.TableCell align="left">Username</Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo} className="">
                            <Index.TableCell>{row?.sNo}</Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.created_by?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.created_by?.countryCode}{" "}
                              {row?.created_by?.mobileNumber}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {" "}
                              {row?.created_by?.userName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              style={{
                                color:
                                  row?.status === "Yes"
                                    ? "green"
                                    : row?.status === "No"
                                    ? "red"
                                    : "blue",
                              }}
                            >
                              {row?.status === "Yes"
                                ? "Approved"
                                : row?.status === "No"
                                ? "Rejected"
                                : row?.status}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="action-btn-icon"
                            >
                              {/* <Index.Box sx={{ display: "flex" }}>
                                {row?.status === "Pending" ||
                                row?.status === "No" ? (
                                  <Index.Tooltip title="Approve">
                                    <Index.Button
                                      onClick={() =>
                                        approveReject({
                                          status: "Yes",
                                          documentId: row?._id,
                                        })
                                      }
                                    >
                                      <Index.ThumbUpOffAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                ) : (
                                  <Index.Tooltip title="Approved">
                                    <Index.Button>
                                      <Index.ThumbUpAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                )}

                                {row?.status === "Pending" ||
                                row?.status === "Yes" ? (
                                  <Index.Tooltip title="Reject">
                                    <Index.Button
                                      onClick={() =>
                                        approveReject({
                                          status: "No",
                                          documentId: row?._id,
                                        })
                                      }
                                    >
                                      <Index.ThumbDownOffAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                ) : (
                                  <Index.Tooltip title="Rejected">
                                    <Index.Button>
                                      <Index.ThumbDownAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                )}

                                {row?.document.length == 1 ? (
                                  <Index.Tooltip title="View Documents">
                                    <a
                                      className="upload-document-img"
                                      href={`${PagesIndex.ImageURL}${row?.document[0]}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Index.SourceIcon className="icon-btns" />
                                    </a>
                                  </Index.Tooltip>
                                ) : (
                                  <Index.Tooltip title="View Documents">
                                    <Index.Button
                                      onClick={() =>
                                        navigate("/admin/view-document", {
                                          state: { document: row?.document },
                                        })
                                      }
                                    >
                                      <Index.SourceIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                )}
                              </Index.Box> */}

                              {/* New code */}
                              {row?.status !== "Pending" ? (
                                row?.status == "Yes" ? (
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Tooltip title="Approved">
                                      <Index.Button>
                                        <Index.ThumbUpAltIcon className="icon-btns" />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.Box>
                                ) : (
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Tooltip title="Rejected">
                                      <Index.Button>
                                        <Index.ThumbDownAltIcon className="icon-btns" />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.Box>
                                )
                              ) : (
                                <Index.Box sx={{ display: "flex" }}>
                                  <Index.Tooltip title="Approve">
                                    <Index.Button
                                      onClick={() =>
                                        approveReject({
                                          status: "Yes",
                                          documentId: row?._id,
                                        })
                                      }
                                    >
                                      <Index.ThumbUpOffAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>

                                  <Index.Tooltip title="Reject">
                                    <Index.Button
                                      onClick={() =>
                                        approveReject({
                                          status: "No",
                                          documentId: row?._id,
                                        })
                                      }
                                    >
                                      <Index.ThumbDownOffAltIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                </Index.Box>
                              )}
                              
                            <Index.Box sx={{ display: "flex" }}>
                              {row?.document.length == 1 ? (
                                <Index.Tooltip title="View Documents">
                                  <a
                                    className="upload-document-img"
                                    href={`${PagesIndex.ImageURL}${row?.document[0]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Index.SourceIcon className="icon-btns" />
                                  </a>
                                </Index.Tooltip>
                              ) : (
                                <Index.Tooltip title="View Documents">
                                  <Index.Button
                                    onClick={() =>
                                      navigate("/admin/view-document", {
                                        state: { document: row?.document },
                                      })
                                    }
                                  >
                                    <Index.SourceIcon className="icon-btns" />
                                  </Index.Button>
                                </Index.Tooltip>
                              )}
                            </Index.Box>

                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default UserRequest;
