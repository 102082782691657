import dashboard from "./images/svg/dashboard.svg";
import logo from "./images/svg/logo.svg";
import filter from "./images/svg/filter.svg";
import close from "./images/svg/close.svg";
import cancel from "./images/svg/cancel.svg";
import righticon from "./images/svg/righticon.svg";
import righticonblue from "./images/svg/righticonblue.svg";


import sidebar1 from "./images/svg/sidebar1.svg";
import sidebar2 from "./images/svg/sidebar2.svg";
import sidebar3 from "./images/svg/sidebar3.svg";
import sidebar4 from "./images/svg/sidebar4.svg";
import sidebar5 from "./images/svg/sidebar5.svg";
import sidebar6 from "./images/svg/sidebar6.svg";
import sidebar7 from "./images/svg/sidebar7.svg";
import sidebar8 from "./images/svg/sidebar8.svg";
import bell from "./images/svg/bell.svg";
import search from "./images/svg/search.svg";
import collapse from "./images/svg/collapse.svg";
import headprofile from "./images/svg/head-profile.svg";
import closeold from "./images/svg/closeold.svg";

import followers from "./images/svg/followers.svg"
import following from "./images/svg/following.svg"

import closeblack from "./images/svg/close-black.svg"
import dashboard2 from "./images/svg/dashboard2.svg";
import userSidebar from "./images/svg/user-sidebar.svg";
import userRequest from "./images/svg/user-request.svg";
import warden from "./images/svg/warden.svg";
import post from "./images/svg/post.svg";
import groups from "./images/svg/people-together.svg";
import amount from "./images/svg/referal-amount.svg";
import pending from "./images/svg/pending.svg";
import employeList from "./images/svg/employe-list.svg";
import category from "./images/svg/category.svg";
import tag from "./images/svg/tag.svg";
import nft from "./images/svg/nft.svg";
import contact from "./images/svg/contact.svg";
import Social from "./images/svg/social-post.svg";
import community from "./images/svg/community.svg";
import block from "./images/svg/block-report.svg";

// New icons

import CompanyLogo from "./images/svg/CompanyLogo";
import CloseIcon from "./images/svg/CloseIcon";
import HumburgerIcon from "./images/svg/HumburgerIcon";
import Dashboard from "./images/svg/Dashboard";
import UserList from "./images/svg/UserList";
import MasterWarden from "./images/svg/MasterWarden";
import UserRequest from "./images/svg/UserRequest";
import Employee from "./images/svg/Employee";
import CategoryList from "./images/svg/CategoryList";
import TagsManagement from "./images/svg/TagsManagement";
import NftList from "./images/svg/NftList";
import ContactList from "./images/svg/ContactList";
import NotificationIcon from "./images/svg/NotificationIcon";
import PfpList from "./images/svg/PfpList";
import SocialList from "./images/svg/SocialList";
import CommunityList from "./images/svg/CommunityList";
import BlockReport from "./images/svg/BlockReport";
import CommunityBlack from "./images/svg/CommunityBlack";
import NftListBlack from "./images/svg/NftListBlack";
import Posts from "./images/svg/Posts";
import WardenListBlack from "./images/svg/WardenListBlack";
import UsersList from "./images/svg/UsersList";
import whiteclose from "./images/svg/whiteclose.svg";
import SetupIcon from "./images/svg/SetupIcon";
import CmsIcon from "./images/svg/CmsIcon";
import TermsConditions from "./images/svg/TermsConditions";
import PrivacyPolicy from "./images/svg/PrivacyPolicy";
import WardenStep from "./images/svg/WardenStep";
import RoleResponsibility from "./images/svg/RoleResponsibility";
import NFTTerm from "./images/svg/NFTTerm";
import NFTPrivacy from "./images/svg/NFTPrivacy";
import ContactComm from "./images/svg/ContactComm";
import AboutUsIcon from "./images/svg/AboutUsIcon";
import AboutUsBlue from "./images/svg/AboutUsBlue";
import blueclose from "./images/svg/blueclose.svg";
import imageSvg from "./images/svg/Image.svg";
import PdfImage from "./images/svg/PdfImage.svg";
import ReportedPost from "./images/svg/ReportedPost";
import Inappropriate from "./images/svg/Inappropriate";
import TransactionHistory from "./images/svg/TransactionHistory";

const Svg = {
  closeblack,
  followers,
  following,
  headprofile,
  bell,
  search,
  dashboard,
  collapse,
  logo,
  filter,
  close,
  cancel,
  righticon,
  righticonblue,

  sidebar1,
  sidebar2,
  sidebar3,
  sidebar4,
  sidebar5,
  sidebar6,
  sidebar7,
  sidebar8,
  closeold,
  dashboard2,
  userSidebar,
  userRequest,
  warden,
  post,
  groups,
  amount,
  pending,
  employeList,
  category,
  tag,
  nft,
  contact,
  Social,
  community,
  block,

  CompanyLogo,
  CloseIcon,
  HumburgerIcon,
  Dashboard,
  UserList,
  MasterWarden,
  UserRequest,
  Employee,
  CategoryList,
  TagsManagement,
  NftList,
  ContactList,
  NotificationIcon,
  PfpList,
  SocialList,
  CommunityList,
  BlockReport,
  CommunityBlack,
  NftListBlack,
  Posts,
  WardenListBlack,
  UsersList,
  whiteclose,
  SetupIcon,
  CmsIcon,
  TermsConditions,
  PrivacyPolicy,
  WardenStep,
  RoleResponsibility,
  NFTTerm,
  NFTPrivacy,
  ContactComm,
  AboutUsIcon,
  AboutUsBlue,
  blueclose,
  imageSvg,
  PdfImage,
  ReportedPost,
  Inappropriate,
  TransactionHistory
};

export default Svg;
