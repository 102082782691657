import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  reportedPostListAction,
  activeDeactiveReportedPostAction,
  deleteSocialPostAction,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import ImageViewExtension from "../../../../components/common/ImageViewExtension";

function ReportedPostList() {
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [reportPostList, setReportPostList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [postId, setPostId] = useState();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageData, setImageData] = useState({});
  const { adminToken, adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  const [openmsgDescription, setOpenmsgDescription] = useState(false);
  const [msgDescription, setmsgDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  const getSocialPost = () => {
    reportedPostListAction(adminToken).then((res) => {
      setReportPostList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSocialPost();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = reportPostList?.filter((item) => {
      return !search
        ? item
        : item?.userId?.userName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item?.reportReason
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item?.postId?.uploadBy?.userName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [reportPostList, search]);

  //Delete post
  const handleDeletePost = () => {
    deleteSocialPostAction({ postId: postId }, adminToken).then((res) => {
      if (res.status == 200) {
        getSocialPost();
        handleDeleteModalClose();
      }
    });
  };

  const handleDeleteModalOpen = (id) => {
    setDeleteModalOpen(!deleteModalOpen);
    setPostId(id);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setPostId();
  };

  // Open single image
  const openSingleImageModal = () => {
    setImageModalOpen(true);
  };
  const closeSingleImageModal = () => {
    setImageModalOpen(false);
    setImageData({});
  };

  // Active deactive function declaration
  const handleSwitchChange = (event, id) => {
    setLoading(true);
    activeDeactiveReportedPostAction(
      { postId: id, isActive: event.target.checked },
      adminToken,
      navigate
    ).then((res) => {
      if (res?.status == 200) {
        Index.toast.success(res?.message);
        getSocialPost();
      }
    });
  };

  const handleClickOpenDescription = (title, description) => {
    setOpenmsgDescription(true);
    setDescriptionTitle(title);
    setmsgDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenmsgDescription(false);
    setDescriptionTitle("");
    setmsgDescription("");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Reported Posts
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Image </Index.TableCell>
                    <Index.TableCell align="left">
                      Report Reason{" "}
                    </Index.TableCell>
                    <Index.TableCell align="left">Posted By </Index.TableCell>
                    <Index.TableCell align="left">Reported By </Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo} className="">
                            <Index.TableCell>{row?.sNo}</Index.TableCell>

                            <Index.TableCell align="left">
                              {/* <img
                                src={`${PagesIndex?.ImageURL}${row?.postId?.uploadPostImage}`}
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              /> */}
                              {row?.postId?.uploadPostImage ? (
                                <ImageViewExtension
                                  imageUrl={row?.postId?.uploadPostImage}
                                  imageTitle="NFT Image"
                                  openSingleImageModal={openSingleImageModal}
                                  setImageData={setImageData}
                                />
                              ) : (
                                "-"
                              )}
                            </Index.TableCell>
                            <Index.TableCell
                              className="lg-box-name"
                              align="left"
                              onClick={() =>
                                handleClickOpenDescription(
                                  "Report Reason",
                                  row?.reportReason
                                )
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              {row?.reportReason &&
                                (row.reportReason?.length > 30
                                  ? row.reportReason.slice(0, 30) + "..."
                                  : row.reportReason)}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.postId?.uploadBy?.userName}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {row?.userId?.userName}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>

                            <Index.TableCell className="action-btn-icon">
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip
                                  title={`${
                                    row?.postId?.isActive ? "Deactive" : "Active"
                                  }`}
                                >
                                  <Index.Switch
                                    size="small"
                                    className="toggel-switch"
                                    {...PagesIndex.label}
                                    checked={row?.postId?.isActive ? true : false}
                                    onChange={(event) => {
                                      handleSwitchChange(event, row?.postId?._id);
                                    }}
                                  />
                                </Index.Tooltip>

                                <Index.Tooltip title="View">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      adminDetails.adminType === 0
                                        ? navigate(
                                            `/admin/reported-social-detail/${row?.postId?._id}`,
                                            { state: { type: "" } }
                                          )
                                        : navigate(
                                            `/warden/reported-social-detail/${row?.postId?._id}`,
                                            { state: { type: "" } }
                                          )
                                    }
                                  >
                                    <Index.Visibility />
                                  </Index.Button>
                                </Index.Tooltip>

                                <Index.Tooltip title="Delete">
                                  <Index.Button
                                    className="icon-btns"
                                    onClick={() =>
                                      handleDeleteModalOpen(row?.postId?._id)
                                    }
                                  >
                                    <Index.DeleteIcon />
                                  </Index.Button>
                                </Index.Tooltip>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.DeleteModal
        deleteOpen={deleteModalOpen}
        handleDeleteRecord={handleDeletePost}
        handleDeleteClose={handleDeleteModalClose}
        deleteMessage={
          "Are you sure? Do you really want to delete this reported post?"
        }
      />

      {/* Single Image show Popup */}
      <Index.Modal
        open={imageModalOpen}
        onClose={closeSingleImageModal}
        className="category-modal"
      >
        <PagesIndex.SingleImageModal
          handleClose={closeSingleImageModal}
          imageData={imageData}
        />
      </Index.Modal>
      {/* End Single Image show Popup */}

      {/* Description Message Popup */}
      <Index.Modal
        open={openmsgDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {msgDescription ? msgDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default ReportedPostList;
