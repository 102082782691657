import React, { useEffect, useState } from "react";
import "./UserDetail.css";
import "./userdetailresponsive.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { getUserDetailListAction } from "../../../../redux/admin/action";
import moment from "moment";
import { PropTypes } from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function UserDetail() {
  // followers modal state declaration
  const { adminProfileData } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [openFollowers, setOpenFollowers] = useState(false);
  const [userDetailData, setUserDetailData] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const [follows, setFollows] = useState([]);
  const [following, setFollowing] = useState([]);
  const [nftListData, setNftListData] = useState([]);
  const [joinedGroup, setJoinedGroup] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [referralData, setReferralData] = useState([]);

  // console.log(adminProfileData, 'adminProfileData')
  // Back Button
  const handleBack = () => {
    if (adminProfileData?.adminType == 1) {
      // Warden
      navigate("/warden/warden-list");
    } else {
      // Admin
      navigate("/admin/user-list");
    }
  };

  // following modal state declaration
  const [openCommunity, setOpenCommunity] = useState(false);
  const [openFollowing, setOpenFollowing] = useState(false);
  const [openNftDescription, setOpenNftDescription] = useState(false);
  const [nftDescription, setNftDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  // followers modal function declaration
  const handleOpenFollowers = () => setOpenFollowers(true);
  const handleCloseFollowers = () => setOpenFollowers(false);

  // following modal function declaration
  const handleOpenFollowing = () => setOpenFollowing(true);
  const handleCloseFollowing = () => setOpenFollowing(false);

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { id } = PagesIndex.useParams();

  const handleClickOpenDescription = (title, description) => {
    setOpenNftDescription(true);
    setDescriptionTitle(title);
    setNftDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenNftDescription(false);
    setDescriptionTitle("");
    setNftDescription("");
  };

  const handleCloseCommunity = () => setOpenCommunity(false);

  const getUserDetailList = () => {
    getUserDetailListAction(id, adminToken, navigate).then((res) => {
      if (res?.status == 200) {
        const userData = res?.data;
        setUserDetailData(userData?.user);
        setFollows(userData?.followerList);
        setFollowing(userData?.followingList);
        // setFollowingListData(userData.followingList.followers);
        setNftListData(userData?.nftDetails);
        setJoinedGroup(userData?.userJoinedGroup);
        setSocialData(userData?.socialMediaList);
        setReferralData(userData?.refferalUsers);
        setUserLoading(false);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getUserDetailList();
  }, []);

  //tab function declaration
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //filter function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };

  useEffect(() => {
    const filtered = (
      value === 0 ? nftListData : value === 1 ? socialData : value === 2 ? joinedGroup : value === 3 ? referralData : null
    )?.filter((item) => {
      return !search
        ? item
        : item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.groupName?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.userName?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
            item?.uploadBy?.userName?.toLowerCase().includes(search.toLowerCase()) || 
            new Date(item?.createdAt)?.toLocaleDateString("en-GB")?.toString().includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [nftListData, socialData, joinedGroup, referralData, search, value]);

  console.log(pageData, 'pageData');

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  User Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="form-back-btn">
                  <Index.Button
                    variant="contained"
                    className="back-btn"
                    type="back"
                    onClick={() => handleBack()}
                  >
                    Back
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Grid container spacing={3}>
            <Index.Grid item xs={12} md={3}>
              <Index.Box className="user-detail-cont">
                <Index.Grid container spacing={2}>
                  <Index.Grid item xs={12} md={12}>
                    <Index.Box className="user-detail-img">
                      <img
                        src={
                          userDetailData?.profile == ""
                            ? PagesIndex.Jpg.dummyImage
                            : `${PagesIndex.ImageURL}${userDetailData?.profile}`
                        }
                        alt=""
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} md={12}>
                    <Index.Grid container>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          First Name
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData?.fullName
                            ? userDetailData?.fullName
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Email ID
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData?.email ? userDetailData?.email : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Mobile No
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData?.countryCode}{" "}
                          <span>{userDetailData?.mobileNumber}</span>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Username
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData?.userName
                            ? userDetailData?.userName
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Categories
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData?.category
                            ? Array.prototype.map
                                .call(
                                  userDetailData?.category,
                                  function (item) {
                                    return item?.categoryName;
                                  }
                                )
                                .join(", ")
                            : ""}
                        </Index.Typography>
                      </Index.Box>
                      
                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Address
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          {userDetailData?.address
                            ? userDetailData?.address
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-detail">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-title"
                        >
                          Wallet Address
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="user-detail-subtitle"
                        >
                          <Index.Tooltip title={userDetailData?.walletAddress}>
                            {userDetailData?.walletAddress
                              ? `${userDetailData?.walletAddress?.substring(
                                  0,
                                  3
                                )}.....${userDetailData?.walletAddress?.substring(
                                  userDetailData?.walletAddress?.length - 3
                                )}`
                              : "-"}
                          </Index.Tooltip>
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="follow-card">
                        <Index.Box
                          className="follow-card-cont"
                          onClick={handleOpenFollowers}
                        >
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Svg.followers} alt="" />
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-title"
                            >
                              Followers
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-subtitle"
                            >
                              {/* {follows[0]?.followers?.length} */}
                              {follows && follows[0]?.followers
                                ? follows[0].followers.length
                                : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          className="follow-card-cont"
                          onClick={handleOpenFollowing}
                        >
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Svg.following} alt="" />
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-title"
                            >
                              Following
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="user-detail-subtitle"
                            >
                              {/* {following[0]?.following?.length} */}
                              {following && following[0]?.following
                                ? following[0].following.length
                                : 0}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        {/* <Index.Box className="follow-card-cont">
                          <Index.Box className="follow-icon-wrap">
                            <img src={PagesIndex.Svg.following} alt="" />
                          </Index.Box>
                          <Index.Box className="user-detail-card">
                            <Index.Typography variant="p" component="p" className="user-detail-title">
                              Community
                            </Index.Typography>
                            <Index.Typography variant="p" component="p" className="user-detail-subtitle">
                              {joinedGroup?.length ? joinedGroup?.map((group, index) => {
                                return (
                                  <Index.Box
                                    className="community"
                                    key={index}
                                    // onClick={() => handleOpenCommunity(index)}
                                    onClick={() => handleOpenCommunity(group)}
                                  >
                                    <Index.Tooltip title={group?.groupName}
                                      // title={`Community ${index + 1}`}
                                      arrow>
                                      <img src={group?.groupIcon == '' ? PagesIndex.Jpg.userprofile : group?.groupIcon} alt="" />
                                    </Index.Tooltip>
                                  </Index.Box>
                                );
                              }) : <p className="user-detail-subtitle">{following.length}</p>}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box> */}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={12} md={9}>
              <Index.Box className="table-wrape pl-0 pr-0">
                <Index.Box className="tab-back-btn">
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="admin-tabs-main"
                  >
                    <Index.Tab
                      label="NFT List"
                      {...a11yProps(0)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Social Post List"
                      {...a11yProps(1)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Community Groups"
                      {...a11yProps(2)}
                      className="admin-tab"
                    />
                    <Index.Tab
                      label="Referral"
                      {...a11yProps(3)}
                      className="admin-tab"
                    />
                  </Index.Tabs>
                </Index.Box>
                <Index.Box className="episap-common-box pl-0 pr-0">
                  <Index.Box className="title-header">
                    <Index.Box className="title-header-flex res-title-header-flex">
                      <Index.Box className="title-main">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title page-sub-title"
                        >
                          {value === 0
                            ? "NFT List"
                            : value === 1
                            ? "Social Post List"
                            : value === 2 
                            ? "Community"
                            : value === 3
                            ? "Referral Users"
                            : ""
                          }
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="d-flex align-items-center res-set-search">
                        <PagesIndex.Search className="searchbar">
                          <PagesIndex.StyledInputBase
                            placeholder="Search"
                            inputProps={{ "aria-label": "search" }}
                            onChange={handleSearch}
                            type="text"
                          />
                        </PagesIndex.Search>
                        {/* <Index.TextField
                      size="small"
                      placeholder="Search"
                      className="search"
                      onChange={(e) => setSearchData(e.target.value)}
           
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="table-box table_scroll">
                    <Index.TableContainer
                      component={Index.Paper}
                      // className="table-container gridtable"
                      className="episap-user-details-table"
                    >
                      {value === 0 ? (
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="episap-table-main contact-table user-details"
                        >
                          <Index.TableHead>
                            <Index.TableRow>
                              <Index.TableCell align="left">No</Index.TableCell>
                              <Index.TableCell align="left">
                                NFT Name
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Image
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Created By
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Created Date & Time
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Description
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody>
                            {!loading ? (
                              pageData.length ? (
                                pageData.map((row) => (
                                  <Index.TableRow key={row._id}>
                                    <Index.TableCell align="left">
                                      {row.sNo}
                                    </Index.TableCell>

                                    <Index.TableCell align="left">
                                      {row.title}
                                    </Index.TableCell>

                                    {value === 0 ? (
                                      <Index.TableCell align="left">
                                        <img
                                          src={`${PagesIndex.ImageURL}${row?.displayImage}`}
                                          alt="Image Preview"
                                          style={{
                                            height: "60px",
                                            width: "80px",
                                            display: "table-column",
                                          }}
                                        />
                                      </Index.TableCell>
                                    ) : null}
                                    <Index.TableCell align="left">
                                      {row?.uploadBy?.userName}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {moment(row?.createdAt).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleClickOpenDescription(
                                          "NFT",
                                          row?.descriptions
                                        )
                                      }
                                    >
                                      <Index.Tooltip title={"View Description"}>
                                        {row?.descriptions &&
                                          (row?.descriptions?.length > 15
                                            ? row?.descriptions?.slice(0, 15) +
                                              "..."
                                            : row?.descriptions)}
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PagesIndex.NoRecordFound colSpan={12} />
                              )
                            ) : (
                              <PagesIndex.PageLoading colSpan={12} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      ) : value === 1 ? (
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="episap-table-main contact-table user-details"
                        >
                          <Index.TableHead>
                            <Index.TableRow>
                              <Index.TableCell align="left">No</Index.TableCell>

                              <Index.TableCell align="left">
                                Post Image
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Description
                              </Index.TableCell>
                              {/* <Index.TableCell align="left">
                                Created By
                              </Index.TableCell> */}

                              <Index.TableCell align="left">
                                Created Date & Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody>
                            {!loading ? (
                              pageData.length ? (
                                pageData.map((row) => (
                                  <Index.TableRow key={row._id}>
                                    <Index.TableCell align="left">
                                      {row.sNo}
                                    </Index.TableCell>
                                    {value === 1 ? (
                                      <Index.TableCell align="left">
                                        <img
                                          src={`${PagesIndex.ImageURL}${row?.uploadPostImage}`}
                                          alt="Image Preview"
                                          style={{
                                            height: "60px",
                                            width: "80px",
                                            display: "table-column",
                                          }}
                                        />
                                      </Index.TableCell>
                                    ) : null}
                                    <Index.TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleClickOpenDescription(
                                          "Social Post",
                                          row?.description
                                        )
                                      }
                                    >
                                      <Index.Tooltip title={"View Description"}>
                                        {row?.description &&
                                          (row?.description?.length > 25
                                            ? row?.description?.slice(0, 25) +
                                              "..."
                                            : row?.description)}
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    {/* <Index.TableCell align="left">
                                      {row?.uploadBy?.userName}
                                    </Index.TableCell> */}
                                    <Index.TableCell align="left">
                                      {moment(row?.createdAt).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PagesIndex.NoRecordFound colSpan={12} />
                              )
                            ) : (
                              <PagesIndex.PageLoading colSpan={12} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      ) : value === 2 ? (
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="episap-table-main contact-table user-details"
                        >
                          <Index.TableHead>
                            <Index.TableRow>
                              <Index.TableCell align="left">No</Index.TableCell>
                              <Index.TableCell align="left">
                                Community Name
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Image
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Created By
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Created Date & Time
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Description
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody>
                            {!loading ? (
                              pageData.length ? (
                                pageData.map((row) => (
                                  <Index.TableRow key={row._id}>
                                    <Index.TableCell align="left">
                                      {row?.sNo}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {row?.groupName}
                                    </Index.TableCell>

                                    {value === 2 ? (
                                      <Index.TableCell align="left">
                                        <img
                                          src={
                                            row?.groupIcon == ""
                                              ? PagesIndex.Jpg.dummyImage
                                              : `${PagesIndex.ImageURL}${row?.groupIcon}`
                                          }
                                          alt="Image Preview"
                                          style={{
                                            height: "60px",
                                            width: "80px",
                                            display: "table-column",
                                          }}
                                        />
                                      </Index.TableCell>
                                    ) : null}
                                    <Index.TableCell align="left">
                                      {row?.owner?.fullName}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {moment(row?.createdAt).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleClickOpenDescription(
                                          "Community",
                                          row?.description
                                        )
                                      }
                                    >
                                      <Index.Tooltip title={"View Description"}>
                                        {row?.description &&
                                          (row?.description?.length > 15
                                            ? row?.description?.slice(0, 15) +
                                              "..."
                                            : row?.description)}
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PagesIndex.NoRecordFound colSpan={12} />
                              )
                            ) : (
                              <PagesIndex.PageLoading colSpan={12} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      ) : value === 3 ? (
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="episap-table-main contact-table user-details"
                        >
                          <Index.TableHead>
                            <Index.TableRow>
                              <Index.TableCell align="left">No</Index.TableCell>
                              <Index.TableCell align="left">
                                Full Name
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Username
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Email
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Created Date & Time
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody>
                            {!loading ? (
                              pageData.length ? (
                                pageData.map((row) => (
                                  <Index.TableRow key={row._id}>
                                    <Index.TableCell align="left">
                                      {row.sNo}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {row?.userId?.fullName}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {row?.userId?.userName}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {row?.userId?.email}
                                    </Index.TableCell>
                                    <Index.TableCell align="left">
                                      {moment(row?.createdAt).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PagesIndex.NoRecordFound colSpan={12} />
                              )
                            ) : (
                              <PagesIndex.PageLoading colSpan={12} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      ) : ""}
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-design flex-end">
                    <Index.Stack spacing={2}>
                      <PagesIndex.Pagination
                        fetchData={filterData}
                        setPageData={setPageData}
                        pageData={pageData}
                      />
                    </Index.Stack>
                  </Index.Box>
                  {/* table end */}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>

          <Index.Grid container spacing={3}>
            <Index.Grid item xs={12} md={12}></Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
      <>
        {/* followers modal start */}
        <Index.Modal
          open={openFollowers}
          onClose={handleCloseFollowers}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Followers
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseFollowers}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="modal-header title-main">
              <Index.Typography
                id="modal-modal-title"
                className="page-title"
                variant="h6"
                component="h2"
              >
                Followers
              </Index.Typography>
              <Index.Box className="edit-modal-circle-main">
                <img
                  src={PagesIndex.Svg.close}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseFollowers}
                />
              </Index.Box>
            </Index.Box> */}

            <Index.Box className="input-design-div admin-design-div login-input-design-div">
              <Index.Box className="follow-list-section">
                {follows?.map((follow) => {
                  return follow.followers.map((data) => {
                    return (
                      <Index.Box
                        className="list modal-total-views"
                        key={data._id}
                      >
                        <Index.Box className="image-main-wrapper">
                          <Index.Box className="image-wrape">
                            <img
                              className="user-profile-view"
                              src={
                                data.profile
                                  ? `${PagesIndex.ImageURL}${data.profile}`
                                  : PagesIndex.Jpg.userprofile
                              }
                              alt="loading..."
                            />
                          </Index.Box>
                          <Index.Box className="user-detail-title">
                            <Index.Typography className="user-detail-title">
                              {data?.fullName}
                            </Index.Typography>
                            <Index.Typography className="user-detail-subtitle">
                              {data?.userName}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    );
                  });
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* followers modal end */}

        {/* following modal start */}
        <Index.Modal
          open={openFollowing}
          onClose={handleCloseFollowing}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        Following
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseFollowing}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-design-div admin-design-div login-input-design-div">
              <Index.Box className="follow-list-section">
                {following.map((follow) => {
                  return follow.following.map((data) => {
                    return (
                      <Index.Box className="list modal-total-views">
                        <Index.Box className="image-main-wrapper">
                          <Index.Box className="image-wrape">
                            {/* <img src={PagesIndex.Jpg.userprofile} alt="" /> */}
                            <img
                              className="user-profile-view"
                              src={
                                data.profile
                                  ? `${PagesIndex.ImageURL}${data.profile}`
                                  : PagesIndex.Jpg.userprofile
                              }
                            />
                          </Index.Box>
                          <Index.Box className="user-detail-title">
                            <Index.Typography className="user-detail-title">
                              {data?.fullName}
                            </Index.Typography>
                            <Index.Typography className="user-detail-subtitle">
                              {data?.userName}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    );
                  });
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* following modal end */}

        {/* community modal start */}
        <Index.Modal
          open={openCommunity && openCommunity}
          onClose={handleCloseCommunity}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {communityData?.groupName}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseCommunity}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="modal-header title-main">
              <Index.Typography
                id="modal-modal-title"
                className="page-title"
                variant="h6"
                component="h2"
              >
                Community Name
                {communityData?.groupName}
              </Index.Typography>
              <Index.Box className="edit-modal-circle-main">
                <img
                  src={PagesIndex.Svg.close}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseCommunity}
                />
              </Index.Box>
            </Index.Box> */}

            <Index.Box className="modal-body ">
              <Index.Box className="community-detail">
                <Index.Box className="group-image-wrape">
                  <Index.Box className="image-wrape">
                    <img
                      src={
                        communityData?.groupIcon == ""
                          ? PagesIndex.Jpg.userprofile
                          : communityData?.groupIcon
                      }
                      alt=""
                      className="img-wraper-img"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="group-detail-wrape">
                  <Index.Typography className="" component="h6" variant="h6">
                    Group More Detail
                    <Index.Box className="" component="span" variant="span">
                      {" "}
                      {/* (243 Members) */}({communityData?.members?.length}{" "}
                      Members)
                    </Index.Box>
                  </Index.Typography>
                  <Index.Typography className="" component="p" variant="p">
                    {/* Group description */}
                    <Index.Tooltip title={communityData?.description}>
                      {communityData?.description &&
                        (communityData?.description?.length > 40
                          ? communityData?.description?.slice(0, 40) + "..."
                          : communityData?.description)}
                    </Index.Tooltip>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="follow-list-section">
                {communityData?.members?.length > 0 &&
                  communityData?.members?.map((data) => {
                    return (
                      <Index.Box className="list" key={data._id}>
                        <Index.Box
                          className="image-wrape"
                          sx={{ marginLeft: "10px" }}
                        >
                          <img
                            src={
                              data?.userId?.profile == ""
                                ? PagesIndex.Jpg.userprofile
                                : data?.userId?.profile
                            }
                            alt=""
                          />
                        </Index.Box>
                        <Index.Box className="user-detail-box">
                          <Index.Box className="detail-text">
                            {data?.userId?.fullName}
                          </Index.Box>
                          <Index.Box component="span" variant="span">
                            {data?.userId?.userName}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* community modal end */}

        {/* Nft description show modal start */}
        <Index.Modal
          open={openNftDescription}
          onClose={handleCloseDescription}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={PagesIndex.style} className="episap-modal-box">
            <Index.Box className="episap-modal-header">
              <Index.Box className="edit-modal-circle-main">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {descriptionTitle} Description
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="user-circle-img">
                  <img
                    src={PagesIndex.Svg.whiteclose}
                    className="modal-close-icon user-circle-img"
                    onClick={handleCloseDescription}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-design-div admin-design-div login-input-design-div">
              <Index.Box className="follow-list-section">
                <Index.FormHelperText>
                  {nftDescription ? nftDescription : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
        {/* Nft description modal end */}
      </>
    </>
  );
}

export default UserDetail;
