import React, { useState, useEffect } from "react";
import "./Login.css";
import "./Login.responsive.css";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { adminLogin } from "../../../../redux/admin/action";
import {messaging} from  '../../../../firebase';
import {getToken } from 'firebase/messaging'
import CircularProgress from '@mui/material/CircularProgress';

function Login() {
  // state declaration
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setDeviceToken]=useState("");

  // Initital values
  let initialValues = {
    email: "",
    password: "",
  };

  // functions declaration
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleLoginFormSubmit = (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(adminLogin(values, navigate, token))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    console.log(permission, 'permission');
    if (permission == "granted") {
      var token = await getToken(messaging, {
        vapidKey:
          "BI2Zgmpskp4NGZKEvpssTREHgrq87ld2-oajmTcn__MnAj6oghFeG-A59sIq965YGupfvTlHo7HSh0oPllY2lzM"
      });

      setDeviceToken(token);
      console.log("FCM Token: ", token);
    } else if (permission === "denied") {
      alert("You denied for the notification");
      setLoading(false);
    }
  }
  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(false);
    }
  }, [token]);

  return (
    <div>
      <Index.Box className="admin-main">
        <Index.Box className="admin-main-bg">
          <Index.Box className="auth-main">
            {/* <Index.Box className="auth-left">
            <img src={PagesIndex.Png.logo} alt="logo" className="" />
          </Index.Box> */}
            <Index.Box className="auth-right">
              <Index.Box className="auth-common-box">
                <Index.Box className="auth-header">
                  <Index.Box className="logo-name">
                    <img src={PagesIndex.Png.LogoName} alt="logo" className="" />
                  </Index.Box>
                  {/* <Index.Box className="auth-title">
                    Login
                  </Index.Box> */}
                  {/* <Index.Box className="auth-subtitle">
                    Please enter your credentials to Login
                  </Index.Box> */}
                </Index.Box>

                <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleLoginFormSubmit}
                  initialValues={initialValues}
                  validationSchema={PagesIndex.validationSchemaLogin}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="auth-body">
                        <Index.Grid container spacing={3}>
                          {/* Email */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Email
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Email"
                                variant="filled"
                                className="form-input login"
                                name="email"
                                data-testid="email-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                // onFocus={handleFocus}
                                value={values.email}
                                onChange={(e) => { 
                                  setFieldValue('email', e.target.value.toLowerCase().trim());
                                }}
                                helperText={touched.email && errors.email}
                                error={Boolean(errors.email && touched.email)}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* Password */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Password
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Password"
                                variant="filled"
                                className="form-input login"
                                name="password"
                                data-testid="password-input"
                                aria-label="password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props",
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                                // onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(
                                  errors.password && touched.password
                                )}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* Forgot Password */}
                          <Index.Grid item xs={6}>
                            <Index.Box
                              className="box-login-text forgot bluebox-text forgot_password"
                            >
                              <PagesIndex.Link to="/admin/forgot-password" className="auth-link forgot-password">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                >
                                  Forgot password?
                                </Index.Typography>
                              </PagesIndex.Link>
                            </Index.Box>
                          </Index.Grid>

                          {/* Button */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="auth-btn-box">
                              <Index.Button
                                type="submit"
                                data-testid="button"
                                variant="contained"
                                disabled={(loading == true || isSubmitting == true) ? true : false}
                                className="login-disable"
                              >
                                {
                                  (loading == true || isSubmitting == true) ? (
                                    <CircularProgress size={20} />
                                  ) : ("Login")
                                }
                              </Index.Button>
                            </Index.Box>
                          </Index.Grid>

                        </Index.Grid>
                      </Index.Box>
                    </Index.Stack>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

    </div>
  );
}

export default Login;
