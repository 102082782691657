import React, { useState, useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../../container/PagesIndex";
import {
  adminLogOutAction,
  getAdminProfile,
  adminNotificationListAction,
} from "../../../redux/admin/action";
import { Badge } from "@mui/material";
import { ImageURL } from "../../../config/DataService";
import { getMessaging, deleteToken } from 'firebase/messaging';

export default function Header(props) {
  const { adminProfileData, adminToken, notifications } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  const navigate = Index.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [profile, setProfile] = useState("");
  const [notificationCounts, setNotificationCounts] = useState("0");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotification = (event) => {
    navigate("notification");
  };

  useEffect(() => {
    if (notifications) {
      const countVal = notifications.filter(
        (item) => item.readUnread === false
      ).length;
      setNotificationCounts(countVal);
    }
  }, [notifications]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const messaging = getMessaging();
    deleteToken(messaging).then(res=>{
      console.log("fcm token remove",res);
      dispatch(adminLogOutAction(navigate));
    }).catch(err=>{
      console.log("fcm token err",err);
    })
  };

  useEffect(() => {
    dispatch(getAdminProfile(adminToken));
    dispatch(adminNotificationListAction(adminToken, navigate));
  }, []);

  return (
    <Index.Box className="admin-header">
      <Index.Box className="admin-header-left">
        <Index.Button
          className="btn nav-menu-btn"
          onClick={() => {
            props.setOpen(!props.open);
          }}
        >
          <Index.Svg.HumburgerIcon />
        </Index.Button>
        <Index.Svg.CompanyLogo />
      </Index.Box>
      <Index.Box className="admin-header-right">
        {adminProfileData && adminProfileData?.adminType != 1 && (
          <Index.Button className="btn icon-box" onClick={handleNotification}>
            <Badge
              badgeContent={notificationCounts ? `${notificationCounts}` : "0"}
              color={notifications?.length ? "secondary" : "primary"}
            >
              <Index.Svg.NotificationIcon />
            </Badge>
          </Index.Button>
        )}

        <Index.Box className="profile-drop-main">
          <Index.Box className="header-user-detail">
            <Index.Button
              className="btn profile-drop-btn"
              onClick={handleClick}
            >
              <Index.Box className="user-profile-btn"></Index.Box>
              <Index.Box className="user-profile-btn">
                <img
                  src={
                    profile
                      ? URL.createObjectURL(profile)
                      : adminProfileData?.profile !== ""
                      ? `${ImageURL}${adminProfileData?.profile}`
                      : PagesIndex.Jpg.dummyImage
                  }
                  className="header-profile"
                  alt="Image Preview"
                />
              </Index.Box>
              <Index.Typography variant="p" className="head-user-title">
                {adminProfileData?.fullName}
              </Index.Typography>
            </Index.Button>
          </Index.Box>

          <Index.Menu
            className="btn profile-drop-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <PagesIndex.Link
              to={`${
                adminProfileData?.adminType == 0 ? "/admin" : "/warden"
              }/update-profile`}
            >
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                }}
                className="profile-drop-menuitem"
              >
                Profile
              </Index.MenuItem>
            </PagesIndex.Link>

            <PagesIndex.Link
              to={`${
                adminProfileData?.adminType == 0 ? "/admin" : "/warden"
              }/change-password`}
            >
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                }}
                className="profile-drop-menuitem"
              >
                Change Password
              </Index.MenuItem>
            </PagesIndex.Link>

            <Index.MenuItem
              onClick={() => {
                handleLogout();
              }}
              className="profile-drop-menuitem"
            >
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
