import axios from "axios";
import Index from "../container/Index";

// const API_ENDPOINT = "http://localhost:3031/api/";
// const API_ENDPOINT = "http://35.177.56.74:3031/api/";
const API_ENDPOINT =  "https://api.episap.appworkdemo.com/api/";
// const API_ENDPOINT = "http://localhost:3031/api/"; // localhost
export const ImageURL = "https://api.episap.appworkdemo.com/public/media/";



/// local host
// const API_ENDPOINT = "https://shiny-barnacle-j6pvg69gpjp257xv-3031.app.github.dev/api/";
// export const ImageURL = "https://special-space-goldfish-r95qx9wx6543xvg5-3031.app.github.dev/public/media/";

const DataServices = (token) => {
  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      // "Content-type": "application/x-www-form-urlencoded",
      auth: token,
    },
  });
};
export default DataServices;

// get call
export const doGet = async (url, token, navigate) => {
  try {
    const response = await DataServices(token).get(url);
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    } else if (error?.response?.data?.status === 401) {
      Index.toast.error(error?.response?.data?.message);
    }
    if (error?.response?.data?.status == 500) {
        // console.log('500 server error');
        navigate("/server-error");
    }
  }
};
// get single user data
export const doGetSingleUser = async (url, id, token, navigate) => {
  try {
    const response = await DataServices(token).get(`${url}/${id}`);
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
    if (error?.response?.data?.status == 500) {
      navigate("/server-error");
    }
  }
};

// get Params id and fetch data
export const doGetParams = async (url, id, token) => {
  try {
    const response = await DataServices(token).get(`${url}?id=${id}`);
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
    // if (error?.response?.data?.status == 500) {
    //   navigate("/server-error");
    // }
  }
};

//Post Call
export const doPost = async (url, data, navigate, path) => {
  try {
    const response = await DataServices().post(url, data);
    if (response?.data?.status === 200 || 201) {
      Index.toast.success(response?.data?.message);
      navigate(response?.data?.data[0]?.findAdmin?.adminType == 0 ? `/admin/${path}` : `/warden/${path}`);
      return response;
    }
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

//PostWithState Call
export const doPostWithState = async (url, data, navigate, path) => {
  try {
    const response = await DataServices().post(url, data);
    if (response?.data?.status === 201 || 200) {
      Index.toast.success(response?.data?.message);
      if(path == '/admin/otp-verify'){
        let newData = {...response.data.data[0], data};
        navigate(path, { state: newData });
      } else {
        navigate(path, { state: response.data.data[0] });
      }
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

//AuthPost Call
export const doPostAuth = async (url, data, token, navigate, path) => {
  try {
    const response = await DataServices(token).post(url, data);
    if (response?.data?.status === 201 || 200) {
      Index.toast.success(response?.data?.message);
      const timer = setTimeout(() => {
        navigate(path);
      }, 5000);
      return () => clearTimeout(timer);
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status == 400) {
      Index.toast.error("Sorry your link is expired.");
    } else {
      Index.toast.error(error?.response?.data?.message);
    }
    const timer = setTimeout(() => {
      navigate(path);
    }, 5000);
    return () => clearTimeout(timer);
  }
};

//Post form data
export const doPostFormData = async (url, data, token) => {
  try {
    const response = await DataServices(token).post(url, data);
    if (response?.status === 201 || 200) {
      Index.toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

//Post form data
export const doPostFormDataWithoutToast = async (url, data, token) => {
  try {
    const response = await DataServices(token).post(url, data);
    if (response?.status === 201 || 200) {
      // Index.toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

//Post data and get Call
export const doPostDataAndGet = async (url, data, token, navigate) => {
  try {
    const response = await DataServices(token).post(url, data);
    // if (response?.status === 201 || 200) {
    //   // Index.toast.success(response.data.message);
    // }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
    if (error?.response?.data?.status == 500) {
      navigate("/server-error");
    }
  }
};
//get data in tag management
export const doGetData = async (url, token) => {
  try {
    const response = await DataServices(token).get(url);
    if (response?.status === 200) {
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};



//Update Call
export const doUpdate = async (url, id, data, navigate, path) => {
  try {
    const response = await DataServices().post(`${url}${id}`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    if (response.data.status === 200) {
      Index.toast.success(response?.data?.message);
      const timer = setTimeout(() => {
        navigate(path);
      }, 1000);
      return () => clearTimeout(timer);
      
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

//Delete by Params Call
export const doDelete = async (url, id) => {
  try {
    const response = await DataServices().post(`${url}${id}`);
    if (response?.data?.status === 200) {
      Index.toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

//Delete by Body Call
export const doDeleteBody = async (url, id) => {
  try {
    const response = await DataServices().post(url, { id: id });
    if (response?.data?.status === 200) {
      Index.toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    if (error?.response?.data?.status != 401) {
      Index.toast.error(error?.response?.data?.message);
    }
  }
};

// get call
export const doLogOut = async (url, token) => {
  try {
    const response = await DataServices(token).get(url);

    return response;
  } catch (error) {
    return error
    // console.log(error,'response');
    // Index.toast.error(error?.response?.data?.message);
  }
};