import profile from './images/Jpg/profile.jpg';
import userprofile from './images/Jpg/profile_user.jpg';
import dummyImage from "./images/Jpg/dummy-image.jpg";
import videoImg from "./images/Jpg/play-video.jpg";
import preview from "./images/Jpg/preview.jpg";
import companyNewLogo from "./images/Jpg/companyNewLogo.jpg";
import userModalImg from "./images/Jpg/userModalImg.jpg";
import addcategory from "./images/Jpg/addcategory.jpg"

const Jpg = {
    profile,
    userprofile,
    dummyImage,
    videoImg,
    preview,
    companyNewLogo,
    userModalImg,
    addcategory
}

export default Jpg;