import { formatDistanceToNow } from 'date-fns';

const customFormatDistanceToNow = (date, options) => {
  const timeAgo = formatDistanceToNow(date, options);
  // Replace "less than a minute" with "just now"
  if (timeAgo.includes('less than a minute')) {
    return 'Just now';
  }
  return timeAgo.replace(/^about /, ''); // Remove the "about" prefix
};

const TimeAgo = ({ date }) => {
  const timeAgo = customFormatDistanceToNow(new Date(date), { addSuffix: true });
  return <span>{timeAgo}</span>;
};

export default TimeAgo;