import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation } from "react-router-dom";
import { resetPasswordAction } from "../../../../redux/admin/action";
import "./ResetPassword.css";

function ResetPassword() {
  // state  declaration
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = PagesIndex.useNavigate();
  const { state } = useLocation();

  // Initital values declaration
  let initialValues = {
    password: "",
    confirm_password: "",
  };

  // function declaration
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = (values) => {
    setLoading(true);
    resetPasswordAction(values, state, navigate, setLoading);
  };

  return (
    <div>
      <Index.Box className="admin-main admin-main-bg">
        <Index.Box className="admin-main-bg">
          <Index.Box className="auth-main">
            <Index.Box className="auth-right">
              <Index.Box className="auth-common-box">
                <Index.Box className="auth-header">
                  <Index.Box className="logo-name">
                    <img
                      src={PagesIndex.Png.LogoName}
                      alt="logo"
                      className=""
                    />
                  </Index.Box>
                  <Index.Box className="auth-title auth-logo-title">
                    Reset Password
                  </Index.Box>
                  {/* <Index.Box className="auth-subtitle">
                    Please enter your new password
                  </Index.Box> */}
                </Index.Box>

                <PagesIndex.Formik
                  // enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={PagesIndex.validationSchemaResetPassword}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="auth-body">
                        <Index.Grid container spacing={3}>
                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Enter your new password
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                className="form-input login"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Enter your new password"
                                name="password"
                                type={showNewPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props",
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(
                                  errors.password && touched.password
                                )}
                                // sx={{ mb: 3 }}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        className="new-password-toggle"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        edge="end"
                                      >
                                        {showNewPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12}>
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Enter your confirm password
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                className="form-input login"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Enter your confirm password"
                                name="confirm_password"
                                type={showConfirmPassword ? "text" : "password"}
                                autoComplete="off"
                                inputProps={{
                                  className: "input_props",
                                }}
                                InputLabelProps={{ className: "add-formlabel" }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                                onBlur={handleBlur}
                                value={values.confirm_password}
                                onChange={handleChange}
                                helperText={
                                  touched.confirm_password &&
                                  errors.confirm_password
                                }
                                error={Boolean(
                                  errors.confirm_password &&
                                    touched.confirm_password
                                )}
                                sx={{ mb: 3 }}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        className="confirm-password-toggle"
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <Index.Visibility />
                                        ) : (
                                          <Index.VisibilityOff />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  ),
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* Button */}
                          <Index.Grid item xs={12}>
                            <Index.Box className="auth-btn-box">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disableRipple
                                disabled={loading}
                              >
                                Submit
                              </Index.Button>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>

                        {/* <Index.Box
                          className="box-login-text forgot bluebox-text back-link-box"
                          sx={{ mt: 3 }}
                        >
                          <PagesIndex.Link to="/">
                            <Index.Box className='back-wraper'>
                              < Index.KeyboardBackspaceIcon className="back-icon" />
                              <Index.Typography className="auth-link"
                                variant="body1"
                                component="p"
                              >
                                Back to Login
                              </Index.Typography>
                            </Index.Box>
                          </PagesIndex.Link>
                        </Index.Box> */}
                      </Index.Box>
                    </Index.Stack>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default ResetPassword;
