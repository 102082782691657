import type from "./type";

const initialState = {
  isAdminLogin: false,
  adminDetails: {},
  adminToken: "",
  adminProfileData: {},
  notifications: [],
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.ADMIN_LOGOUT:
      return {
        ...state,
        // initialState
        isAdminLogin: false,
        adminDetails: {},
        adminToken: "",
        adminProfileData: {},
        notifications: [],
      };

    case type.ADMIN_LOGIN:
      return {
        ...state,
        adminDetails: action.payload[0].findAdmin,
        isAdminLogin: true,
        adminToken: action.payload[0].JwtToken,
      };
    case type.ADMIN_GET_UPDATE_DATA:
      return {
        ...state,
        adminProfileData: action.payload[0],
      };
    case type.ADMIN_GET_PUSH_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};
export default AdminReducer;
