import React, { useEffect, useRef, useState } from 'react'
import Index from '../../../Index';
import { resendOtpAction, userotpVerifyAction } from '../../../../redux/admin/action';
import PagesIndex from '../../../PagesIndex';
// import { style } from './UserModal.Style';

export const OtpVerify = ({ handleCaterSelect, verifyId, handleClose }) => {
    // state declaration
    const [remainingMinutes, setRemainingMinutes] = useState(0);
    const [remainingSeconds, setRemainingSeconds] = useState(0);

    // const declaration
    const intervalRef = useRef(null);
    const formRef = useRef();
    const userData = JSON.parse(localStorage.getItem("addUserData"));

    // Initial values
    let initialValues = {
        emailOtp: "",
        id: verifyId ? verifyId : '',
    };

    useEffect(() => {
        setRemainingSeconds(60)
    }, [])

    const resendOtp = () => {
        setRemainingMinutes(0);
        setRemainingSeconds(60);
        resendOtpAction({ email: userData.email, mobileNumber: userData.mobileNumber })
    };

    useEffect(() => {
        if (remainingMinutes === 0 && remainingSeconds === 0) {
            clearInterval(intervalRef.current);
        }
    }, [remainingMinutes, remainingSeconds]);

    useEffect(() => {
        if (remainingMinutes === 0 && remainingSeconds === 0) {
            clearInterval(intervalRef.current);
        } else {
            intervalRef.current = setInterval(() => {
                if (remainingSeconds > 0) {
                    setRemainingSeconds((prevSeconds) => prevSeconds - 1);
                } else {
                    if (remainingMinutes > 0) {
                        setRemainingMinutes((prevMinutes) => prevMinutes - 1);
                        setRemainingSeconds(59);
                    }
                }
            }, 1000);
        }

        return () => clearInterval(intervalRef.current);
    }, [remainingMinutes, remainingSeconds]);

    const handleFormSubmit = (values) => {
        console.log("Values : ", values);
        const data = {
            emailOtp: +values.emailOtp,
            id: values.id
        }
        console.log("Data : ", data);
        userotpVerifyAction(data).then((res) => {
            if (res?.status == 200) {
                console.log("Success : ", res);
                handleCaterSelect(res?.data?._id)
            } else {
                console.log('else  part');
                formRef.current.setFieldValue('emailOtp', '');
            }
        })
    }
    return (
        <Index.Box className="page-content-box">
            <Index.Box className="episap-modal-box">
                <Index.Box className="episap-modal-header">
                    <Index.Box className="edit-modal-circle-main">
                        <Index.Box className="title-header">
                            <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                                <Index.Box className="title-main">
                                    <Index.Typography variant="p" component="p" className="page-title">
                                        Profile Verification
                                    </Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="user-circle-img" onClick={handleClose}>
                            <img src={PagesIndex.Svg.whiteclose} className="user-circle-img" alt="icon" />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="input-design-div admin-design-div login-input-design-div p-0">
                    <PagesIndex.Formik
                        enableReinitialize
                        innerRef={(f) => (formRef.current = f)}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={PagesIndex.addUserOtpVerifySchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Index.Stack
                                component="form"
                                spacing={2}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <Index.Box className='register-all-inputs'>
                                    <Index.Typography variant="p" component='p' className='page-sub-title mb-5'>
                                        Enter the OTP sent to Your Email.
                                    </Index.Typography>
                                    {/* <Index.Box
                                        autoComplete="off"
                                        className="reg-input reg-otp"
                                    >
                                        <Index.Typography className="re-input-label" variant="label">Enter OTP Sent to {formattedPhoneNumber}</Index.Typography>
                                        <Index.OtpInput
                                            name="otp"
                                            inputStyle="inputStyle txt-black"
                                            numInputs={4}
                                            inputType="number"
                                            renderSeparator={<span> </span>}
                                            value={values.otp}
                                            onChange={(file) => setFieldValue("otp", file)}
                                            error={Boolean(errors.otp)}
                                            className="otp-design-div otp-placeholder"
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <span className="error-text" >{touched.otp && errors.otp}</span>
                                        <Index.Typography className="acc-already-txt resend-otm-timer" variant="p" component='p'>
                                            Resend OTP in <Index.Typography>{remainingMinutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</Index.Typography>
                                        </Index.Typography>
                                    </Index.Box> */}

                                    <Index.Box
                                        autoComplete="off"
                                        className="reg-input reg-otp"
                                    >
                                        <Index.Typography className="re-input-label" variant="label">Enter OTP Sent to {userData.email} </Index.Typography>
                                        <Index.OtpInput
                                            name="emailOtp"
                                            inputStyle="inputStyle txt-black"
                                            numInputs={4}
                                            inputType="number"
                                            renderSeparator={<span> </span>}
                                            value={values.emailOtp}
                                            onChange={(file) => setFieldValue("emailOtp", file)}
                                            error={Boolean(errors.emailOtp)}
                                            className="otp-design-div otp-placeholder"
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <span className="error-text" >{touched.emailOtp && errors.emailOtp}</span>
                                        <Index.Typography className="acc-already-txt resend-link" variant="p" component="p">
                                        <Index.Typography className="acc-already-txt resend-otm-timer" variant="p" component='p'>
                                            Resend OTP in <Index.Typography>{remainingMinutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</Index.Typography>
                                        </Index.Typography>
                                            <Index.Link
                                                aria-disabled={remainingSeconds != 0}
                                                onClick={remainingSeconds != 0 ? undefined : resendOtp}
                                                style={{ cursor: remainingSeconds != 0 ? 'not-allowed' : 'pointer' }}
                                            >
                                                Resend OTP
                                            </Index.Link>
                                        </Index.Typography>

                                    </Index.Box>
                                    <Index.Box className='modal-footer-btn'>
                                        <Index.Button variant="contained" className="submit-btn" type='submit'>
                                            Submit
                                        </Index.Button>
                                    </Index.Box>
                                </Index.Box>


                            </Index.Stack>
                        )}
                    </PagesIndex.Formik>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}
