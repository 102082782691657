import React, { useState } from "react";
// import "./CategoryManagementModal.css";
// import "./CategoryManagementModal.responsive.css";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { addWarden } from "../../../../redux/admin/action";

const AddWardenModal = (props) => {
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { setModalOpen, handleClose, inviteWardenList } = props;

  // Initital values
  const initialValues = {
    email: "",
  };
  // functions declaration
  const handleFormSubmit = (values) => {
    addWarden(values, adminToken).then((res) => {
      if (res?.status == 200) {
        inviteWardenList();
        setModalOpen(false);
      }
    });
  };

  const handleFocus = () => {
    // setLoading(false);
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Invite Warden
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box onClick={handleClose}>
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="user-circle-img"
                  alt="icon"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.AddWardenSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="Email"
                    variant="filled"
                    type="email"
                    className="admin-input-design input-placeholder searchbar"
                    name="email"
                    data-testid="title-input"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value.toLowerCase());
                    }}
                  />
                  <span className="error-text">
                    {errors.email && errors.email}
                  </span>

                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                      // disableRipple
                      // disabled={loading}
                    >
                      Send
                    </Index.Button>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AddWardenModal;
