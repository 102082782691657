import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import PagesIndex from "../../../PagesIndex";
import { useState } from "react";
import { ImageURL } from "../../../../config/DataService";
import { updateProfileAction } from "../../../../redux/admin/action";

const Item = styled(Index.Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
}));

function Profile() {
  // state declaration
  const [profile, setProfile] = useState("");
  const [fileType, setFileType] = useState("");
  const dispatch = PagesIndex.useDispatch();

  // const declaration
  const { adminToken, adminProfileData } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );

  // initial value declaration
  const initialValues = {
    email: adminProfileData ? adminProfileData?.email : "",
    fullName: adminProfileData ? adminProfileData?.fullName : "",
    mobileNumber: adminProfileData ? adminProfileData?.mobileNumber : "",
    profile: adminProfileData ? adminProfileData?.profile : "",
  };

  //function declaration
  const handleFocus = () => {
    // setLoading(false);
  };
  const handleProfileChange = (e) => {
    if (e.target.files.length != 0) {
      setProfile(e.target.files[0]);
      const type = e.target.files[0].type;
      const typeArry = type?.split('/');
      setFileType(typeArry[0])
    }
  };

  const userProfileFormSubmit = (values) => {
    const formData = new FormData();    
    formData.append("fullName", values.fullName);
    formData.append("email", values.email);
    formData.append("mobileNumber", values.mobileNumber);
    formData.append("profile", values.profile ? values.profile : adminProfileData?.profile);
    dispatch(updateProfileAction(formData, adminToken));
  };
  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <PagesIndex.Formik
            // enableReinitialize
            onSubmit={userProfileFormSubmit}
            initialValues={initialValues}
            validationSchema={PagesIndex.validationSchemaProfile}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form
                spacing={2}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <Index.Grid container spacing={3}>
                  <Index.Grid item xs={12} md={12}>
                    <Index.Box className="user-detail-cont">
                      <Index.Grid container spacing={2}>
                        <Index.Grid item xs={12}>
                          <Index.Box className="title-header">
                            <Index.Box className="title-header-flex res-title-header-flex">
                              <Index.Box className="title-main cms-heading">
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="page-title"
                                >
                                  Update Profile
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} md={4}>
                          <Index.Box className="user-detail-img">
                            <img
                              src={
                                (profile && fileType === 'image')
                                  ? URL.createObjectURL(profile)
                                  : adminProfileData?.profile !== ""
                                  ? `${ImageURL}${adminProfileData?.profile}`
                                  : PagesIndex.Jpg.dummyImage
                              }
                              alt="Image Preview"
                              className="profile-img"
                            />
                          </Index.Box>
                          <Index.Box className="common-button grey-button change-profile res-set-search">
                            <Index.Button variant="contained" type="button">
                              Change Profile
                            </Index.Button>
                            <input
                              type="file"
                              className="change-profile-input"
                              accept="image/jpeg"
                              name="profile"
                              // onChange={handleProfileChange}
                              onChange={(e) => {
                                handleProfileChange(e);
                                setFieldValue(
                                  "profile",
                                  e?.target?.files[0]
                                    ? e?.target?.files[0]
                                    : values.profile
                                );
                              }}
                            />
                          </Index.Box>
                          <span className="error-text d-block text-center mr-0">
                            {errors.profile && errors.profile}
                          </span>
                        </Index.Grid>
                        <Index.Grid item xs={12} md={8}>
                          <Index.Box className="profile-inner">
                            <Index.Box className="">
                              <Index.Grid
                                container
                                columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                              >
                                <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                                  <Item className="dashboard-item">
                                    <Index.Box>
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="change-input-label"
                                      >
                                        Name
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border mb-15">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder=""
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          name="fullName"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          value={values.fullName}
                                          onChange={handleChange}
                                          helperText={
                                            touched.fullName && errors.fullName
                                          }
                                          error={Boolean(
                                            errors.fullName && touched.fullName
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Item>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                                  <Item className="dashboard-item">
                                    <Index.Box>
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Email
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border mb-15">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder=""
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          name="email"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          value={values.email}
                                          onChange={handleChange}
                                          helperText={
                                            touched.email && errors.email
                                          }
                                          error={Boolean(
                                            errors.email && touched.email
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Item>
                                </Index.Grid>
                                <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                                  <Item className="dashboard-item">
                                    <Index.Box>
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        Mobile Number
                                      </Index.Typography>
                                      <Index.Box className="input-design-div with-border mb-15">
                                        <Index.TextField
                                          hiddenLabel
                                          id="filled-hidden-label-normal"
                                          placeholder=""
                                          variant="filled"
                                          className="admin-input-design input-placeholder"
                                          name="mobileNumber"
                                          type="number"
                                          onBlur={handleBlur}
                                          onFocus={handleFocus}
                                          value={values.mobileNumber}
                                          onChange={handleChange}
                                          helperText={
                                            touched.mobileNumber &&
                                            errors.mobileNumber
                                          }
                                          error={Boolean(
                                            errors.mobileNumber &&
                                              touched.mobileNumber
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Item>
                                </Index.Grid>
                              </Index.Grid>
                              <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                                <Index.Button variant="contained" type="submit">
                                  Update Profile
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Profile;
