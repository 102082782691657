import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { userContactListAction } from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import RemarkModal from "./RemarkModal";

function ContactUsList() {
  // state declaration
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState(true);
  const [userContactList, setuserContactList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState("");

  const [openmsgDescription, setOpenmsgDescription] = useState(false);
  const [msgDescription, setmsgDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");

  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);

  // function declaration
  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const userContact = () => {
    userContactListAction(adminToken).then((res) => {
      setuserContactList(res?.data);
      setLoading(false);
      // setPageData(res?.data)
    });
  };

  useEffect(() => {
    userContact();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = userContactList?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.userId?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
            ` ${item?.userId?.countryCode}${item?.userId?.mobileNumber}`
              ?.toString()
              .includes(search?.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [userContactList, search]);

  const closeModal = () => {
    setOpenModal(false);

    setUserId("");
  };

  const handleClickOpenDescription = (title, description) => {
    setOpenmsgDescription(true);
    setDescriptionTitle(title);
    setmsgDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenmsgDescription(false);
    setDescriptionTitle("");
    setmsgDescription("");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Contact Us Requests
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>

                {/* <Index.Button
                  variant="contained"
                  className="add-user-btn"
                  onClick={() => {
                    setUserEditData();
                    handleAddUser();
                  }}
                >
                  Add user
                </Index.Button> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="table-box table_scroll">
            <Index.TableContainer
              component={Index.Paper}
              // className="table-container gridtable"
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">No </Index.TableCell>
                    <Index.TableCell align="left">Full Name</Index.TableCell>
                    <Index.TableCell align="left">Username</Index.TableCell>
                    <Index.TableCell align="left">Subject</Index.TableCell>
                    <Index.TableCell align="left">Message </Index.TableCell>
                    <Index.TableCell align="left">
                      Created Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* <Index.Box> */}
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo}>
                            <Index.TableCell align="left">
                              {row.sNo}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.userId?.fullName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.userId?.userName}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.Tooltip title={row?.subject}>
                                {row?.subject &&
                                  (row.subject?.length > 15
                                    ? row.subject.slice(0, 15) + "..."
                                    : row.subject)}
                              </Index.Tooltip>
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              onClick={() =>
                                handleClickOpenDescription(
                                  "Message",
                                  row?.message
                                )
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              <Index.Tooltip>
                                {row?.message &&
                                  (row?.message?.length > 15
                                    ? row?.message.slice(0, 15) + "..."
                                    : row?.message)}
                              </Index.Tooltip>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>
                         
                            <Index.TableCell
                              align="left"
                              onClick={() =>
                                handleClickOpenDescription(
                                  "Remark",
                                  row?.remarks
                                )
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              <Index.Tooltip>
                                {row?.remarks ?
                                  (row?.remarks?.length > 15
                                    ? row?.remarks.slice(0, 15) + "..."
                                    : row?.remarks) : "-"}
                              </Index.Tooltip>
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              style={{
                                color:
                                  row?.requestStatus === "Completed"
                                    ? "green"
                                    : row?.requestStatus === "In Progress"
                                    ? "blue"
                                    : row?.requestStatus === "Pending"
                                    ? "blue"
                                    : "red",
                              }}
                            >
                              {row?.requestStatus}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                              className="action-btn-icon"
                            >
                              <Index.Box sx={{ display: "flex" }}>
                                <Index.Tooltip title="View">
                                  <Index.Button
                                    onClick={() =>
                                      navigate(
                                        `/admin/contact-detail/${row?._id}`
                                      )
                                    }
                                  >
                                    <Index.Visibility className="icon-btns" />
                                  </Index.Button>
                                </Index.Tooltip>
                                {row?.requestStatus !== "Completed" && (
                                  <Index.Tooltip title="Status Update">
                                    <Index.Button
                                      disabled={
                                        row?.requestStatus == "Completed"
                                          ? true
                                          : false
                                      }
                                      onClick={() => (
                                        setOpenModal(!openModal),
                                        setUserId({
                                          id: row?._id,
                                          status: row?.requestStatus,
                                        })
                                      )}
                                    >
                                      <Index.ReplyIcon className="icon-btns" />
                                    </Index.Button>
                                  </Index.Tooltip>
                                )}
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
                {/* </Index.Box> */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openModal}
        // onClose={closeModal}
        className="category-modal"
      >
        <RemarkModal
          handleClose={closeModal}
          userId={userId}
          setOpenModal={setOpenModal}
          setUserId={setUserId}
          userContact={userContact}
        />
      </Index.Modal>

      <Index.Modal
        open={openmsgDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={PagesIndex.style} className="episap-modal-box">
          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {descriptionTitle ? descriptionTitle : "Message"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-circle-img">
                <img
                  src={PagesIndex.Svg.whiteclose}
                  className="modal-close-icon user-circle-img"
                  onClick={handleCloseDescription}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <Index.Box className="follow-list-section">
              <Index.FormHelperText>
                {msgDescription ? msgDescription : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default ContactUsList;
