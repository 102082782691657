import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getMasterWardenExperNftList,
  updateExpertNftStatus,
} from "../../../../redux/admin/action";
import PagesIndex from "../../../PagesIndex";
import moment from "moment";
import "../nftIdea/NftIdeaList.css";
import InappropriateCommentModal from "../../../../components/common/InappropriateCommentModal";

function ExpertIdeaList() {
  // state declaration
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nftList, setNftList] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [type, setType] = useState("");
  const [inappropriate, setInappropriate] = useState({});
  const { adminToken, adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();

  useEffect(() => {
    if (adminDetails?.adminType == 1) {
      setType("warden");
    } else {
      setType("admin");
    }
  }, [adminDetails]);

  // Active Inactive popup
  const handleStatusModalOpen = (data) => {
    setStatusModalOpen(!statusModalOpen);
    setStatusData(data);
  };
  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
    setStatusData({});
  };

  // Active deactive function declaration
  const nftStatusUpdate = () => {
    setLoading(true);
    handleStatusModalClose();
    updateExpertNftStatus(statusData, adminToken).then((res) => {
      if (res?.status == 200) {
        getExpertNftList();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value.trim());
  };
  const getExpertNftList = () => {
    getMasterWardenExperNftList(adminToken).then((res) => {
      setNftList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getExpertNftList();
  }, []);

  // Filter fetched data
  useEffect(() => {
    const filtered = nftList?.filter((item) => {
      // debugger;
      return !search
        ? item
        : item?.userId?.fullName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.ideaType?.toLowerCase().includes(search.toLowerCase()) ||
            (item?.category || []).some((category) =>
              category?.categoryName
                ?.toLowerCase()
                .includes(search.toLowerCase())
            ) ||
            item?.masterAction?.toLowerCase().includes(search.toLowerCase()) ||
            item?.adminAction?.toLowerCase().includes(search.toLowerCase()) ||
            item?.userId?.userName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.userId?.email?.toLowerCase().includes(search.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase());
    });
    if (filtered) {
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [nftList, search]);

  // Inappropriate action popup
  const openModal = (data) => {
    setModalOpen(true);
    setInappropriate(data);
  };
  const closeModal = () => {
    setModalOpen(false);
    setInappropriate({});
  };

  // Master Wardens Status
  // const handleMasterWardensStatus = (masterActionStatus) => {
  //   let result;
  //   if (!masterActionStatus || masterActionStatus === "Pending") {
  //     result = <string style={{ color: "blue" }}>Pending</string>;
  //   } else if (masterActionStatus === "Inappropriate") {
  //     result = <string style={{ color: "red" }}>Inappropriate</string>;
  //   } else if (masterActionStatus === "Approved") {
  //     result = <string style={{ color: "green" }}>Approved</string>;
  //   } else if (masterActionStatus === "Rejected") {
  //     result = <string style={{ color: "red" }}>Rejected</string>;
  //   } else {
  //     result = <string>-</string>;
  //   }
  //   return result;
  // };

  const handleMasterWardensStatus = (nftApprovedStatus, pfpStatus, masterActionStatus, createdBy) => {
    let result;
    if (nftApprovedStatus === "Inappropriate" && !masterActionStatus) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (masterActionStatus === "Inappropriate") {
      result = <string style={{ color: "red" }}>Inappropriate</string>;
    } else if (masterActionStatus === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (masterActionStatus === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else if (nftApprovedStatus === 'Pending' && createdBy === 'Expert' && masterActionStatus !== "Inappropriate") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (nftApprovedStatus === 'Pending' &&  (pfpStatus == "Approved" || pfpStatus === "Rejected") &&  (masterActionStatus == "Approved" || masterActionStatus === "Rejected")) {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  // Admin Action Status
  const handleAdminActionStatus = (status, masterAction) => {
    let result;
    if (
      (status === "Pending" && masterAction === "Approved") ||
      masterAction === "Rejected"
    ) {
      result = <string>-</string>;
    } else if (masterAction === "Inappropriate" && status === "Pending") {
      result = <string style={{ color: "blue" }}>Pending</string>;
    } else if (status === "Approved") {
      result = <string style={{ color: "green" }}>Approved</string>;
    } else if (status === "Rejected") {
      result = <string style={{ color: "red" }}>Rejected</string>;
    } else {
      result = <string>-</string>;
    }
    return result;
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Expert Ideas
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <PagesIndex.Search className="searchbar">
                  <PagesIndex.StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearch}
                  />
                </PagesIndex.Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-box table_scroll cus-table-scroll">
            <Index.TableContainer
              component={Index.Paper}
              className="episap-table-container"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="episap-table-main master-warden-nft-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="table-th" align="left">
                      No
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      NFT Image{" "}
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      NFT Title{" "}
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Full Name{" "}
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Idea Type
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Username{" "}
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Email
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Category
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Master Warden Status
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Admin Action
                    </Index.TableCell>
                    <Index.TableCell className="table-th" align="left">
                      Created Date & Time
                    </Index.TableCell>
                    {adminDetails?.adminType == 1 && (
                      <Index.TableCell className="table-th" align="center">
                        Action
                      </Index.TableCell>
                    )}
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {!loading ? (
                    pageData?.length ? (
                      pageData.map((row) => {
                        return (
                          <Index.TableRow key={row?.sNo} className="">
                            <Index.TableCell className="table-td">
                              {row?.sNo}
                            </Index.TableCell>
                            <Index.TableCell
                              className="table-td"
                              align="left"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/${type}/expert-nft-details/${row?._id}`,
                                  {
                                    state: { row },
                                  }
                                )
                              }
                            >
                              <img
                                src={
                                  row?.displayImage
                                    ? `${PagesIndex.ImageURL}${row?.displayImage}`
                                    : ""
                                }
                                alt="Image Preview"
                                style={{
                                  height: "60px",
                                  width: "80px",
                                  display: "table-column",
                                  borderRadius: "6px",
                                }}
                              />
                            </Index.TableCell>

                            <Index.TableCell
                              className="table-td"
                              align="left"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/${type}/expert-nft-details/${row?._id}`,
                                  {
                                    state: { row },
                                  }
                                )
                              }
                            >
                              {row?.title.length > 18
                                ? row?.title.substring(0, 18) + "..."
                                : row?.title}
                            </Index.TableCell>

                            <Index.TableCell
                              className="table-td"
                              align="left"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/${type}/expert-nft-details/${row?._id}`,
                                  {
                                    state: { row },
                                  }
                                )
                              }
                            >
                              {row?.userId?.fullName
                                ? row?.userId?.fullName
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {row?.ideaType == "Main Idea" && (
                                <Index.Chip
                                  color="success"
                                  size="small"
                                  label={row?.ideaType}
                                />
                              )}
                              {row?.ideaType == "Evolve Idea" && (
                                <Index.Chip
                                  color="warning"
                                  size="small"
                                  label={row?.ideaType}
                                />
                              )}
                              {row?.ideaType == "PFP Idea" && (
                                <Index.Chip
                                  color="primary"
                                  size="small"
                                  label={row?.ideaType}
                                />
                              )}
                              {(row?.ideaType == "Merge Idea" ||
                                row?.ideaType == "Unite Idea") && (
                                <Index.Chip
                                  color="secondary"
                                  size="small"
                                  label={"Merge Idea"}
                                />
                              )}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {row?.userId?.userName
                                ? row?.userId?.userName
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {row?.userId?.email ? row?.userId?.email : "-"}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {row?.category
                                ? Array.prototype.map
                                    .call(row?.category, function (item) {
                                      return item?.categoryName;
                                    })
                                    .join(", ")
                                : ""}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {/* {handleMasterWardensStatus(row?.masterAction)} */}
                              {handleMasterWardensStatus(
                              row?.nftApproved,
                              row?.pfpStatus,
                              row?.masterAction,
                              row?.createdBy
                            )}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {handleAdminActionStatus(
                                row?.adminAction,
                                row?.masterAction
                              )}
                            </Index.TableCell>

                            <Index.TableCell className="table-td" align="left">
                              {moment(row?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </Index.TableCell>

                            {adminDetails?.adminType == 1 && (
                              <Index.TableCell
                                className="table-td"
                                align="left"
                              >
                                <Index.Box sx={{ display: "flex" }}>
                                  <Index.Tooltip title="View">
                                    <Index.Button
                                      className="icon-btns"
                                      onClick={() =>
                                        navigate(
                                          `/${type}/expert-nft-details/${row?._id}`,
                                          {
                                            state: { row },
                                          }
                                        )
                                      }
                                    >
                                      <Index.Visibility />
                                    </Index.Button>
                                  </Index.Tooltip>

                                  {row?.masterAction !== "Inappropriate" ? (
                                    row?.masterAction == "Rejected" ? (
                                      <Index.Tooltip title="Downvote">
                                        <Index.Button>
                                          <Index.ThumbDownAltIcon className="icon-btns" />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    ) : row?.masterAction == "Approved" ? (
                                      <Index.Tooltip title="Upvote">
                                        <Index.Button>
                                          <Index.ThumbUpAltIcon className="icon-btns" />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    ) : (
                                      <Index.Box sx={{ display: "flex" }}>
                                        <Index.Tooltip title="Upvote">
                                          <Index.Button
                                            onClick={() => {
                                              handleStatusModalOpen({
                                                masterAction: "Approved",
                                                id: row?._id,
                                              });
                                              // nftStatusUpdate({ masterAction: "Approved", id: row?._id });
                                            }}
                                          >
                                            <Index.ThumbUpOffAltIcon className="icon-btns" />
                                          </Index.Button>
                                        </Index.Tooltip>
                                        <Index.Tooltip title="Downvote">
                                          <Index.Button
                                            onClick={() => {
                                              handleStatusModalOpen({
                                                masterAction: "Rejected",
                                                id: row?._id,
                                              });
                                            }}
                                          >
                                            <Index.ThumbDownOffAltIcon className="icon-btns" />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      </Index.Box>
                                    )
                                  ) : (
                                    <>
                                      <Index.Tooltip title="Inappropriated">
                                        <Index.Button className="icon-btns">
                                          <PagesIndex.DoNotDisturbOnTotalSilenceIcon />
                                        </Index.Button>
                                      </Index.Tooltip>
                                    </>
                                  )}

                                  {
                                    // Master action not available then show button. it is in pending state
                                    !row?.masterAction &&
                                      type == "warden" && ( // Type warden then only Inappropriate button will show
                                        <Index.Tooltip title="Inappropriate">
                                          <Index.Button
                                            className="icon-btns"
                                            onClick={() => {
                                              openModal({
                                                action: "Inappropriate",
                                                id: row?._id,
                                              });
                                            }}
                                          >
                                            <PagesIndex.DoNotDisturbOnTotalSilenceIcon />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      )
                                  }
                                </Index.Box>
                              </Index.TableCell>
                            )}
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <PagesIndex.NoRecordFound colSpan={12} />
                    )
                  ) : (
                    <PagesIndex.PageLoading colSpan={12} />
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <PagesIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.ConfirmModal
        statusModalOpen={statusModalOpen}
        handleUpdateRecord={nftStatusUpdate}
        handleStatusModalClose={handleStatusModalClose}
        message={`Are you sure? Do you really want to ${
          statusData?.masterAction === "Approved" ? "Upvote" : "Downvote"
        } this Expert Idea?`}
      />

      {/* Inappropriate Confirmation */}
      <Index.Modal
        open={modalOpen}
        onClose={closeModal}
        className="category-modal"
      >
        <InappropriateCommentModal
          handleClose={closeModal}
          data={inappropriate}
          setModalOpen={setModalOpen}
          getInappropriateList={getExpertNftList}
        />
      </Index.Modal>
    </>
  );
}

export default ExpertIdeaList;
