import React, { useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { sendWardenWarningAction } from "../../../../redux/admin/action";

const WardenWarningModel = (props) => {
  // const declaration
  const { adminToken } = PagesIndex.useSelector((state) => state.AdminReducer);
  const { setModalOpen, handleClose, data, getWardenActions } = props;

  // Initital values
  const initialValues = {
    comment: "",
    ideaId: data?.ideaId,
    wardenId: data?.wardenId,
    ideaActionId: data?.ideaActionId
  };

  // functions declaration
  const handleFormSubmit = (values) => {
    sendWardenWarningAction(values, adminToken).then((res) => {
      if (res?.status == 200) {
        setModalOpen(false);
        getWardenActions();
      }
    });
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="episap-modal-box">

          <Index.Box className="episap-modal-header">
            <Index.Box className="edit-modal-circle-main">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex category-modal-header">
                  <Index.Box className="title-main">
                    <Index.Typography variant="p" component="p" className="page-title">
                      Send Warning
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box onClick={handleClose}>
                <img src={PagesIndex.Svg.whiteclose} className="user-circle-img" alt="icon" />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-design-div admin-design-div login-input-design-div">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.wardenWarningSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Index.Stack
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box className="input-textarea">
                    <Index.TextareaAutosize aria-label="empty textarea" placeholder="Warning Message"
                      multiline
                      name="comment"
                      onBlur={handleBlur}
                      value={values.comment}
                      // onChange={handleChange}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/\s\s+/g, " ")
                          .replace(/\n\s*/g, "\n");
                        setFieldValue("comment", newValue);
                      }}
                    />
                  </Index.Box>

                  <span className="error-text">
                    {touched.comment && errors.comment}
                  </span>
                  <Index.Box className="orange-btn login-btn login-btn-main">
                    <Index.Button
                      type="submit"
                      data-testid="button"
                      variant="contained"
                    // disableRipple
                    // disabled={loading}
                    >
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default WardenWarningModel;
